<template>
    <button class="px-3 py-1.5 font-proxima rounded" :class="buttonClass">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        buttonClass: { type: String }
    }
}
</script>