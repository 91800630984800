import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import LitepieDatepicker from 'litepie-datepicker';
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

createApp(App)
    .use(store)
    .use(router)
    .use(VueToast)
    .use(LitepieDatepicker)
    .use(VCalendar, {})
    .use(VueLoading)
    .mount('#app')
