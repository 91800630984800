<template>
    <div>
        <div class="fixed w-full z-20">

            <!--------------->
            <!--- Top Bar --->
            <!--------------->
            <Disclosure as="nav" class="bg-diginesia" v-slot="{ open }">
                <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div class="relative flex items-center justify-between h-16">
                        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
                            <!-- Mobile menu button-->
                            <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span class="sr-only">Open main menu</span>
                                <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                                <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
                            </DisclosureButton>
                        </div>
                        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <a href="/" class="flex-shrink-0 flex items-center">
                                <img class="mr-1" width="35" src="@/assets/images/logo/logo-gold.png" alt="SatuStock" />
                                <!-- <img class="hidden lg:block h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg" alt="Workflow" /> -->
                                <h1 class="text-white text-xl font-bold font-diginesia">SatuStock <span class="text-xs text-yellow-500" v-if="profileData.role === 'VENDOR'">Seller Center</span></h1>
                            </a>
                        </div>
                        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <!-- Cart - Mobile -->
                            <button v-if="profileData.role === 'RESELLER'" @click="$router.push('/cart')" class="ml-2 relative p-1 rounded-full text-gray-200 hover:text-white focus:outline-none md:block hidden">
                                <span class="sr-only">View carts</span>
                                <ShoppingCartIcon class="h-6 w-6" aria-hidden="true" />
                                <span class="absolute bottom-5 left-5 rounded-full w-4 h-4 p-0 m-0 text-gray-800 font-mono text-sm leading-tight text-center bg-yellow-500">{{ total_item ? total_item : totalCartItem }}</span>
                            </button>

                            <!-- Notification - Mobile -->
                            <Menu as="div" class="ml-1 relative">
                                <div>
                                    <button @click="$router.push('/order/list')" class="ml-2 relative p-1 rounded-full text-gray-200 hover:text-white focus:outline-none">
                                        <span class="sr-only">View notifications</span>
                                        <BellIcon class="h-6 w-6" aria-hidden="true" />
                                        <span class="flex absolute -mt-7 ml-3">
                                            <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-400 opacity-75"></span>
                                            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                        </span>
                                    </button>
                                    <!-- <MenuButton class="p-1 rounded-full text-gray-200 hover:text-white focus:outline-none">
                                        <span class="sr-only">View notifications</span>
                                        <BellIcon class="h-6 w-6" aria-hidden="true" />
                                        <span class="flex absolute -mt-7 ml-3">
                                            <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-400 opacity-75"></span>
                                            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                        </span>
                                    </MenuButton> -->
                                </div>
                                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                    <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-proxima font-semibold">
                                        <MenuItem v-slot="{ active }">
                                            <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Akun Saya</a>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }">
                                            <a @click="handleLogout" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer']">Keluar</a>
                                        </MenuItem>
                                    </MenuItems>
                                </transition>
                            </Menu>

                            <!-- Profile dropdown -->
                            <Menu as="div" class="ml-3 relative z-20">
                                <div>
                                    <MenuButton class="flex text-sm font-medium text-gray-200 hover:text-white rounded-full focus:outline-none">
                                        <span class="sr-only">Open user menu</span>
                                        <h4 class="font-proxima md:block hidden">{{ profileData.fullName }} 
                                            <span class="text-xs" v-if="profileData.role === 'ADMIN'">(Admin)</span>
                                            <span class="text-xs" v-if="profileData.role === 'VENDOR'">(Brand Owner/Supplier)</span>
                                            <span class="text-xs" v-if="profileData.role === 'RESELLER'">(Reseller/Dropshipper)</span>
                                            <span class="text-xs" v-if="profileData.role === 'CS'">(Customer Service)</span>
                                        </h4>
                                        <svg class="w-3 h-3 shrink-0 ml-1.5 fill-current mt-0.5" viewBox="0 0 12 12">
                                            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                        </svg>
                                    </MenuButton>
                                </div>
                                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                    <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-proxima font-semibold">
                                        <div v-if="profileData.role === 'RESELLER'" class="px-4 py-2 border-b border-gray-200">
                                            <h4 class="text-xs text-yellow-600 font-proxima font-semibold mb-1">
                                                Saldo DS: Rp{{ formatPrice(totalDropshipBalance) }}
                                                <br/>
                                                Saldo MP: Rp{{ formatPrice(totalMarketplaceBalance) }}
                                            </h4>
                                            <button @click="$router.push('/auth/topup')" class="text-xs font-semibold px-2 py-0.5 rounded bg-gray-700 hover:bg-gray-800 font-proxima text-white -mt-1">Topup Saldo</button>
                                        </div>
                                        <!-- <MenuItem v-slot="{ active }">
                                            <a href="/auth/edit-profile" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Akun Saya</a>
                                        </MenuItem> -->
                                        <MenuItem v-slot="{ active }" v-if="profileData.role === 'RESELLER'">
                                            <a href="/settings/edit-account" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Pengaturan</a>
                                        </MenuItem>
                                        <!-- <MenuItem v-slot="{ active }" v-if="profileData.role === 'RESELLER'">
                                            <a href="/affiliate/dashboard" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Affiliasi</a>
                                        </MenuItem> -->
                                        <MenuItem v-slot="{ active }">
                                            <a @click="handleLogout" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer']">Keluar</a>
                                        </MenuItem>
                                    </MenuItems>
                                </transition>
                            </Menu>
                        </div>
                    </div>
                </div>

                <DisclosurePanel class="sm:hidden">
                    <div class="px-2 pt-2 pb-3 space-y-1">
                        <template v-for="item in navigation" :key="item.name">
                            <div v-if="item.child && item.access.includes(profileData.role)">
                                <a class="flex items-center justify-between" @click="openMobile === item.name ? openMobile = null : openMobile = item.name" :class="[$route.name === item.name ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">
                                    {{ item.name }}
                                    <ChevronDownIcon :class="[openMobile == item.name ? 'text-gray-300' : 'text-gray-200', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
                                </a>
                                <template v-if="openMobile === item.name">
                                    <template v-for="(child, i) in item.child" :key="i">
                                        <a v-if="child.access.includes(profileData.role)" class="flex items-center justify-between pl-8" :href="child.href" :class="[$route.name === child.routeName ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="child.current ? 'page' : undefined">
                                            {{ child.name }}
                                        </a>
                                    </template>
                                </template>
                            </div>
                            <a v-else-if="item.child === undefined && item.access.includes(profileData.role)" class="flex items-center justify-between" @click="$router.push(item.href)" :class="[$route.name === item.name ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">
                                {{ item.name }}
                            </a>
                        </template>
                    </div>
                </DisclosurePanel>
            </Disclosure>
            <!------------------->
            <!--- End Top Bar --->
            <!------------------->

            <!------------------>
            <!--- Second Bar --->
            <!------------------>
            <nav class="bg-white shadow-md">
                <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div class="relative flex items-center justify-between">
                        <div class="hidden sm:block">
                            <div class="flex">
                                <div v-for="item in navigation" :key="item.name">
                                    <!-- Menu with Child -->
                                    <Popover class="relative" v-slot="{ open }" v-if="item.child && item.access.includes(profileData.role)">
                                        <PopoverButton :class="[$route.meta.group === item.group ? 'text-yellow-600' : 'text-gray-600 hover:text-gray-800', 'flex items-center py-3.5 text-sm font-semibold font-proxima mr-5']" :aria-current="item.current ? 'page' : undefined"><img :src="require(`@/assets/images/icons/${item.icon ? item.icon : ''}`)" class="w-4 h-4 mb-0.5 mr-2">
                                            <span>{{ item.name }}</span>
                                            <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
                                        </PopoverButton>

                                        <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                                            <PopoverPanel class="absolute z-10 -ml-4 mt-0 transform px-2 py-2 w-40 sm:px-0 lg:ml-0 lg:left-1 lg:-translate-x-1">
                                                <div class="rounded shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                    <div class="relative grid bg-white py-2">
                                                        <template v-for="(child, i) in item.child"> 
                                                            <a v-if="child.access.includes(profileData.role)" :key="i" :href="child.href" :class="$route.name === child.routeName ? 'text-yellow-600' : 'text-gray-600 hover:text-gray-800'" class="px-2 py-2 flex items-start hover:bg-gray-50 text-sm font-semibold font-proxima">
                                                                <!-- <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" /> -->
                                                                <div class="ml-4">
                                                                    <p>
                                                                        {{ child.name }}
                                                                    </p>
                                                                </div>
                                                            </a>
                                                        </template>
                                                    </div>
                                                </div>
                                            </PopoverPanel>
                                        </transition>
                                    </Popover>
                                    <!-- Menu Without Child -->
                                    <a v-else-if="item.child === undefined && item.access.includes(profileData.role)" :href="item.href" :class="[$route.meta.group === item.group ? 'text-yellow-600' : 'text-gray-600 hover:text-gray-800', 'flex items-center py-3.5 text-sm font-semibold font-proxima mr-5']" :aria-current="item.current ? 'page' : undefined"><img :src="require(`@/assets/images/icons/${item.icon ? item.icon : ''}`)" class="w-4 h-4 mb-0.5 mr-2" />{{ item.name }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- <button v-if="profileData.role === 'VENDOR'" @click="$router.push('/product/add')" class="flex items-center text-sm font-semibold text-yellow-600 py-3.5 font-proxima">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 fill-current mr-1.5" viewBox="0 0 24 24" style="transform:;-ms-filter:"><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4z"></path><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path></svg>
                            Tambah Produk
                        </button> -->
                    </div>
                </div>
            </nav>
            <!---------------------->
            <!--- End Second Bar --->
            <!---------------------->

        </div>
        <div class="md:h-32 h-16"></div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import { ref, computed, onMounted } from 'vue';
import router from "@/router";
import { useStore } from 'vuex';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { BellIcon, ShoppingCartIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import formatPrice from '../../utils/formatPrice.js'

const navigation = [
    { 
        name: 'Dashboard', 
        href: '/', 
        group: 'dashboard', 
        icon: 'dashboard.png',
        access: ['ADMIN', 'RESELLER', 'VENDOR']
    },
    { 
        name: 'Produk', 
        href: '#', 
        group: 'product', 
        icon: 'product.png',
        child: [
            {
                name: 'Daftar Produk',
                href: '/product/list', 
                routeName: 'list-product', 
                icon: '',
                access: ['ADMIN', 'RESELLER', 'VENDOR']
            },
            {
                name: 'Master Produk',
                href: '/product/list-admin', 
                routeName: 'list-admin-product', 
                icon: '',
                access: ['ADMIN', 'VENDOR']
            },
            {
                name: 'Kategori',
                href: '/category/list', 
                routeName: 'list-category', 
                icon: '',
                access: ['ADMIN']
            },
            {
                name: 'Merk',
                href: '/brand/list', 
                routeName: 'list-brand', 
                icon: '', 
                access: ['ADMIN', 'VENDOR']
            },
            { 
                name: 'Listing Produk', 
                href: '/my-product/list', 
                routeName: 'my-product', 
                icon: 'list_product.png',
                access: ['RESELLER']
            },
        ],
        access: ['ADMIN', 'RESELLER', 'VENDOR']
    },
    { 
        name: 'Pesanan', 
        href: '/order/list', 
        icon: 'order.png', 
        group: 'order',
        access: ['ADMIN', 'VENDOR', 'RESELLER', 'CS']
    },
    { 
        name: 'Form Order', 
        href: '#', 
        group: 'my_form', 
        icon: 'form_order.png',
        child: [
            { 
                name: 'List Form Order', 
                href: '/my-form/list',
                routeName: 'list-my-form',
                icon: 'form_order.png',
                access: ['RESELLER']
            },
            { 
                name: 'Customer Service', 
                href: '/member/list', 
                routeName: 'list-member', 
                icon: 'customer-service.png',
                access: ['RESELLER']
            },
        ],
        access: ['RESELLER'],
    },
    { 
        name: 'Pesan Manual', 
        href: '/order/create', 
        icon: 'create-order.png', 
        group: 'create-order',
        access: ['VENDOR', 'RESELLER']
    },
    { 
        name: 'Marketplace', 
        href: '#',
        group: 'marketplace',
        icon: 'marketplace.png',
        child: [
            {
                name: 'Pilih Gudang',
                href: '/settings/select-warehouse', 
                routeName: 'settings-select-warehouse', 
                icon: '', 
                access: ['RESELLER']
            },
            {
                name: 'Atur Integrasi',
                href: '/settings/marketplace-integration', 
                routeName: 'settings-marketplace-integration', 
                icon: '',
                access: ['RESELLER', 'VENDOR']
            },
            {
                name: 'Produk Shopee',
                href: '/shopee/list-product', 
                routeName: 'shopee-list-product', 
                icon: '',
                access: ['RESELLER']
            },
            {
                name: 'Produk Tiktok',
                href: '/tiktokshop/list-product', 
                routeName: 'tiktokshop-list-product', 
                icon: '',
                access: ['RESELLER']
            },
            {
                name: 'Produk Lazada',
                href: '/lazada/list-product', 
                routeName: 'lazada-list-product', 
                icon: '',
                access: ['RESELLER']
            },
            {
                name: 'Watermark',
                href: '/watermark', 
                routeName: 'watermark', 
                icon: '',
                access: ['RESELLER']
            },
        ],
        access: ['RESELLER', 'VENDOR']
    },
    { 
        name: 'Persediaan', 
        href: '/stock/list', 
        icon: 'stock.png', 
        group: 'inventory',
        access: ['ADMIN', 'VENDOR']
    },
    { 
        name: 'Laporan', 
        href: '#',
        group: 'report',
        icon: 'report.png',
        child: [
            {
                name: 'Profit Produk',
                href: '/report/reseller-profit', 
                routeName: 'reseller-profit', 
                icon: '', 
                access: ['RESELLER']
            },
            {
                name: 'Cashback Ongkir',
                href: '/report/reseller-cashback', 
                routeName: 'reseller-cashback', 
                icon: '',
                access: ['RESELLER']
            },
            {
                name: 'Penjualan Produk',
                href: '/report/vendor-cogs', 
                routeName: 'vendor-cogs', 
                icon: '',
                access: ['VENDOR']
            },
            {
                name: 'Admin Fee Reseller',
                href: '/report/platform-admin-fee', 
                routeName: 'platform-admin-fee', 
                icon: '',
                access: ['ADMIN']
            },
            {
                name: 'Cashback Ongkir',
                href: '/report/platform-cashback', 
                routeName: 'platform-cashback', 
                icon: '',
                access: ['ADMIN']
            },
            {
                name: 'Admin Fee Supplier',
                href: '/report/platform-vendor-admin-fee', 
                routeName: 'platform-vendor-admin-fee', 
                icon: '',
                access: ['ADMIN']
            },
        ],
        access: ['RESELLER', 'VENDOR', 'ADMIN']
    },
    { 
        name: 'Keuangan', 
        href: '#', 
        icon: 'finance.png',
        group: 'finance',
        child: [
            {
                name: 'Pencairan Dana',
                href: '/finance/settlement/list', 
                routeName: 'list-settlement', 
                icon: '', 
                access: ['ADMIN', 'RESELLER', 'VENDOR']
            },
            {
                name: 'Riwayat Transaksi',
                href: '/finance/reseller-transaction-history', 
                routeName: 'reseller-transaction-history', 
                icon: '', 
                access: ['RESELLER']
            },
            // {
            //     name: 'Riwayat Transaksi',
            //     href: '/finance/vendor-transaction-history', 
            //     routeName: 'vendor-transaction-history', 
            //     icon: '', 
            //     access: ['VENDOR']
            // },
            {
                name: 'Riwayat Transaksi',
                href: '/finance/platform-transaction-history', 
                routeName: 'platform-transaction-history', 
                icon: '', 
                access: ['ADMIN']
            },
            {
                name: 'Topup Saldo',
                href: '/finance/topup/list', 
                routeName: 'list-topup', 
                icon: '', 
                access: ['ADMIN', 'RESELLER']
            },
        ],
        access: ['ADMIN', 'RESELLER', 'VENDOR']
    },
    { 
        name: 'Pengguna', 
        href: '/user/list', 
        icon: 'users.png', 
        group: 'user',
        access: ['ADMIN']
    },
    { 
        name: 'Pengaturan', 
        href: '#', 
        group: 'settings',
        icon: 'settings.png',
        child: [
            // {
            //     name: 'Halaman',
            //     href: '/product/list', 
            //     routeName: 'list-product', 
            //     icon: '', 
            //     access: ['ADMIN']
            // },
            {
                name: 'Rekening Bank',
                href: '/bank/list', 
                routeName: 'list-bank', 
                icon: '',
                access: ['ADMIN']
            },
        ],
        access: ['ADMIN']
    },
    { 
        name: 'Support', 
        href: '#', 
        group: 'support',
        icon: 'help.png',
        child: [
            {
                name: 'Form Pengaduan',
                href: '/support/form-ticket', 
                routeName: 'support-form-ticket', 
                icon: '',
                access: ['RESELLER']
            },
        ],
        access: ['RESELLER']
    },
]

export default {
    components: {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        Popover, 
        PopoverButton, 
        PopoverPanel,
        BellIcon,
        ShoppingCartIcon,
        MenuIcon,
        XIcon,
        ChevronDownIcon,
    },
    props: {
        total_item: { type: Number }
    },
    setup() {
        const userToggle = ref(false);
        const totalCartItem = ref(0);
        const totalDropshipBalance = ref(0);
        const totalMarketplaceBalance = ref(0);
        const openMobile = ref(null);

        const store = useStore();
        const profileData = computed(() => store.getters['auth/userProfile'] ? store.getters['auth/userProfile'] : null);

        const handleLogout = () => {
            store.dispatch('auth/logout').then(() => {
                router.push('/auth/login');
            });
        }

        const getCartItems = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/cart/get-cart`, {
                    headers: {
                        'Authorization': store.state.auth.token
                    }
                });

                totalCartItem.value = response.data.data.cart_items.length > 0 ? response.data.data.cart_items.reduce((total, item) => { return Number(total) + Number(item.quantity) }, 0) : 0;
            } catch (error) {
                console.log(error)
            }
        }
        getCartItems();

        const getTotalBalance = async () => {
            try {
                // let endpoint_url = '';
                // if(profileData.value.role === 'RESELLER') {
                //     endpoint_url = '/api/auth/get-profile';
                // }else if(profileData.value.role === 'ADMIN') {
                //     endpoint_url = '/api/income/get-total-balance-platform';
                // }else if(profileData.value.role === 'VENDOR') {
                //     endpoint_url = '/api/income/get-total-balance-vendor/' + profileData.value.vendor;
                // }

                // const response = await axios.get(`${API_URL}${endpoint_url}`, {
                //     headers: {
                //         'Authorization': store.state.auth.token
                //     }
                // });

                // if(profileData.value.role === 'RESELLER') {
                //     totalBalance.value = response.data.data.balance ? response.data.data.balance : 0;
                // }else if(profileData.value.role === 'ADMIN') {
                //     totalBalance.value = response.data.data.total_balance ? response.data.data.total_balance : 0;
                // }else if(profileData.value.role === 'VENDOR') {
                //     totalBalance.value = response.data.data.balance ? response.data.data.balance: 0;
                // }

                const response = await axios.get(`${API_URL}/api/auth/get-profile`, {
                    headers: {
                        'Authorization': store.state.auth.token
                    }
                });

                totalDropshipBalance.value = response.data.data.user.balance ? response.data.data.user.balance : 0;
                totalMarketplaceBalance.value = response.data.data.user.balance_marketplace ? response.data.data.user.balance_marketplace : 0;
            } catch (error) {
                console.log(error)
            }
        }
        getTotalBalance();

        onMounted(() => {
            // if(profileData.value.role === 'RESELLER') {
            //     if(profileData.value.reseller_url == null) {
            //         router.push('/auth/edit-profile');
            //     }
            // }

            if(profileData.value.role === 'VENDOR') {
                if(profileData.value.vendor == null) {
                    router.push('/auth/edit-profile');
                }
            }
        });

        return {
            userToggle,
            navigation,
            profileData,
            handleLogout,
            getCartItems,
            totalCartItem,
            totalDropshipBalance,
            totalMarketplaceBalance,
            formatPrice,
            openMobile
        }
    },
}
</script>

<style scoped>
</style>