<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <section v-if="loading" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="md:flex md:justify-between md:items-center">
                <!-- Left: Title -->
                <div class="mb-4 sm:md:-0">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold">Data Pesanan</h1>
                </div>

                <!-- Right: Actions  -->
                <div class="md:grid md:grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    <!-- Search form -->
                    <z-input placeholder="Cari pesanan..." v-model="search" @input="tableInputSearchHandler" class="mt-1 md:block hidden"></z-input>
                    <z-input placeholder="Cari pesanan..." v-model="search" @input="tableInputSearchHandler" class="mt-1 md:hidden" full></z-input>
                    <!-- Filter Date -->
                    <litepie-datepicker
                        :formatter="{ date: 'DD MMM YYYY', month: 'MMM' }"
                        v-model="filterDate"
                        :options="dateOptions"
                        class="mt-1"
                    ></litepie-datepicker>
                    <!-- Filter Kategori -->
                    <z-select v-model="typeOrder" label="Tipe Order" :options="[{label: 'Form Order', value: 'form'}, {label: 'Belanja Langsung', value: 'direct'}]" full @changeData="getOrder(this.page, this.limit)"></z-select>
                </div>
            </div>
            <div class="mb-8 mt-4 md:flex items-center justify-end">
                <!-- Tarik Data Button -->
                <z-button @click="exportOrder" class="bg-blue-600 hover:bg-blue-700 text-white flex items-center text-sm mr-2">
                    <svg class="w-5 h-5 fill-current opacity-50 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                        <path d="M18.948 11.112C18.511 7.67 15.563 5 12.004 5c-2.756 0-5.15 1.611-6.243 4.15-2.148.642-3.757 2.67-3.757 4.85 0 2.757 2.243 5 5 5h1v-2h-1c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.757 2.673-3.016l.581-.102.192-.558C8.153 8.273 9.898 7 12.004 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-2v2h2c2.206 0 4-1.794 4-4a4.008 4.008 0 0 0-3.056-3.888z"></path><path d="M13.004 14v-4h-2v4h-3l4 5 4-5z"></path>
                    </svg>
                    <span class="ml-2">Tarik Data Pesanan</span>
                </z-button>
                <!-- Buat Pesanan -->
                <z-button @click="$router.push('/order/create')" class="bg-green-600 hover:bg-green-700 text-white flex items-center text-sm md:w-auto w-full justify-center">
                    <svg class="w-3 h-3 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span class="ml-2">Buat Pesanan</span>
                </z-button>
                <!-- Take Action for Mutiple Selected -->
                <div class="flex items-center" v-if="selectedItems && selectedItems.length > 0">
                    <div class="hidden xl:block text-sm italic mr-2 whitespace-nowrap"><span>{{selectedItems.length}}</span> item yang dipilih</div>
                    <z-button @click="printOrder" class="bg-blue-600 hover:bg-blue-700 text-white text-sm mr-2">Print Label Masal</z-button>
                    <z-button @click="downloadResiMassal" class="bg-blue-600 hover:bg-blue-700 text-white text-sm">Download Resi Masal</z-button>
                </div>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!---------------->
            <!-- Statistics -->
            <!---------------->
            <div class="md:grid md:grid-cols-5 md:gap-4 gap-2 mb-5">
                <div class="bg-white rounded border border-gray-300 p-6 flex items-center justify-center md:mb-0 mb-2">
                    <div>
                        <h4 class="font-statistics text-gray-400 font-medium text-sm text-center mb-2">Total Pesanan</h4>
                        <h1 class="font-statistics text-4xl font-bold text-center text-gray-800 mb-2">{{ totalOrder }}</h1>
                    </div>
                </div>
                <div class="bg-white rounded border border-gray-300 p-6 flex items-center justify-center md:mb-0 mb-2">
                    <div>
                        <h4 class="font-statistics text-yellow-600 font-medium text-sm text-center mb-2">Pesanan Pending</h4>
                        <h1 class="font-statistics text-4xl font-bold text-center text-gray-800 mb-2">{{ totalPending }}</h1>
                    </div>
                </div>
                <div class="bg-white rounded border border-gray-300 p-6 flex items-center justify-center md:mb-0 mb-2">
                    <div>
                        <h4 class="font-statistics text-green-600 font-medium text-sm text-center mb-2">Pesanan Sedang Proses</h4>
                        <h1 class="font-statistics text-4xl font-bold text-center text-gray-800 mb-2">{{ Number(totalShipped) + Number(totalOnProcess) }}</h1>
                    </div>
                </div>
                <div class="bg-white rounded border border-gray-300 p-6 flex items-center justify-center md:mb-0 mb-2">
                    <div>
                        <h4 class="font-statistics text-green-700 font-medium text-sm text-center mb-2">Pesanan Selesai</h4>
                        <h1 class="font-statistics text-4xl font-bold text-center text-gray-800 mb-2">{{ totalDelivered }}</h1>
                    </div>
                </div>
                <div class="bg-white rounded border border-gray-300 p-6 flex items-center justify-center md:mb-0 mb-2">
                    <div>
                        <h4 class="font-statistics text-red-600 font-medium text-sm text-center mb-2">Pesanan Gagal</h4>
                        <h1 class="font-statistics text-4xl font-bold text-center text-gray-800 mb-2">{{ Number(totalCancelled) + Number(totalReturn) + Number(totalRefund) }}</h1>
                    </div>
                </div>
            </div>
            <!-------------------->
            <!-- End Statistics -->
            <!-------------------->

            <!----------->
            <!-- Table -->
            <!----------->
            <div class="w-full">
                <z-table
                    title="Pesanan"
                    :data="data.data"
                    :meta="data.meta"
                    :columns="columns"
                    :mutipleCheck="true"
                    @updateSelectedItems="updateSelectedItems"
                    :sorting="true"
                    @sortingHandler="sortingHandler"
                >
                    <template v-slot:header>
                        <div class="md:flex md:items-center md:space-x-2">
                            <button @click="filterStatusOrder('PENDING')" class="px-3 py-1 border-2 border-gray-800 hover:border-gray-600 hover:bg-gray-600 rounded-full text-xs text-gray-600 hover:text-white font-semibold md:w-auto w-full md:mb-0 mb-1">
                                PENDING ({{ totalPending }})
                            </button>
                            <button @click="filterStatusOrder('PAID')" class="px-3 py-1 border-2 border-gray-800 hover:border-gray-600 hover:bg-gray-600 rounded-full text-xs text-gray-600 hover:text-white font-semibold md:w-auto w-full md:mb-0 mb-1">
                                PAID ({{ totalPaid }})
                            </button>
                            <button @click="filterStatusOrder('ON PROCESS')" class="px-3 py-1 border-2 border-gray-800 hover:border-gray-600 hover:bg-gray-600 rounded-full text-xs text-gray-600 hover:text-white font-semibold md:w-auto w-full md:mb-0 mb-1">
                                ON PROCESS ({{ totalOnProcess }})
                            </button>
                            <button @click="filterStatusOrder('SHIPPED')" class="px-3 py-1 border-2 border-gray-800 hover:border-gray-600 hover:bg-gray-600 rounded-full text-xs text-gray-600 hover:text-white font-semibold md:w-auto w-full md:mb-0 mb-1">
                                SHIPPED ({{ totalShipped }})
                            </button>
                            <button @click="filterStatusOrder('DELIVERED')" class="px-3 py-1 border-2 border-gray-800 hover:border-gray-600 hover:bg-gray-600 rounded-full text-xs text-gray-600 hover:text-white font-semibold md:w-auto w-full md:mb-0 mb-1">
                                DELIVERED ({{ totalDelivered }})
                            </button>
                            <button @click="filterStatusOrder('UNDELIVERY')" class="px-3 py-1 border-2 border-gray-800 hover:border-gray-600 hover:bg-gray-600 rounded-full text-xs text-gray-600 hover:text-white font-semibold md:w-auto w-full md:mb-0 mb-1">
                                UNDELIVERY ({{ totalUndelivered }})
                            </button>
                            <button @click="filterStatusOrder('RETURN')" class="px-3 py-1 border-2 border-gray-800 hover:border-gray-600 hover:bg-gray-600 rounded-full text-xs text-gray-600 hover:text-white font-semibold md:w-auto w-full md:mb-0 mb-1">
                                RETURN ({{ totalReturn }})
                            </button>
                            <button @click="filterStatusOrder('REFUND')" class="px-3 py-1 border-2 border-gray-800 hover:border-gray-600 hover:bg-gray-600 rounded-full text-xs text-gray-600 hover:text-white font-semibold md:w-auto w-full md:mb-0 mb-1">
                                REFUND ({{ totalRefund }})
                            </button>
                            <button @click="filterStatusOrder('CANCELLED')" class="px-3 py-1 border-2 border-gray-800 hover:border-gray-600 hover:bg-gray-600 rounded-full text-xs text-gray-600 hover:text-white font-semibold md:w-auto w-full md:mb-0 mb-1">
                                CANCELLED ({{ totalCancelled }})
                            </button>
                        </div>
                    </template>
                    <template v-slot:invoice="{item}">
                        <h4>{{ item.invoice }}</h4>
                    </template>
                    <template v-slot:status="{item}">
                        <h4 class="text-xs font-proxima text-gray-600 mb-2" v-if="item.status">
                            <span class="bg-blue-100 px-2 py-1 rounded-full" v-if="item.status === 'PENDING'">{{ item.status }}</span>
                            <span class="bg-green-100 px-2 py-1 rounded-full" v-if="item.status === 'PAID' || item.status === 'ON PROCESS' || item.status === 'SHIPPED' || item.status === 'DELIVERED'">{{ item.status }}</span>
                            <span class="bg-yellow-100 px-2 py-1 rounded-full" v-if="item.status === 'UNDELIVERY' || item.status === 'RE-DELIVERY'">{{ item.status }}</span>
                            <span class="bg-red-100 px-2 py-1 rounded-full" v-if="item.status === 'CANCELLED' || item.status === 'REFUND' || item.status === 'RETURN'">{{ item.status }}</span>
                        </h4>
                        <h5 class="text-xs font-proxima" v-if="item.awb_status">
                            <span class="px-1 py-0.5 bg-gray-100 rounded">{{ item.awb_status }}</span>
                        </h5>
                    </template>
                    <template v-slot:grand_total="{item}">
                        <h4 class="font-proxima" v-if="item.order_type !== 'form' && item.order_type !== 'create-order'">Rp{{ formatPrice( item.items.reduce((total, item) => { return Number(total) + Number(item.variant.price * item.quantity)},0) + Number((item.shipping_fee !== null ? item.shipping_fee : 0) - (item.shipping_fee_discount !== null ? item.shipping_fee_discount : 0)) + Number(item.admin_fee !== null ? item.admin_fee : 0) ) }}</h4>
                        <h4 class="font-proxima" v-else>Rp{{ formatPrice( item.items.reduce((total, item) => { return Number(total) + Number(item.variant.sell_price * item.quantity)},0) + Number((item.shipping_fee !== null ? item.shipping_fee : 0) - (item.shipping_fee_discount !== null ? item.shipping_fee_discount : 0)) + Number(item.admin_fee !== null ? item.admin_fee : 0) ) }}</h4>
                        <h5 class="font-proxima text-gray-500 text-xs">{{ item.payment_method }}</h5>
                    </template>
                    <template v-slot:order_type="{item}">
                        <span v-if="item.order_type === 'direct'">Belanja Langsung/Resi Otomatis</span>
                        <span v-else-if="item.order_type === 'form'">Form Order</span>
                        <span v-else-if="item.order_type === 'create-order'">Order Manual</span>
                        <span v-else-if="item.order_type === 'marketplace-order'">Marketplace Order</span>
                    </template>
                    <template v-slot:createdAt="{item}">
                        {{ formatDate(item.createdAt) }}<br/> 
                        <h4 class="text-xs text-gray-500">{{ formatTime(item.createdAt) }} WIB</h4>
                    </template>
                    <template v-slot:action="{item}">
                        <span class="hidden">{{item}}</span>
                        <!-- Action Button -->
                        <div class="flex items-center">
                            <button @click.stop="openFollowUpCustomer(item)" class="relative p-1.5 rounded-full text-green-600 hover:text-green-700 hover:bg-gray-50 focus:outline-non" v-if="item.order_type === 'form' || item.order_type === 'create-order'">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-current h-5 w-5"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"></path></svg>
                            </button>
                            <button v-if="item.order_type === 'form' || item.order_type === 'create-order'" @click="selectedItems.push(item); printOrder()" class="relative p-1.5 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-50 focus:outline-none">
                                <span class="sr-only">Print</span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-5 h-5" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M19 7h-1V2H6v5H5c-1.654 0-3 1.346-3 3v7c0 1.103.897 2 2 2h2v3h12v-3h2c1.103 0 2-.897 2-2v-7c0-1.654-1.346-3-3-3zM8 4h8v3H8V4zm8 16H8v-4h8v4zm4-3h-2v-3H6v3H4v-7c0-.551.449-1 1-1h14c.552 0 1 .449 1 1v7z"></path><path d="M14 10h4v2h-4z"></path></svg>
                            </button>
                            <button @click.stop="detailOrderModal = true; selectedOrder = item" class="relative p-1.5 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-50 focus:outline-none">
                                <span class="sr-only">View order</span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-5 h-5" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h16l.002 14H4z"></path><path d="M6 7h12v2H6zm0 4h12v2H6zm0 4h6v2H6z"></path></svg>
                            </button>
                            <ZDropdown class="ml-2 inline-flex" v-if="item.status !== 'DELIVERED' && item.order_type !== 'marketplace-order'">
                                <li v-if="profileData.role !== 'VENDOR'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click.stop="selectedOrder = item; addressEditToggle = true">Edit Address</a>
                                </li>
                                <li>
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click.stop="selectedOrder = item; order_note = item.order_note ? item.order_note : ''; orderNoteToggle = true">Edit Catatan</a>
                                </li>
                                <li v-if="item.awb_no === null && item.order_type === 'form' && profileData.role !== 'VENDOR' || item.awb_no === undefined && item.order_type === 'form' && profileData.role !== 'VENDOR'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click.stop="selectedOrder = item; editOrderToggle = true">Edit Order</a>
                                </li>
                                <hr class="h-0.5 my-1 bg-gray-200" v-if="profileData.role !== 'VENDOR'" />
                                <li v-if="profileData.role === 'ADMIN' || profileData.role === 'RESELLER'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="changeStatus(item._id, 'PENDING')">Mark As Pending</a>
                                </li>
                                <li v-if="profileData.role === 'ADMIN' || profileData.role === 'RESELLER'">
                                    <a class="font-medium text-sm text-red-600 hover:text-red-800 flex py-1 px-3 cursor-pointer" @click.stop="selectedOrder = item._id; confirmCancelOrderModal = true">Mark As Cancelled</a>
                                </li>
                                <hr class="h-0.5 my-1 bg-gray-200" v-if="profileData.role !== 'VENDOR'" />
                                <li v-if="profileData.role === 'ADMIN'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="changeStatus(item._id, 'PAID')">Mark As Paid</a>
                                </li>
                                <li v-if="profileData.role === 'ADMIN'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="changeStatus(item._id, 'ON PROCESS')">Mark As On Process</a>
                                </li>
                                <li v-if="profileData.role !== 'ADMIN' && profileData.role !== 'VENDOR' && item.awb_status === undefined && item.order_type === 'form'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="requestAWB(item._id)">Request Awb</a>
                                </li>
                                <li v-if="profileData.role === 'ADMIN' && item.order_type === 'form'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="generateAWB(item)">Approve Request Awb</a>
                                </li>
                                <li v-if="profileData.role === 'ADMIN' || profileData.role === 'VENDOR'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="changeStatus(item._id, 'SHIPPED')">Mark As Shipped</a>
                                </li>
                                <hr class="h-0.5 my-1 bg-gray-200" v-if="profileData.role !== 'RESELLER' && profileData.role !== 'VENDOR' && item.order_type !== 'create-order'" />
                                <li v-if="profileData.role === 'ADMIN'">
                                    <a class="font-medium text-sm text-green-600 hover:text-green-800 flex py-1 px-3 cursor-pointer" @click.stop="selectedOrder = item._id; confirmStatusDoneModal = true">Mark As Delivered</a>
                                </li>
                                <li v-if="profileData.role === 'ADMIN'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="changeStatus(item._id, 'UNDELIVERY')">Mark As Undelivery</a>
                                </li>
                                <li v-if="profileData.role === 'ADMIN' || profileData.role === 'RESELLER'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="changeStatus(item._id, 'RE-DELIVERY')">Mark As Re-Delivery</a>
                                </li>
                                <hr class="h-0.5 my-1 bg-gray-200" v-if="profileData.role !== 'RESELLER'" />
                                <li v-if="profileData.role === 'ADMIN' || profileData.role === 'VENDOR'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="changeStatus(item._id, 'RETURN')">Mark As Return</a>
                                </li>
                                <li v-if="profileData.role === 'ADMIN'">
                                    <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="changeStatus(item._id, 'REFUND')">Mark As Refund</a>
                                </li>
                            </ZDropdown>
                        </div>
                    </template>
                </z-table>
            </div>
            <!--------------->
            <!-- End Table -->
            <!--------------->

            <!---------------->
            <!-- Pagination -->
            <!---------------->
            <div class="mt-8">
                <z-pagination 
                    :meta="data.meta"
                    :PageNavHandler="pageNavHandler"
                    :pageNavNumberHandler="pageNavNumberHandler"
                />
            </div>
            <!-------------------->
            <!-- End Pagination -->
            <!-------------------->
            
            <ZDetailOrder v-if="detailOrderModal === true" :order="selectedOrder" :modalOpen="detailOrderModal" @closeModal="detailOrderModal = false"></ZDetailOrder>
            <!-- Follow Up Whatsapp -->
            <ZModal id="follow-up-whatsapp" title="Follow Up Customer" :modalOpen="followUpModal" @close-modal="followUpModal = false">
                <!-- Main Content -->
                <div class="px-5 py-6 pb-4 text-gray-600" v-if="selectedOrder !== null">
                    <div class="text-gray-800 mb-4">
                        <h2 class="font-medium text-gray-500">Nama Customer: {{ selectedOrder.customer_name }}</h2>
                    </div>
                    <hr class="my-5" />
                    <div class="mb-3">
                        <label class="font-proxima font-semibold block">No Handphone Customer</label>
                        <z-input v-model="follow_up_phone" full></z-input>
                    </div>
                    <div>
                        <label class="block font-semibold font-proxima" for="follow_up_content">Teks Follow Up</label>
                        <textarea v-model="follow_up_content" id="follow_up_content" class="w-full bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima" rows="10">
                        </textarea>
                    </div>
                </div>
                <!-- / End Main Content -->

                <!-- Footer Content -->
                <div class="px-5 pb-5">
                    <button @click="followUpCustomer" class="w-full bg-yellow-500 rounded font-semibold text-sm py-2 px-5 text-white hover:bg-yellow-600 transition duration-150 focus:outline-none">Follow Up</button>
                </div>
                <!-- / End Footer Content -->
            </ZModal>
            <!-- History Stock -->

            <!-- Confirm Status Done Modal -->
            <ZModalAction id="danger-modal" :modalOpen="confirmStatusDoneModal" @close-modal="confirmStatusDoneModal = false">
                <div class="flex space-x-4">
                    <!-- Icon -->
                    <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-red-100">
                        <svg class="w-4 h-4 shrink-0 fill-current text-yellow-500" viewBox="0 0 16 16">
                            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                        </svg>
                    </div>
                    <!-- Content -->
                    <div>
                        <!-- Modal header -->
                        <div class="mb-2">
                            <div class="text-lg font-semibold text-gray-800 font-proxima">Konfirmasi Pesanan Selesai</div>
                        </div>
                        <!-- Modal content -->
                        <div class="text-sm mb-10 font-proxima">
                            <div class="space-y-2">
                                <p>Anda yakin ingin menyelesaikan pesanan ini?<br/> Status pesanan tidak dapat diubah kembali. Tindakan ini tidak bisa dibatalkan.</p>
                            </div>
                        </div>
                        <!-- Modal footer -->
                        <div class="flex flex-wrap justify-end space-x-2">
                            <button class="px-3 py-1.5 text-sm rounded border-gray-200 hover:border-gray-300 text-gray-600 font-proxima" @click.stop="confirmStatusDoneModal = false">Batal</button>
                            <button @click="changeStatus(selectedOrder, 'DELIVERED'); confirmStatusDoneModal = false" class="px-3 py-1.5 text-sm rounded bg-green-500 hover:bg-green-600 text-white font-proxima">Ya, Konfirmasi Sekarang</button>
                        </div>
                    </div>
                </div>
            </ZModalAction>

            <!-- Edit Address Order -->
            <ZModal id="address-modal" :modalOpen="addressEditToggle" @close-modal="addressEditToggle = false" title="Edit Alamat">
                <ZAddressEdit v-if="addressEditToggle" :order="selectedOrder" @saveAddress="addressEditToggle = false; this.getOrder(this.page, this.limit)"></ZAddressEdit>
            </ZModal>

            <!-- Edit Order -->
            <ZEditOrder v-if="editOrderToggle === true" :order="selectedOrder" :modalOpen="editOrderToggle" @closeModal="editOrderToggle = false"></ZEditOrder>

            <!-- Edit Order Note -->
            <ZModal id="order-note-modal" :modalOpen="orderNoteToggle" @close-modal="orderNoteToggle = false" title="Catatan Pesanan">
                <div class="px-5 py-4 space-y-2">
                    <div>
                        <label class="block text-sm font-semibold mb-1 font-proxima" for="order_note">Isi Catatan Pesanan</label>
                        <textarea v-model="order_note" id="order_note" class="w-full bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima" row="3">
                        </textarea>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="px-5 py-4 border-t border-gray-200">
                    <button @click="saveOrderNote" class="bg-yellow-500 hover:bg-yellow-600 px-4 py-2 text-white text-center font-semibold font-proxima rounded w-full">Simpan Catatan</button>
                </div>
            </ZModal>

            <!-- Cancel Order Modal -->
            <ZModalAction id="danger-modal" :modalOpen="confirmCancelOrderModal" @close-modal="confirmCancelOrderModal = false">
                <div class="flex space-x-4">
                    <!-- Icon -->
                    <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-red-100">
                        <svg class="w-4 h-4 shrink-0 fill-current text-red-500" viewBox="0 0 16 16">
                            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                        </svg>
                    </div>
                    <!-- Content -->
                    <div>
                        <!-- Modal header -->
                        <div class="mb-2">
                            <div class="text-lg font-semibold text-gray-800 font-proxima">Konfirmasi Pesanan Dibatalkan</div>
                        </div>
                        <!-- Modal content -->
                        <div class="text-sm mb-10 font-proxima">
                            <div class="space-y-2">
                                <!-- Cancel Reason -->
                                <h4 class="font-semibold mt-2">Alasan Pembatalan</h4>
                                <z-select v-model="cancel_reason" label="Alasan Pembatalan" :options="[{label: 'CHANGED MIND', value: 'CHANGED MIND'}, {label: 'DUPLICATE', value: 'DUPLICATE'}, {label: 'OVERDUE', value: 'OVERDUE'}, {label: 'OUT OF STOCK', value: 'OUT OF STOCK'}, {label: 'OTHER', value: 'OTHER'}]" full></z-select>
                                <!-- <p class="mt-8 block">Anda yakin ingin membatalkan pesanan ini?</p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="flex flex-wrap justify-end space-x-2">
                    <button class="px-3 py-1.5 text-sm rounded border-gray-200 hover:border-gray-300 text-gray-600 font-proxima" @click.stop="confirmCancelOrderModal = false">Batal</button>
                    <button @click.stop="confirmCancelOrder" class="px-3 py-1.5 text-sm rounded bg-red-500 hover:bg-red-600 text-white font-proxima">Ya, Batalkan Pesanan</button>
                </div>
            </ZModalAction>

            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatDate from '../../utils/dateFormat.js'
import formatPrice from '../../utils/formatPrice.js'
import dayjs from 'dayjs';

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZDropdown from '@/components/common/ZDropdown.vue';
import ZDetailOrder from '@/components/special/ZDetailOrder.vue';
import ZSelect from '@/components/common/ZSelect.vue';
import ZModal from '@/components/common/ZModal.vue';
import ZModalAction from '@/components/common/ZModalAction.vue';
import ZAddressEdit from '@/components/special/ZAddressEdit.vue';
import ZEditOrder from '@/components/special/ZEditOrder.vue';

export default {
    components: {
        NavigationMenu,
        ZTable,
        ZPagination,
        ZInput,
        ZButton,
        ZDropdown,
        ZDetailOrder,
        ZSelect,
        ZModal,
        ZModalAction,
        ZAddressEdit,
        ZEditOrder
    },
    data() {
        return {
            /* Core Data */
			data: [],
            selectedItems: [],
            dataSelected: null,
            deleteMutiple: false,
            selectedOrder: null,
            order_note: '',
            cancel_reason: '',
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'invoice',
                    label: 'Invoice',
                    visibility: true,
                    sort: true
                },
                {
                    id: 2,
                    field: 'customer_name',
                    label: 'Nama Pelanggan',
                    visibility: true,
                    sort: false
                },
                {
                    id: 3,
                    field: 'status',
                    label: 'Status Pesanan',
                    visibility: true,
                    sort: false
                },
                {
                    id: 4,
                    field: 'grand_total',
                    label: 'Total Tagihan',
                    visibility: true,
                    sort: false
                },
                {
                    id: 5,
                    field: 'order_type',
                    label: 'Tipe Order',
                    visibility: true,
                    sort: false
                },
                {
                    id: 6,
                    field: 'createdAt',
                    label: 'Tanggal Order',
                    visibility: true,
                    sort: false
                },
                {
                    id: 7,
                    field: 'action',
                    label: 'Action',
                    visibility: true,
                },
			],
			/* Toggle */
			sidebarOpen: false,
            loading: false,
            confirmDeleteModal: false,
            detailOrderModal: false,
            followUpModal: false,
            confirmStatusDoneModal: false,
            addressEditToggle: false,
            editOrderToggle: false,
            orderNoteToggle: false,
            confirmCancelOrderModal: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: {'invoice': -1},
            sort_order: -1,
            filterDate: {
                startDate: '',
                endDate: ''
            },
            statusOrder: '',
            typeOrder: '',
            /* Extra */
            totalOrder: 0,
            totalPending: 0,
            totalPaid: 0,
            totalOnProcess: 0,
            totalShipped: 0,
            totalUndelivered: 0,
            totalDelivered: 0,
            totalReturn: 0,
            totalRefund: 0,
            totalCancelled: 0,
            dateOptions: {
                shortcuts: {
                    today: 'Hari ini',
                    yesterday: 'Kemarin',
                    past: period => period + ' hari terakhir',
                    currentMonth: 'Bulan ini',
                    pastMonth: 'Bulan lalu'
                },
                footer: {
                    apply: 'Terapkan',
                    cancel: 'Batal'
                }
            },
            /* Followup Customer */
            follow_up_phone: '',
            follow_up_content: '',
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    watch: {
        filterDate() {
            this.getOrder(this.page, this.limit);
        }
    },
	async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        /* Validasi Vendor Terverifikasi */
        if(this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === undefined || this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === false) {
            this.$router.push('/');
        }

        let today = new Date();
        let start_date = dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('DD MMM YYYY');
        let end_date = dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('DD MMM YYYY');
        this.filterDate.startDate = start_date;
        this.filterDate.endDate = end_date;
        this.getOrder(this.page, this.limit);

        // if(this.profileData.guide_form_order.order_monitor === false && this.profileData.guide_manual_order.order_monitor === false && this.profileData.guide_marketplace.order_monitor === false) {
        //     this.updateGuide();
        // }
	},
	methods: {
		/*
		 * Get List Order
		 */
		async getOrder(page, limit) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                start_date: this.filterDate.startDate,
                end_date: this.filterDate.endDate,
                status: this.statusOrder,
                order_type: this.typeOrder.value,
                sort: JSON.stringify(this.sort_by)
            };

            if(this.profileData.role === 'VENDOR') {
                params.vendor = this.profileData.vendor ? this.profileData.vendor : null;
            }
            if(this.profileData.role === 'RESELLER') {
                params.user = this.profileData.id;
            }
            if(this.profileData.role === 'CS') {
                params.cs = this.profileData.id;
            }

			try {
                const response = await axios.get(API_URL + '/api/order/get-all', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;
                this.getTotalOrder();
                this.getTotalOrder('PENDING');
                this.getTotalOrder('PAID');
                this.getTotalOrder('ON PROCESS');
                this.getTotalOrder('SHIPPED');
                this.getTotalOrder('DELIVERED');
                this.getTotalOrder('UNDELIVERY');
                this.getTotalOrder('RETURN');
                this.getTotalOrder('REFUND');
                this.getTotalOrder('CANCELLED');

                /* Stop Loading */
                loading.hide();
                this.loading = false;
			} catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dimuat!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getOrder(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getOrder(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getOrder(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getOrder(this.page, this.limit);
            } else {
                this.search = value;
                this.getOrder(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

		/*
		 * Update Selected Items
		 */
		async updateSelectedItems(selected) {
			this.selectedItems = selected;
		},

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getOrder(this.page, this.limit);
        },

        /*
         * Change Order Status Function
         */
        async changeStatus(id, status) {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            let data = {
                status: status
            }

            try {
                const res = await axios.put(API_URL + '/api/order/change-status/' + id, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                console.log(res);
                this.getOrder(this.page, this.limit);
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                console.log(error)
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Request AWB
         */
        async requestAWB(id) {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                await axios.put(API_URL + '/api/order/request-awb/' + id, {}, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.getOrder(this.page, this.limit);
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                console.log(error)
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Create Resi Otomatis
         */
        async generateAWB(item) {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Ambil Data Vendor */
            const getVendorData = await axios.get(API_URL + '/api/vendor/get-by-id/' + item.items[0].vendor._id, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });

            /* Hitung Berat */
            let weight;
            if(item.items.reduce((total, item) => { return Number(total) + Number(item.product.package_weight) }, 0) / 1000 < 1) {
                weight = 1;
            }else{
                weight = item.items.reduce((total, item) => { return Number(total) + Number(item.product.package_weight) }, 0) / 1000;
            }

            let data = {
                /* Seller Data */
                merchant_id: getVendorData.data.vendor.vendor_name,
                pic_name: getVendorData.data.vendor.vendor_name,
                pic_phone: getVendorData.data.vendor.vendor_phone,
                pic_city: getVendorData.data.vendor.city,
                pic_district: getVendorData.data.vendor.district,
                pic_address: getVendorData.data.vendor.address,
                pic_province: getVendorData.data.vendor.province,
                pic_postal_code: getVendorData.data.vendor.postal_code,
                customer_id: item.user,
                invoice: item._id.slice(16, 24),
                customer_name: item.customer_name,
                customer_address1: item.customer_address,
                customer_address2: item.customer_district ? item.customer_district : '',
                customer_city: item.customer_city,
                customer_province: item.customer_province,
                customer_postal_code: item.customer_postal_code,
                customer_phone: item.customer_phone,
                qty: item.items.reduce((total, item) => { return Number(total) + Number(item.quantity) }, 0),
                weight: weight,
                product: item.items[0].product.name,
                total_price: item.items.reduce((total, item) => { return Number(total) + Number(item.variant.sell_price) }, 0),
                grand_total: item.total_payment,
                note: '',
                branch_code: getVendorData.data.vendor.branch,
                origin_code: getVendorData.data.vendor.origin,
                destination_code: item.shipping_tariff_code,
                service: item.shipping_method,
                cod: item.payment_method == 'COD' ? 'YES' : 'NO'
            };

            let url_endpoint = '/api/integration/jne/generate-awb/';
            if(item.shipping_courier === 'Ninja Xpress') {
                url_endpoint = '/api/integration/ninja/generate-awb/';
                data.items = item.items.map(item => {
                    return {
                        item_description: item.product.name,
                        quantity: item.quantity,
                        is_dangerous_good: false
                    };
                });
                data.invoice = item.invoice;
                data.pickup_id = getVendorData.data.vendor._id;
            }

            try {
                await axios.post(API_URL + url_endpoint + item._id, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.getOrder(this.page, this.limit);
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                console.log(error)
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Export Order
         */
        async exportOrder() {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let filter = {
                    start_date: this.convertDate(this.filterDate.startDate),
                    end_date: this.convertDate(this.filterDate.endDate),
                }

                if(this.profileData.role !== 'ADMIN') {
                    filter.user = this.profileData.id
                }

                const response = await axios.get(API_URL + '/api/order/export-order', {
                    params: filter,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;
                const fileDownload = require('js-file-download');
                return fileDownload(response.data, 'export-order.csv');
            } catch (error) {
                console.log(error)
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
		 * Get Total Order
		 */
        async getTotalOrder(status) {
            try {
                let params = {};
                if(this.filterDate.startDate !== '') {
                    params.start_date = this.convertDate(this.filterDate.startDate);
                }
                if(this.filterDate.endDate !== '') {
                    params.end_date = this.convertDate(this.filterDate.endDate);
                }
                if(this.profileData.role === 'RESELLER') {
                    params.user = this.profileData.id;
                }
                if(this.profileData.role === 'CS') {
                    params.cs = this.profileData.id;
                }
                if(this.profileData.role === 'VENDOR') {
                    params.vendor = this.profileData.vendor;
                }
                if(status) {
                    params.status = status;
                }

                const response = await axios.get(`${API_URL}/api/report-order/get-total-order`, {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                if(status === 'PENDING') {
                    this.totalPending = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(status === 'PAID') {
                    this.totalPaid = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(status === 'ON PROCESS') {
                    this.totalOnProcess = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(status === 'SHIPPED') {
                    this.totalShipped = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(status === 'DELIVERED') {
                    this.totalDelivered = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(status === 'UNDELIVERY') {
                    this.totalUndelivered = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(status === 'RETURN') {
                    this.totalReturn = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(status === 'REFUND') {
                    this.totalRefund = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(status === 'CANCELLED') {
                    this.totalCancelled = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else{
                    this.totalOrder = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }
            } catch (error) {
                console.log(error)
            }
        },

        /*
		 * Open Follow Up Customer
		 */
        openFollowUpCustomer(item) {
            this.selectedOrder = item;
            this.followUpModal = true;
            this.follow_up_phone = this.phoneNumberFormatter(item.customer_phone);
            this.follow_up_content = `Selamat datang di Toko kami Kak ${item.customer_name}

Kami sudah terima pesanan anda dengan rincian sebagai berikut,
Produk: ${item.items[0].product.name}
Harga: Rp${formatPrice( item.items.reduce((total, val) => { return Number(total) + Number(val.variant.sell_price * val.quantity) },0) )}
Ongkir: Rp${formatPrice( Number(item.shipping_fee !== null ? item.shipping_fee : 0) )}
Total: Rp${formatPrice( item.total_payment )}

Dikirim ke:
Nama: ${item.customer_name}
No HP: ${item.customer_phone}
Alamat: ${item.customer_address}
Kota: ${item.customer_city}
Kecamatan: ${item.customer_district}

Jika boleh tau untuk lokasinya ada nama jalan atau RT/RW/Dusun atau tidak ya Kak? ada patokan bangunan seperti Mesjid/Gereja/Sekolah/Pasar/Jembatan/Tugu/Kantor Desa/ kantor Polisi  yang mudah di kenali agar Pak Kurir dah mudah menemukan alamatnya ? mohon konfirmasinya ya Kak ${item.customer_name}`;
        },
        /*
		 * Follow Up Customer
		 */
        followUpCustomer() {
            window.open(`https://wa.me/${this.follow_up_phone}?text=${encodeURIComponent(this.follow_up_content)}`, '_blank');
        },

        /*
		 * Print
		 */
        printOrder() {
            const selectedItems = this.selectedItems.map(item => {
                return item._id;
            })

            window.open('/order/print/' + selectedItems.toString(), '_blank');
            this.selectedItems = [];
        },

        /* Save Order Note */
        async saveOrderNote() {
            try {
                const data = {
                    order_note: this.order_note,
                };

                await axios.put(`${API_URL}/api/order/update/${this.selectedOrder._id}`, {order: data}, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                this.getOrder(this.page, this.limit);
                this.orderNoteToggle = false;
            } catch (error) {
                console.log(error)
                this.$router.push('/auth/login')
            }
        },

        /* Confirm Cancel Order */
        async confirmCancelOrder() {
            try {
                this.changeStatus(this.selectedOrder, 'CANCELLED');

                const data = {
                    cancel_reason: this.cancel_reason.value,
                };

                await axios.put(`${API_URL}/api/order/update/${this.selectedOrder}`, {order: data}, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                this.getOrder(this.page, this.limit);
                this.confirmCancelOrderModal = false;
            } catch (error) {
                console.log(error)
                this.$router.push('/auth/login')
            }
        },

        /*
         * Update Guide
         */
        async updateGuide() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Update Type */
                update_type: 'order-monitor'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

		/*
        * Other Helpers
        */
        /* Download Resi Massal */
        downloadResiMassal() {
            this.selectedItems.forEach(item => {
                if(item.order_type === 'direct') {
                    window.open(item.cashless_proof, '_blank');
                }
            });
        },
        /* Filter Status */
        filterStatusOrder(status) {
            this.statusOrder = status;
            this.getOrder(1, this.limit);
        },
        convertDate(date) {
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        phoneNumberFormatter(phone) {
            if(phone.substr(0,1) === '0') {
                const oldStr = phone.substr(0,1);
                return phone.replace(oldStr, '62');
            }else if(phone.substr(0,1) === '+') {
                return phone.replace('+', '');
            }
        },
        formatDate,
        formatPrice,
        formatTime(date) {
            var format = date ? new Date(date).toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' }) : ''
            return format;
        }
    }
}
</script>

<style scoped>
</style>