import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index' // Import Store Vuex
/* Authentication */
import Login from '../pages/Auth/Login.vue'
import LoginAdmin from '../pages/Auth/LoginAdmin.vue'
import LoginAsUser from '../pages/Auth/LoginAsUser.vue'
// import Register from '../pages/Auth/Register.vue'
import RegisterWhatsappOtp from '../pages/Auth/Register/WhatsappOtp.vue'
import VendorRegister from '../pages/Auth/Register/VendorRegister.vue'
import EditProfile from '../pages/Auth/EditProfile.vue'
import ForgotPassword from '../pages/Auth/ForgotPassword.vue'
import Topup from '../pages/Auth/TopupSaldo.vue'
import TopupPayment from '../pages/Auth/TopupSaldoPayment.vue'
/* Dashboard */
import Dashboard from '../pages/Dashboard.vue'
/* Selling */
import SellingFormOrder from '../pages/Selling/FormOrder.vue'
import SellingManualOrder from '../pages/Selling/ManualOrder.vue'
import SellingMarketplace from '../pages/Selling/Marketplace.vue'
/* Management Product */
import AddProduct from '../pages/Product/AddProduct.vue'
import EditProduct from '../pages/Product/EditProduct.vue'
import ListProduct from '../pages/Product/ListProduct.vue'
import ImportProductShopee from '../pages/Product/ImportProductShopee.vue'
import ImportProductLazada from '../pages/Product/ImportProductLazada.vue'
import ListProductByGroup from '../pages/Product/ListProductByGroup.vue'
import ListProductSearch from '../pages/Product/ListProductSearch.vue'
import ListProductAdmin from '../pages/Product/ListProductAdmin.vue'
/* My Product */
import MyProductList from '../pages/Product/MyProduct/MyProductList.vue'
/* My Form */
import AddMyForm from '../pages/Product/MyForm/AddMyForm.vue'
import EditMyForm from '../pages/Product/MyForm/EditMyForm.vue'
import ListMyForm from '../pages/Product/MyForm/ListMyForm.vue'
/* Management Category */
import AddCategory from '../pages/Category/AddCategory.vue'
import EditCategory from '../pages/Category/EditCategory.vue'
import ListCategory from '../pages/Category/ListCategory.vue'
/* Management Brand */
import AddBrand from '../pages/Brand/AddBrand.vue'
import EditBrand from '../pages/Brand/EditBrand.vue'
import ListBrand from '../pages/Brand/ListBrand.vue'
/* Management User */
import AddUser from '../pages/User/AddUser.vue'
import EditUser from '../pages/User/EditUser.vue'
import ListUser from '../pages/User/ListUser.vue'
/* Management Order */
import ListOrder from '../pages/Order/ListOrder.vue'
import Cart from '../pages/Order/Cart.vue'
import Payment from '../pages/Order/Payment.vue'
import PrintLabel from '../pages/Order/PrintLabel.vue'
import CreateOrder from '../pages/Order/CreateOrder.vue'
/* Management Inventory */
import ListStock from '../pages/Inventory/ListStock.vue'
/* Management Report */
import ResellerCashback from '../pages/Report/ResellerCashback.vue'
import ResellerProfit from '../pages/Report/ResellerProfit.vue'
import VendorCOGS from '../pages/Report/VendorCOGS.vue'
import PlatformAdminFee from '../pages/Report/PlatformAdminFee.vue'
import PlatformCashback from '../pages/Report/PlatformCashback.vue'
import PlatformVendorAdminFee from '../pages/Report/PlatformVendorAdminFee.vue'
/* Management Finance */
import AddSettlement from '../pages/Finance/Settlement/AddSettlement.vue'
import ListSettlement from '../pages/Finance/Settlement/ListSettlement.vue'
import ResellerTransactionHistory from '../pages/Finance/TransactionHistory/ResellerTransactionHistory.vue'
import VendorTransactionHistory from '../pages/Finance/TransactionHistory/VendorTransactionHistory.vue'
import PlatformTransactionHistory from '../pages/Finance/TransactionHistory/PlatformTransactionHistory.vue'
import ListTopup from '../pages/Finance/ListTopup.vue'
/* Management Settings */
// import Settings from '../pages/Settings/SettingPanel.vue'
import MarketplaceIntegration from '../pages/Settings/Marketplace/MarketplaceIntegration.vue'
import EditAccount from '../pages/Settings/Account/EditAccount.vue'
import AccountDropship from '../pages/Settings/Account/AccountDropship.vue'
import SelectWarehouse from '../pages/Settings/Marketplace/SelectWarehouse.vue'
import AddBank from '../pages/Settings/Bank/AddBank.vue'
import EditBank from '../pages/Settings/Bank/EditBank.vue'
import ListBank from '../pages/Settings/Bank/ListBank.vue'
/* Management Team Member */
import AddMember from '../pages/Team/AddMember.vue'
import EditMember from '../pages/Team/EditMember.vue'
import ListMember from '../pages/Team/ListMember.vue'
/* Integration */
import ShopeeIntegration from '../pages/Settings/Marketplace/ShopeeIntegration'
import TiktokShopIntegration from '../pages/Settings/Marketplace/TiktokShopIntegration'
import LazadaIntegration from '../pages/Settings/Marketplace/LazadaIntegration'
import ShopeeEditProduct from '../pages/Integration/Shopee/EditProduct'
import ShopeeListProduct from '../pages/Integration/Shopee/ListProduct'
import ShopeeSyncProduct from '../pages/Integration/Shopee/SyncProduct.vue'
import TiktokShopEditProduct from '../pages/Integration/TiktokShop/EditProduct'
import TiktokShopListProduct from '../pages/Integration/TiktokShop/ListProduct'
import LazadaEditProduct from '../pages/Integration/Lazada/EditProduct'
import LazadaListProduct from '../pages/Integration/Lazada/ListProduct'
import Watermark from '../pages/Integration/Watermark'
/* Affiliate */
import Affiliate from '../pages/Affiliate/Affiliate.vue'
/* Support */
import SupportFormTicket from '../pages/Support/FormTicket.vue'

const routes = [
    /* Authentication Route */
    {
        path: '/auth/login',
        name: 'login',
        component: Login,
        meta: {
            group: 'auth'
        }
    },
    {
        path: '/ketoprak',
        name: 'login-admin',
        component: LoginAdmin,
        meta: {
            group: 'auth'
        }
    },
    {
        path: '/login-as-user/:token',
        name: 'login-as-user',
        component: LoginAsUser,
        meta: {
            group: 'auth'
        }
    },
    {
        path: '/auth/register',
        name: 'register',
        component: RegisterWhatsappOtp,
        meta: {
            group: 'auth'
        }
    },
    {
        path: '/auth/register-whatsapp-otp',
        name: 'register-whatsapp-otp',
        component: RegisterWhatsappOtp,
        meta: {
            group: 'auth'
        }
    },
    {
        path: '/daftar-supplier',
        name: 'daftar-supplier',
        component: VendorRegister,
        meta: {
            group: 'auth'
        }
    },
    {
        path: '/auth/edit-profile',
        name: 'edit-profile',
        component: EditProfile,
        meta: {
            requiresAuth: true,
            group: 'auth'
        }
    },
    {
        path: '/auth/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            group: 'auth'
        }
    },
    {
        path: '/auth/topup',
        name: 'topup',
        component: Topup,
        meta: {
            requiresAuth: true,
            group: 'auth'
        }
    },
    {
        path: '/auth/topup-payment',
        name: 'topup-payment',
        component: TopupPayment,
        meta: {
            requiresAuth: true,
            group: 'auth'
        }
    },

    /* Dashboard Route */
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            group: 'dashboard'
        }
    },

    /* Selling Route */
    {
        path: '/selling/form-order',
        name: 'selling-form-order',
        component: SellingFormOrder,
        meta: {
            requiresAuth: true,
            group: 'selling'
        }
    },
    {
        path: '/selling/manual-order',
        name: 'selling-manual-order',
        component: SellingManualOrder,
        meta: {
            requiresAuth: true,
            group: 'selling'
        }
    },
    {
        path: '/selling/marketplace',
        name: 'selling-marketplace',
        component: SellingMarketplace,
        meta: {
            requiresAuth: true,
            group: 'selling'
        }
    },
    
    /* Management Product Route */
    {
        path: '/product/add',
        name: 'add-product',
        component: AddProduct,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/product/edit/:id',
        name: 'edit-product',
        component: EditProduct,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/product/list',
        name: 'list-product',
        component: ListProduct,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/product-shopee/import',
        name: 'import-product-shopee',
        component: ImportProductShopee,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/product-lazada/import',
        name: 'import-product-lazada',
        component: ImportProductLazada,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/product/list-group/:group',
        name: 'list-group-product',
        component: ListProductByGroup,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/product/list-search/:search',
        name: 'list-search-product',
        component: ListProductSearch,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/product/list-admin',
        name: 'list-admin-product',
        component: ListProductAdmin,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },

    /* Management My Product Route */
    {
        path: '/my-product/list',
        name: 'my-product',
        component: MyProductList,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },

    /* Management My Form Route */
    {
        path: '/my-form/add/:slug',
        name: 'add-my-form',
        component: AddMyForm,
        meta: {
            requiresAuth: true,
            group: 'my_form'
        }
    },
    {
        path: '/my-form/edit/:id',
        name: 'edit-my-form',
        component: EditMyForm,
        meta: {
            requiresAuth: true,
            group: 'my_form'
        }
    },
    {
        path: '/my-form/list',
        name: 'list-my-form',
        component: ListMyForm,
        meta: {
            requiresAuth: true,
            group: 'my_form'
        }
    },

    /* Management Category Route */
    {
        path: '/category/add',
        name: 'add-category',
        component: AddCategory,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/category/edit/:id',
        name: 'edit-category',
        component: EditCategory,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/category/list',
        name: 'list-category',
        component: ListCategory,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },

    /* Management Brand Route */
    {
        path: '/brand/add',
        name: 'add-brand',
        component: AddBrand,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/brand/edit/:id',
        name: 'edit-brand',
        component: EditBrand,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },
    {
        path: '/brand/list',
        name: 'list-brand',
        component: ListBrand,
        meta: {
            requiresAuth: true,
            group: 'product'
        }
    },

    /* Management User Route */
    {
        path: '/user/add',
        name: 'add-user',
        component: AddUser,
        meta: {
            requiresAuth: true,
            group: 'user'
        }
    },
    {
        path: '/user/edit/:id',
        name: 'edit-user',
        component: EditUser,
        meta: {
            requiresAuth: true,
            group: 'user'
        }
    },
    {
        path: '/user/list',
        name: 'list-user',
        component: ListUser,
        meta: {
            requiresAuth: true,
            group: 'user'
        }
    },

    /* Management Order & Cart Route */
    {
        path: '/order/list',
        name: 'list-order',
        component: ListOrder,
        meta: {
            requiresAuth: true,
            group: 'order'
        }
    },
    {
        path: '/cart',
        name: 'cart',
        component: Cart,
        meta: {
            requiresAuth: true,
            group: 'cart'
        }
    },
    {
        path: '/payment',
        name: 'payment',
        component: Payment,
        meta: {
            requiresAuth: true,
            group: 'payment'
        }
    },
    {
        path: '/order/print/:id',
        name: 'print-label',
        component: PrintLabel,
        meta: {
            requiresAuth: true,
            group: 'order'
        }
    },
    {
        path: '/order/create',
        name: 'create-order',
        component: CreateOrder,
        meta: {
            requiresAuth: true,
            group: 'create-order'
        }
    },

    /* Management Inventory Route */
    {
        path: '/stock/list',
        name: 'list-stock',
        component: ListStock,
        meta: {
            requiresAuth: true,
            group: 'inventory'
        }
    },

    /* Report Route */
    {
        path: '/report/reseller-cashback',
        name: 'reseller-cashback',
        component: ResellerCashback,
        meta: {
            requiresAuth: true,
            group: 'report'
        }
    },
    {
        path: '/report/reseller-profit',
        name: 'reseller-profit',
        component: ResellerProfit,
        meta: {
            requiresAuth: true,
            group: 'report'
        }
    },
    {
        path: '/report/vendor-cogs',
        name: 'vendor-cogs',
        component: VendorCOGS,
        meta: {
            requiresAuth: true,
            group: 'report'
        }
    },
    {
        path: '/report/platform-admin-fee',
        name: 'platform-admin-fee',
        component: PlatformAdminFee,
        meta: {
            requiresAuth: true,
            group: 'report'
        }
    },
    {
        path: '/report/platform-cashback',
        name: 'platform-cashback',
        component: PlatformCashback,
        meta: {
            requiresAuth: true,
            group: 'report'
        }
    },
    {
        path: '/report/platform-vendor-admin-fee',
        name: 'platform-vendor-admin-fee',
        component: PlatformVendorAdminFee,
        meta: {
            requiresAuth: true,
            group: 'report'
        }
    },

    /* Finance Route */
    {
        path: '/finance/settlement/add',
        name: 'add-settlement',
        component: AddSettlement,
        meta: {
            requiresAuth: true,
            group: 'finance'
        }
    },
    {
        path: '/finance/settlement/list',
        name: 'list-settlement',
        component: ListSettlement,
        meta: {
            requiresAuth: true,
            group: 'finance'
        }
    },
    {
        path: '/finance/reseller-transaction-history',
        name: 'reseller-transaction-history',
        component: ResellerTransactionHistory,
        meta: {
            requiresAuth: true,
            group: 'finance'
        }
    },
    {
        path: '/finance/vendor-transaction-history',
        name: 'vendor-transaction-history',
        component: VendorTransactionHistory,
        meta: {
            requiresAuth: true,
            group: 'finance'
        }
    },
    {
        path: '/finance/platform-transaction-history',
        name: 'platform-transaction-history',
        component: PlatformTransactionHistory,
        meta: {
            requiresAuth: true,
            group: 'finance'
        }
    },
    {
        path: '/finance/topup/list',
        name: 'list-topup',
        component: ListTopup,
        meta: {
            requiresAuth: true,
            group: 'finance'
        }
    },

    /* Settings Route */
    // {
    //     path: '/settings',
    //     name: 'settings',
    //     component: Settings,
    //     meta: {
    //         requiresAuth: true,
    //         group: 'settings'
    //     }
    // },
    {
        path: '/settings/marketplace-integration',
        name: 'settings-marketplace-integration',
        component: MarketplaceIntegration,
        meta: {
            requiresAuth: true,
            group: 'settings'
        }
    },
    {
        path: '/settings/marketplace-integration/shopee',
        name: 'settings-shopee-integration',
        component: ShopeeIntegration,
        meta: {
            requiresAuth: true,
            group: 'settings'
        }
    },
    {
        path: '/settings/marketplace-integration/tiktokshop',
        name: 'settings-tiktokshop-integration',
        component: TiktokShopIntegration,
        meta: {
            requiresAuth: true,
            group: 'settings'
        }
    },
    {
        path: '/settings/marketplace-integration/lazada',
        name: 'settings-lazada-integration',
        component: LazadaIntegration,
        meta: {
            requiresAuth: true,
            group: 'settings'
        }
    },
    // {
    //     path: '/settings/marketplace-integration/:platform',
    //     name: 'settings-marketplace-integration-with-platform',
    //     component: MarketplaceIntegration,
    //     meta: {
    //         requiresAuth: true,
    //         group: 'settings'
    //     }
    // },
    {
        path: '/settings/edit-account',
        name: 'settings-edit-account',
        component: EditAccount,
        meta: {
            requiresAuth: true,
            group: 'settings'
        }
    },
    {
        path: '/settings/dropship-setting',
        name: 'settings-dropship-setting',
        component: AccountDropship,
        meta: {
            requiresAuth: true,
            group: 'settings'
        }
    },
    {
        path: '/settings/select-warehouse',
        name: 'settings-select-warehouse',
        component: SelectWarehouse,
        meta: {
            requiresAuth: true,
            group: 'settings'
        }
    },

    {
        path: '/bank/add',
        name: 'add-bank',
        component: AddBank,
        meta: {
            requiresAuth: true,
            group: 'settings'
        }
    },
    {
        path: '/bank/edit/:id',
        name: 'edit-bank',
        component: EditBank,
        meta: {
            requiresAuth: true,
            group: 'settings'
        }
    },
    {
        path: '/bank/list',
        name: 'list-bank',
        component: ListBank,
        meta: {
            requiresAuth: true,
            group: 'settings'
        }
    },

    /* Management Team Member Route */
    {
        path: '/member/add',
        name: 'add-member',
        component: AddMember,
        meta: {
            requiresAuth: true,
            group: 'my_form'
        }
    },
    {
        path: '/member/edit/:id',
        name: 'edit-member',
        component: EditMember,
        meta: {
            requiresAuth: true,
            group: 'my_form'
        }
    },
    {
        path: '/member/list',
        name: 'list-member',
        component: ListMember,
        meta: {
            requiresAuth: true,
            group: 'my_form'
        }
    },

    /* Integration Route */
    {
        path: '/shopee/edit-product/:slug',
        name: 'shopee-edit-product',
        component: ShopeeEditProduct,
        meta: {
            requiresAuth: true,
            group: 'marketplace'
        }
    },
    {
        path: '/shopee/list-product',
        name: 'shopee-list-product',
        component: ShopeeListProduct,
        meta: {
            requiresAuth: true,
            group: 'marketplace'
        }
    },
    {
        path: '/shopee/sync-product',
        name: 'shopee-sync-product',
        component: ShopeeSyncProduct,
        meta: {
            requiresAuth: true,
            group: 'marketplace'
        }
    },
    {
        path: '/tiktokshop/edit-product/:slug',
        name: 'tiktokshop-edit-product',
        component: TiktokShopEditProduct,
        meta: {
            requiresAuth: true,
            group: 'marketplace'
        }
    },
    {
        path: '/tiktokshop/list-product',
        name: 'tiktokshop-list-product',
        component: TiktokShopListProduct,
        meta: {
            requiresAuth: true,
            group: 'marketplace'
        }
    },
    {
        path: '/lazada/edit-product/:slug',
        name: 'lazada-edit-product',
        component: LazadaEditProduct,
        meta: {
            requiresAuth: true,
            group: 'marketplace'
        }
    },
    {
        path: '/lazada/list-product',
        name: 'lazada-list-product',
        component: LazadaListProduct,
        meta: {
            requiresAuth: true,
            group: 'marketplace'
        }
    },
    {
        path: '/watermark',
        name: 'watermark',
        component: Watermark,
        meta: {
            requiresAuth: true,
            group: 'marketplace'
        }
    },

    /* Affiliate Route */
    {
        path: '/affiliate/dashboard',
        name: 'affiliate-dashboard',
        component: Affiliate,
        meta: {
            requiresAuth: true,
            group: 'affiliate'
        }
    },
    {
        path: '/affiliate/link',
        name: 'affiliate-link',
        component: Affiliate,
        meta: {
            requiresAuth: true,
            group: 'affiliate'
        }
    },
    {
        path: '/affiliate/payout',
        name: 'affiliate-payout',
        component: Affiliate,
        meta: {
            requiresAuth: true,
            group: 'affiliate'
        }
    },

    /* Support Route */
    {
        path: '/support/form-ticket',
        name: 'support-form-ticket',
        component: SupportFormTicket,
        meta: {
            requiresAuth: true,
            group: 'support'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

/* Navigation Guard for Auth Access */
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['auth/isLoggedIn']) {
            next()
            return
        }
        next('/auth/login')
    } else {
        next()
    }
})

export default router
