<template>
    <div class="bg-white rounded border border-gray-300 p-6 md:w-3/4 w-full">
        <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Dashboard Affiliasi</h2>

        <!-- Info Affiliasi -->

        <!-- Statistik Affiliasi -->
        <div class="md:grid md:grid-cols-4 gap-4 mb-5 items-center">
            <div class="bg-green-50 rounded border border-gray-300 p-5 py-4 md:mb-0 mb-2">
                <h4 class="font-statistics text-gray-500 font-medium text-sm text-left mb-1">Saldo Komisi</h4>
                <h1 class="font-statistics text-3xl font-bold text-gray-600 text-left">Rp1.000.000</h1>
            </div>
            <div class="bg-white rounded border border-gray-300 p-5 py-4 md:mb-0 mb-2">
                <h4 class="font-statistics text-gray-500 font-medium text-sm text-left mb-1">Total Klik</h4>
                <h1 class="font-statistics text-3xl font-bold text-gray-600 text-left">247</h1>
            </div>
            <div class="bg-white rounded border border-gray-300 p-5 py-4 md:mb-0 mb-2">
                <h4 class="font-statistics text-gray-500 font-medium text-sm text-left mb-1">Total Referral</h4>
                <h1 class="font-statistics text-3xl font-bold text-gray-600 text-left">40</h1>
            </div>
            <div class="bg-white rounded border border-gray-300 p-5 py-4 md:mb-0 mb-2">
                <h4 class="font-statistics text-gray-500 font-medium text-sm text-left mb-1">Konversi</h4>
                <h1 class="font-statistics text-3xl font-bold text-gray-600 text-left">20%</h1>
            </div>
        </div>
        <div class="md:grid md:grid-cols-4 gap-4 mb-5 items-center">
            <div class="bg-white rounded border border-gray-300 p-5 py-4 md:mb-0 mb-2">
                <h4 class="font-statistics text-gray-500 font-medium text-sm text-left mb-1">Komisi belum dibayar</h4>
                <h1 class="font-statistics text-3xl font-bold text-gray-600 text-left">Rp1.000.000</h1>
            </div>
            <div class="bg-white rounded border border-gray-300 p-5 py-4 md:mb-0 mb-2">
                <h4 class="font-statistics text-gray-500 font-medium text-sm text-left mb-1">Komisi telah dibayar</h4>
                <h1 class="font-statistics text-3xl font-bold text-gray-600 text-left">247</h1>
            </div>
            <div class="bg-white rounded border border-gray-300 p-5 py-4 md:mb-0 mb-2">
                <h4 class="font-statistics text-gray-500 font-medium text-sm text-left mb-1">Referral Aktif</h4>
                <h1 class="font-statistics text-3xl font-bold text-gray-600 text-left">40</h1>
            </div>
            <div class="bg-white rounded border border-gray-300 p-5 py-4 md:mb-0 mb-2">
                <h4 class="font-statistics text-gray-500 font-medium text-sm text-left mb-1">Referral Pending</h4>
                <h1 class="font-statistics text-3xl font-bold text-gray-600 text-left">20%</h1>
            </div>
        </div>

        <!-- Rincian Referral dan Komisi -->
        <!----------->
        <!-- Table -->
        <!----------->
        <div class="w-full">
            <z-table
                title="Rincian Referral dan Komisi"
                :data="data.data"
                :meta="data.meta"
                :columns="columns"
                :mutipleCheck="false"
                :sorting="true"
                @sortingHandler="sortingHandler"
            >
                <template v-slot:category_id="{item}">
                    <span class="px-3">{{ item._id }}</span>
                </template>
            </z-table>
        </div>
        <!--------------->
        <!-- End Table -->
        <!--------------->

        <!---------------->
        <!-- Pagination -->
        <!---------------->
        <div class="mt-8">
            <z-pagination 
                :meta="data.meta"
                :PageNavHandler="pageNavHandler"
                :pageNavNumberHandler="pageNavNumberHandler"
            />
        </div>
        <!-------------------->
        <!-- End Pagination -->
        <!-------------------->
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

// import ZInput from '@/components/common/ZInput.vue';
// import ZButton from '@/components/common/ZButton.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';

export default {
    components: {
        // ZInput,
        // ZButton,
        ZTable,
        ZPagination
    },
    data() {
        return {
            /* Core Data */
			data: [],
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'category_id',
                    label: 'ID Kategori',
                    visibility: true
                },
                {
                    id: 2,
                    field: 'name',
                    label: 'Nama Kategori',
                    visibility: true
                },
			],
            /* Toggle */
            loading: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: '',
            sort_order: -1,
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getCategory(this.page, this.limit);
        //this.getUserById();
	},
    methods: {
        /*
		* Get List Category
		*/
		async getCategory(page, limit) {
            /* Run Loading */
            this.loadingToggle = true;

			const params = [
                'page='+page,
                'limit='+limit,
                'pagination='+'true',
                'search='+this.search,
                'sort='+JSON.stringify(this.sort_by)
			].join('&');

			try {
                const response = await axios.get(API_URL + '/api/category/get-all?' + params, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dimuat!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getCategory(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getCategory(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getCategory(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getCategory(this.page, this.limit);
            } else {
                this.search = value;
                this.getCategory(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getCategory(this.page, this.limit);
        },

        /*
         * Get User By Id
         */
        async getUserById () {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/user/get/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                this.reseller_url = response.data.data.reseller_url ? response.data.data.reseller_url : '';
                this.store_name = response.data.data.store_name ? response.data.data.store_name : '';
                this.store_logo = response.data.data.store_logo ? response.data.data.store_logo : '';
                this.store_phone = response.data.data.store_phone ? response.data.data.store_phone : '';

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

        /*
         * Update Pengguna
         */
        async updateUser () {
            /* Validasi Form Update */
            if(this.reseller_url === '') {
                this.$toast.error('Url toko wajib diisi!', {duration: 3000});
                return;
            }
            if(this.store_name === '') {
                this.$toast.error('Nama toko wajib diisi!', {duration: 3000});
                return;
            }

            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Reseller */
                reseller_url: this.reseller_url,
                store_name: this.store_name,
                store_logo: this.store_logo,
                store_phone: this.store_phone,
                /* Bank Information */
                bank_name: this.bank_name,
                bank_account_name: this.bank_account_name,
                bank_account_number: this.bank_account_number,
                /* Update Type */
                update_type: 'account-dropship'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                if(this.profileData.guide_form_order.setup_dropshipper_account === false && this.profileData.guide_manual_order.setup_dropshipper_account === false) {
                    if(this.$route.query.redirect_from == 'selling_form_order') {
                        this.$router.push('/selling/form-order');
                    }else if(this.$route.query.redirect_from == 'selling_manual_order') {
                        this.$router.push('/selling/manual-order');
                    }
                }

                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        this.$toast.success('Perubahan Berhasil Disimpan!', {duration: 3000});
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },
        /* Helpers */
        resellerURLFormatter(event) {
            this.reseller_url = event.target.value.replace(/[^a-zA-Z_-]/g, '').toLowerCase();
        }
    }
}
</script>

<style scoped>
</style>