<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="sm:flex sm:justify-between sm:items-center mb-8">
                <!-- Left: Title -->
                <div class="mb-4 sm:mb-0">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold">Riwayat Transaksi (Saldo)</h1>
                </div>

                <!-- Right: Actions  -->
                <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center">
                    <!-- Search form -->
                    <!-- <z-input placeholder="Cari merk..." v-model="search" @input="getBrand(page, limit)"></z-input> -->

                    <!-- <litepie-datepicker
                        :formatter="{ date: 'DD MMM YYYY', month: 'MMM' }"
                        v-model="filterDate"
                        :options="dateOptions"
                    ></litepie-datepicker> -->
                    
                    <!-- Add Button -->
                    <!-- <z-button @click="$router.push('/settlement/add')" class="bg-green-600 hover:bg-green-700 text-white flex items-center text-sm">
                        <svg class="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span class="ml-2">Tarik Dana</span>
                    </z-button> -->

                    <!-- Tarik Data Button -->
                    <z-button @click="exportTransactionHistory" class="bg-blue-600 hover:bg-blue-700 text-white flex items-center text-sm mr-2">
                        <svg class="w-5 h-5 fill-current opacity-50 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M18.948 11.112C18.511 7.67 15.563 5 12.004 5c-2.756 0-5.15 1.611-6.243 4.15-2.148.642-3.757 2.67-3.757 4.85 0 2.757 2.243 5 5 5h1v-2h-1c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.757 2.673-3.016l.581-.102.192-.558C8.153 8.273 9.898 7 12.004 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-2v2h2c2.206 0 4-1.794 4-4a4.008 4.008 0 0 0-3.056-3.888z"></path><path d="M13.004 14v-4h-2v4h-3l4 5 4-5z"></path>
                        </svg>
                        <span class="ml-2">Tarik Data Transaksi</span>
                    </z-button>
                </div>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!---------------->
            <!-- Statistics -->
            <!---------------->
            <div class="grid grid-cols-4 gap-4 mb-5">
                <div class="bg-white rounded border border-gray-300 p-6 flex items-center justify-center">
                    <div>
                        <h4 class="font-statistics text-gray-400 font-medium text-sm text-center mb-2">Total Saldo</h4>
                        <h1 class="font-statistics text-4xl font-bold text-center text-gray-800 mb-2">Rp{{ formatPrice(total_balance) }}</h1>
                    </div>
                </div>
            </div>
            <!-------------------->
            <!-- End Statistics -->
            <!-------------------->

            <!----------->
            <!-- Table -->
            <!----------->
            <div class="w-full">
                <z-table
                    title="Riwayat Transaksi"
                    :data="data.data"
                    :meta="data.meta"
                    :columns="columns"
                    :mutipleCheck="false"
                    @updateSelectedItems="updateSelectedItems"
                    :sorting="true"
                    @sortingHandler="sortingHandler"
                >
                    <template v-slot:amount="{item}">
                        <h4 class="font-proxima px-3">Rp{{ formatPrice(item.amount) }}</h4>
                    </template>
                    <template v-slot:open_balance="{item}">
                        <h4 class="font-proxima">Rp{{ formatPrice(item.open_balance) }}</h4>
                    </template>
                    <template v-slot:current_balance="{item}">
                        <h4 class="font-proxima">Rp{{ formatPrice(item.current_balance) }}</h4>
                    </template>
                    <template v-slot:order="{item}">
                        <h4 class="font-proxima">{{ item.order.invoice }}</h4>
                    </template>
                </z-table>
            </div>
            <!--------------->
            <!-- End Table -->
            <!--------------->

            <!---------------->
            <!-- Pagination -->
            <!---------------->
            <div class="mt-8">
                <z-pagination 
                    :meta="data.meta"
                    :PageNavHandler="pageNavHandler"
                    :pageNavNumberHandler="pageNavNumberHandler"
                />
            </div>
            <!-------------------->
            <!-- End Pagination -->
            <!-------------------->
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL
import formatPrice from '../../../utils/formatPrice.js'
//import dayjs from 'dayjs';

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
// import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
//import ZDropdown from '@/components/common/ZDropdown.vue';
//import ZSelect from '@/components/common/ZSelect.vue';

export default {
    components: {
        NavigationMenu,
        ZTable,
        ZPagination,
        // ZInput,
        ZButton,
        //ZDropdown,
        //ZSelect
    },
    data() {
        return {
            /* Core Data */
			data: [],
            selectedItems: [],
            dataSelected: null,
            deleteMutiple: false,
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'amount',
                    label: 'Jumlah',
                    visibility: true
                },
                {
                    id: 2,
                    field: 'open_balance',
                    label: 'Saldo Awal',
                    visibility: true
                },
                {
                    id: 3,
                    field: 'current_balance',
                    label: 'Saldo Akhir',
                    visibility: true
                },
                {
                    id: 4,
                    field: 'invoice',
                    label: 'Invoice',
                    visibility: true
                },
                {
                    id: 5,
                    field: 'description',
                    label: 'Keterangan',
                    visibility: true
                },
                // {
                //     id: 4,
                //     field: 'isPaid',
                //     label: 'Status Pencairan',
                //     visibility: true
                // },
                // {
                //     id: 5,
                //     field: 'action',
                //     label: 'Action',
                //     visibility: true,
                // },
			],
			/* Toggle */
			sidebarOpen: false,
            loading: false,
            confirmDeleteModal: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: '',
            sort_order: -1,
            filter_date: '',
            filterDate: {
                startDate: '',
                endDate: ''
            },
            /* Extra */
            total_balance: 0,
            dateOptions: {
                shortcuts: {
                    today: 'Hari ini',
                    yesterday: 'Kemarin',
                    past: period => period + ' hari terakhir',
                    currentMonth: 'Bulan ini',
                    pastMonth: 'Bulan lalu'
                },
                footer: {
                    apply: 'Terapkan',
                    cancel: 'Batal'
                }
            }
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    // watch: {
    //     filterDate() {
    //         this.getPlatformTransactionHistory(this.page, this.limit);
    //         this.getTotalBalance();
    //     }
    // },
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        // let today = new Date();
        // let start_date = dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('DD MMM YYYY');
        // let end_date = dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('DD MMM YYYY');
        // this.filterDate.startDate = start_date;
        // this.filterDate.endDate = end_date;
        this.getPlatformTransactionHistory(this.page, this.limit);
        this.getTotalBalance();
	},
	methods: {
		/*
		* Get Reseller Transaction History
		*/
		async getPlatformTransactionHistory(page, limit) {
            /* Run Loading */
            this.loadingToggle = true;

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                user: this.profileData.id,
            };

			try {
                const response = await axios.get(API_URL + '/api/income/platform-transaction-history', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dimuat!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getPlatformTransactionHistory(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getPlatformTransactionHistory(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getPlatformTransactionHistory(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getPlatformTransactionHistory(this.page, this.limit);
            } else {
                this.search = value;
                this.getPlatformTransactionHistory(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getPlatformTransactionHistory(this.page, this.limit);
        },

        /*
		* Get Total Balance
		*/
		async getTotalBalance() {
            /* Run Loading */
            this.loadingToggle = true;

            let endpoint_url = '';
            if(this.profileData.role === 'RESELLER') {
                endpoint_url = '/api/user/get/' + this.profileData.id;
            }else if(this.profileData.role === 'ADMIN') {
                endpoint_url = '/api/income/get-total-balance-platform';
            }else if(this.profileData.role === 'VENDOR') {
                endpoint_url = '/api/income/get-total-balance-vendor/' + this.profileData.vendor;
            }

			try {
                const response = await axios.get(API_URL + endpoint_url, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                if(this.profileData.role === 'RESELLER') {
                    this.total_balance = this.total_balance = response.data.data.balance ? response.data.data.balance : 0;
                }else if(this.profileData.role === 'ADMIN') {
                    this.total_balance = this.total_balance = response.data.data.total_balance ? response.data.data.total_balance : 0;
                }else if(this.profileData.role === 'VENDOR') {
                    this.total_balance = this.total_balance = response.data.data.balance ? response.data.data.balance: 0;
                }

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

				console.error(error);
			}
		},

        /*
         * Export Transaction History
         */
        async exportTransactionHistory() {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(API_URL + '/api/income/export-platform-transaction-history', {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;
                const fileDownload = require('js-file-download');
                return fileDownload(response.data, 'export-platform-transaction-history.csv');
            } catch (error) {
                console.log(error)
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

		/*
        * Other Helpers
        */
        convertDate(date) {
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        formatPrice
	}
}
</script>

<style scoped>
</style>