<template>
    <div>
        Login Sebagai User
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    created() {
        this.loginAsUser();
    },
    methods: {
        async loginAsUser() {
            this.loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Commit ke Vuex */
            this.$store.dispatch('auth/loginAsUser', { token: this.$route.params.token })
                .then(() => {
                    this.$router.push('/');
                    this.loading.hide();
                    this.loading = null;
                }).catch(err => {
                    /* Gagal munculkan alert */
                    if (err) {
                        /* Show Error Message */
                        this.error_message = err.response.data.message;
                        this.alertToggle = true;
                        //this.loadingButton = false;
                        this.loading.hide();
                        this.loading = null;
                    }
                });
        }
    }
}
</script>