<template>
    <ZModal id="detail-product-modal" title="Pilih Variant/Model Produk" :modalOpen="modalOpen" size="max-w-2xl" @close-modal="this.$emit('closeModal')">
        <div class="p-6" v-if="data_model && data_model.length > 0">

            <!-- Product Model -->
            <h4 class="font-proxima font-bold mb-2">Produk Model</h4>
            <div class="relative overflow-x-auto border border-gray-300">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                SKU & Variasi
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Harga
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Stok
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Qty
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Aksi
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in data_model" :key="index" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 dark:text-white whitespace-nowrap">
                                <h3 class="font-semibold text-gray-900">{{ item.sku }}</h3>
                                <h4 class="font-medium text-gray-600 text-sm">{{ item.variant }}</h4>
                            </th>
                            <td class="px-6 py-4">
                                Rp{{ formatPrice(item.price) }}
                            </td>
                            <td class="px-6 py-4">
                                {{ item.stock }}
                            </td>
                            <td class="px-6 py-4">
                                <div class="custom-number-input h-8 w-24">
                                    <div class="flex flex-row h-8 w-full rounded-lg relative bg-transparent mt-1">
                                        <button @click="item.qty--" :disabled="item.qty < 1" data-action="decrement" class="border border-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-16 cursor-pointer outline-none rounded-l">
                                            <span class="m-auto text-xl" style="line-height: 15px">−</span>
                                        </button>
                                        <input v-model="item.qty" type="number" class="border-t border-b border-gray-200 outline-none focus:outline-none text-center w-full font-semibold text-sm hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700" name="custom-input-number">
                                        <button @click="item.qty++" data-action="increment" class="border border-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-16 cursor-pointer rounded-r">
                                            <span class="m-auto text-xl" style="line-height: 15px">+</span>
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <button @click="addToCart(item)" class="px-3 py-1 font-medium text-xs bg-blue-500 hover:bg-blue-600 rounded text-white">Tambah ke<br/>keranjang</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </ZModal>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatPrice from '../../utils/formatPrice.js'
import ZModal from '@/components/common/ZModal.vue';

export default {
    props: ['modalOpen', 'product'],
    components: {
        ZModal
    },
    data() {
        return {
            data_model: []
        }
    },
    created() {
        this.data_model = this.product.variants.map(item => {
            return {
                sku: item.sku,
                variant: item.options.toString().replace(',', ' '),
                price: item.price,
                qty: 0,
                stock: item.stock.stock
            }
        });
    },
    methods: {
        /* Add To Cart */
        async addToCart(item) {
            /* Check Apakah Stok Tersedia atau tidak? */
            if(item.stock < item.qty) {
                this.$toast.error('Stok tidak tersedia', {duration: 3000});
                return
            }

            try {
                const items = {
                    /* Product Detail + Harga Satuan */
                    vendor: this.product.vendor,
                    product: this.product,
                    variant: item,
                    /* Qty */
                    quantity: item.qty,
                };
                const response = await axios.post(`${API_URL}/api/cart/add-to-cart`, items, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                console.log(response);

                this.$toast.success('Produk Telah Ditambahkan Ke Keranjang Belanja', {duration: 3000});
                this.$emit('updateCart');
            } catch (error) {
                console.log(error)
                this.$router.push('/auth/login')
            }
        },
        /* Helpers */
        formatPrice
    }
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
-webkit-appearance: none;
    margin: 0;
}
</style>