<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-3xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Guide Dropshipper -->
            <div v-if="$route.query.redirect_from && $route.query.redirect_from == 'selling_marketplace'" class="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                <h2><b>Perhatian!</b><br/>Jika topup saldo berhasil silahkan kembali ke panduan => <a @click="$router.push('/selling/marketplace')" class="text-blue-600 hover:text-blue-500 cursor-pointer font-semibold">Lihat Panduan</a></h2>
            </div>

            <!-- Payment Method -->
            <div class="bg-white rounded-md mb-5 p-8">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima text-center">Topup Saldo</h2>

                <div class="p-8">
                    <!-- <h4 class="font-segoe-ui font-bold text-2xl block text-center mb-5" style="color: #333333">Barang berhasil dipesan.</h4> -->
                    <p class="font-clickcuan text-sm text-gray-500 text-center mb-8">Agar topup segera diproses, silahkan selesaikan pembayaran, Sesuai nominal dibawah ini.</p>
                    <h2 class="font-sans text-3xl text-green-700 font-bold text-center mb-8">Rp{{ formatPrice($route.query.total_payment) }}</h2>
                    <div class="border-t border-dashed border-gray-300 mb-8"></div><!-- Divider -->
                    <p class="font-clickcuan text-sm text-gray-500 text-center mb-5">Ke rekening dibawah ini</p>
                    <div class="mb-8" v-for="(item, index) in bank_data" :key="index">
                        <div class="text-center">
                            <h4 class="text-lg font-semibold">{{ item.bank_name }}</h4>
                            <h4 class="font-bold font-proxima">{{ item.account_number }}</h4>
                            <h4 class="text-gray-600">A.N {{ item.account_name }}</h4>
                        </div>
                    </div>
                    <div class="border-t border-dashed border-gray-300 mb-8"></div><!-- Divider -->
                </div>
            </div>

            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
// import formatDate from '../../utils/dateFormat.js'
import formatPrice from '../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';

export default {
    components: {
        NavigationMenu,
    },
    data() {
        return {
            /* Data */
            bank_data: [],
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        },
	},
	async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/signin');
            });
        }

        this.getBank();
	},
	methods: {
		/*
        * Get Bank
        */
        async getBank() {
            try {
                const response = await axios.get(`${API_URL}/api/bank/get-all`, {
                    params: {
                        pagination: false
                    }
                });
                
                /* Set Province Options */
                this.bank_data = response.data;
            } catch (error) {
                console.log(error)
            }
        },
        formatPrice
    }
}
</script>

<style scoped>
</style>