<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu :total_item="total_item" />
        <section v-if="loading" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Guide Dropshipper -->
            <div v-if="$route.query.redirect_from && $route.query.redirect_from == 'selling_form_order'" class="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                <h2 class="text-base font-medium mb-2">Panduan Lihat Produk dan Buat Form Order</h2>
                <h4><b>Langkah 1</b>: Pilih produk lalu klik tombol <i>Detail Produk</i></h4>
                <h4><b>Langkah 2</b>: Jika sudah mengambil informasi produk, selanjutnya klik <i>Buat Form</i></h4>
            </div>
            <div v-if="$route.query.redirect_from && $route.query.redirect_from == 'selling_manual_order'" class="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                <h2 class="text-base font-medium mb-2">Panduan Lihat Produk dan Buat Pesanan Manual</h2>
                <h4><b>Langkah 1</b>: Pilih produk lalu klik tombol <i>Detail Produk</i>, Ambil informasi produk untuk kebutuhan jualan anda.</h4>
                <h4><b>Langkah 2</b>: Jika ada pesanan, anda bisa pesan manual disini => <a @click="$router.push('/order/create?redirect_from=selling_manual_order')" class="text-blue-600 hover:text-blue-500 cursor-pointer font-semibold">Buat Pesanan</a></h4>
            </div>

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="bg-white w-full px-5 py-4 rounded flex items-center space-x-3">
                <!-- Search form -->
                <z-input placeholder="Cari produk..." v-model="search" full class="w-4/5"></z-input>
                <!-- Button -->
                <button @click="$router.push(`/product/list-search/${search}`)" class="bg-yellow-500 hover:bg-yellow-600 px-3 py-2 text-white font-proxima rounded border border-yellow-600 w-1/5">Cari</button>
            </div>

            <div class="w-full mt-6 rounded grid md:grid-cols-4 grid-cols-1 gap-3">
                <template v-for="(item, index) in data" :key="index">
                    <button v-if="item.vendor_product.length > 0" @click="$router.push('/product/list-group/' + item.vendor_id)" class="bg-white hover:bg-gray-50 flex items-center px-6 py-4 space-x-3 rounded">
                        <img src="@/assets/images/fulfillment4.png" width="42" />
                        <h4 class="font-proxima font-semibold text-left text-sm">Gudang<br/>{{ item.fulfillment_name }}</h4>
                    </button>
                </template>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!------------------>
            <!-- Product Card -->
            <!------------------>
            <ProductGroup
                :data="data"
                :shopee_token="shopee_token"
                :tiktok_token="tiktok_token"
                :lazada_token="lazada_token"
                :profileData="profileData"
                @addProductToListingProduct="addProductToListingProduct"
                @addToCart="addToCart"
                @detailProduct="detailProduct"
            />
            <!---------------------->
            <!-- End Product Card -->
            <!---------------------->
            
            <!-- Modal Area -->
            <ZDetailProduct v-if="detailProductModal === true" :product="selectedProduct" :modalOpen="detailProductModal" @closeModal="detailProductModal = false"></ZDetailProduct>
            <ZAddToCart v-if="addToCartModal === true" :product="selectedProduct" :modalOpen="addToCartModal" @closeModal="addToCartModal = false" @updateCart="getTotalCartItems"></ZAddToCart>
            <!-- End Modal Area -->

            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZDetailProduct from '@/components/special/ZDetailProduct.vue';
import ZAddToCart from '@/components/special/ZAddToCart.vue';
import ProductGroup from '@/components/product/ProductGroup.vue';

export default {
    components: {
        NavigationMenu,
        ZInput,
        ZDetailProduct,
        ZAddToCart,
        ProductGroup
    },
    data() {
        return {
            /* Core Data */
			data: [],
            dataSelected: null,
            selectedProduct: '',
            vendorData: [],
			/* Toggle */
			sidebarOpen: false,
            loading: false,
            detailProductModal: false,
            addToCartModal: false,
			/* Filter */
			search: '',
            /* Extra */
            total_item: 0,
            shopee_token: null,
            tiktok_token: null,
            lazada_token: null,
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        /* Validasi Vendor Terverifikasi */
        if(this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === undefined || this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === false) {
            this.$router.push('/');
        }

        if(this.$route.query.search) {
            this.search = this.$route.query.search;
            this.getVendor();
        }else{
            /* Pagination via URL */
            if(this.$route.query.page) {
                this.page = this.$route.query.page;
            }
            this.getVendor();
        }
        this.getProduct();
        /* Get Auth Token Platform */
        this.checkShopeeTokenLogin();
        this.checkTiktokShopTokenLogin();
        this.checkLazadaTokenLogin();

        if(this.$route.query.redirect_from && this.$route.query.redirect_from == 'selling_form_order' || this.$route.query.redirect_from == 'selling_manual_order') {
            this.updateGuide();
        }
	},
	methods: {
		/*
		* Get List Product
		*/
		async getProduct() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

			try {
                const response = await axios.get(API_URL + '/api/product/get-product-by-group', {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data.data.filter(item => item.vendor_id !== "63e9cfb7f8144715ecf34654");

                /* Stop Loading */
                loading.hide();
                this.loading = false;
			} catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
				console.error(error);
			}
		},

        /*
         * Get Vendor
         */
        async getVendor() {
            try {
                const response = await axios.get(`${API_URL}/api/vendor/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.vendorData = response.data;
            } catch (error) {
                console.error(error);
            }
        },

        /*
         * Get Total Cart Items
         * Desc: Ambil Data Item Keranjang Belanja
         */
        async getTotalCartItems() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });
            
            try {
                const response = await axios.get(`${API_URL}/api/cart/get-cart`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                this.total_item = response.data.data.cart_items.length > 0 ? response.data.data.cart_items.reduce((total, item) => { return Number(total) + Number(item.quantity) }, 0) : 0;
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                console.log(error)
            }
        },

        /*
		 * Add To Cart
		 */
        addToCart(value) {
            this.addToCartModal = value.addToCartModal;
            this.selectedProduct = value.selectedProduct;
        },
        /*
		 * Detail Product
		 */
        detailProduct(value) {
            this.detailProductModal = value.detailProductModal;
            this.selectedProduct = value.selectedProduct;
        },

        /*
		 * Add Product To Listing Product
		 */
        async addProductToListingProduct(product) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let data = {
                    product: product,
                    user: this.profileData.id
                };

                await axios.post(`${API_URL}/api/my-product/add`, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Refresh Page */
                this.$toast.success('Produk Telah Ditambahkan Ke Listing Produk Anda', {duration: 3000});
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal disetujui!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
            }
        },

        /*
         * Check Shopee Token Login
         */
        async checkShopeeTokenLogin() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.shopee_token = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /*
         * Check Tiktok Shop Token Login
         */
        async checkTiktokShopTokenLogin() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/tiktokshop/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.tiktok_token = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /*
         * Check Lazada Token Login
         */
        async checkLazadaTokenLogin() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/lazada/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.lazada_token = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Update Guide
         */
        async updateGuide() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Update Type */
                update_type: 'list-product'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        }
	}
}
</script>

<style scoped>
</style>