<template>
    <div class="space-y-2">
        <h2 class="font-bold font-proxima pb-2 mt-5">Pengaturan Stok & Harga</h2>
        <div class="grid grid-cols-3 gap-2 mb-3">
            <z-input v-model="variantMassPrice" placeholder="Harga Produk" type="number" full></z-input>
            <z-input v-model="variantMassQty" placeholder="Stok Produk" type="number" full></z-input>
            <z-button @click="generateValueProductVariant" class="text-white button-diginesia w-full">
                Terapkan Masal
            </z-button>
        </div>
        <div v-if="sku === ''" class="px-4 py-2 my-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
            Sku Belum Diisi! untuk generate harga & stok masal silahkan isi sku terlebih dahulu.
        </div>
        <div v-for="(item, index) in data_variant" :key="index" class="bg-gray-50 border border-gray-300 p-6">
            <h3 class="font-proxima font-semibold mb-3">{{ item.options.toString() }}</h3>
            <div class="grid grid-cols-2 gap-3 mb-3">
                <div>
                    <label class="font-proxima font-semibold block mb-1 text-sm">SKU</label>
                    <z-input v-model="item.sku" full @input="updateValue" disabled></z-input>
                </div>
                <div>
                    <label class="font-proxima font-semibold block mb-1 text-sm">Harga Jual</label>
                    <z-input v-model="item.price" full @input="updateValue"></z-input>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-3 mb-3">
                <div>
                    <label class="font-proxima font-semibold block mb-1 text-sm">Stok</label>
                    <z-input v-model="item.stock" full @input="updateValue"></z-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';

export default {
    props: {
        modelValue: {
            type: Array
        },
        sku: {
            type: String
        }
    },
    components: {
        ZInput,
        ZButton
    },
    data() {
        return {
            variantMassPrice: '',
            variantMassQty: '',
        }
    },
    computed: {
        data_variant() {
            return this.modelValue
        }
    },
    methods: {
        updateValue() {
            this.$emit('update:modelValue', this.data_variant);
        },
        generateValueProductVariant() {
            let data = {
                variantMassPrice: this.variantMassPrice,
                variantMassQty: this.variantMassQty
            };

            this.$emit('generateValueProductVariant', data)
        }
    }
}
</script>