import { createStore } from 'vuex';
/* Modules */
import auth from './modules/auth';
/* Tambah Vuex PersistedState */
import createPersistedState from "vuex-persistedstate";

export default createStore({
    modules: {
        auth,
    },
    /* Tambah Vuex PersistedState */
    plugins: [createPersistedState()],
})
