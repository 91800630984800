<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu :total_item="total_item" />
        <div class="max-w-3xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Address Setup -->
            <!-- <div class="bg-white rounded-md mb-5">
                <Address :address="address" @openListAddress="listAddressToggle = true" @openAddAddress="formAddressToggle = true" />
            </div> -->

            <!-- Cart Items -->
            <div class="bg-white rounded-md mb-5">
                <div class="px-8 pt-8">
                    <div class="p-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                        Warning! <b>SKU Produk</b> di Marketplace Anda Wajib Sama dengan <b>SKU Produk</b> yang ada di SatuStock. Agar Pesanan Terkirim dengan Lancar. Terima Kasih.
                    </div>
                </div>

                <ProductItems class="col-span-7 md:mb-0 mb-8" :items="cartItems" type="cart" @updateData="getCartItems" :adminFee="admin_fee" />
            </div>

            <!-- Resi Otomatis -->
            <div class="bg-white rounded-md mb-5 p-8">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima">Pilih Resi Otomatis</h2>

                <div class="mt-4 grid grid-cols-3 gap-3">
                    <div @click="cashless_platform = 'Lazada'" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="cashless_platform" value="Lazada" class="block">
                        <div>
                            <img src="@/assets/images/cashless_platform/lazada.png" class="w-full" />
                        </div>
                        <!-- <h3 class="font-semibold">Transfer</h3> -->
                    </div>
                    <div @click="cashless_platform = 'Shopee'" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="cashless_platform" value="Shopee" class="block">
                        <div>
                            <img src="@/assets/images/cashless_platform/shopee.png" class="w-full" />
                        </div>
                        <!-- <h3 class="font-semibold">COD</h3> -->
                    </div>
                    <div @click="cashless_platform = 'Tiktok Shop'" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="cashless_platform" value="Tiktok Shop">
                        <div>
                            <img src="@/assets/images/cashless_platform/tiktok.png" class="w-full" />
                        </div>
                    </div>
                    <div @click="cashless_platform = 'Tokopedia'" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="cashless_platform" value="Tokopedia" class="block">
                        <div>
                            <img src="@/assets/images/cashless_platform/tokopedia.png" class="w-full" />
                        </div>
                        <!-- <h3 class="font-semibold">Transfer</h3> -->
                    </div>
                    <div @click="cashless_platform = 'Bukalapak'" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="cashless_platform" value="Bukalapak" class="block">
                        <div>
                            <img src="@/assets/images/cashless_platform/bukalapak.png" class="w-full" />
                        </div>
                        <!-- <h3 class="font-semibold">COD</h3> -->
                    </div>
                    <div @click="cashless_platform = 'Mengantar'" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="cashless_platform" value="Mengantar">
                        <div>
                            <img src="@/assets/images/cashless_platform/mengantar.png" class="w-full" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Resi Otomatis -->
            <div class="bg-white rounded-md mb-5 p-8">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima">Upload Resi Otomatis</h2>

                <ZUploadPDF v-model="cashless_proof" class="mt-4"></ZUploadPDF>
            </div>

            <!-- Payment Method -->
            <div class="bg-white rounded-md mb-5 p-8">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima">Metode Pembayaran</h2>

                <div class="mt-4 grid grid-cols-2 gap-3">
                    <div @click="payment_method = 'Bank Transfer'" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="payment_method" value="Bank Transfer" class="block">
                        <h3 class="font-semibold">Bank Transfer</h3>
                    </div>
                </div>
            </div>

            <!-- Checkout Button -->
            <div class="bg-white rounded-md px-8 py-5">
                <template v-if="errors.length > 0">
                    <div v-for="(item, index) in errors" :key="index" class="p-4 my-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                        {{ item }}
                    </div>
                </template>
                <button @click="uploadCashlessProof" class="bg-green-600 hover:bg-green-500 px-4 py-2.5 text-white text-center font-semibold font-dosis rounded w-full">Pesan Sekarang</button>
            </div>
            
            <!-- Modal -->
            <!-- List Address -->
            <!-- <ZModal id="address-modal" :modalOpen="listAddressToggle" @close-modal="listAddressToggle = false" title="Daftar Alamat">
                <ListAddress v-if="listAddressToggle" @addAddress="listAddressToggle = false; formAddressToggle = true" @editAddress="editAddress" />
            </ZModal> -->
            <!-- Form Address -->
            <!-- <ZModal id="address-modal" :modalOpen="formAddressToggle" @close-modal="formAddressToggle = false" title="Form Alamat">
                <FormAddress v-if="formAddressToggle" :address="addressData" />
            </ZModal> -->


            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
// import formatDate from '../../utils/dateFormat.js'
// import formatPrice from '../../utils/formatPrice.js'
import * as _ from 'lodash';

import NavigationMenu from '@/components/header/NavigationMenu.vue';
// import Address from '@/components/cart/Address.vue';
// import ListAddress from '@/components/cart/ListAddress.vue';
// import FormAddress from '@/components/cart/FormAddress.vue';
import ProductItems from '@/components/cart/ProductItems.vue';
// import ZModal from '@/components/common/ZModal.vue';
import ZUploadPDF from '@/components/common/ZUploadPDF.vue';

export default {
    components: {
        NavigationMenu,
        // Address,
        // ListAddress,
        // FormAddress,
        ProductItems,
        // ZModal,
        ZUploadPDF,
    },
    data() {
        return {
            /* Address */
            // address: null,
            // addressData: null, // Untuk Edit Data
            /* Cart Items */
            cartItems: null,
            /* Shipping */
            cashless_platform: '',
            cashless_proof: null,
            /* Payment */
            payment_method: 'Bank Transfer',
            admin_fee: 0,
            /* Toggle */
            formAddressToggle: false,
            listAddressToggle: false,
            /* Extra */
            total_item: 0,
            errors: []
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
	async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/signin');
            });
        }

        // this.getPrimaryAddress();
        this.getCartItems();
	},
	methods: {
		// /*
        //  * Get Primary Address
        //  */
        // async getPrimaryAddress() {
        //     try {
        //         const response = await axios.get(`${API_URL}/api/address/get-primary/${this.profileData.id}`, {
        //             headers: {
        //                 'Authorization': this.$store.state.auth.token
        //             }
        //         });
                
        //         /* Set Address */
        //         this.address = response.data.data;
        //         //this.getTariff();
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
        // /*
        //  * Edit Address
        //  */
        // editAddress(value) {
        //     this.addressData = value;
        //     this.listAddressToggle = false;
        //     this.formAddressToggle = true;
        // },

        /*
         * Get Cart Items
         * Desc: Ambil Data Item Keranjang Belanja
         */
        async getCartItems() {
            this.isLoading = true;
            
            try {
                const response = await axios.get(`${API_URL}/api/cart/get-cart`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.cartItems = response.data.data.cart_items;
                this.total_item = response.data.data.cart_items.length > 0 ? response.data.data.cart_items.reduce((total, item) => { return Number(total) + Number(item.quantity) }, 0) : 0;
                this.admin_fee = response.data.data.cart_items.length > 0 ? Math.round( response.data.data.cart_items.reduce((total, item) => { return Number(total) + (Number(item.variant.price) * Number(item.quantity)) }, 0) ) * ( 1 / 100 ) : 0;
                //this.cartItems = _.groupBy(response.data.data.cart_items, item => item.vendor.city);
                this.isLoading = false;

                if(response.data.data.cart_items.length === 0) {
                    // /* Reset Cart */
                    // try {
                    //     await axios.delete(`${API_URL}/api/cart/reset-cart`, {
                    //         headers: {
                    //             'Authorization': this.$store.state.auth.token
                    //         }
                    //     });
                    // } catch (error) {
                    //     console.log(error)
                    // }
                    this.$router.push('/product/list');
                }
            } catch (error) {
                window.location.href = '/product/list';
                this.isLoading = false;
                // this.$store.dispatch('auth/logout').then(() => {
                //     this.$router.push('/auth/login');
                // });
            }
        },

        /*
         * Upload Bukti Resi Otomatis
         */
        async uploadCashlessProof() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            this.checkForm();
            if(this.errors.length > 0) {
                return;
            }

            /* Mapping Images - Upload ke Cloudinary*/
            try {
                if(this.cashless_proof.file) {
                    let formData = new FormData();
                    formData.append("file", this.cashless_proof.file);

                    const image = await axios.post(API_URL + '/api/media/upload-pdf', formData, {
                        headers: {
                            'Authorization': this.$store.state.auth.token
                        }
                    });

                    /* Extract Product berdasarkan Supplier/Vendor */
                    const data_order = [];
                    const items = _.groupBy(this.cartItems, item => item.vendor._id);
                    Object.keys(items).forEach((key, index) => {
                        const admin_fee = index === 0 ? this.admin_fee : 0;
                        const total_payment = Number( items[key].reduce((total, val) => { return total + (val.variant.price * val.quantity) }, 0) ) + Number(admin_fee);
                        let data = {
                            items: items[key],
                            admin_fee: admin_fee,
                            total_payment: total_payment,
                            vendor: key,
                            cashless_proof: image.data.file_url
                        }

                        data_order.push(data);
                    });

                    /* Create Order */
                    for(let i = 0; i < data_order.length; i++){
                        await new Promise((resolve) => {
                            setTimeout(() => {
                                this.createOrder(data_order[i]);
                                resolve();
                            }, 3000);
                        });
                    }

                    /* Stop Loading */
                    loading.hide();
                    this.loading = false;

                    /* Redirect After Create Order */
                    const total_payment = this.cartItems.reduce((total, item) => { return total + (item.variant.price * item.quantity) }, 0) + this.admin_fee;
                    this.$router.push('/payment?total_payment=' + total_payment);

                    /* Reset Cart */
                    try {
                        await axios.delete(`${API_URL}/api/cart/reset-cart`, {
                            headers: {
                                'Authorization': this.$store.state.auth.token
                            }
                        });

                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    } catch (error) {
                        console.log(error)
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    }
                }
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                return null;
            }
        },
        /*
         * Form Validation
         */
        checkForm() {
            this.errors = [];

            if (this.cashless_platform === '') {
                this.errors.push('Platform resi otomatis belum dipilih!');
            }
            if (this.cashless_proof === null) {
                this.errors.push('Bukti resi otomatis belum diupload!');
            }
            if (this.payment_method === '') {
                this.errors.push('Metode pembayaran belum dipilih!');
            }
        },
        /*
         * Create Order
         */
        async createOrder(data) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });
            //const total_payment = this.cartItems.reduce((total, item) => { return total + (item.variant.price * item.quantity) }, 0) + this.admin_fee;

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Order Information */
                order_type: 'direct',
                /* Item Information */
                items: data.items,
                /* Shipping Information */
                cashless_platform: this.cashless_platform,
                cashless_proof: data.cashless_proof,
                /* Payment Information */
                payment_method: this.payment_method,
                admin_fee: data.admin_fee,
                total_payment: data.total_payment,
                /* Customer Information */
                customer_name: this.profileData.fullName,
                customer_phone: this.profileData.phoneNumber,
                customer_address: '',
                customer_district: '',
                customer_city: '',
                customer_province: '',
                customer_postal_code: '',
                /* Informasi Relasi/Integrasi dengan Entitas Lain */
                user: this.profileData.id ? this.profileData.id : null,
                vendor: data.vendor
            }

            try {
                await axios.post(API_URL + '/api/order/create-order', form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                console.log(error)
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
    }
}
</script>

<style scoped>
</style>