<template>
    <div>
        <h2 class="text-gray-700 text-lg font-semibold font-proxima px-8 pt-8">Pesanan Anda</h2>
        <CardItem :items="items" :type="type" @updateData="updateData" :adminFee="adminFee" />
    </div>
</template>

<script>
import CardItem from '@/components/cart/CardItem';

export default {
    name: 'ProductItems',
    components: {
        CardItem
    },
    props: {
        items: { type: Array },
        type: { type: String },
        adminFee: { type: Number },
    },
    methods: {
        updateData() {
            this.$emit('updateData')
        }
    }
}
</script>