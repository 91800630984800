<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Pengaturan Form Order</h2>

                <div class="w-full md:flex md:items-center mb-3" v-if="product_data !== null">
                    <img :src="product_data.images[0]" class="border border-gray-200 rounded mr-4 md:mb-0 mb-4 md:block hidden" width="120" />
                    <img :src="product_data.images[0]" class="border border-gray-200 rounded mr-4 md:mb-0 mb-4 w-full md:hidden" />
                    <div>
                        <h4 class="font-semibold font-proxima">{{ product_data.name }}</h4>
                        <h4 class="text-gray-700 font-medium font-proxima text-xs mt-2">Pengiriman dari {{ product_data.vendor.city }}</h4>
                        <!-- Mobile -->
                        <div>
                            <h3 class="text-gray-700 font-proxima font-medium text-sm">Rp{{ formatPrice(product_data.variants[0].price) }}</h3>
                        </div>
                    </div>
                </div>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Judul Form Order</label>
                    <z-input v-model="form_title" full></z-input>
                </div>

                <div>
                    <label class="font-proxima font-semibold block">Form Slug/URL</label>
                    <z-input v-model="slug" full @input="slugValidationAndFormatter"></z-input>
                    <h4 v-if="slugValidation" class="font-medium text-red-600 text-xs mt-1">Alamat form/slug sudah pernah terpakai!</h4>
                </div>

                <!-- <div>
                    <label class="block font-semibold font-proxima" for="receiver_address">Kode untuk Verifikasi Domain</label>
                    <textarea v-model="domain_verification" id="custom_script" class="w-full bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima" row="3">
                    </textarea>
                </div> -->

            </div>

            <!-- Benefit -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Custom Manfaat/Benefit Produk</h2>

                <label class="font-proxima">Benefit</label>
                <div class="flex items-center mb-3" v-for="(item, index) in benefit" :key="index">
                    <z-input v-model="item.benefit_text" placeholder="Contoh: Menjaga daya tahan tubuh" class="w-full" full></z-input>
                    <button class="text-red-600 hover:text-red-700 ml-3" @click="benefit.splice(index, 1)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current" style="transform: ;msFilter:;">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path><path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                        </svg>
                    </button>
                </div>
                <!-- Tombol Tambah Pilihan -->
                <z-button @click="benefit.push({ benefit_text: '' })" class="flex items-center text-white button-diginesia w-full justify-center">
                    <svg class="ionicon w-5 H-5" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Add Circle</title><path d='M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z' fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='32'/><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M256 176v160M336 256H176'/></svg>
                    <span class="ml-2">Tambah Benefit</span>
                </z-button>
            </div>

            <!-- Form Order Pixel Settings -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Setting Pixel Form Order</h2>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">FB Pixel ID</label>
                    <z-input v-model="form_fb_pixel_id" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">FB Pixel Events</label>
                    <z-select v-model="form_fb_event_selected" label="Pilih Pixel Events" :options="filteredFormCustomEventsOptions" full @changeData="addPixelEvent('form')"></z-select>
                </div>
                <div class="mb-3" v-if="form_fb_event.length > 0">
                    <div v-for="(item, index) in form_fb_event" :key="index" class="w-full flex items-center justify-between px-3 py-2 border border-gray-300">
                        <h4>{{ item }}</h4>
                        <button @click="removePixelEvent('form', index)" class="text-red-600 hover:text-red-700 ml-3">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current" style="transform: ;msFilter:;">
                                <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path><path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Google Pixel ID</label>
                    <z-input v-model="form_google_pixel_id" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Tiktok Pixel ID</label>
                    <z-input v-model="form_tiktok_pixel_id" full></z-input>
                </div>
            </div>

            <!-- Thank You Page Pixel Settings -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Setting Pixel Thank You Page</h2>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">FB Pixel ID</label>
                    <z-input v-model="thank_fb_pixel_id" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">FB Pixel Events</label>
                    <z-select v-model="thank_fb_event_selected" label="Pilih Pixel Events" :options="filteredThankCustomEventsOptions" full @changeData="addPixelEvent('thank')"></z-select>
                </div>
                <div class="mb-3" v-if="form_fb_event.length > 0">
                    <div v-for="(item, index) in thank_fb_event" :key="index" class="w-full flex items-center justify-between px-3 py-2 border border-gray-300">
                        <h4>{{ item }}</h4>
                        <button @click="removePixelEvent('thank', index)" class="text-red-600 hover:text-red-700 ml-3">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current" style="transform: ;msFilter:;">
                                <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path><path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Google Pixel ID</label>
                    <z-input v-model="thank_google_pixel_id" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Tiktok Pixel ID</label>
                    <z-input v-model="thank_tiktok_pixel_id" full></z-input>
                </div>
            </div>

            <!-- Custom Price -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Setting Harga Jual</h2>
                
                <!-- Input Margin Masal -->
                <div class="mb-3 md:flex items-end justify-start md:space-x-2">
                    <div class="md:w-1/3 w-full md:mb-0 mb-1">
                        <h4 class="text-sm font-proxima">Margin Produk</h4>
                        <z-input v-model="marginMassPrice" placeholder="Margin" type="number" full></z-input>
                    </div>
                    <z-button @click="generateMarginProduct" class="text-white button-diginesia md:w-1/3 w-full">
                        Terapkan Masal
                    </z-button>
                </div>

                <!-- Tabel Product Variant yang sudah digenerated + Input Stok dan Harga Variant -->
                <table class="md:block hidden table-fixed w-full border-collapse text-gray-600 text-md text-center">
                    <!-- Judul Data Produk Varian -->
                    <thead class="bg-white border border-gray-200 text-gray-700 font-medium">
                        <tr>
                            <td class="w-2/6 py-2">Info SKU</td>
                            <td class="w-2/6 py-2">Harga Barang</td>
                            <td class="w-2/6 py-2">Margin</td>
                            <td class="w-2/6 py-2">Harga Jual</td>
                        </tr>
                    </thead>
                    <!-- Input Isian Data Produk Varian : Harga, Stok, dan Kode SKU -->
                    <tbody class="bg-white border border-gray-200">
                        <tr v-for="(item, i) in custom_price" :key="i">
                            <td class="border-gray-200 border-t py-2">
                                <h4 class="text-sm font-proxima font-semibold">{{ item.sku }}</h4>
                                <h4 class="text-xs font-proxima">{{ item.options.toString().replace(',', ' ') }}</h4>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.price) }}</h4>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <div class="relative">
                                    <input v-model="item.margin" class="bg-gray-50 pr-3 pl-8 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima w-full" type="number" @input="item.sell_price = item.price + item.margin">
                                    <div class="absolute inset-0 right-auto flex items-center pointer-events-none">
                                        <span class="text-sm text-gray-400 font-medium px-3 font-proxima">Rp</span>
                                    </div>
                                </div>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.sell_price) }}</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- [Mobile] Tabel Product Variant yang sudah digenerated + Input Stok dan Harga Variant -->
                <table class="md:hidden table-fixed w-full border-collapse text-gray-600 text-md text-center">
                    <!-- Judul Data Produk Varian -->
                    <thead class="bg-white border border-gray-200 text-gray-700 font-medium">
                        <tr>
                            <td class="w-1/2 py-2">Info SKU & Harga Barang</td>
                            <td class="w-1/2 py-2">Margin & Harga Jual</td>
                        </tr>
                    </thead>
                    <!-- Input Isian Data Produk Varian : Harga, Stok, dan Kode SKU -->
                    <tbody class="bg-white border border-gray-200">
                        <tr v-for="(item, i) in custom_price" :key="i">
                            <!-- Mobile -->
                            <td class="border-gray-200 border-t py-2">
                                <h4 class="text-sm font-proxima font-semibold">{{ item.sku }}</h4>
                                <h4 class="text-xs font-proxima">{{ item.options.toString().replace(',', ' ') }}</h4>
                                <br/>
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.price) }}</h4>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <div class="relative">
                                    <input v-model="item.margin" class="bg-gray-50 pr-3 pl-8 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima w-full" type="number" @input="item.sell_price = item.price + item.margin">
                                    <div class="absolute inset-0 right-auto flex items-center pointer-events-none">
                                        <span class="text-sm text-gray-400 font-medium px-3 font-proxima">Rp</span>
                                    </div>
                                </div>
                                <br/>
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.sell_price) }}</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Team CS -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Atur Team CS</h2>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Customer Service</label>
                    <z-select v-model="csSelected" label="Pilih CS" :options="filteredTeamCSOptions" full @changeData="addTeamCS"></z-select>
                </div>
                <div class="mb-3" v-if="csList.length > 0">
                    <div v-for="(item, index) in csList" :key="index" class="w-full flex items-center justify-between px-3 py-2 border border-gray-300">
                        <h4>{{ item.label }}</h4>
                        <button @click="removeTeamCS(index)" class="text-red-600 hover:text-red-700 ml-3">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current" style="transform: ;msFilter:;">
                                <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path><path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Save Button -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <template v-if="errors.length > 0">
                    <div v-for="(item, index) in errors" :key="index" class="p-4 my-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                        {{ item }}
                    </div>
                </template>
                <div>
                    <z-button @click="createMyForm" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Simpan</z-button>
                </div>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL
import formatPrice from '../../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZSelect from '@/components/common/ZSelect.vue';

export default {
    components: {
        NavigationMenu,
        ZInput,
        ZButton,
        ZSelect
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Data */
            product_data: null,
            /* Form Settings */
            form_title: '',
            slug: '',
            benefit: [{
                benefit_text: ''
            }],
            domain_verification: '',
            /* Custom Script */
            customEventsOptions: [{
                label: 'ViewContent',
                value: 'ViewContent'
            },{
                label: 'AddPaymentInfo',
                value: 'AddPaymentInfo'
            },{
                label: 'AddToCart',
                value: 'AddToCart'
            },{
                label: 'AddToWishlist',
                value: 'AddToWishlist'
            },{
                label: 'CompleteRegistration',
                value: 'CompleteRegistration'
            },{
                label: 'InitiateCheckout',
                value: 'InitiateCheckout'
            },{
                label: 'Lead',
                value: 'Lead'
            },{
                label: 'Purchase',
                value: 'Purchase'
            },{
                label: 'Search',
                value: 'Search'
            }],
            form_fb_pixel_id: '',
            form_fb_event_selected: '',
            form_fb_event: [],
            thank_fb_pixel_id: '',
            thank_fb_event_selected: '',
            thank_fb_event: [],
            form_google_pixel_id: '',
            thank_google_pixel_id: '',
            form_tiktok_pixel_id: '',
            thank_tiktok_pixel_id: '',
            /* Customer Service */
            csOptions: [],
            csSelected: '',
            csList: [],
            /* Pricing */
            custom_price: [],
            marginMassPrice: 0,
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false,
            slugValidation: false,
            /* Form Validation */
            errors: []
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        },
        filteredFormCustomEventsOptions() {
            return this.customEventsOptions.filter(item => {
                const checkValue = this.form_fb_event.includes(item.value);
                if(!checkValue) {
                    return item
                }
            })
        },
        filteredThankCustomEventsOptions() {
            return this.customEventsOptions.filter(item => {
                const checkValue = this.thank_fb_event.includes(item.value);
                if(!checkValue) {
                    return item
                }
            })
        },
        filteredTeamCSOptions() {
            return this.csOptions.filter(item => {
                const checkValue = this.csList.includes(item);
                if(!checkValue) {
                    return item
                }
            })
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getProductBySlug();
        this.getTeamCS();
	},
    methods: {
        /*
         * Get Product By Slug
         */
        async getProductBySlug () {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/product/get/slug/${this.$route.params.slug}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                this.product_data = response.data.data;
                this.custom_price = response.data.data.variants.map(item => {
                    return {
                        sku: item.sku,
                        price: item.price,
                        margin: 0,
                        sell_price: item.price,
                        options: item.options,
                        stock: item.stock.stock,
                        product: item.product
                    }
                });

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;

                this.$notify({
                    title: "Gagal",
                    text: "Product gagal diambil!",
                    position: "bottom",
                    type: "error"
                });
            }
        },

        /*
         * Form Validation
         */
        checkForm() {
            this.errors = [];

            if (this.form_title === '') {
                this.errors.push('Judul form wajib diisi!');
            }
            if (this.slug === '') {
                this.errors.push('Slug/alamat form wajib diisi!');
            }
            if (this.slugValidation === true) {
                this.errors.push('Slug/alamat form sudah pernah dipakai!');
            }
        },
        /*
         * Create MyForm
         */
        async createMyForm () {
            this.checkForm();
            if(this.errors.length > 0) {
                return;
            }

            this.loadingToggle = true;

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Form Settings */
                form_title: this.form_title,
                slug: this.slug,
                benefit: this.benefit,
                domain_verification: this.domain_verification,
                /* Custom Script */
                form_fb_pixel_id: this.form_fb_pixel_id,
                form_fb_event: this.form_fb_event,
                thank_fb_pixel_id: this.thank_fb_pixel_id,
                thank_fb_event: this.thank_fb_event,
                form_google_pixel_id: this.form_google_pixel_id,
                thank_google_pixel_id: this.thank_google_pixel_id,
                form_tiktok_pixel_id: this.form_tiktok_pixel_id,
                thank_tiktok_pixel_id: this.thank_tiktok_pixel_id,
                /* Pricing */
                custom_price: this.custom_price,
                /* Customer Service */
                cs_list: this.csList,
                /* Relational */
                user: this.profileData.id,
                product: this.product_data._id
            }

            try {
                await axios.post(API_URL + '/api/my-form/save', form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loadingToggle = false;
                
                if(this.profileData.guide_form_order.create_form_order && this.profileData.guide_form_order.create_form_order === true) {
                    this.$router.push('/my-form/list');
                }else{
                    this.updateGuide();
                }
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;

                this.$notify({
                    title: "Gagal",
                    text: "My Form gagal disimpan!",
                    position: "bottom",
                    type: "error"
                });
            }
        },

        /* Add Pixel Event */
        addPixelEvent(type) {
            if(type === 'form') {
                this.form_fb_event.push(this.form_fb_event_selected.value);
                this.form_fb_event_selected = '';
            }
            if(type === 'thank') {
                this.thank_fb_event.push(this.thank_fb_event_selected.value);
                this.thank_fb_event_selected = '';
            }
        },
        /* Remove Pixel Event */
        removePixelEvent(type, index) {
            if(type === 'form') {
                this.form_fb_event.splice(index, 1);
            }
            if(type === 'thank') {
                this.thank_fb_event.splice(index, 1);
            }
        },

        /*
         * Get Team CS
         */
        async getTeamCS() {
            /* Reset Options */
            this.csOptions = []; // Reset
            
            try {
                const response = await axios.get(`${API_URL}/api/user/get-all-cs/${this.profileData.id}?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.csData = response.data;

                for(let i=0; i < this.csData.length; i++){
                    this.csOptions.push({
                        label: this.csData[i].fullName,
                        value: this.csData[i]._id
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        /* Add Team CS */
        addTeamCS() {
            this.csList.push(this.csSelected);
            this.csSelected = '';
        },
        /* Remove Team CS */
        removeTeamCS(index) {
            this.csList.splice(index, 1);
        },

        /*
         * Update Guide
         */
        async updateGuide() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Update Type */
                update_type: 'create-form-order'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        this.$router.push('/my-form/list?redirect_from=selling_form_order');
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

        /* Helpers */
        generateMarginProduct() {
            this.custom_price.map((item) => {
                item.margin = this.marginMassPrice;
                item.sell_price = Number(item.price) + Number(this.marginMassPrice);
            });
        },
        async slugValidationAndFormatter(event) {
            this.slug = event.target.value.replace(/\s+/g, '-').toLowerCase();

            try {
                const checkSlug = await axios.get(API_URL + '/api/my-form/slug-validation/' + this.slug + '/user/' + this.profileData.id, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loadingToggle = false;
                this.slugValidation = checkSlug.data.data;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },
        formatPrice
    }
}
</script>

<style scoped>
</style>