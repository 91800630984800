<template>
    <div v-if="orderData && orderData.length > 0">
        <div v-for="order in orderData" :key="order._id" style="max-width: 600px; padding: 20px; page-break-inside: avoid; ">
            <div class="flex items-center border border-gray-500 px-6 py-6">
                <div class="w-1/2">
                    <h4 class="text-2xl font-bold font-proxima">{{ order.user.reseller_url.replace('-', ' ').toUpperCase() }} Store</h4>
                    <!-- <img src="@/assets/images/logo-seebuh-monokrom.png" width="180" /> -->
                </div>
                <div class="ml-auto">
                    <div class="border border-gray-700 px-4 py-1.5">
                        <h4 class="text-lg font-semibold text-center">{{ order.payment_method }}</h4>
                    </div>
                </div>
            </div>
            <div class="flex items-start border-r border-l border-gray-500 px-6 py-6">
                <div class="w-1/2" id="invoice">
                    <h4 class="font-semibold mb-6 text-sm">No Invoice: {{ order.invoice }}</h4>
                    <div class="flex items-center mb-4">
                        <div class="w-1/2">
                            <img src="@/assets/images/courier/jne.png" width="100" />
                        </div>
                        <div class="w-1/2">
                            <h4>JNE</h4>
                            <h4 class="font-semibold">{{ order.shipping_method }}</h4>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <div class="w-1/2">
                            <h4>Berat</h4>
                            <h4 class="font-semibold">{{ order.items.reduce((total, item) => { return Number(total) + item.product.package_weight }, 0) / 1000 }} Kg</h4>
                        </div>
                        <div class="w-1/2">
                            <h4><span v-if="order.payment_method === 'COD'">Nominal</span><span v-else-if="order.payment_method === 'Transfer'">Ongkir</span></h4>
                            <template v-if="order.payment_method === 'COD'">
                                <h4 class="font-semibold">{{ 'Rp' + formatPrice(order.total_payment) }}</h4>
                            </template>
                            <template v-else-if="order.payment_method === 'Bank Transfer'">
                                <h4 class="font-semibold">{{ 'Rp' + formatPrice(order.shipping_fee) }}</h4>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="w-1/2">
                    <center>
                        <template v-if="order.awb_no">
                            {{ generateBarcode(order.awb_no, order.invoice) }}
                            <svg :id="`barcode-${order.invoice}`"></svg>
                            <h4 class="mt-2 font-semibold text-sm">Nomor resi untuk melacak pengiriman</h4>
                        </template>
                        <template v-else>
                            <h4 class="mt-16 font-semibold text-sm">Nomor resi belum tersedia</h4>
                        </template>
                    </center>
                </div>
            </div>
            <div class="flex items-start border-r border-l border-b border-gray-500 px-6 py-6">
                <div class="w-1/2">
                    <h5 class="text-sm">Kepada:</h5>
                    <h4 class="text-sm font-semibold">{{ order.customer_name }}</h4>
                    <h4 class="text-sm">{{ order.customer_address }}</h4>
                    <h4 class="text-sm">{{ order.customer_district }}, {{ order.customer_city }}</h4>
                    <h4 class="text-sm">{{ order.customer_province }}, {{ order.customer_postal_code }}</h4>
                    <h4 class="text-sm">{{ order.customer_phone }}</h4>
                </div>
                
                <div class="w-1/2">
                    <h5 class="text-sm">Dari:</h5>
                    <div>
                        <h4 class="text-sm font-semibold"><span> {{ order.user.reseller_url.replace('-', ' ').toUpperCase() }} Store</span></h4>
                        <h4 class="text-sm"><span>{{ order.vendor.city }}, {{ order.vendor.province }}</span></h4>
                        <h4 class="text-sm"><span>08118485811</span></h4>
                    </div>
                </div>
            </div>
            <div class="flex items-start border-r border-l border-b border-gray-500 px-6 py-6 space-x-2">
                <div class="w-7/12">
                    <h4 class="text-sm font-semibold mb-2">Produk</h4>
                    <h4 class="text-sm" v-for="(item, index) in order.items" :key="index">{{ item.product.name }}</h4>
                </div>
                <!-- <div class="w-3/12">
                    <h4 class="text-sm font-semibold mb-2">Varian</h4>
                    <h4 class="text-sm" v-for="(item, index) in invoiceData.itemsDetail" :key="index">{{ item.variant ? item.variant.options.toString() : '-' }}</h4>
                </div> -->
                <div class="w-4/12">
                    <h4 class="text-sm font-semibold mb-2">SKU</h4>
                    <h4 class="text-sm" v-for="(item, index) in order.items" :key="index">{{ item.variant ? item.variant.sku : item.product.sku }}</h4>
                </div>
                <div class="w-1/12">
                    <h4 class="text-sm font-semibold mb-2">Jumlah</h4>
                    <h4 class="text-sm" v-for="(item, index) in order.items" :key="index">{{ item.quantity }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL } from '@/config.js' // Import API URL
import axios from 'axios'
import formatPrice from '@/utils/formatPrice'
import Printd from 'printd';

export default {
    data() {
        return {
            orderData: [],
            // warehouseDatas: [],
            cssText: [
                'https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.1.4/tailwind.min.css'
            ]
        }
    },
    async created() {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/login');
            });
        }

        this.getOrder();
    },
    mounted() {
        /* Config Print */
        this.d = new Printd()
        const { contentWindow } = this.d.getIFrame()
        contentWindow.addEventListener(
            'beforeprint', () => console.log('before print event!')
        )
        contentWindow.addEventListener(
            'afterprint', () => console.log('after print event!')
        )
    },
    methods: {
        async getOrder() {
            let orderIds = this.$route.params.id.split(',').map(s => s.trim());
            try {
                let data = {};

                if(orderIds) {
                    data = {
                        orders: orderIds
                    };
                }
                const response = await axios.post(`${API_URL}/api/order/print`, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.orderData = response.data.data;

                /* Print Page */
                setTimeout(function () { this.printPage() }.bind(this), 2000);
            } catch (error) {
                console.error(error);
            }
        },
        generateBarcode(value, invoice) {
            let JsBarcode = require('jsbarcode');
            window.setTimeout(function(){
                JsBarcode(`#barcode-${invoice}`, value)
            }, 0);
        },
        async printPage() {
            await this.d.print( this.$el, [this.cssText])
        },
        formatPrice
    }
}
</script>

<style scoped>
svg#barcode {
    margin: 0px;
    width: 100%;
    margin-top: -15px;
}
</style>