<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mx-auto">
                <!-- <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Form Pengaduan SatuStock</h2> -->

                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdcDlnXLYc6WHLTHiOF0G7zKp_xA6j-Q4nbvnLK862ZPIGAJg/viewform?embedded=true" width="100%" height="750" frameborder="0" marginheight="0" marginwidth="0">Memuat…</iframe>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

import NavigationMenu from '@/components/header/NavigationMenu.vue'

export default {
    components: {
        NavigationMenu
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/signin');
            });
        }
    },
    methods: {}
}
</script>

<style scoped>
</style>