<template>
    <div class="flex justify-center" v-if="meta">
        <nav class="flex" role="navigation" aria-label="Navigation">
            <div class="mr-2">
                <button
                    @click="PageNavHandler('previous')"
                    :disabled="meta.hasPrevPage == false"
                    :class="meta.hasPrevPage ? 'hover:bg-yellow-500 border-gray-200 text-gray-600 hover:text-gray-800 shadow-sm' : 'border-gray-200 text-gray-300'"
                    class="inline-flex items-center justify-center border border-transparent rounded leading-5 px-2.5 py-2 bg-white"
                >
                    <span class="sr-only">Previous</span><wbr />
                    <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
                        <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
                    </svg>
                </button>
            </div>
            <ul class="inline-flex text-sm font-medium -space-x-px shadow-sm" v-for="page in pages" :key="page.name">
                <li v-if="page.name > 0">
                    <span v-if="meta.currentPage == page.name" class="inline-flex items-center justify-center border border-transparent leading-5 px-3.5 py-2 bg-white border-gray-200 text-yellow-500">{{ page.name }}</span>
                    <a v-else class="inline-flex items-center justify-center border border-transparent leading-5 px-3.5 py-2 bg-white hover:bg-yellow-500 border-gray-200 text-gray-600 hover:text-gray-800 cursor-pointer" @click="pageNavNumberHandler(page.name)">{{ page.name }}</a>
                </li>
            </ul>
            <div class="ml-2">
                <button
                    @click="PageNavHandler('next')"
                    :disabled="meta.hasNextPage == false"
                    :class="meta.hasNextPage ? 'hover:bg-yellow-500 border-gray-200 text-gray-600 hover:text-gray-800 shadow-sm' : 'border-gray-200 text-gray-300'"
                    class="inline-flex items-center justify-center border border-transparent rounded leading-5 px-2.5 py-2 bg-white"
                >
                    <span class="sr-only">Next</span><wbr />
                    <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
                        <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                    </svg>
                </button>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'PaginationNumeric',
    props: {
        meta: {
            type: Object,
            default: () => {}
        },
        /* Handler Function */
        PageNavHandler: { default: () => {}, type: Function },
        pageNavNumberHandler: { default: () => {}, type: Function },
    },
    data() {
        return {
            maxVisibleButtons: 7
        }
    },
    computed: {
        startPage() {
            // When on the first page
            if (this.meta.currentPage === 1) {
                return 1;
            }

            // When on the last page
            if (this.meta.currentPage === this.meta.totalPages) {
                return this.meta.totalPages - this.maxVisibleButtons;
            }

            // When inbetween
            return this.meta.currentPage - 1;
        },
        pages() {
            let diff = Math.floor(this.maxVisibleButtons / 2);
            let evenOffset = this.maxVisibleButtons % 2 === 0 ? 1 : 0;
            if (this.meta.currentPage + diff >= this.meta.totalPages)
                return this.range(
                    this.meta.totalPages - this.maxVisibleButtons + 1,
                    this.meta.totalPages
                );
            if (this.meta.currentPage - diff <= 0) return this.range(1, this.maxVisibleButtons);
            return this.range(this.meta.currentPage - diff + evenOffset, this.meta.currentPage + diff);
        }
    },
    methods: {
        /* Pagination */
        range(x, y) {
            let array = [];
            for (let i = x; i <= y; i++) {
                array.push({
                    name: i,
                    isDisabled: i === this.meta.currentPage
                });
            }
            return array;
        },
    }
}
</script>