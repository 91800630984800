<template>
    <div>
        <!--------------->
        <!-- Watermark -->
        <!--------------->
        <div class="w-full">
            <z-table
                title="Watermark"
                :data="data.data"
                :meta="data.meta"
                :columns="columns"
                :mutipleCheck="false"
                @updateSelectedItems="updateSelectedItems"
                :sorting="false"
                @sortingHandler="sortingHandler"
            >
                <template v-slot:file_url="{item}">
                    <div class="px-3">
                        <img :src="item.file_url.replace('upload/', 'upload/c_fill,h_300,w_300/')" width="100" />
                    </div>
                </template>
                <template v-slot:file_name="{item}">
                    {{ item.file_name.replace('clickcuan/media/', '') }}
                </template>
                <template v-slot:action="{item}">
                    <button @click="selectWatermark(item)" class="bg-gray-50 hover:bg-gray-100 border border-gray-300 rounded px-3 py-1 text-sm font-proxima text-gray-500 font-semibold">Pilih Watermark</button>
                    <span class="hidden">{{item}}</span>
                </template>
            </z-table>
        </div>
        <!------------------->
        <!-- End Watermark -->
        <!------------------->

        <!---------------->
        <!-- Pagination -->
        <!---------------->
        <div class="mt-8">
            <z-pagination 
                :meta="data.meta"
                :PageNavHandler="pageNavHandler"
                :pageNavNumberHandler="pageNavNumberHandler"
            />
        </div>
        <!-------------------->
        <!-- End Pagination -->
        <!-------------------->
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';

export default {
    components: {
        ZTable,
        ZPagination
    },
    data() {
        return {
            /* Core Data */
			data: [],
            selectedWatermark: null,
            /* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'file_url',
                    label: 'Preview Watermark',
                    visibility: true,
                },
                {
                    id: 2,
                    field: 'file_name',
                    label: 'Nama Watermark',
                    visibility: true,
                    sort: true
                },
                {
                    id: 8,
                    field: 'action',
                    label: 'Action',
                    visibility: true,
                }
			],
            /* Toggle */
            loading: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: {'createdAt': -1},
            sort_order: -1
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        this.getWatermark(this.page, this.limit);
	},
    methods: {
		/*
		* Get Watermark
		*/
		async getWatermark(page, limit) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                //sort: JSON.stringify(this.sort_by),
                user: this.profileData.id
            };

			try {
                const response = await axios.get(API_URL + '/api/media/get-all', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
			} catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getWatermark(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getWatermark(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getWatermark(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getWatermark(this.page, this.limit);
            } else {
                this.search = value;
                this.getWatermark(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

		/*
		 * Update Selected Items
		 */
		async updateSelectedItems(selected) {
			this.selectedItems = selected;
		},

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getWatermark(this.page, this.limit);
        },

        /* Select Watermark */
        selectWatermark(value) {
            this.$emit('selectedWatermark', value);
        },

        /*
         * Other Helpers
         */
    }
}
</script>