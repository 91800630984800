<template>
    <div class="min-h-screen bg-gray-800 text-gray-800 antialiased flex items-center justify-center sm:py-12">
        <section v-if="loading !== null" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="relative py-3 px-4 md:px-0 mx-auto text-center w-full max-w-sm">

            <div class="flex-shrink-0 flex items-center justify-center">
                <img class="mr-1" width="40" src="@/assets/images/logo/logo-gold.png" alt="SatuStock" />
                <h1 class="text-white text-2xl font-bold font-diginesia">SatuStock</h1>
            </div>

            <div class="relative mt-4 bg-white shadow-md rounded-lg text-left">
                <div class="h-2 bg-yellow-400 rounded-t-md"></div>
                <div class="py-6 px-8">
                    <h4 class="text-xl font-semibold font-proxima mb-4 text-center">Lupa Kata Sandi</h4>
                    
                    <!-- Error Message -->
                    <div v-if="error_message" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                        <span class="font-medium">Peringatan!</span><br/>{{ error_message }}.
                    </div>
                    <!-- Success Message -->
                    <div v-if="success_message" class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                        <span class="font-medium">Sukses!</span><br/>{{ success_message }}.
                    </div>

                    <template v-if="step === 1">
                        <!-- Email -->
                        <div class="mb-3">
                            <label class="font-proxima font-semibold block mb-1">Email</label>
                            <z-input v-model="email" full @input="emailFormatter"></z-input>
                        </div>
                    </template>

                    <template v-if="step === 2">
                        <!-- Kode Token -->
                        <div class="mb-3">
                            <label class="font-proxima font-semibold block mb-1">Kode Token</label>
                            <z-input v-model="token" type="text" full></z-input>
                        </div>

                        <!-- Password Baru -->
                        <div class="mb-3">
                            <label class="font-proxima font-semibold block mb-1">Password Baru</label>
                            <z-input v-model="new_password" type="password" full></z-input>
                        </div>

                        <!-- Konfirmasi Password Baru -->
                        <div class="mb-3">
                            <label class="font-proxima font-semibold block mb-1">Konfirmasi Password Baru</label>
                            <z-input v-model="new_password_confirm" type="password" full></z-input>
                        </div>
                    </template>

                    <!-- Button Action -->
                    <div class="flex justify-between items-baseline mt-8" v-if="step === 1">
                        <z-button @click="sendForgotPassword" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Kirim Kode Reset Password</z-button>
                    </div>

                    <!-- Button Action -->
                    <div class="flex justify-between items-baseline mt-8" v-if="step === 2">
                        <z-button @click="resetPassword" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Ubah Kata Sandi</z-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        ZInput,
        ZButton
    },
    data() {
        return {
            email: '',
            token: '',
            /* New Password */
            new_password: '',
            new_password_confirm: '',
            /* Tab */
            step: 1,
            /* Toggle */
            alertToggle: false,
            loadingButton: false,
            /* Extra */
            loading: null,
            error_message: null,
            success_message: null
        }
    },
    computed: {
        loggedIn() {
            return this.$store.getters['auth/isLoggedIn']; // <== ini kalau pakai namespace, kalau engga tinggal this.$store.getters.isLoggedIn
        }
    },
    created() {
        if (this.loggedIn) {
            this.$router.push('/');
        }
    },
    methods: {
        async sendForgotPassword() {
            this.error_message = null;
            this.loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let data = {
                    email: this.email
                }

                const response = await axios.post(API_URL + '/api/auth/forgot-password', data);
                this.step = 2;
                this.loading.hide();
                this.loading = null;
                console.log(response)
            } catch (error) {
                this.loading.hide();
                this.loading = null;

                /* Show Error Message */
                this.error_message = error.response.data.message;
            }
        },

        async resetPassword() {
            this.error_message = null;
            this.loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let data = {
                    password: this.new_password,
                    password_confirm: this.new_password_confirm
                }

                const response = await axios.post(API_URL + '/api/auth/reset-from-email/' + this.token, data);
                console.log(response);

                this.loading.hide();
                this.loading = null;

                /* Show Success Message */
                this.success_message = response.data.message;
                
                setTimeout(() => {
                    this.$router.push('/auth/login');
                    this.loading.hide();
                    this.loading = null;
                }, 3000);
            } catch (error) {
                this.loading.hide();
                this.loading = null;

                /* Show Error Message */
                this.error_message = error.response.data.message;
            }
        },

        /* Email Formatter */
        emailFormatter(event) {
            this.email = event.target.value.toLowerCase();
        }
    }
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}
</style>