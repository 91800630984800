<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="md:flex md:items-start md:space-x-3 max-w-7xl mx-auto md:px-2 px-4 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/4 w-full md:mb-0 mb-2">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Menu Pengaturan</h2>
                
                <div class="space-y-2">
                    <button @click="$router.push('/settings/edit-account'); menu_selected = 'edit-account'" class="w-full px-2 py-1.5 border border-gray-200 rounded font-proxima font-semibold flex items-center text-gray-600" :class="menu_selected === 'edit-account' ? 'bg-gray-200' : ''">
                        <svg class="fill-current h-5 w-5 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10 10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z"></path>
                        </svg>
                        Edit Profile
                    </button>
                    <button @click="$router.push('/settings/dropship-setting'); menu_selected = 'dropship-setting'" class="w-full px-2 py-1.5 border border-gray-200 rounded font-proxima font-semibold flex items-center text-gray-600" :class="menu_selected === 'dropship-setting' ? 'bg-gray-200' : ''">
                        <svg class="fill-current h-5 w-5 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M21.993 7.95a.96.96 0 0 0-.029-.214c-.007-.025-.021-.049-.03-.074-.021-.057-.04-.113-.07-.165-.016-.027-.038-.049-.057-.075-.032-.045-.063-.091-.102-.13-.023-.022-.053-.04-.078-.061-.039-.032-.075-.067-.12-.094-.004-.003-.009-.003-.014-.006l-.008-.006-8.979-4.99a1.002 1.002 0 0 0-.97-.001l-9.021 4.99c-.003.003-.006.007-.011.01l-.01.004c-.035.02-.061.049-.094.073-.036.027-.074.051-.106.082-.03.031-.053.067-.079.102-.027.035-.057.066-.079.104-.026.043-.04.092-.059.139-.014.033-.032.064-.041.1a.975.975 0 0 0-.029.21c-.001.017-.007.032-.007.05V16c0 .363.197.698.515.874l8.978 4.987.001.001.002.001.02.011c.043.024.09.037.135.054.032.013.063.03.097.039a1.013 1.013 0 0 0 .506 0c.033-.009.064-.026.097-.039.045-.017.092-.029.135-.054l.02-.011.002-.001.001-.001 8.978-4.987c.316-.176.513-.511.513-.874V7.998c0-.017-.006-.031-.007-.048zm-10.021 3.922L5.058 8.005 7.82 6.477l6.834 3.905-2.682 1.49zm.048-7.719L18.941 8l-2.244 1.247-6.83-3.903 2.153-1.191zM13 19.301l.002-5.679L16 11.944V15l2-1v-3.175l2-1.119v5.705l-7 3.89z"></path>
                        </svg>
                        Pengaturan Akun Dropship
                    </button>
                    <button @click="$router.push('/settings/select-warehouse'); menu_selected = 'select-warehouse'" class="w-full px-2 py-1.5 border border-gray-200 rounded font-proxima font-semibold flex items-center text-gray-600" :class="menu_selected === 'select-warehouse' ? 'bg-gray-200' : ''">
                        <svg class="fill-current h-5 w-5 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M18.991 2H9.01C7.899 2 7 2.899 7 4.01v5.637l-4.702 4.642A1 1 0 0 0 3 16v5a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4.009C21 2.899 20.102 2 18.991 2zm-8.069 13.111V20H5v-5.568l2.987-2.949 2.935 3.003v.625zM13 9h-2V7h2v2zm4 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V7h2v2z"></path><path d="M7 15h2v2H7z"></path>
                        </svg>
                        Pilih Gudang Pengiriman
                    </button>
                    <div>
                        <div class="w-full px-2 py-1.5 border border-gray-200 rounded-t font-proxima font-semibold flex items-center text-gray-600">
                            <svg class="fill-current h-5 w-5 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                                <path d="M21.999 8a.997.997 0 0 0-.143-.515L19.147 2.97A2.01 2.01 0 0 0 17.433 2H6.565c-.698 0-1.355.372-1.714.971L2.142 7.485A.997.997 0 0 0 1.999 8c0 1.005.386 1.914 1 2.618V20a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-5h4v5a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-9.382c.614-.704 1-1.613 1-2.618zm-2.016.251A2.002 2.002 0 0 1 17.999 10c-1.103 0-2-.897-2-2 0-.068-.025-.128-.039-.192l.02-.004L15.219 4h2.214l2.55 4.251zm-9.977-.186L10.818 4h2.361l.813 4.065C13.957 9.138 13.079 10 11.999 10s-1.958-.862-1.993-1.935zM6.565 4h2.214l-.76 3.804.02.004c-.015.064-.04.124-.04.192 0 1.103-.897 2-2 2a2.002 2.002 0 0 1-1.984-1.749L6.565 4zm3.434 12h-4v-3h4v3z"></path>
                            </svg>
                            Integrasi Marketplace
                        </div>
                        <!-- Child -->
                        <div class="text-sm space-y-1.5 border-b border-x border-gray-200 rounded-b p-3">
                            <button @click="$router.push('/settings/marketplace-integration/shopee'); menu_selected = 'marketplace-integration'" class="w-full px-2 py-1.5 border border-gray-200 rounded font-proxima font-semibold flex items-center text-gray-600">
                                <img src="@/assets/images/logo_mp/shopee.png" class="w-5 h-5 rounded-full mr-2" />
                                Shopee
                            </button>
                            <button @click="$router.push('/settings/marketplace-integration/tiktokshop'); menu_selected = 'marketplace-integration'" class="w-full px-2 py-1.5 border border-gray-200 rounded font-proxima font-semibold flex items-center text-gray-600">
                                <img src="@/assets/images/logo_mp/tiktok.png" class="w-5 h-5 rounded-full mr-2" />
                                Tiktok Shop
                            </button>
                            <button @click="$router.push('/settings/marketplace-integration/lazada'); menu_selected = 'marketplace-integration'" class="w-full px-2 py-1.5 border border-gray-200 rounded font-proxima font-semibold flex items-center text-gray-600">
                                <img src="@/assets/images/logo_mp/lazada.png" class="w-5 h-5 rounded-full mr-2" />
                                Lazada
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Product Information -->
            <slot></slot>
            <!-- <MarketplaceIntegration v-if="menu_selected === 'marketplace-integration'" />
            <EditAccount v-if="menu_selected === 'edit-account'" />
            <AccountDropship v-if="menu_selected === 'dropship-setting'" />
            <SelectWarehouse v-if="menu_selected === 'select-warehouse'" /> -->
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

import NavigationMenu from '@/components/header/NavigationMenu.vue';
// import MarketplaceIntegration from '@/pages/Settings/Marketplace/MarketplaceIntegration.vue';
// import EditAccount from '@/pages/Settings/Account/EditAccount.vue';
// import AccountDropship from '@/pages/Settings/Account/AccountDropship.vue';
// import SelectWarehouse from '@/pages/Settings/Marketplace/SelectWarehouse.vue';
// import ZInput from '@/components/common/ZInput.vue';
// import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        NavigationMenu,
        // MarketplaceIntegration,
        // EditAccount,
        // AccountDropship,
        // SelectWarehouse
        // ZInput,
        // ZButton
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            menu_selected: '',
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.menu_selected = this.$route.path.split('/')[2];
	},
    methods: {
    }
}
</script>

<style scoped>
</style>