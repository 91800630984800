<template>
    <div class="bg-white rounded-sm border border-gray-200 relative">
        <header class="px-5 py-4 md:flex md:justify-between">
            <h2 class="font-semibold text-gray-800 md:mb-0 mb-3">Semua {{ title ? title : 'Data' }} <span class="text-gray-400 font-medium">{{ meta ? meta.totalItems : 0 }}</span></h2>
            <div>
                <slot name="header"></slot>
            </div>
        </header>
        
        <div>
            <!-- Table -->
            <div class="overflow-x-auto">
                <table class="table-auto w-full">
                    <!------------------->
                    <!-- Header Column -->
                    <!------------------->
                    <thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
                        <tr>
                            <th v-if="mutipleCheck" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                                <div class="flex items-center">
                                    <label class="inline-flex">
                                        <span class="sr-only">Select all</span>
                                        <input class="form-checkbox" type="checkbox" v-model="selectAllItem" @click="selectItem" />
                                    </label>
                                </div>
                            </th>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" v-for="(item, index) in columns" :key="index">
                                <div class="font-semibold text-left flex items-center">
                                    {{ item.label }}
                                    <button v-if="sorting && item.sort" @click="sortingHandler(item.field)" class="focus:outline-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-current ml-2" viewBox="0 0 24 24" style="transform:;-ms-filter:"><path d="M6.227 11h11.547c.862 0 1.32-1.02.747-1.665l-5.773-6.495c-.397-.448-1.097-.448-1.494 0L5.479 9.335C4.906 9.98 5.364 11 6.227 11zM11.253 21.159c.397.448 1.097.448 1.494 0l5.773-6.495C19.094 14.02 18.636 13 17.773 13H6.227c-.862 0-1.32 1.02-.747 1.665L11.253 21.159z"></path></svg>
                                    </button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <!------------------------->
                    <!-- / End Header Column -->
                    <!------------------------->

                    <!--------------->
                    <!-- List Item -->
                    <!--------------->
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(item, index) in data" :key="index" class="text-sm font-medium">
                            <td v-if="mutipleCheck" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                                <div class="flex items-center">
                                    <label class="inline-flex">
                                        <span class="sr-only">Select</span>
                                        <input class="form-checkbox" type="checkbox" :value="item" v-model="selectedItem" @change="updateSelectedItems" />
                                    </label>
                                </div>
                            </td>
                            <template v-for="col in columns">
                                <td v-if="typeof $slots[col.field] !== 'undefined'" :key="col.id" class="px-2 py-3 whitespace-nowrap">
                                    <slot :name="col.field" :field="col.field" :item="item"></slot>
                                </td>
                                <td v-else :key="col.id" class="px-2 py-3 whitespace-nowrap">
                                    {{ item[col.field] }}
                                </td>
                            </template>
                        </tr>
                    </tbody>
                    <!--------------------->
                    <!-- / End List Item -->
                    <!--------------------->
                </table>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ZTable',
    props: {
        /* Core Data */
        title: String,
        data: {
            type: Array,
            default: () => []
        },
        meta: {
            type: Object,
            default: () => {}
        },
        columns: {
            type: Array
        },
        /* Toggle */
        mutipleCheck: {
            type: Boolean,
            default: false
        },
        sorting: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectAllItem: false,
            selectedItem: []
        }
    },
    methods: {
        /*
         * Multiple Check
         */
        /* Select Item Check */
        selectItem() {
			this.selectedItem = [];
			if (!this.selectAllItem) {
				for (let i in this.data) {
					this.selectedItem.push(this.data[i]);
				}
            }
            
            this.updateSelectedItems();
        },
        /* Update Selected Item */
        updateSelectedItems() {
            this.$emit('updateSelectedItems', this.selectedItem);
        },

        /*
         * Sorting
         */
        /* Handle Sorting */
        sortingHandler(field) {
            this.$emit('sortingHandler', field);
        },
    }
}
</script>