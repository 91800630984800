<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto md:px-2 px-4 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Main Category -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 mb-3 mx-auto">
                <h2 class="text-xl font-bold font-proxima mb-1">Jualan Organik seperti FB MP, Whatsapp, atau Jualan Langsung</h2>
                <h4 class="text-sm text-gray-500 font-medium font-proxima pb-2 mb-5">Silahkan ikuti langkah-langkah dibawah ini, untuk memulai berjualan organik.</h4>

                <!-- Step 1 -->
                <div class="px-6 py-5 border border-gray-200 rounded flex items-start mb-3">
                    <div class="mr-1.5" :class="profileData.guide_manual_order.setup_dropshipper_account ? 'text-green-600' : 'text-gray-300'">
                        <svg class="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path>
                        </svg>
                    </div>
                    <div>
                        <h2 class="text-base font-bold font-proxima -mt-1">1. Setup Akun Dropshipper</h2>
                        <h4 class="text-sm text-gray-500 font-medium font-proxima mb-5">Pengaturan akun dropshipper seperti nama toko, rekening pencairan dana, dll.</h4>
                        <a @click="$router.push('/settings/dropship-setting?redirect_from=selling_manual_order')" class="text-sm text-yellow-600 hover:text-yellow-500 font-bold font-proxima flex items-center cursor-pointer">
                            Pergi ke Setup Akun
                            <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4" viewBox="0 0 24 24">
                                <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                            </svg>
                        </a>
                    </div>
                </div>

                <!-- Step 2 -->
                <div class="px-6 py-5 border border-gray-200 rounded flex items-start mb-3">
                    <div class="mr-1.5" :class="profileData.guide_manual_order.list_product ? 'text-green-600' : 'text-gray-300'">
                        <svg class="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path>
                        </svg>
                    </div>
                    <div>
                        <h2 class="text-base font-bold font-proxima -mt-1">2. Lihat List Produk</h2>
                        <h4 class="text-sm text-gray-500 font-medium font-proxima mb-5">Di halaman produk anda bisa ambil informasi produk untuk bahan promosi.</h4>
                        <a @click="$router.push('/product/list?redirect_from=selling_manual_order')" class="text-sm text-yellow-600 hover:text-yellow-500 font-bold font-proxima flex items-center cursor-pointer">
                            Pergi ke List Produk
                            <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4" viewBox="0 0 24 24">
                                <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                            </svg>
                        </a>
                    </div>
                </div>

                <!-- Buat Pesanan Manual -->
                <div class="px-6 py-5 border border-gray-200 rounded flex items-start mb-3">
                    <div class="mr-1.5" :class="profileData.guide_manual_order.create_manual_order ? 'text-green-600' : 'text-gray-300'">
                        <svg class="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path>
                        </svg>
                    </div>
                    <div>
                        <h2 class="text-base font-bold font-proxima -mt-1">3. Buat Pesanan Manual</h2>
                        <h4 class="text-sm text-gray-500 font-medium font-proxima mb-5">Jika anda mendapat pesanan baru. anda bisa pakai fitur ini untuk memproses pesanan anda.</h4>
                        <a @click="$router.push('/order/create?redirect_from=selling_manual_order')" class="text-sm text-yellow-600 hover:text-yellow-500 font-bold font-proxima flex items-center cursor-pointer">
                            Buat Pesanan Manual
                            <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4" viewBox="0 0 24 24">
                                <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                            </svg>
                        </a>
                    </div>
                </div>

                <!-- Monitor Pesanan -->
                <div class="px-6 py-5 border border-gray-200 rounded flex items-start">
                    <div class="mr-1.5" :class="profileData.guide_manual_order.order_monitor ? 'text-green-600' : 'text-gray-300'">
                        <svg class="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path>
                        </svg>
                    </div>
                    <div>
                        <h2 class="text-base font-bold font-proxima -mt-1">4. Monitor Pesanan</h2>
                        <h4 class="text-sm text-gray-500 font-medium font-proxima mb-5">Setelah anda pesan manual, anda bisa cek pesanannya di menu pesanan.</h4>
                        <a @click="$router.push('/order/list')" class="text-sm text-yellow-600 hover:text-yellow-500 font-bold font-proxima flex items-center cursor-pointer">
                            Lihat Semua Pesanan
                            <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4" viewBox="0 0 24 24">
                                <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

import NavigationMenu from '@/components/header/NavigationMenu.vue';
// import ZSelect from '@/components/common/ZSelect.vue';

export default {
    components: {
        NavigationMenu,
        // ZSelect,
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            name: '',
            category_id: '',
            /* Relation */
            parent_category_id: null,
            /* Extra */
            shopee_category_id: '',
            shopee_category_name: '',
            tiktok_category_id: '',
            tiktok_category_name: '',

            /*
             * Relation Options
             */
            /* Category Options */
            categoryData: '',
            categoryOptions: [],

            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getCategory();
	},
    methods: {
        /*
         * Get Category
         */
        async getCategory() {
            try {
                const response = await axios.get(`${API_URL}/api/category/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.categoryData = response.data;

                for(let i=0; i < this.categoryData.length; i++){
                    this.categoryOptions.push({
                        label: this.categoryData[i].name,
                        value: this.categoryData[i]._id
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        /*
         * Create Category
         */
        async createCategory () {
            this.loadingToggle = true;

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Basic Information */
                name: this.name,
                image: '',
                category_id: this.category_id,
                /* Relational */
                parent_category_id: this.parent_category_id && this.parent_category_id.value ? this.parent_category_id.value : undefined,
                /* Extra */
                shopee_category_id: this.shopee_category_id,
                shopee_category_name: this.shopee_category_name,
                tiktok_category_id: this.tiktok_category_id,
                tiktok_category_name: this.tiktok_category_name
            }

            try {
                await axios.post(API_URL + '/api/category/save', form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loadingToggle = false;
                this.$router.push('/category/list');
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;

                this.$notify({
                    title: "Gagal",
                    text: "Kategori gagal disimpan!",
                    position: "bottom",
                    type: "error"
                });
            }
        },
    }
}
</script>

<style scoped>
</style>