<template>
    <div>
        <!-- Modal content -->
        <div class="px-5 py-4 space-y-2">
            <div class="grid grid-cols-1 gap-3">
                <div>
                    <label class="block text-sm font-semibold mb-1 font-proxima" for="receiver_name">Nama Lengkap</label>
                    <z-input v-model="customer_name" full></z-input>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-3">
                <div>
                    <label class="block text-sm font-semibold mb-1 font-proxima" for="receiver_phone">No Telepon</label>
                    <div class="relative flex items-center bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima">
                        <h4 class="font-proxima text-gray-900 text-base mr-2">+62</h4>
                        <input v-model="customer_phone" id="receiver_phone" class="w-full bg-gray-50 focus:outline-none font-proxima" type="number" />
                    </div>
                </div>
                <div>
                    <label class="block text-sm font-semibold mb-1 font-proxima" for="receiver_province">Provinsi</label>
                    <z-input v-model="customer_province" full></z-input>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-3">
                <div>
                    <label class="block text-sm font-semibold mb-1 font-proxima" for="receiver_city">Kota/Kabupaten</label>
                    <z-input v-model="customer_city" full></z-input>
                </div>
                <div>
                    <label class="block text-sm font-semibold mb-1 font-proxima" for="receiver_district">Kecamatan</label>
                    <z-input v-model="customer_district" full></z-input>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-3">
                <div>
                    <label class="block text-sm font-semibold mb-1 font-proxima" for="receiver_postal_code">Kode POS</label>
                    <z-input v-model="customer_postal_code" full></z-input>
                </div>
            </div>
            <div>
                <label class="block text-sm font-semibold mb-1 font-proxima" for="receiver_address">Alamat</label>
                <textarea v-model="customer_address" id="receiver_address" class="w-full bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima" row="3">
                </textarea>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="px-5 py-4 border-t border-gray-200">
            <button @click="saveAddress" class="bg-yellow-500 hover:bg-yellow-600 px-4 py-2 text-white text-center font-semibold font-proxima rounded w-full">Simpan Alamat</button>
        </div>
    </div>
</template>

<script>
/* Import Library, Config & Utils */
import axios from 'axios';
import { API_URL } from '@/config.js';
/* Helpers Components */
import ZInput from '@/components/common/ZInput.vue'

export default {
    components: {
        ZInput,
    },
    props: ['order'],
    data() {
        return {
            /* Form Value */
            customer_name: '',
            customer_phone: '',
            customer_address: '',
            customer_province: '',
            customer_city: '',
            customer_district: '',
            customer_postal_code: '',
        }
    },
    computed: {
        userProfile() {
            return this.$store.getters['auth/userProfile'];
        },
    },
    async created() {
        /* Set Form Data | For Edit Address */
        if(this.order) {
            this.customer_name = this.order.customer_name;
            this.customer_phone = this.order.customer_phone;
            this.customer_address = this.order.customer_address;
            this.customer_province = this.order.customer_province;
            this.customer_city = this.order.customer_city;
            this.customer_district = this.order.customer_district;
            this.customer_postal_code = this.order.customer_postal_code;
        }
    },
    methods: {
        /*
         * Save Address
         */
        async saveAddress() {
            try {
                const data = {
                    customer_name: this.customer_name,
                    customer_phone: this.customer_phone,
                    customer_address: this.customer_address,
                    customer_province: this.customer_province,
                    customer_city: this.customer_city,
                    customer_district: this.customer_district,
                    customer_postal_code: this.customer_postal_code
                }

                await axios.put(`${API_URL}/api/order/update/${this.order._id}`, {order: data}, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                this.$emit('saveAddress');
            } catch (error) {
                console.log(error)
            }
        },
    }
}
</script>

<style scoped>
</style>