<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="sm:flex sm:justify-between sm:items-center mb-8">
                <!-- Left: Title -->
                <div class="mb-4 sm:mb-0">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold">Penarikan Dana</h1>
                </div>

                <!-- Right: Actions  -->
                <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    <!-- Search form -->
                    <!-- <z-input placeholder="Cari merk..." v-model="search" @input="getBrand(page, limit)"></z-input> -->

                    <litepie-datepicker
                        :formatter="{ date: 'DD MMM YYYY', month: 'MMM' }"
                        v-model="filterDate"
                        :options="dateOptions"
                    ></litepie-datepicker>
                    
                    <!-- Add Button -->
                    <!-- <z-button @click="$router.push('/settlement/add')" class="bg-green-600 hover:bg-green-700 text-white flex items-center text-sm">
                        <svg class="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span class="ml-2">Tarik Dana</span>
                    </z-button> -->
                </div>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!---------------->
            <!-- Statistics -->
            <!---------------->
            <div class="md:grid md:grid-cols-4 gap-4 mb-5 md:space-y-0 space-y-2">
                <div class="bg-white rounded border border-gray-300 p-6 flex items-center justify-center">
                    <div>
                        <h4 class="font-statistics text-gray-400 font-medium text-sm text-center mb-2">Saldo Tertunda</h4>
                        <h1 class="font-statistics text-4xl font-bold text-center text-gray-800">Rp{{ formatPrice(total_income) }}</h1>
                    </div>
                </div>
                <div class="bg-white rounded border border-gray-300 p-6 flex items-center justify-center">
                    <div>
                        <h4 class="font-statistics text-gray-400 font-medium text-sm text-center mb-2">Saldo Tersedia</h4>
                        <h1 class="font-statistics text-4xl font-bold text-center text-gray-800 mb-2">Rp{{ formatPrice(total_balance) }}</h1>
                        <button @click="$router.push('/finance/settlement/add')" class="mx-auto px-3 py-1 text-xs bg-green-600 hover:bg-green-700 text-white flex items-center rounded">
                            <svg class="w-3 h-3 fill-current opacity-75 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M12 15c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92 0-1.12-.52-3-4-3-2 0-2-.63-2-1s.7-1 2-1 1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3 2 0 2 .68 2 1s-.62 1-2 1z"></path><path d="M5 2H2v2h2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4h2V2H5zm13 18H6V4h12z"></path></svg>
                            <span class="ml-1">Tarik Dana</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-------------------->
            <!-- End Statistics -->
            <!-------------------->

            <!----------->
            <!-- Table -->
            <!----------->
            <div class="w-full">
                <z-table
                    title="Riwayat Pencairan"
                    :data="data.data"
                    :meta="data.meta"
                    :columns="columns"
                    :mutipleCheck="false"
                    @updateSelectedItems="updateSelectedItems"
                    :sorting="true"
                    @sortingHandler="sortingHandler"
                >
                    <template v-slot:_id="{item}">
                        <h4 class="px-3">{{ item._id }}</h4>
                    </template>
                    <template v-slot:user="{item}">
                        {{ item.user.fullName }} - {{ item.user.email }}
                        <h5 class="text-xs text-gray-500">{{ item.user.role }}</h5>
                    </template>
                    <template v-slot:total_settlement="{item}">
                        <h4>Rp{{ formatPrice(item.total_settlement) }}</h4>
                    </template>
                    <template v-slot:createdAt="{item}">
                        {{ formatDate(item.createdAt) }}
                    </template>
                    <template v-slot:action="{item}" v-if="profileData.role === 'ADMIN'">
                        <button v-if="item.status !== 'Pencairan Selesai'" @click="markIsPaid(item._id)" class="bg-green-600 hover:bg-green-700 border border-green-700 px-3 py-1.5 text-sm text-white font-medium font-proxima rounded">Tandai Sudah Ditransfer</button>
                        <div v-else>
                            <span class="bg-gray-50 px-2 py-1 rounded text-xs text-center text-gray-800 font-proxima">Selesai</span>    
                        </div>
                        <!-- <span class="hidden">{{item}}</span>
                        <ZDropdown class="relative inline-flex">
                            <li>
                                <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="$router.push(`/settlement/edit/${item._id}`)">Lihat Rincian</a>
                            </li>
                        </ZDropdown> -->
                    </template>
                </z-table>
            </div>
            <!--------------->
            <!-- End Table -->
            <!--------------->

            <!---------------->
            <!-- Pagination -->
            <!---------------->
            <div class="mt-8">
                <z-pagination 
                    :meta="data.meta"
                    :PageNavHandler="pageNavHandler"
                    :pageNavNumberHandler="pageNavNumberHandler"
                />
            </div>
            <!-------------------->
            <!-- End Pagination -->
            <!-------------------->
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL
import formatPrice from '../../../utils/formatPrice.js'
import formatDate from '../../../utils/dateFormat.js'
import dayjs from 'dayjs';

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
// import ZInput from '@/components/common/ZInput.vue';
// import ZButton from '@/components/common/ZButton.vue';
// import ZDropdown from '@/components/common/ZDropdown.vue';

export default {
    components: {
        NavigationMenu,
        ZTable,
        ZPagination,
        // ZInput,
        // ZButton,
        // ZDropdown,
    },
    data() {
        return {
            /* Statistics */
            total_income: 0,
            total_balance: 0,
            /* Core Data */
			data: [],
            selectedItems: [],
            dataSelected: null,
            deleteMutiple: false,
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: '_id',
                    label: 'No Pencairan',
                    visibility: true
                },
                {
                    id: 2,
                    field: 'total_settlement',
                    label: 'Jumlah',
                    visibility: true
                },
                {
                    id: 4,
                    field: 'user',
                    label: 'Pengguna',
                    visibility: true
                },
                {
                    id: 5,
                    field: 'createdAt',
                    label: 'Tanggal Pencairan',
                    visibility: true
                },
                {
                    id: 6,
                    field: 'status',
                    label: 'Status',
                    visibility: true
                },
			],
			/* Toggle */
			sidebarOpen: false,
            loadingToggle: false,
            confirmDeleteModal: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: '',
            sort_order: -1,
            filter_date: '',
            filterDate: {
                startDate: '',
                endDate: ''
            },
            /* Extra */
            dateOptions: {
                shortcuts: {
                    today: 'Hari ini',
                    yesterday: 'Kemarin',
                    past: period => period + ' hari terakhir',
                    currentMonth: 'Bulan ini',
                    pastMonth: 'Bulan lalu'
                },
                footer: {
                    apply: 'Terapkan',
                    cancel: 'Batal'
                }
            }
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    watch: {
        filterDate() {
            this.getSettlement(this.page, this.limit);
            this.getTotalIncome();
            this.getTotalBalance();
        }
    },
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        /* Validasi Vendor Terverifikasi */
        if(this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === undefined || this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === false) {
            this.$router.push('/');
        }

        if(this.profileData.role === 'ADMIN') {
            this.columns.push({
                id: 5,
                field: 'action',
                label: 'Action',
                visibility: true,
            });
        }

        // let today = dayjs(new Date()).format('DD MMM YYYY');
        let today = new Date();
        let start_date = dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('DD MMM YYYY');
        let end_date = dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('DD MMM YYYY');
        this.filterDate.startDate = start_date;
        this.filterDate.endDate = end_date;
        this.getSettlement(this.page, this.limit);
        this.getTotalIncome();
        this.getTotalBalance();
	},
	methods: {
		/*
		* Get List Settlement
		*/
		async getSettlement(page, limit) {
            /* Run Loading */
            this.loadingToggle = true;

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                sort: JSON.stringify(this.sort_by),
                start_date: this.filterDate.startDate,
                end_date: this.filterDate.endDate,
            }

            if(this.profileData.role !== 'ADMIN') {
                params.user = this.profileData.id;
            }

			try {
                const response = await axios.get(API_URL + '/api/settlement/get-all', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dimuat!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getSettlement(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getSettlement(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getSettlement(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getSettlement(this.page, this.limit);
            } else {
                this.search = value;
                this.getSettlement(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

		/*
		 * Update Selected Items
		 */
		async updateSelectedItems(selected) {
			this.selectedItems = selected;
		},

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getSettlement(this.page, this.limit);
        },

        /*
		* Get Total Reseller / Platform Income
		*/
		async getTotalIncome() {
            /* Run Loading */
            this.loadingToggle = true;

            let endpoint_url = '';
            let params = {};
            if(this.filterDate.startDate !== '') {
                params.start_date = this.convertDate(this.filterDate.startDate);
            }
            if(this.filterDate.endDate !== '') {
                params.end_date = this.convertDate(this.filterDate.endDate);
            }
            if(this.profileData.role === 'RESELLER') {
                params.user = this.profileData.id;
                endpoint_url = '/api/income/get-total-reseller-income';
            }else if(this.profileData.role === 'ADMIN') {
                endpoint_url = '/api/income/get-total-platform-income';
            }else if(this.profileData.role === 'VENDOR') {
                endpoint_url = '/api/income/get-total-vendor-income/' + this.profileData.vendor;
            }

			try {
                const response = await axios.get(API_URL + endpoint_url, {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.total_income = response.data.data.length > 0 ? response.data.data[0].total_income : 0;

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

				console.error(error);
			}
		},

        /*
		* Get Total Balance
		*/
		async getTotalBalance() {
            /* Run Loading */
            this.loadingToggle = true;

            let endpoint_url = '';
            if(this.profileData.role === 'RESELLER') {
                endpoint_url = '/api/user/get/' + this.profileData.id;
            }else if(this.profileData.role === 'ADMIN') {
                endpoint_url = '/api/income/get-total-balance-platform';
            }else if(this.profileData.role === 'VENDOR') {
                endpoint_url = '/api/income/get-total-balance-vendor/' + this.profileData.vendor;
            }

			try {
                const response = await axios.get(API_URL + endpoint_url, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                if(this.profileData.role === 'RESELLER') {
                    this.total_balance = this.total_balance = response.data.data.balance ? response.data.data.balance : 0;
                }else if(this.profileData.role === 'ADMIN') {
                    this.total_balance = this.total_balance = response.data.data.total_balance ? response.data.data.total_balance : 0;
                }else if(this.profileData.role === 'VENDOR') {
                    this.total_balance = this.total_balance = response.data.data.balance ? response.data.data.balance: 0;
                }

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

				console.error(error);
			}
		},

        async markIsPaid(id) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                await axios.put(API_URL + '/api/settlement/approve-settlement/' + id, {}, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.getSettlement(this.page, this.limit);
                this.getTotalIncome();
                this.getTotalBalance();

                /* Stop Loading */
                loading.hide();
                this.loading = false;
			} catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

				console.error(error);
			}
        },

		/*
        * Other Helpers
        */
        convertDate(date) {
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        formatPrice,
        formatDate
	}
}
</script>

<style scoped>
</style>