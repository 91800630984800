<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <!-- <div class="bg-white rounded border border-gray-300 p-6 w-full">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Topup Saldo</h2>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nominal Topup</label>
                    <input v-model="total_topup" type="number" :max="total_balance" class="w-full bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima" />
                </div>

                <div class="bg-gray-50 p-5 rounded">
                    <div class="w-full flex items-center justify-between mb-3">
                        <h4 class="font-proxima text-sm">Saldo Anda</h4>
                        <h4 class="font-proxima text-sm">Rp{{ formatPrice(total_balance) }}</h4>
                    </div>
                    <div class="w-full flex items-center justify-between">
                        <h4 class="font-proxima text-sm">Minimum Topup Saldo</h4>
                        <h4 class="font-proxima text-sm">Rp{{ formatPrice(100000) }}</h4>
                    </div>
                </div>

                <div class="mt-8" v-if="total_topup > 0">
                    <z-button @click="createTopup" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Topup Saldo</z-button>
                </div>
            </div> -->

            <section class="bg-white dark:bg-gray-900">
                <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                        <h2 class="mb-1 text-3xl font-bold font-proxima text-gray-900">Pilih Paket Topup Saldo</h2>
                        <p class="mb-5 font-light text-gray-500 text-base">Pilih paket topup sesuai kebutuhanmu. saldo topup ini berguna untuk membeli produk di SatuStock ketika terjadi pesanan di marketplace anda.</p>
                    </div>
                    <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                        <!-- Pricing Card -->
                        <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 class="mb-4 text-2xl font-semibold">Newbie</h3>
                            <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">Cocok untuk Pemula di Dunia Marketplace.</p>
                            <div class="flex justify-center items-baseline my-8">
                                <span class="text-4xl font-bold">Rp500.000</span>
                            </div>
                            <!-- List -->
                            <ul role="list" class="mb-8 space-y-4 text-left">
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Perkiraan Order Hingga 10 Pesanan*</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Integrasi dengan Marketplace Shopee dan TiktokShop</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Notif pesanan melalui email dan whatsapp</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-red-500 dark:text-red-400" fill="currentColor" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg>
                                    <span>Tidak dapat bonus saldo</span>
                                </li>
                            </ul>
                            <a @click="total_topup = 500000; createTopup();" class="cursor-pointer text-white bg-yellow-500 hover:bg-yellow-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Pilih Paket Ini</a>
                        </div>
                        <!-- Pricing Card -->
                        <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 class="mb-4 text-2xl font-semibold">Praktisi</h3>
                            <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">Cocok untuk pemain marketplace dengan budget medium.</p>
                            <div class="flex justify-center items-baseline my-8">
                                <span class="text-4xl font-bold">Rp1.000.000</span>
                            </div>
                            <!-- List -->
                            <ul role="list" class="mb-8 space-y-4 text-left">
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Perkiraan Order Hingga 100 Pesanan*</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Integrasi dengan Marketplace Shopee dan TiktokShop</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Notif pesanan melalui email dan whatsapp</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Bonus Saldo <span class="text-green-600"><b>Rp100.000</b></span>*</span>
                                </li>
                            </ul>
                            <a @click="total_topup = 1000000; createTopup();" class="cursor-pointer text-white bg-yellow-500 hover:bg-yellow-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Pilih Paket Ini</a>
                        </div>
                        <!-- Pricing Card -->
                        <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 class="mb-4 text-2xl font-semibold">Suhu</h3>
                            <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">Untuk para suhu marketplace yang kalau jualan tinggal merem.</p>
                            <div class="flex justify-center items-baseline my-8">
                                <span class="text-4xl font-bold">Rp10.000.000</span>
                            </div>
                            <!-- List -->
                            <ul role="list" class="mb-8 space-y-4 text-left">
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Perkiraan Order Hingga 1000 Pesanan*</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Integrasi dengan Marketplace Shopee dan TiktokShop</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Notif pesanan melalui email dan whatsapp</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <!-- Icon -->
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Bonus Saldo <span class="text-green-600"><b>Rp150.000</b></span>*</span>
                                </li>
                            </ul>
                            <a @click="total_topup = 10000000; createTopup();" class="cursor-pointer text-white bg-yellow-500 hover:bg-yellow-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Pilih Paket Ini</a>
                        </div>
                    </div>
                    <h5 class="text-gray-500 font-medium text-xs text-center mt-8"><b>Keterangan:</b>
                        <br/>- Perkiraan harga beli produk rata-rata Rp50.000
                        <br/>- Bonus saldo hanya berlaku saat topup pertama kali
                    </h5>
                </div>
            </section>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatPrice from '../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
// import ZInput from '@/components/common/ZInput.vue';
//import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        NavigationMenu,
        // ZInput,
        //ZButton
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            total_balance: 0,
            total_topup: 0,
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getTotalBalance();
	},
    methods: {
        /*
         * Create Topup
         */
        async createTopup () {
            this.loadingToggle = true;

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Basic Information */
                total_topup: this.total_topup
            }

            try {
                await axios.post(API_URL + '/api/topup/save', form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loadingToggle = false;
                if(this.profileData.guide_marketplace.balance_topup === false) {
                    this.updateGuide();
                }else{
                    this.$router.push('/auth/topup-payment?total_payment='+this.total_topup);
                }
                this.$router.push('/auth/topup-payment?total_payment='+this.total_topup);
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;

                this.$notify({
                    title: "Gagal",
                    text: "Topup gagal disimpan!",
                    position: "bottom",
                    type: "error"
                });
            }
        },

        /*
		* Get Total Balanace
		*/
		async getTotalBalance() {
            /* Run Loading */
            this.loadingToggle = true;

			let endpoint_url = '';
            if(this.profileData.role === 'RESELLER') {
                endpoint_url = '/api/user/get/' + this.profileData.id;
            }else if(this.profileData.role === 'ADMIN') {
                endpoint_url = '/api/income/get-total-balance-platform';
            }else if(this.profileData.role === 'VENDOR') {
                endpoint_url = '/api/income/get-total-balance-vendor/' + this.profileData.vendor;
            }

			try {
                const response = await axios.get(API_URL + endpoint_url, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                if(this.profileData.role === 'RESELLER') {
                    this.total_balance = this.total_balance = response.data.data.balance ? response.data.data.balance : 0;
                }else if(this.profileData.role === 'ADMIN') {
                    this.total_balance = this.total_balance = response.data.data.total_balance ? response.data.data.total_balance : 0;
                }else if(this.profileData.role === 'VENDOR') {
                    this.total_balance = this.total_balance = response.data.data.balance ? response.data.data.balance : 0;
                }

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

				console.error(error);
			}
		},

        /*
         * Update Guide
         */
        async updateGuide() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Update Type */
                update_type: 'balance-topup'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        this.$router.push(`/auth/topup-payment?total_payment=${this.total_topup}&redirect_from=selling_marketplace`);
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

        formatPrice
    }
}
</script>

<style scoped>
</style>