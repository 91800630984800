<template>
    <div>
        <div class="flex items-center justify-center w-full">
            <label
                class="flex flex-col w-full h-32 border-4 border-yellow-500 border-dashed hover:bg-gray-100 hover:border-gray-300 rounded hover:cursor-pointer">
                <div class="flex flex-col items-center justify-center pt-7">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                        fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                        Pilih Gambar
                    </p>
                </div>
                <input type="file" accept="image/*" class="opacity-0" @change="uploadFile($event)" />
            </label>
        </div>
        <div class="mt-5" v-if="images && images.length > 0">
            <div class="flex items-center">
                <h3 class="font-proxima text-sm font-semibold mb-2">Upload {{ images.length }}/10</h3>
            </div>
            <div class="space-y-2">
                <div v-for="(item, index) in images" :key="index" class="px-5 py-3 bg-gray-50 border border-gray-300 rounded flex items-center">
                    <div class="rounded overflow-hidden h-12 w-12 mr-5">
                        <img :src="item.imagePreview" class="object-cover object-center w-full h-full">
                    </div>
                    <div>
                        <h3 class="font-proxima text-sm">{{ item.name }}</h3>
                        <h4 class="font-proxima text-gray-600 text-xs" v-if="item.size !== ''">{{ Math.round((item.size / 1024) * 100) / 100 }} Kb</h4>
                    </div>
                    <button @click="images.splice(index, 1)" class="text-diginesia ml-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current" style="transform: ;msFilter:;">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path><path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Array
        }
    },
    data() {
        return {
            //images: this.modelValue
        }
    },
    computed: {
        images: {
            get() {
                return this.modelValue
            },
        }
    },
    methods: {
        uploadFile(event) {
            /* Cek Apakah Upload Sudah Maximal? */
            if(this.images.length >= 10) {
                return;
            }

            /* Define Input Variable */
            let input = event.target;

            /* Loop Image File */
            for (let i = 0; i < input.files.length; i++) {
                if (input.files && input.files[i]) {

                    let reader = new FileReader();
                    reader.onload = async (e) => {
                        console.log(input.files[i])
                        
                        this.images.push({
                            name: input.files[i].name,
                            size: input.files[i].size,
                            file: input.files[i],
                            imagePreview: URL.createObjectURL(input.files[i])
                        })
                        this.$emit('update:modelValue', this.images);

                        if(e) {
                            return;
                        }
                    }
                    reader.readAsDataURL(input.files[i]);

                }
            }
        }
    }
}
</script>