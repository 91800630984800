<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu/>
        <section v-if="loading" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="sm:flex sm:justify-between sm:items-center mb-8">
                <!-- Left: Title -->
                <div class="mb-4 sm:mb-0">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold">Watermark</h1>
                </div>

                <!-- Right: Actions  -->
                <div class="flex items-center">
                    <!-- Add Button -->
                    <button @click.stop="watermarkOpen = true" class="mr-2 px-4 py-2.5 rounded bg-green-600 hover:bg-green-700 text-white flex items-center text-sm">
                        <span class="ml-1">Tambah Watermark</span>
                    </button>
                    <!-- Search form -->
                    <z-input placeholder="Cari produk..." v-model="search" @input="tableInputSearchHandler"></z-input>
                </div>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!--------------->
            <!-- Watermark -->
            <!--------------->
            <div class="w-full">
                <z-table
                    title="Watermark"
                    :data="data.data"
                    :meta="data.meta"
                    :columns="columns"
                    :mutipleCheck="false"
                    @updateSelectedItems="updateSelectedItems"
                    :sorting="false"
                    @sortingHandler="sortingHandler"
                >
                    <template v-slot:file_url="{item}">
                        <div class="px-3">
                            <img :src="item.file_url.replace('upload/', 'upload/c_fill,h_300,w_300/')" width="100" />
                        </div>
                    </template>
                    <template v-slot:file_name="{item}">
                        {{ item.file_name.replace('clickcuan/media/', '') }}
                    </template>
                    <template v-slot:action="{item}">
                        <span class="hidden">{{item}}</span>
                    </template>
                </z-table>
            </div>
            <!------------------->
            <!-- End Watermark -->
            <!------------------->

            <!---------------->
            <!-- Pagination -->
            <!---------------->
            <div class="mt-8">
                <z-pagination 
                    :meta="data.meta"
                    :PageNavHandler="pageNavHandler"
                    :pageNavNumberHandler="pageNavNumberHandler"
                />
            </div>
            <!-------------------->
            <!-- End Pagination -->
            <!-------------------->

            <!-- Add Watermark -->
            <ZModal id="watermark-modal" :modalOpen="watermarkOpen" @close-modal="watermarkOpen = false" title="Tambah Watermark">
                <div class="px-5 py-4 space-y-2">
                    <div>
                        <div class="bg-gray-50 border border-gray-300 p-3 rounded mb-5">
                            <h1 class="text-sm font-semibold text-gray-600 mb-3 border-b border-gray-300 pb-2">Preview</h1>
                            <img v-if="selectedWatermark" :src="`https://res.cloudinary.com/seebuh/image/upload/c_scale,w_500/c_scale,l_${selectedWatermark.replaceAll('/', ':')},w_500/v1658731585/clickcuan/media/2%20-%20Nerley.jpg.jpg`" class="w-full" />
                            <img v-else src="https://res.cloudinary.com/seebuh/image/upload/v1658731585/clickcuan/media/2%20-%20Nerley.jpg.jpg" class="w-full" />
                        </div>
                        <div class="bg-gray-50 border border-gray-300 p-3 rounded">
                            <h1 class="text-sm font-semibold text-gray-600 mb-3 border-b border-gray-300 pb-2">Upload Gambar</h1>
                            <p v-if="selectedWatermark" class="bg-green-100 px-3 py-2 rounded border border-green-400 text-xs font-semibold text-green-600 mb-3">Upload watermark berhasil!</p>
                            <div class="w-full">
                                <input id="upload-watermark" class="hidden" accept="image" type="file" @change="uploadWatermark($event)">
                                <label 
                                    for="upload-watermark"
                                    type="button"
                                    class="w-full cursor-pointer focus:outline-none border py-2 px-4 rounded-lg shadow-sm text-center text-sm text-gray-600 bg-white hover:bg-gray-100 font-medium"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex flex-shrink-0 w-4 h-4 -mt-1 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                    </svg>				
                                    Upload Watermark
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="px-5 py-4 border-t border-gray-200">
                    <button @click="watermarkOpen = false" class="bg-yellow-500 hover:bg-yellow-600 px-4 py-2 text-white text-center font-semibold font-proxima rounded w-full">Tutup</button>
                </div>
            </ZModal>

            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatPrice from '../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
// import ZInput from '@/components/common/ZInput.vue';
// import ZButton from '@/components/common/ZButton.vue';
// import ZDropdown from '@/components/common/ZDropdown.vue';
// import ZModalAction from '@/components/common/ZModalAction.vue';
// import ZSelect from '@/components/common/ZSelect.vue';
import ZModal from '@/components/common/ZModal.vue';

export default {
    components: {
        NavigationMenu,
        ZTable,
        ZPagination,
        // ZInput,
        // ZButton,
        // ZDropdown,
        // ZModalAction,
        // ZSelect,
        ZModal
    },
    data() {
        return {
            /* Core Data */
			data: [],
            selectedItems: [],
            dataSelected: null,
            deleteMutiple: false,
            selectedWatermark: null,
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'file_url',
                    label: 'Preview Watermark',
                    visibility: true,
                },
                {
                    id: 2,
                    field: 'file_name',
                    label: 'Nama Watermark',
                    visibility: true,
                    sort: true
                },
                // {
                //     id: 8,
                //     field: 'action',
                //     label: 'Action',
                //     visibility: true,
                // }
			],
			/* Toggle */
			watermarkOpen: false,
            loading: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: {'createdAt': -1},
            sort_order: -1
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getWatermark(this.page, this.limit);
	},
	methods: {
		/*
		* Get Watermark
		*/
		async getWatermark(page, limit) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                //sort: JSON.stringify(this.sort_by),
                user: this.profileData.id
            };

			try {
                const response = await axios.get(API_URL + '/api/media/get-all', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
			} catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getWatermark(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getWatermark(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getWatermark(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getWatermark(this.page, this.limit);
            } else {
                this.search = value;
                this.getWatermark(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

		/*
		 * Update Selected Items
		 */
		async updateSelectedItems(selected) {
			this.selectedItems = selected;
		},

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getWatermark(this.page, this.limit);
        },

        /*
		 * Upload Watermark
		 */
        async uploadWatermark(event) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let formData = new FormData();
                formData.append("file", event.target.files[0]);

                const image = await axios.post(API_URL + '/api/media/upload-media', formData, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.selectedWatermark = image.data.file_name;
                this.getWatermark(this.page, this.limit);   

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                
                console.log(error);
            }
        },

        /*
         * Other Helpers
         */
        formatPriceRange(variant, type) {
            const max_price = Math.max.apply(Math, this.convertVariantPriceToArray(variant, type));
            const min_price = Math.min.apply(Math, this.convertVariantPriceToArray(variant, type));

            if(max_price === min_price) {
                return 'Rp' + this.formatPrice(max_price);
            }else{
                return `Rp ${this.formatPrice(min_price)} - Rp ${this.formatPrice(max_price)}`;
            }
        },
		convertVariantPriceToArray(variant, type) {
            var array = [];

            for(let i=0; i < variant.length; i++){
                if(type === 'price') {
                    array.push(variant[i].price);
                }else if(type === 'sell_price') {
                    array.push(variant[i].sell_price);
                }else if(type === 'margin') {
                    array.push(variant[i].margin);
                }
            }

            return array;
		},
        formatPrice
	}
}
</script>

<style scoped>
</style>