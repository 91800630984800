<template>
    <template v-for="(item, index) in data" :key="index">
        <div class="mt-8" v-if="item.vendor_product.length > 1">
            <!-- Left: Title -->
            <div class="mb-4 flex items-center" v-if="item.vendor_product.length > 1">
                <div class="grow-0 mr-3 flex items-center">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold mr-2">Gudang {{ item.fulfillment_name }}</h1>
                    <a :href="'/product/list-group/' + item.vendor_id" class="bg-yellow-500 px-2 py-0.5 rounded-full text-xs text-white font-proxima font-medium cursor-pointer flex items-center">
                        <span class="ml-1">Lihat Semua</span>
                        <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                        </svg>
                    </a>
                </div>
                <div class="h-0.5 grow bg-gray-300"></div>
            </div>

            <div class="grid md:grid-cols-5 grid-cols-2 gap-2">
                <div v-for="(product, i) in item.vendor_product" :key="i">
                    <ProductCard 
                        v-if="item.vendor_product.length > 1"
                        :product_item="product"
                        :shopee_token="shopee_token"
                        :tiktok_token="tiktok_token"
                        :lazada_token="lazada_token"
                        :profileData="profileData"
                        :vendorID="item.vendor_id"
                        @addProductToListingProduct="addProductToListingProduct"
                        @addToCart="addToCart"
                        @detailProduct="detailProduct"
                    />
                </div>
            </div>
        </div>
    </template>
</template>

<script>
import ProductCard from './ProductCard.vue';

export default {
    props: {
        data: {
            type: Array
        },
        shopee_token: {
            type: Object
        },
        tiktok_token: {
            type: Object
        },
        lazada_token: {
            type: Object
        },
        profileData: {
            type: Object
        }
    },
    components: {
        ProductCard
    },
    methods: {
        addProductToListingProduct(item_id) {
            this.$emit('addProductToListingProduct', item_id);
        },
        addToCart(value) {
            this.$emit('addToCart', value);
        },
        detailProduct(value) {
            this.$emit('detailProduct', value);
        }
    }
}
</script>