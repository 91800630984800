<template>
    <div>
        <label v-if="label" :for="id" class="font-proxima font-semibold block mb-1">{{ label }}</label>
        <input :id="id" :type="type ? type : 'text'" :value="modelValue" @input="input" class="bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima" :class="full ? 'w-full' : ''" :disabled="disabled ? true : false" :placeholder="placeholder" />
    </div>
</template>

<script>
export default {
    props: {
        modelValue: { // Declare the property
            type: Object,
            default: () => ({}),  // Do not forget about default value
        },
        id: { type: String },
        label: { type: String },
        full: { type: Boolean },
        placeholder: { type: String },
        type: { type: String },
        disabled: { type: Boolean }
    },
    methods: {
        input(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
}
</script>