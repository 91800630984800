<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu/>
        <section v-if="loading" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="sm:flex sm:justify-between sm:items-center mb-8">
                <!-- Left: Title -->
                <div class="mb-4 sm:mb-0">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold">Produk Lazada</h1>
                </div>

                <!-- Right: Actions  -->
                <div class="flex items-center">
                    <!-- Add Button -->
                    <!-- <button @click="$router.push('/lazada/sync-product')" class="mr-2 px-4 py-2.5 rounded bg-green-600 hover:bg-green-700 text-white flex items-center text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current opacity-50 flex-shrink-0" viewBox="0 0 24 24">
                            <path d="m13 7.101.01.001a4.978 4.978 0 0 1 2.526 1.362 5.005 5.005 0 0 1 1.363 2.528 5.061 5.061 0 0 1-.001 2.016 4.976 4.976 0 0 1-1.363 2.527l1.414 1.414a7.014 7.014 0 0 0 1.908-3.54 6.98 6.98 0 0 0 0-2.819 6.957 6.957 0 0 0-1.907-3.539 6.97 6.97 0 0 0-2.223-1.5 6.921 6.921 0 0 0-1.315-.408c-.137-.028-.275-.043-.412-.063V2L9 6l4 4V7.101zm-7.45 7.623c.174.412.392.812.646 1.19.249.37.537.718.854 1.034a7.036 7.036 0 0 0 2.224 1.501c.425.18.868.317 1.315.408.167.034.338.056.508.078v2.944l4-4-4-4v3.03c-.035-.006-.072-.003-.107-.011a4.978 4.978 0 0 1-2.526-1.362 4.994 4.994 0 0 1 .001-7.071L7.051 7.05a7.01 7.01 0 0 0-1.5 2.224A6.974 6.974 0 0 0 5 12a6.997 6.997 0 0 0 .55 2.724z"></path>
                        </svg>
                        <span class="ml-1">Sinkronkan Produk</span>
                    </button> -->
                    <!-- Search form -->
                    <!-- <z-input placeholder="Cari produk..." v-model="search" @input="tableInputSearchHandler"></z-input> -->
                </div>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!-- Guide Dropshipper -->
            <div v-if="$route.query.redirect_from && $route.query.redirect_from == 'selling_marketplace'" class="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                <h2><b>Langkah Terakhir!</b><br/>Jika ada pesanan dari Marketplace anda bisa cek pesanannya disini => <a @click="$router.push('/order/list?redirect_form=selling_marketplace')" class="text-blue-600 hover:text-blue-500 cursor-pointer font-semibold">Lihat Pesanan</a></h2>
            </div>

            <!----------->
            <!-- Table -->
            <!----------->
            <div class="w-full">
                <z-table
                    title="Produk"
                    :data="data.data"
                    :meta="data.meta"
                    :columns="columns"
                    :mutipleCheck="false"
                    @updateSelectedItems="updateSelectedItems"
                    :sorting="false"
                    @sortingHandler="sortingHandler"
                >
                    <template v-slot:name="{item}">
                        <span v-if="item.product">
                            {{ item.product.name.slice(0, 40) + (item.product.name.length > 40 ? "..." : "") }}
                        </span>
                    </template>
                    <template v-slot:images="{item}">
                        <div class="px-3" v-if="item.product">
                            <img :src="item.product.images[0].replace('upload/', 'upload/c_fill,h_300,w_300/')" width="100" />
                        </div>
                    </template>
                    <template v-slot:price="{item}">
                        <span v-if="item.product">
                            {{ formatPriceRange(item.variant, 'price') }}
                        </span>
                    </template>
                    <template v-slot:sell_price="{item}">
                        <span v-if="item.product" class="text-yellow-600">
                            {{ formatPriceRange(item.variant, 'sell_price') }}
                        </span>
                    </template>
                    <template v-slot:margin="{item}">
                        <span v-if="item.product" class="text-green-500">
                            {{ formatPriceRange(item.variant, 'margin') }}
                        </span>
                    </template>
                    <template v-slot:link="{item}">
                        <a :href="`https://lazada.co.id/products/i${item.item_id}.html`" target="_blank" class="text-blue-500 hover:text-blue-600 font-proxima">Link Produk Lazada</a>
                    </template>
                    <template v-slot:action="{item}">
                        <span class="hidden">{{item}}</span>
                    </template>
                </z-table>
            </div>
            <!--------------->
            <!-- End Table -->
            <!--------------->

            <!---------------->
            <!-- Pagination -->
            <!---------------->
            <div class="mt-8">
                <z-pagination 
                    :meta="data.meta"
                    :PageNavHandler="pageNavHandler"
                    :pageNavNumberHandler="pageNavNumberHandler"
                />
            </div>
            <!-------------------->
            <!-- End Pagination -->
            <!-------------------->

            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL
import formatPrice from '../../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
// import ZInput from '@/components/common/ZInput.vue';
// import ZButton from '@/components/common/ZButton.vue';
// import ZDropdown from '@/components/common/ZDropdown.vue';
// import ZModalAction from '@/components/common/ZModalAction.vue';
// import ZSelect from '@/components/common/ZSelect.vue';

export default {
    components: {
        NavigationMenu,
        ZTable,
        ZPagination,
        // ZInput,
        // ZButton,
        // ZDropdown,
        // ZModalAction,
        // ZSelect
    },
    data() {
        return {
            /* Core Data */
			data: [],
            selectedItems: [],
            dataSelected: null,
            deleteMutiple: false,
            selectedProduct: '',
            lazada_token: null,
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'images',
                    label: 'Gambar',
                    visibility: true,
                },
                {
                    id: 2,
                    field: 'name',
                    label: 'Nama Produk',
                    visibility: true,
                    sort: true
                },
                // {
                //     id: 3,
                //     field: 'sku',
                //     label: 'SKU',
                //     visibility: true,
                //     sort: true
                // },
                {
                    id: 4,
                    field: 'price',
                    label: 'Harga Beli',
                    visibility: true,
                },
                {
                    id: 5,
                    field: 'sell_price',
                    label: 'Harga Jual',
                    visibility: true,
                },
                {
                    id: 6,
                    field: 'margin',
                    label: 'Profit',
                    visibility: true,
                },
                {
                    id: 7,
                    field: 'link',
                    label: 'Link Produk',
                    visibility: true,
                },
                // {
                //     id: 8,
                //     field: 'action',
                //     label: 'Action',
                //     visibility: true,
                // }
			],
			/* Toggle */
			sidebarOpen: false,
            loading: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: {'createdAt': -1},
            sort_order: -1
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getProduct(this.page, this.limit);
        this.getAuthTokenLazada();
	},
	methods: {
		/*
		* Get List Product
		*/
		async getProduct(page, limit) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                sort: JSON.stringify(this.sort_by),
                user: this.profileData.id
            };

			try {
                const response = await axios.get(API_URL + '/api/integration/lazada/get-list-product', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
			} catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getProduct(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getProduct(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getProduct(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getProduct(this.page, this.limit);
            } else {
                this.search = value;
                this.getProduct(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

		/*
		 * Update Selected Items
		 */
		async updateSelectedItems(selected) {
			this.selectedItems = selected;
		},

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getProduct(this.page, this.limit);
        },

        /*
         * Get Auth Token Lazada
         */
        async getAuthTokenLazada() {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/integration/lazada/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.lazada_token = response.data.data;

                this.loadingToggle = false;
            } catch (error) {
                this.loadingToggle = false;
                this.$router.push('/settings/marketplace-integration');
            }
        },

       /*
        * Other Helpers
        */
        formatPriceRange(variant, type) {
            const max_price = Math.max.apply(Math, this.convertVariantPriceToArray(variant, type));
            const min_price = Math.min.apply(Math, this.convertVariantPriceToArray(variant, type));

            if(max_price === min_price) {
                return 'Rp' + this.formatPrice(max_price);
            }else{
                return `Rp ${this.formatPrice(min_price)} - Rp ${this.formatPrice(max_price)}`;
            }
        },
		convertVariantPriceToArray(variant, type) {
            var array = [];

            for(let i=0; i < variant.length; i++){
                if(type === 'price') {
                    array.push(variant[i].price);
                }else if(type === 'sell_price') {
                    array.push(variant[i].sell_price);
                }else if(type === 'margin') {
                    array.push(variant[i].margin);
                }
            }

            return array;
		},
        formatPrice
	}
}
</script>

<style scoped>
</style>