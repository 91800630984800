<template>
    <Listbox as="div">
        <div class="mt-1 relative">
            <ListboxButton class="relative bg-gray-50 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500 font-proxima" :class="full ? 'w-full' : ''">
                <span class="flex items-center">
                    <span class="block truncate" :class="modelValue ? 'text-gray-800' : 'text-gray-400'">{{ modelValue ? modelValue.label : `Silahkan Pilih ${label}` }}</span>
                </span>
                <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions class="absolute z-10 mt-1 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none font-proxima" :class="full ? 'w-full' : ''">
                    <!-- untuk kembalikan value ke v-model component jangan lupa pakai emit update:modelValue -->
                    <ListboxOption as="template" v-for="(item, index) in options" :key="index" :value="item" v-slot="{ active, modelValue }" @click="this.$emit('update:modelValue', item); this.$emit('changeData')">
                        <li :class="[active ? 'text-gray-800 bg-gray-200' : 'text-gray-800', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                            <div class="flex items-center mr-10">
                                <span :class="[modelValue ? 'font-semibold' : 'font-normal', 'block truncate']">
                                    {{ item.label }}
                                </span>
                            </div>

                            <span v-if="modelValue" :class="[active ? 'text-gray-800' : 'text-gray-800', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

export default {
    props: {
        modelValue: { type: Object }, // untuk get value dari model jangan lupa pakai modelValue
        id: { type: String },
        label: { type: String },
        full: { type: Boolean },
        options: { type: Array }
    },
    components: {
        Listbox,
        ListboxButton,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        SelectorIcon,
    }
}
</script>