<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Customer Service</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Lengkap</label>
                    <z-input v-model="fullName" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Email</label>
                    <z-input v-model="email" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">No Handphone</label>
                    <z-input v-model="phoneNumber" full></z-input>
                </div>
                <!-- <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Tipe Pengguna</label>
                    <z-select v-model="role" label="Tipe Pengguna" :options="roleOptions" full></z-select>
                </div> -->
                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Jenis Kelamin</label>
                    <z-select v-model="gender" label="Jenis Kelamin" :options="genderOptions" full></z-select>
                </div>
                <div class="mb-3" v-if="role.value === 'AFFILIATE'">
                    <label class="font-proxima font-semibold block mb-1 text-blue-500">Affiliate Manager</label>
                    <z-select v-model="affiliate_manager" label="Jenis Kelamin" :options="affiliateManagerOptions" full></z-select>
                </div>
                <div class="mb-0">
                    <label class="font-proxima font-semibold block">Password</label>
                    <z-input v-model="password" type="password" full></z-input>
                </div>

                <div class="mt-8">
                    <z-button @click="updateUser" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Simpan</z-button>
                </div>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZSelect from '@/components/common/ZSelect.vue';

export default {
    components: {
        NavigationMenu,
        ZInput,
        ZButton,
        ZSelect
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            fullName: '',
            email: '',
            phoneNumber: '',
            role: 'CS',
            gender: '',
            password: '',
            affiliate_manager: '',
            /* Options */
            genderOptions: [
                {label: 'Pria', value: 'Male'}, 
                {label: 'Wanita', value: 'Female'}
            ],
            roleOptions: [
                {label: 'Admin', value: 'ADMIN'},
                {label: 'Reseller/Dropshipper', value: 'RESELLER'}, 
                {label: 'Brand Owner/Supplier', value: 'VENDOR'}
            ],
            affiliateManagerOptions: [],
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/signin');
            });
        }

        this.getUserById();
        this.getListAffiliateManager();
    },
    methods: {
        /*
         * Get User By Id
         */
        async getUserById () {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/user/get/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                this.fullName = response.data.data.fullName;
                this.email = response.data.data.email;
                this.phoneNumber = response.data.data.phoneNumber;
                //this.role = this.roleOptions.find(item => item.value === response.data.data.role);
                this.gender = this.genderOptions.find(item => item.value === response.data.data.gender);
                this.affiliate_manager = this.affiliateManagerOptions.find(item => item.value === response.data.data.affiliate_manager);
                this.parent_account = response.data.data.parent_account ? response.data.data.parent_account : null;

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;

                this.$notify({
                    title: "Gagal",
                    text: "Pengguna gagal diambil!",
                    position: "bottom",
                    type: "error"
                });
            }
        },

        /*
		* Get List Manager Affiliasi
		*/
		async getListAffiliateManager() {
            /* Run Loading */
            this.loadingToggle = true;

            let params = {
                pagination: false,
                role: 'MANAGEMENT'
            };

			try {
                const response = await axios.get(API_URL + '/api/user/get-all', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Set Affiliate Manager Options */
                response.data.map(item => {
                    this.affiliateManagerOptions.push({
                        label: item.fullName + ' - ' + item.email,
                        value: item._id
                    });
                })

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dimuat!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
			}
		},

        /*
         * Update Pengguna
         */
        async updateUser () {
            this.loadingToggle = true;

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Basic Information */
                fullName: this.fullName,
                email: this.email,
                phoneNumber: this.phoneNumber,
                role: 'CS',
                gender: this.gender.value,
                password: this.password,
                parent_account: this.profileData.id
            }

            if(this.role.value === 'AFFILIATE') {
                form.affiliate_manager = this.affiliate_manager.value;
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.$route.params.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loadingToggle = false;
                this.$router.push('/member/list');
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;

                this.$notify({
                    title: "Gagal",
                    text: "Pengguna gagal disimpan!",
                    position: "bottom",
                    type: "error"
                });
            }
        },
    }
}
</script>

<style scoped>
</style>