<template>
    <!-- <canvas ref="canvas" :data="data" :width="width" :height="height"></canvas> -->
    <LineChart v-bind="lineChartProps" />
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { LineChart, useLineChart } from 'vue-chart-3';
import { computed, defineComponent } from 'vue';

Chart.register(...registerables);

export default defineComponent({
    name: 'DashboardChart',
    props: ['allOrder', 'deliveredOrder', 'dateStatistics'],
    components: {
        LineChart
    },
    setup(props) {
        /* Computed */
        const chartData = computed(() => ({
            labels: props.dateStatistics,
            datasets: [
                {
                    label: 'Total Pesanan',
                    data: props.allOrder,
                    backgroundColor: ['rgb(255, 204, 51, 0.3)'],
                    fill: true,
                },
                {
                    label: 'Pesanan Terkirim',
                    data: props.deliveredOrder,
                    backgroundColor: ['rgb(183, 147, 40, 0.3)'],
                    fill: true,
                },
            ],
        }));

        /* Extra */
        const { lineChartProps, lineChartRef } = useLineChart({
            chartData,
        });

        /* Define State & Methods */
        return {
            lineChartProps, 
            lineChartRef
        };
    },
});
</script>