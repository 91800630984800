<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="sm:flex sm:justify-between sm:items-center mb-8">
                <!-- Left: Title -->
                <div class="mb-4 sm:mb-0">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold">Master Inventory</h1>
                </div>

                <!-- Right: Actions  -->
                <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    <!-- Search form -->
                    <z-input placeholder="Cari produk..." v-model="search" @input="getStock(page, limit)"></z-input>
                    <!-- Delete button -->
                    <div class="flex items-center" v-if="selectedItems && selectedItems.length > 0">
                        <div class="hidden xl:block text-sm italic mr-2 whitespace-nowrap"><span>{{selectedItems.length}}</span> item yang dipilih</div>
                        <z-button class="button-diginesia text-white text-sm" @click.stop="deleteMutiple = true; confirmDeleteModal = true">Hapus Masal</z-button>
                    </div>
                    <!-- Add Button -->
                    <!-- <z-button @click="$router.push('/stock/add')" class="bg-green-600 hover:bg-green-700 text-white flex items-center text-sm">
                        <svg class="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span class="ml-2">Tambah Stok</span>
                    </z-button> -->
                </div>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!----------->
            <!-- Table -->
            <!----------->
            <div class="w-full">
                <z-table
                    title="Stok"
                    :data="data.data"
                    :meta="data.meta"
                    :columns="columns"
                    :mutipleCheck="false"
                    @updateSelectedItems="updateSelectedItems"
                    :sorting="true"
                    @sortingHandler="sortingHandler"
                >
                    <template v-slot:product="{item}">
                        <h4 class="px-3">{{ item.product.name }}</h4>
                    </template>
                    <template v-slot:action="{item}">
                        <span class="hidden">{{item}}</span>
                        <!-- Action Button -->
                        <ZDropdown class="relative inline-flex">
                            <li>
                                <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click.stop="updateStockModal = true; skuSelected = item">Update Stok</a>
                            </li>
                            <li>
                                <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click.stop="historyStockModal = true; skuSelected = item">Riwayat Perubahan Stok</a>
                            </li>
                        </ZDropdown>
                    </template>
                </z-table>
            </div>
            <!--------------->
            <!-- End Table -->
            <!--------------->

            <!---------------->
            <!-- Pagination -->
            <!---------------->
            <div class="mt-8">
                <z-pagination 
                    :meta="data.meta"
                    :PageNavHandler="pageNavHandler"
                    :pageNavNumberHandler="pageNavNumberHandler"
                />
            </div>
            <!-------------------->
            <!-- End Pagination -->
            <!-------------------->
            
            <!-- Confirm Delete Modal -->
            <ZModalAction id="danger-modal" :modalOpen="confirmDeleteModal" @close-modal="confirmDeleteModal = false">
                <div class="flex space-x-4">
                    <!-- Icon -->
                    <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-red-100">
                        <svg class="w-4 h-4 shrink-0 fill-current text-red-500" viewBox="0 0 16 16">
                            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                        </svg>
                    </div>
                    <!-- Content -->
                    <div>
                        <!-- Modal header -->
                        <div class="mb-2">
                            <div class="text-lg font-semibold text-gray-800 font-proxima">Hapus Data Stok</div>
                        </div>
                        <!-- Modal content -->
                        <div class="text-sm mb-10 font-proxima">
                            <div class="space-y-2">
                                <p>Anda yakin ingin menghapus data stok ini?<br/> Data akan dihapus secara permanen. Tindakan ini tidak bisa dibatalkan.</p>
                            </div>
                        </div>
                        <!-- Modal footer -->
                        <div class="flex flex-wrap justify-end space-x-2">
                            <button class="px-3 py-1.5 text-sm rounded border-gray-200 hover:border-gray-300 text-gray-600 font-proxima" @click.stop="confirmDeleteModal = false">Batal</button>
                            <button @click="deleteMutiple ? handleMutipleDelete() :  handleDelete()" class="px-3 py-1.5 text-sm rounded bg-red-500 hover:bg-red-600 text-white font-proxima">Ya, Hapus Sekarang</button>
                        </div>
                    </div>
                </div>
            </ZModalAction>
            <!-- End Content Area -->

            <!-- Manage Stock -->
            <ZModal id="manage-stock" title="Ubah Stok Langsung" :modalOpen="updateStockModal" @close-modal="updateStockModal = false">
                <!-- Main Content -->
                <div class="px-5 py-6 text-gray-600">
                    <!-- Detail Produk -->
                    <div v-if="skuSelected !== null" class="mb-5">
                        <div class="text-gray-800 mb-4">
                            <h2 class="font-medium text-gray-500">{{ skuSelected.product.name }}</h2>
                            <h2 class="font-bold text-sm">{{ skuSelected.sku }}</h2>
                        </div>
                        <h4 class="text-gray-500 text-sm">
                            Stok saat ini: {{ skuSelected.stock }}
                        </h4>
                    </div>
                    <!-- / End Detail Produk -->

                    <hr class="border-t border-gray-200" /><!-- Divider -->

                    <!-- Jumlah Stok -->
                    <h4 class="text-sm text-gray-900 mb-1 mt-5">Jumlah Stok Baru</h4>
                    <input v-model="stockQty" class="bg-white text-sm rounded border-2 border-gray-400 focus:outline-none focus:border-yellow-500 px-3 py-2 w-full" placeholder="0" type="number">
                    <!-- / End Jumlah Stok -->
                </div>
                <!-- / End Main Content -->

                <!-- Footer Content -->
                <div class="px-5 py-4 flex justify-end">
                    <button @click="updateStock(skuSelected._id)" class="bg-yellow-500 mr-1 rounded text-sm py-2 px-5 text-white hover:bg-yellow-600 transition duration-150 focus:outline-none">Ubah Stok</button>
                </div>
                <!-- / End Footer Content -->
            </ZModal>
            <!-- End Manage Stock -->

            <!-- History Stock -->
            <ZModal id="history-stock" title="Riwayat Perubahan Stok" :modalOpen="historyStockModal" @close-modal="historyStockModal = false">
                <!-- Main Content -->
                <div class="px-5 py-6 text-gray-600" v-if="skuSelected !== null">
                    <div class="text-gray-800 mb-4">
                        <h2 class="font-medium text-gray-500">{{ skuSelected.product.name }}</h2>
                        <h2 class="font-bold text-sm">{{ skuSelected.sku }}</h2>
                    </div>
                    <hr class="mt-5 mb-8" />
                    <table class="table-fixed min-w-full mb-3">
                        <thead>
                            <tr class="bg-gray-700">
                                <th class="text-left px-4 py-2">
                                    <span class="text-gray-300">Tanggal</span>
                                </th>
                                <th class="text-left px-4 py-2">
                                    <span class="text-gray-300">Stock</span>
                                </th>
                                <th class="text-left px-4 py-2">
                                    <span class="text-gray-300">Keterangan</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-gray-200">
                            <tr v-for="(item, index) in skuSelected.tracking" :key="index" class="bg-white border-2 border-gray-200">
                                <td class="px-4 py-2">
                                    {{ formatDate(item.date) }}
                                </td>
                                <td class="px-4 py-2">
                                    {{ item.stock }}
                                </td>
                                <td class="px-4 py-2">
                                    {{ item.info }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- / End Main Content -->
            </ZModal>
            <!-- History Stock -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatDate from '../../utils/dateFormat.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZDropdown from '@/components/common/ZDropdown.vue';
import ZModalAction from '@/components/common/ZModalAction.vue'
import ZModal from '@/components/common/ZModal.vue'

export default {
    components: {
        NavigationMenu,
        ZTable,
        ZPagination,
        ZInput,
        ZButton,
        ZDropdown,
        ZModalAction,
        ZModal
    },
    data() {
        return {
            /* Core Data */
			data: [],
            selectedItems: [],
            dataSelected: null,
            deleteMutiple: false,
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'product',
                    label: 'Produk',
                    visibility: true
                },
                {
                    id: 2,
                    field: 'sku',
                    label: 'Sku',
                    visibility: true
                },
                {
                    id: 3,
                    field: 'stock',
                    label: 'Stok',
                    visibility: true
                },
                {
                    id: 4,
                    field: 'action',
                    label: 'Action',
                    visibility: true,
                },
			],
			/* Toggle */
			sidebarOpen: false,
            loadingToggle: false,
            confirmDeleteModal: false,
            updateStockModal: false,
            historyStockModal: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: '',
            sort_order: -1,
            /* Update Stock */
            skuSelected: null,
            stockQty: 0
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        /* Validasi Vendor Terverifikasi */
        if(this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === undefined || this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === false) {
            this.$router.push('/');
        }

        this.getStock(this.page, this.limit);
	},
	methods: {
		/*
		* Get List Stock
		*/
		async getStock(page, limit) {
            /* Run Loading */
            this.loadingToggle = true;

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                sort: JSON.stringify(this.sort_by)
            }

			if(this.profileData.role === 'VENDOR') {
                params.user = this.profileData.id;
            }

			try {
                const response = await axios.get(API_URL + '/api/stock/get-all', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dimuat!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getStock(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getStock(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getStock(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getStock(this.page, this.limit);
            } else {
                this.search = value;
                this.getStock(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

		/*
		 * Update Selected Items
		 */
		async updateSelectedItems(selected) {
			this.selectedItems = selected;
		},

        /*
		 * Delete Product
		 */
        /* Handle Delete Product */
        async handleDelete(id = null) {
            /* Run Loading */
            this.loadingToggle = true;

            try {
                const product_id = id ? id : this.dataSelected;
                await axios.delete(API_URL + '/api/stock/delete/' + product_id, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                this.loadingToggle = false;

                /* Refresh Page */
                window.location.reload();
            } catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dihapus!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
            }
        },
        /* Handle Mutiple Delete */
        handleMutipleDelete() {
            // Looping dan Eksekusi penghapusan data
            for(let i = 0; i < this.selectedItems.length; i++){
                this.handleDelete(this.selectedItems[i]._id);
            }
        },

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getStock(this.page, this.limit);
        },

        /*
		 * Update Stock by ID
		 */
        async updateStock(id) {
            try {
                let data = {
                    stock: this.stockQty
                };

                await axios.put(API_URL + '/api/stock/update-stock/' + id, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.updateStockModal = false; // Sembunyikan Modal Ubah Stok
                this.getStock(this.page, this.limit);
            } catch (error) {
                console.log(error)
            }
        },

		/*
        * Other Helpers
        */
        formatDate
	}
}
</script>

<style scoped>
</style>