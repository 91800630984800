<template>
    <div>
        <SettingPanel>
            <div class="bg-white rounded border border-gray-300 p-6 md:w-3/4 w-full">
                <div class="border-b border-gray-200 pb-2 mb-5">
                    <h2 class="text-xl font-bold font-proxima">Integrasi Marketplace (Lazada)</h2>
                </div>

                <div class="mb-3 lg:flex lg:items-center lg:justify-between">
                    <div class="text-sm font-semibold">
                        <span>Total Integrasi Toko : </span>
                        <span class="text-gray-400">({{ lazada_meta ? lazada_meta.totalItems : 0 }}/1)</span>
                    </div>
                    <button @click.stop="marketplaceIntegrationToggle = true" class="border border-gray-200 rounded shadow-sm text-sm font-semibold px-3 py-1 hover:bg-gray-50">Tambah Integrasi</button>
                </div>

                <div class="rounded-sm border border-gray-200">
                    <table class="table-auto w-full" v-if="lazada_data.length > 0">
                        <thead class="text-xs font-semibold text-left text-gray-500 bg-gray-50 border-b border-gray-200">
                            <tr>
                                <th class="p-3 whitespace-nowrap">Nama Akun</th>
                                <th class="p-3 whitespace-nowrap">Status</th>
                                <th class="p-3 whitespace-nowrap">Waktu Integrasi</th>
                                <!-- <th class="p-3 whitespace-nowrap">Aksi</th> -->
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr class="text-sm font-medium" v-for="(item, index) in lazada_data" :key="index">
                                <td class="p-3 whitespace-nowrap">{{ item.account }}</td>
                                <td class="p-3 whitespace-nowrap flex items-center text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4 mr-1" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                                        <path d="M11.488 21.754c.294.157.663.156.957-.001 8.012-4.304 8.581-12.713 8.574-15.104a.988.988 0 0 0-.596-.903l-8.05-3.566a1.005 1.005 0 0 0-.813.001L3.566 5.747a.99.99 0 0 0-.592.892c-.034 2.379.445 10.806 8.514 15.115zM8.674 10.293l2.293 2.293 4.293-4.293 1.414 1.414-5.707 5.707-3.707-3.707 1.414-1.414z"></path>
                                    </svg>
                                    <span class="font-semibold font-proxima text-gray-700 text-sm">Terhubung</span>
                                </td>
                                <td class="p-3 whitespace-nowrap">{{ formatDate(item.createdAt) }} - {{ formatTime(item.createdAt) }} WIB</td>
                                <!-- <td class="p-3 whitespace-nowrap"></td> -->
                            </tr>
                        </tbody>
                    </table>
                    <div v-else>
                        <div class="flex justify-center items-center p-8">
                            <p class="text-xs text-center text-gray-500">Belum ada toko yang terintegrasi. Silahkan tambah integrasi dengan mengklik tombol tambah integrasi.</p>
                        </div>
                    </div>
                </div>

                <!-- Edit Order Note -->
                <ZModalAction id="marketplace-integration-modal" :modalOpen="marketplaceIntegrationToggle" @close-modal="marketplaceIntegrationToggle = false" title="Integrasi Marketplace">
                    <!-- Modal header -->
                    <div class="mb-5 text-center">
                        <!-- Icons -->
                        <div class="inline-flex items-center justify-center space-x-3 mb-4">
                            <!-- SatuStock Logo -->
                            <div class="bg-gray-100 w-12 h-12 rounded-full p-2">
                                <img src="@/assets/images/logo/logo-gold.png" />
                            </div>
                            <!-- Arrows -->
                            <svg class="h-4 w-4 fill-current text-gray-400" viewBox="0 0 16 16">
                                <path d="M5 3V0L0 4l5 4V5h8a1 1 0 000-2H5zM11 11H3a1 1 0 000 2h8v3l5-4-5-4v3z" />
                            </svg>
                            <!-- Platform Logo -->
                            <img src="@/assets/images/logo_mp/lazada.png" class="w-12 h-12 rounded-full" />
                        </div>
                        <div class="text-lg font-semibold text-gray-800">Hubungkan SatuStock dengan Akun Lazada Anda</div>
                    </div>
                    <!-- Modal content -->
                    <div class="text-sm mb-5">
                        <div class="font-medium text-gray-800 mb-3">SatuStock akan mengakses Data Lazada Anda seperti:</div>
                        <ul class="space-y-2 mb-5">
                            <li class="flex items-center">
                                <svg class="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                </svg>
                                <div>Data Produk</div>
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                </svg>
                                <div>Data Penjualan</div>
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                </svg>
                                <div>Data Stok</div>
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                </svg>
                                <div>Dan Data Penunjang Lainnya</div>
                            </li>
                        </ul>
                        <div class="text-xs text-gray-500">Dengan mengklik Izinkan akses, Anda mengizinkan SatuStock untuk menggunakan informasi Anda sesuai dengan Kebijakan Privasinya. Anda dapat mengetahuinya kapan saja di halaman integrasi akun SatuStock Anda.</div>
                    </div>
                    <!-- Modal footer -->
                    <div class="flex flex-wrap justify-end space-x-2">
                        <button class="px-3 py-1 rounded border border-gray-300 hover:border-gray-300 text-gray-600 font-proxima" @click.stop="marketplaceIntegrationToggle = false">Batal</button>
                        <button @click="goToIntegration()" class="px-3 py-1 rounded border border-green-700 bg-green-600 hover:bg-green-700 text-white font-proxima">Setuju</button>
                    </div>
                </ZModalAction>
            </div>
        </SettingPanel>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL
import formatDate from '../../../utils/dateFormat.js'

import SettingPanel from '@/components/special/SettingPanel.vue';

// import ZButton from '@/components/common/ZButton.vue';
import ZModalAction from '@/components/common/ZModalAction.vue';

export default {
    components: {
        SettingPanel,
        //ZButton
        ZModalAction
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            lazada_data: [],
            lazada_meta: null,
            lazada_token: null,
            /* Toggle */
            marketplaceIntegrationToggle: false,
            loading: false,
            /* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: {'createdAt': -1},
            sort_order: -1
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        /* Validasi Vendor Terverifikasi */
        if(this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === undefined || this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === false) {
            this.$router.push('/');
        }

        /* Get Auth Token Platform */
        this.getAllAuthorization(this.page, this.limit);
        //this.checkLazadaTokenLogin();

        /* Callback Integration */
        if(this.$route.query.code) {
            let data = {
                code: this.$route.query.code,
                user: this.profileData.id ? this.profileData.id : null
            }
            this.callbackProcess('/api/integration/lazada/callback', data);
        }
        
        if(this.$route.query.redirect_from == 'selling_marketplace_integration') {
            this.updateGuide();
        }
	},
    methods: {
        /*
         * Get All Auhorization
         */
        async getAllAuthorization(page, limit) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let params = {
                    page: page,
                    limit: limit,
                    pagination: true,
                    search: this.search,
                    sort: JSON.stringify(this.sort_by)
                };

                const response = await axios.get(`${API_URL}/api/integration/lazada/get-all-authorization/${this.profileData.id}`, {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.lazada_data = response.data.data;
                this.lazada_meta = response.data.meta;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        // /*
        //  * Check Lazada Token Login
        //  */
        // async checkLazadaTokenLogin() {
        //     /* Run Loading */
        //     this.loading = true;
        //     const loading = this.$loading.show({ container: this.$refs.loadingContainer });

        //     try {
        //         const response = await axios.get(`${API_URL}/api/integration/lazada/get-auth-token/${this.profileData.id}`, {
        //             headers: {
        //                 'Authorization': this.$store.state.auth.token
        //             }
        //         });

        //         /* Set Integration Data */
        //         this.lazada_token = response.data;

        //         /* Stop Loading */
        //         loading.hide();
        //         this.loading = false;
        //     } catch (error) {
        //         /* Stop Loading */
        //         loading.hide();
        //         this.loading = false;
        //     }
        // },

        /*
         * Go To Integration
         */
        async goToIntegration() {
            this.getAuthUrl('/api/integration/lazada/auth-url');
        },

        /*
         * Update Guide
         */
        async updateGuide() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Update Type */
                update_type: 'integration'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        this.$router.push('/selling/marketplace')
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

        /* Helpers */
        /*
         * Get Auth URL
         */
        async getAuthUrl(path) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });
            try {
                const authUrl = await axios.get(`${API_URL}${path}`);
                window.location.href = authUrl.data.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /*
         * Callback Process
         */
        async callbackProcess(path, data) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {                
                await axios.post(`${API_URL}${path}`, data);
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                
                window.location.href = '/settings/marketplace-integration/lazada?redirect_from=selling_marketplace_integration';
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        formatDate,
        formatTime(date) {
            var format = date ? new Date(date).toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' }) : ''
            return format;
        }
    }
}
</script>

<style scoped>
</style>