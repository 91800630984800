<template>
    <SettingPanel>
        <div class="bg-white rounded border border-gray-300 p-6 md:w-3/4 w-full">
            <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Integrasi Marketplace</h2>

            <div class="md:grid md:grid-cols-3 md:gap-3">
                <!-- Shopee -->
                <div class="border border-gray-200 shadow-sm flex flex-col h-full p-5 md:mb-0 mb-2">
                    <!-- Head Logo -->
                    <div class="grow">
                        <header class="flex items-center mb-4">
                            <img src="@/assets/images/logo_mp/shopee.png" class="w-10 h-10 rounded-full mr-3" />
                            <h3 class="text-lg text-gray-800 font-semibold">Shopee</h3>
                        </header>
                        <!-- Description -->
                        <h4 class="font-proxima font-medium text-sm text-gray-600">Integrasi dengan Marketplace Shopee</h4>
                    </div>

                    <!-- Button Action -->
                    <!-- <button v-if="shopee_token === null" @click.stop="selectedPlatform = 'Shopee'; marketplaceIntegrationToggle = true" class="mt-4 border border-gray-200 rounded shadow-sm text-sm font-semibold w-full px-3 py-1 hover:bg-gray-50">Mulai Integrasi</button>
                    <div v-else class="flex items-center justify-center w-full text-green-500 border border-gray-200 rounded px-3 py-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4 mr-1" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M11.488 21.754c.294.157.663.156.957-.001 8.012-4.304 8.581-12.713 8.574-15.104a.988.988 0 0 0-.596-.903l-8.05-3.566a1.005 1.005 0 0 0-.813.001L3.566 5.747a.99.99 0 0 0-.592.892c-.034 2.379.445 10.806 8.514 15.115zM8.674 10.293l2.293 2.293 4.293-4.293 1.414 1.414-5.707 5.707-3.707-3.707 1.414-1.414z"></path>
                        </svg>
                        <h4 class="font-semibold font-proxima text-gray-700 text-sm mt-0.5">Sudah Terhubung</h4>
                    </div> -->
                    <button @click="$router.push('/settings/marketplace-integration/shopee')" class="mt-4 border border-gray-200 rounded shadow-sm text-sm font-semibold w-full px-3 py-1 hover:bg-gray-50">Kelola Integrasi</button>
                </div>

                <!-- Tiktok -->
                <div class="border border-gray-200 shadow-sm flex flex-col h-full p-5 md:mb-0 mb-2">
                    <div class="grow">
                        <!-- Head Logo -->
                        <header class="flex items-center mb-4">
                            <img src="@/assets/images/logo_mp/tiktok.png" class="w-10 h-10 rounded-full mr-3" />
                            <h3 class="text-lg text-gray-800 font-semibold">Tiktok Shop</h3>
                        </header>
                        <!-- Description -->
                        <h4 class="font-proxima font-medium text-sm text-gray-600">Integrasi dengan Marketplace Tiktok Shop</h4>
                    </div>

                    <!-- Button Action -->
                    <!-- <button @click.stop="selectedPlatform = 'Tiktok'; marketplaceIntegrationToggle = true" class="mt-4 border border-gray-200 rounded shadow-sm text-sm font-semibold w-full px-3 py-1">Mulai Integrasi</button> -->
                    <!-- <button v-if="tiktok_token === null" @click.stop="selectedPlatform = 'Tiktok'; marketplaceIntegrationToggle = true" class="mt-4 border border-gray-200 rounded shadow-sm text-sm font-semibold w-full px-3 py-1 hover:bg-gray-50">Mulai Integrasi</button>
                    <div v-else class="flex items-center justify-center w-full text-green-500 border border-gray-200 rounded px-3 py-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4 mr-1" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M11.488 21.754c.294.157.663.156.957-.001 8.012-4.304 8.581-12.713 8.574-15.104a.988.988 0 0 0-.596-.903l-8.05-3.566a1.005 1.005 0 0 0-.813.001L3.566 5.747a.99.99 0 0 0-.592.892c-.034 2.379.445 10.806 8.514 15.115zM8.674 10.293l2.293 2.293 4.293-4.293 1.414 1.414-5.707 5.707-3.707-3.707 1.414-1.414z"></path>
                        </svg>
                        <h4 class="font-semibold font-proxima text-gray-700 text-sm mt-0.5">Sudah Terhubung</h4>
                    </div> -->
                    <button @click="$router.push('/settings/marketplace-integration/tiktokshop')" class="mt-4 border border-gray-200 rounded shadow-sm text-sm font-semibold w-full px-3 py-1 hover:bg-gray-50">Kelola Integrasi</button>
                </div>

                <!-- Lazada -->
                <div class="border border-gray-200 shadow-sm flex flex-col h-full p-5 md:mb-0 mb-2">
                    <div class="grow">
                        <!-- Head Logo -->
                        <header class="flex items-center mb-4">
                            <img src="@/assets/images/logo_mp/lazada.png" class="w-10 h-10 rounded-full mr-3" />
                            <h3 class="text-lg text-gray-800 font-semibold">Lazada</h3>
                        </header>
                        <!-- Description -->
                        <h4 class="font-proxima font-medium text-sm text-gray-600">Integrasi dengan Marketplace Lazada</h4>
                    </div>

                    <!-- Button Action -->
                    <!-- <button @click.stop="selectedPlatform = 'Lazada'; marketplaceIntegrationToggle = true" class="mt-4 border border-gray-200 rounded shadow-sm text-sm font-semibold w-full px-3 py-1">Mulai Integrasi</button> -->
                    <!-- <button class="mt-4 bg-gray-300 border border-gray-200 rounded shadow-sm text-sm font-semibold w-full px-3 py-1">Coming Soon</button> -->
                    <!-- <button v-if="lazada_token === null" @click.stop="selectedPlatform = 'Lazada'; marketplaceIntegrationToggle = true" class="mt-4 border border-gray-200 rounded shadow-sm text-sm font-semibold w-full px-3 py-1 hover:bg-gray-50">Mulai Integrasi</button>
                    <div v-else class="flex items-center justify-center w-full text-green-500 border border-gray-200 rounded px-3 py-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4 mr-1" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M11.488 21.754c.294.157.663.156.957-.001 8.012-4.304 8.581-12.713 8.574-15.104a.988.988 0 0 0-.596-.903l-8.05-3.566a1.005 1.005 0 0 0-.813.001L3.566 5.747a.99.99 0 0 0-.592.892c-.034 2.379.445 10.806 8.514 15.115zM8.674 10.293l2.293 2.293 4.293-4.293 1.414 1.414-5.707 5.707-3.707-3.707 1.414-1.414z"></path>
                        </svg>
                        <h4 class="font-semibold font-proxima text-gray-700 text-sm mt-0.5">Sudah Terhubung</h4>
                    </div> -->
                    <button @click="$router.push('/settings/marketplace-integration/lazada')" class="mt-4 border border-gray-200 rounded shadow-sm text-sm font-semibold w-full px-3 py-1 hover:bg-gray-50">Kelola Integrasi</button>
                </div>
            </div>

            <!-- Edit Order Note -->
            <ZModalAction id="marketplace-integration-modal" :modalOpen="marketplaceIntegrationToggle" @close-modal="marketplaceIntegrationToggle = false" title="Integrasi Marketplace">
                <!-- Modal header -->
                <div class="mb-5 text-center">
                    <!-- Icons -->
                    <div class="inline-flex items-center justify-center space-x-3 mb-4">
                        <!-- SatuStock Logo -->
                        <div class="bg-gray-100 w-12 h-12 rounded-full p-2">
                            <img src="@/assets/images/logo/logo-gold.png" />
                        </div>
                        <!-- Arrows -->
                        <svg class="h-4 w-4 fill-current text-gray-400" viewBox="0 0 16 16">
                            <path d="M5 3V0L0 4l5 4V5h8a1 1 0 000-2H5zM11 11H3a1 1 0 000 2h8v3l5-4-5-4v3z" />
                        </svg>
                        <!-- Platform Logo -->
                        <img src="@/assets/images/logo_mp/shopee.png" class="w-12 h-12 rounded-full" v-if="selectedPlatform === 'Shopee'" />
                        <img src="@/assets/images/logo_mp/tiktok.png" class="w-12 h-12 rounded-full" v-if="selectedPlatform === 'Tiktok'" />
                        <img src="@/assets/images/logo_mp/lazada.png" class="w-12 h-12 rounded-full" v-if="selectedPlatform === 'Lazada'" />
                    </div>
                    <div class="text-lg font-semibold text-gray-800" v-if="selectedPlatform === 'Shopee'">Hubungkan SatuStock dengan Akun Shopee Anda</div>
                    <div class="text-lg font-semibold text-gray-800" v-if="selectedPlatform === 'Tiktok'">Hubungkan SatuStock dengan Akun Tiktok Anda</div>
                    <div class="text-lg font-semibold text-gray-800" v-if="selectedPlatform === 'Lazada'">Hubungkan SatuStock dengan Akun Lazada Anda</div>
                </div>
                <!-- Modal content -->
                <div class="text-sm mb-5">
                    <div class="font-medium text-gray-800 mb-3" v-if="selectedPlatform === 'Shopee'">SatuStock akan mengakses Data Shopee Anda seperti:</div>
                    <div class="font-medium text-gray-800 mb-3" v-if="selectedPlatform === 'Tiktok'">SatuStock akan mengakses Data Tiktok Anda seperti:</div>
                    <div class="font-medium text-gray-800 mb-3" v-if="selectedPlatform === 'Lazada'">SatuStock akan mengakses Data Lazada Anda seperti:</div>
                    <ul class="space-y-2 mb-5">
                        <li class="flex items-center">
                            <svg class="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                                <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                            </svg>
                            <div>Data Produk</div>
                        </li>
                        <li class="flex items-center">
                            <svg class="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                                <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                            </svg>
                            <div>Data Penjualan</div>
                        </li>
                        <li class="flex items-center">
                            <svg class="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                                <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                            </svg>
                            <div>Data Stok</div>
                        </li>
                        <li class="flex items-center">
                            <svg class="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                                <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                            </svg>
                            <div>Dan Data Penunjang Lainnya</div>
                        </li>
                    </ul>
                    <div class="text-xs text-gray-500">Dengan mengklik Izinkan akses, Anda mengizinkan SatuStock untuk menggunakan informasi Anda sesuai dengan Kebijakan Privasinya. Anda dapat mengetahuinya kapan saja di halaman integrasi akun SatuStock Anda.</div>
                </div>
                <!-- Modal footer -->
                <div class="flex flex-wrap justify-end space-x-2">
                    <button class="px-3 py-1 rounded border border-gray-300 hover:border-gray-300 text-gray-600 font-proxima" @click.stop="marketplaceIntegrationToggle = false">Batal</button>
                    <button @click="goToIntegration()" class="px-3 py-1 rounded border border-green-700 bg-green-600 hover:bg-green-700 text-white font-proxima">Setuju</button>
                </div>
            </ZModalAction>
        </div>
    </SettingPanel>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL

import SettingPanel from '@/components/special/SettingPanel.vue';

// import ZButton from '@/components/common/ZButton.vue';
import ZModalAction from '@/components/common/ZModalAction.vue';

export default {
    components: {
        //ZButton
        ZModalAction,
        SettingPanel
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            selectedPlatform: '',
            shopee_token: null,
            tiktok_token: null,
            lazada_token: null,
            /* Toggle */
            marketplaceIntegrationToggle: false,
            loading: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        /* Validasi Vendor Terverifikasi */
        if(this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === undefined || this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === false) {
            this.$router.push('/');
        }

        /* Get Auth Token Platform */
        // this.checkShopeeTokenLogin();
        // this.checkTiktokShopTokenLogin();
        // this.checkLazadaTokenLogin();

        /* Callback Integration */
        // if(this.$route.params.platform) {
        //     switch (this.$route.params.platform) {
        //         case 'shopee': {
        //             let data = {
        //                 code: this.$route.query.code,
        //                 shop_id: this.$route.query.shop_id,
        //                 user: this.profileData.id ? this.profileData.id : null
        //             }
        //             this.callbackProcess('/api/integration/shopee/callback', data);
        //             break;
        //         }

        //         case 'tiktok': {
        //             let data = {
        //                 code: this.$route.query.code,
        //                 user: this.profileData.id ? this.profileData.id : null
        //             }
        //             this.callbackProcess('/api/integration/tiktokshop/callback', data);
        //             break;
        //         }

        //         case 'lazada': {
        //             let data = {
        //                 code: this.$route.query.code,
        //                 user: this.profileData.id ? this.profileData.id : null
        //             }
        //             this.callbackProcess('/api/integration/lazada/callback', data);
        //             break;
        //         }
            
        //         default:
        //             break;
        //     }
        // }
        
        // if(this.$route.query.redirect_from == 'selling_marketplace_integration') {
        //     this.updateGuide();
        // }
	},
    methods: {
        /*
         * Check Shopee Token Login
         */
        async checkShopeeTokenLogin() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.shopee_token = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /*
         * Check Tiktok Shop Token Login
         */
        async checkTiktokShopTokenLogin() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/tiktokshop/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.tiktok_token = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /*
         * Check Lazada Token Login
         */
        async checkLazadaTokenLogin() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/lazada/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.lazada_token = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Go To Integration
         */
        async goToIntegration() {
            switch (this.selectedPlatform) {
                case 'Shopee':
                    this.getAuthUrl('/api/integration/shopee/auth-url');
                    break;

                case 'Tiktok':
                    this.getAuthUrl('/api/integration/tiktokshop/auth-url?state=' + this.profileData.id);
                    break;

                case 'Lazada':
                    this.getAuthUrl('/api/integration/lazada/auth-url');
                    break;
            
                default:
                    break;
            }
        },

        /*
         * Update Guide
         */
        async updateGuide() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Update Type */
                update_type: 'integration'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        this.$router.push('/selling/marketplace')
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

        /* Helpers */
        /*
         * Get Auth URL
         */
        async getAuthUrl(path) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });
            try {
                const authUrl = await axios.get(`${API_URL}${path}`);
                window.location.href = authUrl.data.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /*
         * Callback Process
         */
        async callbackProcess(path, data) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {                
                await axios.post(`${API_URL}${path}`, data);
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                
                window.location.href = '/settings/marketplace-integration?redirect_from=selling_marketplace_integration';
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
</style>