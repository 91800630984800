<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Basic Information -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Akun</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Lengkap</label>
                    <z-input v-model="fullName" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Email</label>
                    <z-input v-model="email" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">No Handphone</label>
                    <z-input v-model="phoneNumber" full></z-input>
                </div>
                <!-- <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Tipe Pengguna</label>
                    <z-select v-model="role" label="Tipe Pengguna" :options="roleOptions" full></z-select>
                </div> -->
                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Jenis Kelamin</label>
                    <z-select v-model="gender" label="Jenis Kelamin" :options="genderOptions" full></z-select>
                </div>
                <div class="mb-0">
                    <label class="font-proxima font-semibold block">Password Baru</label>
                    <z-input v-model="password" type="password" full placeholder="Kosongkan jika tidak ingin ganti password.."></z-input>
                </div>
            </div>

            <!-- Info Reseller -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-6" v-if="role && role.value === 'RESELLER'">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Reseller Link</h2>

                <div class="mb-0">
                    <label class="font-proxima font-semibold block">URL Toko <span class="text-xs text-red-600">(Wajib Diisi!)</span></label>
                    <z-input v-model="reseller_url" full @input="resellerURLFormatter"></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Toko <span class="text-xs text-red-600">(Wajib Diisi!)</span></label>
                    <z-input v-model="store_name" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">URL Logo Toko <span class="text-xs text-gray-400">(Opsional)</span></label>
                    <z-input v-model="store_logo" full></z-input>
                </div>
                <div class="mb-0">
                    <label class="font-proxima font-semibold block">No Handphone Toko <span class="text-xs text-gray-400">(Opsional)</span></label>
                    <z-input v-model="store_phone" full></z-input>
                </div>
            </div>

            <!-- Info Bank -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-6">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Bank untuk Pencairan Dana</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Bank</label>
                    <z-input v-model="bank_name" full placeholder="contoh: BCA"></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Pemilik Rekening</label>
                    <z-input v-model="bank_account_name" full placeholder="contoh: Ridwan"></z-input>
                </div>
                <div class="mb-0">
                    <label class="font-proxima font-semibold block">Nomor Rekening</label>
                    <z-input v-model="bank_account_number" full placeholder="contoh: 1122334455"></z-input>
                </div>
            </div>

            <!-- Info Supplier -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-6" v-if="role && role.value === 'VENDOR'">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Supplier</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Supplier</label>
                    <z-input v-model="vendor_name" full placeholder="contoh: Ridwan"></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">No Handphone Supplier</label>
                    <z-input v-model="vendor_phone" full placeholder="contoh: 08123456789"></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Gudang/Fulfillment</label>
                    <z-input v-model="fulfillment_name" full placeholder="contoh: Gudang Tangerang 2"></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Icon Gudang/Fulfillment</label>
                    <z-input v-model="fulfillment_icon" full placeholder="contoh: https://google.com/image.png"></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Alamat Supplier</label>
                    <z-input v-model="address" full placeholder="contoh: RT 1 RW 1, Jl Pegangsaan"></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Provinsi</label>
                    <z-auto-complete-input v-model="province" label="Provinsi" :options="provinceOptions" full @changeData="getCity" placeholder="Ketik nama provinsi"></z-auto-complete-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Kota</label>
                    <z-auto-complete-input v-model="city" label="Kota" :options="cityOptions" @changeData="getDistrict" full placeholder="Ketik nama kota"></z-auto-complete-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Kecamatan</label>
                    <z-auto-complete-input v-model="district" label="Kecamatan" :options="districtOptions" full placeholder="Ketik nama kecamatan"></z-auto-complete-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Kode POS</label>
                    <z-input v-model="postal_code" full placeholder="contoh: 46674"></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Origin (Asal Pengiriman)</label>
                    <z-auto-complete-input v-model="origin" label="Origin" :options="originOptions" full placeholder="Ketik nama kota"></z-auto-complete-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Branch (Cabang Pengiriman)</label>
                    <z-auto-complete-input v-model="branch" label="Branch" :options="branchOptions" full placeholder="Ketik nama kota"></z-auto-complete-input>
                </div>
            </div>

            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-8">
                <z-button @click="updateUser" class="bg-yellow-600 hover:bg-yellow-700 text-white px-8 py-2 w-full">Simpan</z-button>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZAutoCompleteInput from '@/components/common/ZAutoCompleteInput.vue';
import ZSelect from '@/components/common/ZSelect.vue';

export default {
    components: {
        NavigationMenu,
        ZInput,
        ZButton,
        ZAutoCompleteInput,
        ZSelect
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            fullName: '',
            email: '',
            phoneNumber: '',
            role: '',
            gender: '',
            password: '',
            /* Reseller */
            reseller_url: '',
            store_name: '',
            store_logo: '',
            store_phone: '',
            /* Bank Information */
            bank_name: '',
            bank_account_name: '',
            bank_account_number: '',
            /* Vendor Information */
            vendor_name: '',
            vendor_phone: '',
            fulfillment_name: '',
            fulfillment_icon: '',
            address: '',
            province: '',
            city: '',
            district: '',
            postal_code: '',
            origin: '',
            branch: '',
            /* Options */
            genderOptions: [
                {label: 'Pria', value: 'Male'}, 
                {label: 'Wanita', value: 'Female'}
            ],
            roleOptions: [
                {label: 'Admin', value: 'ADMIN'},
                {label: 'Reseller/Dropshipper', value: 'RESELLER'},
                {label: 'Brand Owner/Supplier', value: 'VENDOR'}
            ],
            provinceOptions: [],
            cityOptions: [],
            districtOptions: [],
            originOptions: [],
            branchOptions: [],
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/signin');
            });
        }

        this.getUserById();
    },
    methods: {
        /*
         * Get User By Id
         */
        async getUserById () {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/user/get/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                this.fullName = response.data.data.fullName;
                this.email = response.data.data.email;
                this.phoneNumber = response.data.data.phoneNumber;
                this.role = this.roleOptions.find(item => item.value === response.data.data.role);
                this.gender = this.genderOptions.find(item => item.value === response.data.data.gender);

                this.reseller_url = response.data.data.reseller_url ? response.data.data.reseller_url : '';

                this.bank_name = response.data.data.bank_name ? response.data.data.bank_name : '';
                this.bank_account_name = response.data.data.bank_account_name ? response.data.data.bank_account_name : '';
                this.bank_account_number = response.data.data.bank_account_number ? response.data.data.bank_account_number : '';

                if(this.role && this.role.value === 'VENDOR') {
                    this.getVendorById();
                }

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },
        /*
         * Get Vendor By Id
         */
        async getVendorById () {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/vendor/get/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Mapping Form Data */
                this.vendor_name = response.data.vendor.vendor_name ? response.data.vendor.vendor_name : '';
                this.vendor_phone = response.data.vendor.vendor_phone ? response.data.vendor.vendor_phone : '';
                this.fulfillment_name = response.data.vendor.fulfillment_name ? response.data.vendor.fulfillment_name : '';
                this.fulfillment_icon = response.data.vendor.fulfillment_icon ? response.data.vendor.fulfillment_icon : '';
                this.address = response.data.vendor.address ? response.data.vendor.address : '';
                await this.getProvince();
                this.province = this.provinceOptions.find(item => item.value === response.data.vendor.province);
                await this.getCity();
                this.city = this.cityOptions.find(item => item.value === response.data.vendor.city);
                await this.getDistrict();
                this.district = this.districtOptions.find(item => item.value === response.data.vendor.district);
                this.postal_code = response.data.vendor.postal_code ? response.data.vendor.postal_code : '';
                await this.getOrigin();
                this.origin = this.originOptions.find(item => item.value === response.data.vendor.origin);
                await this.getBranch();
                this.branch = this.branchOptions.find(item => item.value === response.data.vendor.branch);

                this.loadingToggle = false;
                this.vendorExist = true;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
                this.vendorExist = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

        /*
         * Get Province
         */
        async getProvince() {
            try {
                const response = await axios.get(`${API_URL}/api/province/get-all?pagination=false`);
                
                /* Reset Data */
                this.provinceOptions = [];
                /* Set Province Options */
                response.data.map(item => {
                    this.provinceOptions.push({
                        label: item.province,
                        value: item.province
                    });
                })
            } catch (error) {
                console.log(error)
            }
        },
        /*
         * Get City
         */
        async getCity() {
            try {
                const response = await axios.get(`${API_URL}/api/city/get-all/${this.province.value}?pagination=false`);
                
                /* Reset Data */
                this.cityOptions = [];
                /* Set City Options */
                response.data.map(item => {
                    this.cityOptions.push({
                        label: item.city,
                        value: item.city
                    });
                })
            } catch (error) {
                console.log(error)
            }
        },
        /*
         * Get District
         */
        async getDistrict() {
            try {
                const response = await axios.get(`${API_URL}/api/district/get-all/${this.city.value}?pagination=false`);
                
                /* Reset Data */
                this.districtOptions = [];
                /* Set District Options */
                response.data.map(item => {
                    this.districtOptions.push({
                        label: item.district,
                        value: item.district
                    });
                })
            } catch (error) {
                console.log(error)
            }
        },

        /*
         * Get Origin
         */
        async getOrigin() {
            try {
                const response = await axios.get(`${API_URL}/api/integration/jne/get-origin?pagination=false`);
                
                /* Reset Data */
                this.originOptions = [];
                /* Set Origin Options */
                response.data.map(item => {
                    this.originOptions.push({
                        label: item.origin_name,
                        value: item.origin_code
                    });
                })
            } catch (error) {
                console.log(error)
            }
        },
        /*
         * Get Branch
         */
        async getBranch() {
            try {
                const response = await axios.get(`${API_URL}/api/integration/jne/get-branch?pagination=false`);
                
                /* Reset Data */
                this.branchOptions = [];
                /* Set Province Options */
                response.data.map(item => {
                    this.branchOptions.push({
                        label: item.branch_name,
                        value: item.branch_code
                    });
                })
            } catch (error) {
                console.log(error)
            }
        },

        /*
         * Simpan Data Vendor
         */
        async saveVendor () {
            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Basic Information */
                vendor_name: this.vendor_name,
                vendor_phone: this.vendor_phone,
                fulfillment_name: this.fulfillment_name,
                fulfillment_icon: this.fulfillment_icon,
                address: this.address,
                province: this.province.value,
                city: this.city.value,
                district: this.district.value,
                postal_code: this.postal_code,
                origin: this.origin.value,
                branch: this.branch.value
            }

            let URL;
            if(this.vendorExist) {
                URL = `${API_URL}/api/vendor/update-by-admin/${this.$route.params.id}`;
            }else{
                URL = `${API_URL}/api/vendor/save/${this.$route.params.id}`;
            }

            try {
                await axios.post(`${URL}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
            } catch (error) {
                console.log(error)
                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },
        /*
         * Form Validation
         */
        formValidation() {
            if (this.fullName === '') {
                this.$toast.error('Nama lengkap harus diisi!', {duration: 3000});
                return false;
            }
            if (this.email === '') {
                this.$toast.error('Email belum diisi!', {duration: 3000});
                return false;
            }
            if (this.phoneNumber === '') {
                this.$toast.error('No handphone belum diisi!', {duration: 3000});
                return false;
            }
            if (this.gender === '') {
                this.$toast.error('Jenis kelamin belum diisi!', {duration: 3000});
                return false;
            }
            if (this.bank_name === '') {
                this.$toast.error('Nama bank belum diisi!', {duration: 3000});
                return false;
            }
            if (this.bank_account_name === '') {
                this.$toast.error('Nama pemilik rekening belum diisi!', {duration: 3000});
                return false;
            }
            if (this.bank_account_number === '') {
                this.$toast.error('No rekening belum diisi!', {duration: 3000});
                return false;
            }
            if (this.vendor_name === '') {
                this.$toast.error('Nama supplier belum diisi!', {duration: 3000});
                return false;
            }
            if (this.vendor_phone === '') {
                this.$toast.error('No handphone supplier belum diisi!', {duration: 3000});
                return false;
            }
            if (this.address === '') {
                this.$toast.error('Alamat supplier belum diisi!', {duration: 3000});
                return false;
            }
            if (this.province === '' || this.provinceOptions.find(item => item.value === this.province.value) === undefined) {
                this.$toast.error('Provinsi belum diisi!', {duration: 3000});
                return false;
            }
            if (this.city === '' || this.cityOptions.find(item => item.value === this.city.value) === undefined) {
                this.$toast.error('Kota belum diisi!', {duration: 3000});
                return false;
            }
            if (this.district === '' || this.districtOptions.find(item => item.value === this.district.value) === undefined) {
                this.$toast.error('Kecamatan belum diisi!', {duration: 3000});
                return false;
            }
            if (this.postal_code === '') {
                this.$toast.error('Kode pos belum diisi!', {duration: 3000});
                return false;
            }
            if (this.origin === '' || this.originOptions.find(item => item.value === this.origin.value) === undefined) {
                this.$toast.error('Asal pengiriman belum dipilih!', {duration: 3000});
                return false;
            }
            if (this.branch === '' || this.branchOptions.find(item => item.value === this.branch.value) === undefined) {
                this.$toast.error('Cabang pengiriman belum dipilih!', {duration: 3000});
                return false;
            }
            return true;
        },
        /*
         * Update Pengguna
         */
        async updateUser () {
            const checkValidation = await this.formValidation();
            if(checkValidation === false) {
                return;
            }

            /* Simpan Data Perusahaan */
            if(this.role && this.role.value === 'VENDOR') {
                this.saveVendor();
            }

            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Basic Information */
                fullName: this.fullName,
                email: this.email,
                phoneNumber: this.phoneNumber,
                role: this.role.value,
                gender: this.gender.value,
                password: this.password,
                /* Bank Information */
                bank_name: this.bank_name,
                bank_account_name: this.bank_account_name,
                bank_account_number: this.bank_account_number
            }

            if(this.role && this.role.value === 'RESELLER') {
                form.reseller_url = this.reseller_url;
                form.store_name = this.store_name;
                form.store_logo = this.store_logo;
                form.store_phone = this.store_phone;
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.$route.params.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.success('Perubahan Berhasil Disimpan!', {duration: 3000});
                this.$router.push('/user/list');
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },
        /* Helpers */
        resellerURLFormatter(event) {
            this.reseller_url = event.target.value.replace(/\s+/g, '-').toLowerCase();
        }
    }
}
</script>

<style scoped>
</style>