<template>
    <SettingPanel>
        <div class="bg-white rounded border border-gray-300 p-6 md:w-2/4 w-full">
            <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Edit Akun Profile</h2>

            <!-- Basic Information -->
            <div class="w-full">
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Lengkap</label>
                    <z-input v-model="fullName" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Email</label>
                    <z-input v-model="email" full disabled></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">No Handphone</label>
                    <z-input v-model="phoneNumber" full disabled></z-input>
                </div>
                <!-- <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Tipe Pengguna</label>
                    <z-select v-model="role" label="Tipe Pengguna" :options="roleOptions" full></z-select>
                </div> -->
                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Jenis Kelamin</label>
                    <z-select v-model="gender" label="Jenis Kelamin" :options="genderOptions" full></z-select>
                </div>
                <div class="mb-0">
                    <label class="font-proxima font-semibold block">Password Baru <span class="text-gray-500 font-medium text-sm">(Kosongkan Jika Tidak Ingin Ganti Password)</span></label>
                    <z-input v-model="password" type="password" full></z-input>
                </div>
                <z-button @click="updateUser" class="bg-yellow-600 hover:bg-yellow-700 text-white px-8 py-2 w-full mt-8">Simpan</z-button>
            </div>
        </div>
    </SettingPanel>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL

import SettingPanel from '@/components/special/SettingPanel.vue';

import ZInput from '@/components/common/ZInput.vue';
import ZSelect from '@/components/common/ZSelect.vue';
import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        ZInput,
        ZSelect,
        ZButton,
        SettingPanel
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            fullName: '',
            email: '',
            phoneNumber: '',
            role: '',
            gender: '',
            password: '',
            /* Options */
            genderOptions: [
                {label: 'Pria', value: 'Male'}, 
                {label: 'Wanita', value: 'Female'}
            ],
            roleOptions: [
                {label: 'Admin', value: 'ADMIN'},
                {label: 'Reseller/Dropshipper', value: 'RESELLER'},
                {label: 'Brand Owner/Supplier', value: 'VENDOR'}
            ],
            /* Toggle */
            loading: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getUserById();
	},
    methods: {
        /*
         * Get User By Id
         */
        async getUserById () {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/user/get/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                this.fullName = response.data.data.fullName;
                this.email = response.data.data.email;
                this.phoneNumber = response.data.data.phoneNumber;
                this.role = this.roleOptions.find(item => item.value === response.data.data.role);
                this.gender = this.genderOptions.find(item => item.value === response.data.data.gender);

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

        /*
         * Update Pengguna
         */
        async updateUser () {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Basic Information */
                fullName: this.fullName,
                email: this.email,
                phoneNumber: this.phoneNumber,
                role: this.role.value,
                gender: this.gender.value,
                password: this.password,
                /* Update Type */
                update_type: 'update-profile'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        this.$toast.success('Perubahan Berhasil Disimpan!', {duration: 3000});
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            console.log(err)
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
                
                // //this.$router.push('/');
                // this.$store.dispatch('auth/logout').then(() => {
                //     this.$router.push('/auth/login');
                // });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },
    }
}
</script>

<style scoped>
</style>