<template>
    <div class="relative">
        <div>
            <div class="relative mt-1" v-click-outside="handleClickOutside">
                <button @click="isOpen = !isOpen" type="button" class="relative bg-gray-50 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500 font-proxima w-full" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                    <span class="flex items-center">
                        <span class="block truncate" :class="modelValue ? 'text-gray-800' : 'text-gray-400'">{{ modelValue ? modelValue.label : `Silahkan Pilih ${label}` }}</span>
                    </span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                        </svg>
                    </span>
                </button>

                <ul v-show="isOpen" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0" class="absolute z-10 mt-1 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none font-proxima w-full" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                    <li class="text-gray-900 relative cursor-default select-none py-2 px-3" id="listbox-option-0" role="option">
                        <input type="text" v-model="searchInput" class="bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima w-full" placeholder="Ketik untuk mencari.." />
                    </li>
                    <li v-for="(item, index) in searchData" :key="index" @click.stop="selectValue(item)" class="text-gray-900 relative cursor-pointer select-none py-2 px-3 hover:bg-gray-200" id="listbox-option-0" role="option" :class="focus == index ? 'bg-gray-200' : ''">
                        <div class="flex items-center">
                            <span v-if="item.label" class="font-normal block truncate">{{ item.label }}</span>
                            <span v-else class="font-normal block truncate">{{ item }}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'click-outside-vue3'
export default {
    props: {
        label: { type: String },
        modelValue: { type: Object },
        options: { type: Array },
        placeholder: { type: String }
    },
    data() {
        return {
            isOpen: false,
            searchInput: '',
            focus: null
        }
    },
    computed: {
        searchData() {
            if(this.searchInput === '') {
                return this.options;
            }
            let matches = 0
            return this.options.filter(item => {
                if(item.label) {
                    if (item.label.toLowerCase().includes(this.searchInput.toLowerCase()) && matches < 10) {
                        matches++
                        return item
                    }
                }else{
                    if (item.toLowerCase().includes(this.searchInput.toLowerCase()) && matches < 10) {
                        matches++
                        return item
                    }
                }
            })
        }
    },
    methods: {
        selectValue(item) {
            this.isOpen = false;
            this.$emit('update:modelValue', item);
            this.$emit('changeData');
        },

        /* Helpers */
        handleClickOutside() {
            this.isOpen = false;
        }
    },
    directives: {
        clickOutside: ClickOutside.directive
    }
}
</script>