<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu :total_item="total_item" />
        <section v-if="loading" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="sm:flex sm:justify-between sm:items-center mb-2">
                <!-- Left: Title -->
                <div class="mb-4 sm:mb-0">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold">Master Produk</h1>
                </div>

                <!-- Right: Actions  -->
                <div class="flex items-center">
                    <!-- Search form -->
                    <z-input placeholder="Cari produk..." v-model="search" @input="tableInputSearchHandler"></z-input>
                    <!-- Filter Kategori -->
                    <z-select v-model="categorySelected" label="Kategori" :options="categoryOptions" full @changeData="getProduct(this.page, this.limit)" class="ml-2 mr-2 mb-1 w-52"></z-select>
                    <!-- Add Button -->
                    <button v-if="profileData.role === 'VENDOR' || profileData.role === 'ADMIN'" @click.stop="bulkUpdateStockModal = true" class="mr-2 px-4 py-2.5 rounded bg-green-600 hover:bg-green-700 text-white flex items-center text-sm">
                        <svg class="w-4 h-4 fill-current opacity-50 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="m12 18 4-5h-3V2h-2v11H8z"></path><path d="M19 9h-4v2h4v9H5v-9h4V9H5c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2z"></path>
                        </svg>
                        <span class="ml-2">Update Stock Masal</span>
                    </button>
                    <!-- Add Button -->
                    <button v-if="profileData.role === 'VENDOR'" @click="$router.push('/product/add')" class="px-4 py-2.5 rounded bg-green-600 hover:bg-green-700 text-white flex items-center text-sm">
                        <svg class="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span class="ml-2">Tambah Produk</span>
                    </button>
                </div>
            </div>
            <div class="flex items-center justify-end mb-8">
                <!-- Mutiple Action -->
                <div class="flex items-center">
                    <template v-if="selectedItems && selectedItems.length > 0">
                        <div class="hidden xl:block text-sm italic mr-2 whitespace-nowrap"><span>{{selectedItems.length}}</span> item yang dipilih</div>
                        <z-button class="button-diginesia text-white text-sm mr-2" @click.stop="deleteMutiple = true; confirmDeleteModal = true">Hapus Masal</z-button>
                        <z-button class="bg-blue-600 hover:bg-blue-700 py-2 px-3 border-2 border-blue-700 text-white text-sm" @click.stop="downloadStockByProduct('mutiple')">Download Template Stock</z-button>
                    </template>

                    <!-- Import Produk Shopee -->
                    <button v-if="profileData.role === 'VENDOR'" @click="$router.push('/product-shopee/import')" class="mr-2 px-4 py-2.5 rounded bg-green-600 hover:bg-green-700 text-white flex items-center text-sm">
                        <svg class="w-4 h-4 fill-current opacity-50 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="m12 18 4-5h-3V2h-2v11H8z"></path><path d="M19 9h-4v2h4v9H5v-9h4V9H5c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2z"></path>
                        </svg>
                        <span class="ml-2">Import Produk Shopee</span>
                    </button>
                    <!-- Import Produk Lazada -->
                    <button v-if="profileData.role === 'VENDOR'" @click="$router.push('/product-lazada/import')" class="px-4 py-2.5 rounded bg-green-600 hover:bg-green-700 text-white flex items-center text-sm">
                        <svg class="w-4 h-4 fill-current opacity-50 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="m12 18 4-5h-3V2h-2v11H8z"></path><path d="M19 9h-4v2h4v9H5v-9h4V9H5c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2z"></path>
                        </svg>
                        <span class="ml-2">Import Produk Lazada</span>
                    </button>
                </div>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!----------->
            <!-- Table -->
            <!----------->
            <div class="w-full">
                <z-table
                    title="Produk"
                    :data="data.data"
                    :meta="data.meta"
                    :columns="columns"
                    :mutipleCheck="profileData.role === 'ADMIN' || profileData.role === 'VENDOR' ? true : false"
                    @updateSelectedItems="updateSelectedItems"
                    :sorting="true"
                    @sortingHandler="sortingHandler"
                >
                    <template v-slot:name="{item}">
                        {{ item.name.slice(0, 40) + (item.name.length > 40 ? "..." : "") }}
                    </template>
                    <template v-slot:images="{item}">
                        <div :class="profileData.role !== 'ADMIN' && profileData.role !== 'VENDOR' ? 'px-3' : ''">
                            <img :src="item.images[0].replace('upload/', 'upload/c_fill,h_300,w_300/')" width="100" />
                        </div>
                    </template>
                    <template v-slot:price="{item}">
                        {{ formatPriceRange(item.variants) }}
                    </template>
                    <template v-slot:stock="{item}">
                        {{ item.variants.reduce((total, value) => { return Number(total) + Number(value.stock.stock) }, 0) }}
                    </template>
                    <template v-slot:vendor="{item}">
                        {{ item.vendor.city }}
                        <!-- <a class="text-sm text-blue-400 hover:text-blue-500" :href="`http://localhost:4000/order/${item.slug}`" target="_blank">Kunjungi Form</a> -->
                    </template>
                    <template v-slot:isActive="{item}">
                        {{ item.isActive ? 'Aktif' : 'Nonaktif' }}
                    </template>
                    <template v-slot:action="{item}">
                        <span class="hidden">{{item}}</span>
                        <!-- Action Button -->
                        <ZDropdown class="inline-flex">
                            <li v-if="profileData.role === 'ADMIN'">
                                <a @click="approveProduct(item._id)" v-if="item.isActive === false" class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer">Setujui Produk</a>
                            </li>
                            <li>
                                <a @click.stop="detailProductModal = true; selectedProduct = item" class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer">Detail Produk</a>
                            </li>
                            <li>
                                <a @click.stop="addToCartModal = true; selectedProduct = item" class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer">Tambah ke Keranjang</a>
                            </li>
                            <li v-if="profileData.role === 'RESELLER'">
                                <a @click="$router.push('/my-form/add/' + item.slug)" class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer">Buat Form Order</a>
                            </li>
                            <li v-if="profileData.role === 'RESELLER'">
                                <a @click="addProductToListingProduct(item._id)" class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer">Simpan ke List Produk Saya</a>
                            </li>
                            <li v-if="profileData.role === 'RESELLER'">
                                <a :href="'/shopee/edit-product/' + item.slug" target="_blank" class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer">Ekspor Produk ke Shopee</a>
                            </li>
                            <li v-if="profileData.role === 'RESELLER'">
                                <a :href="'/tiktokshop/edit-product/' + item.slug" target="_blank" class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer">Ekspor Produk ke Tiktok Shop</a>
                            </li>
                            <li v-if="profileData.role === 'ADMIN' || profileData.role === 'VENDOR'">
                                <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="$router.push(`/product/edit/${item._id}`)">Edit Produk</a>
                            </li>
                            <li v-if="profileData.role === 'ADMIN' || profileData.role === 'VENDOR'">
                                <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click.stop="dataSelected = item._id; downloadStockByProduct('single')">Download Template Stock</a>
                            </li>
                            <li v-if="profileData.role === 'ADMIN' || profileData.role === 'VENDOR'">
                                <a class="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3 cursor-pointer" @click.stop="dataSelected = item._id; confirmDeleteModal = true">Hapus</a>
                            </li>
                        </ZDropdown>
                    </template>
                </z-table>
            </div>
            <!--------------->
            <!-- End Table -->
            <!--------------->

            <!---------------->
            <!-- Pagination -->
            <!---------------->
            <div class="mt-8">
                <z-pagination 
                    :meta="data.meta"
                    :PageNavHandler="pageNavHandler"
                    :pageNavNumberHandler="pageNavNumberHandler"
                />
            </div>
            <!-------------------->
            <!-- End Pagination -->
            <!-------------------->
            
            <!-- Confirm Delete Modal -->
            <ZModalAction id="danger-modal" :modalOpen="confirmDeleteModal" @close-modal="confirmDeleteModal = false">
                <div class="flex space-x-4">
                    <!-- Icon -->
                    <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-red-100">
                        <svg class="w-4 h-4 shrink-0 fill-current text-red-500" viewBox="0 0 16 16">
                            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                        </svg>
                    </div>
                    <!-- Content -->
                    <div>
                        <!-- Modal header -->
                        <div class="mb-2">
                            <div class="text-lg font-semibold text-gray-800 font-proxima">Hapus Data Produk</div>
                        </div>
                        <!-- Modal content -->
                        <div class="text-sm mb-10 font-proxima">
                            <div class="space-y-2">
                                <p>Anda yakin ingin menghapus data produk ini?<br/> Data akan dihapus secara permanen. Tindakan ini tidak bisa dibatalkan.</p>
                            </div>
                        </div>
                        <!-- Modal footer -->
                        <div class="flex flex-wrap justify-end space-x-2">
                            <button class="px-3 py-1.5 text-sm rounded border-gray-200 hover:border-gray-300 text-gray-600 font-proxima" @click.stop="confirmDeleteModal = false">Batal</button>
                            <button @click="deleteMutiple ? handleMutipleDelete() :  handleDelete()" class="px-3 py-1.5 text-sm rounded bg-red-500 hover:bg-red-600 text-white font-proxima">Ya, Hapus Sekarang</button>
                        </div>
                    </div>
                </div>
            </ZModalAction>
            <ZDetailProduct v-if="detailProductModal === true" :product="selectedProduct" :modalOpen="detailProductModal" @closeModal="detailProductModal = false"></ZDetailProduct>
            <ZAddToCart v-if="addToCartModal === true" :product="selectedProduct" :modalOpen="addToCartModal" @closeModal="addToCartModal = false" @updateCart="getTotalCartItems"></ZAddToCart>
            
            <!-- Import Stock Massal -->
            <ZModal v-if="bulkUpdateStockModal" id="bulk-update-stock-modal" title="Update Stok Masal" :modalOpen="bulkUpdateStockModal" @close-modal="bulkUpdateStockModal = false">
                <div class="p-6">

                    <h4 class="font-proxima text-sm">Import Update Stock</h4>
                    <div>
                        <input id="upload-csv" class="hidden" accept=".csv, text/csv" type="file" @change="importUpdateStock($event)">
                        <label 
                            for="upload-csv"
                            type="button"
                            class="cursor-pointer focus:outline-none border py-2 px-4 rounded-lg shadow-sm text-left text-sm text-gray-600 bg-white hover:bg-gray-100 font-medium"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex flex-shrink-0 w-4 h-4 -mt-1 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>				
                            Upload CSV
                        </label>
                    </div>

                </div>
            </ZModal>
            <!-- End Import Stock Massal -->


            <!-- End Content Area -->
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatPrice from '../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZDropdown from '@/components/common/ZDropdown.vue';
import ZModalAction from '@/components/common/ZModalAction.vue';
import ZDetailProduct from '@/components/special/ZDetailProduct.vue';
import ZAddToCart from '@/components/special/ZAddToCart.vue';
import ZSelect from '@/components/common/ZSelect.vue';
import ZModal from '@/components/common/ZModal.vue';

export default {
    components: {
        NavigationMenu,
        ZTable,
        ZPagination,
        ZInput,
        ZButton,
        ZDropdown,
        ZModalAction,
        ZDetailProduct,
        ZAddToCart,
        ZSelect,
        ZModal
    },
    data() {
        return {
            /* Core Data */
			data: [],
            selectedItems: [],
            dataSelected: null,
            deleteMutiple: false,
            selectedProduct: '',
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'images',
                    label: 'Gambar',
                    visibility: true,
                },
                {
                    id: 2,
                    field: 'name',
                    label: 'Nama Produk',
                    visibility: true,
                    sort: true
                },
                {
                    id: 3,
                    field: 'sku',
                    label: 'SKU',
                    visibility: true,
                    sort: true
                },
                {
                    id: 4,
                    field: 'price',
                    label: 'Harga',
                    visibility: true,
                },
                {
                    id: 5,
                    field: 'stock',
                    label: 'Stok',
                    visibility: true,
                },
                {
                    id: 6,
                    field: 'vendor',
                    label: 'Asal Pengiriman/Gudang',
                    visibility: true,
                },
                // {
                //     id: 7,
                //     field: 'isActive',
                //     label: 'Status',
                //     visibility: true,
                // },
                {
                    id: 8,
                    field: 'action',
                    label: 'Action',
                    visibility: true,
                }
			],
			/* Toggle */
			sidebarOpen: false,
            loading: false,
            confirmDeleteModal: false,
            detailProductModal: false,
            addToCartModal: false,
            bulkUpdateStockModal: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: {'createdAt': -1},
            sort_order: -1,
            /* Filter Category */
            categorySelected: null,
            categoryOptions: [],
            /* Extra */
            total_item: 0
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        /* Validasi Vendor Terverifikasi */
        if(this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === undefined || this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === false) {
            this.$router.push('/');
        }

        this.getProduct(this.page, this.limit);
        this.getCategory();
	},
	methods: {
		/*
		* Get List Product
		*/
		async getProduct(page, limit) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                sort: JSON.stringify(this.sort_by)
            };

            if(this.profileData.role === 'VENDOR') {
                params.user = this.profileData.id;
            }

            if(this.categorySelected !== null) {
                params.category = this.categorySelected.value
            }

			try {
                const response = await axios.get(API_URL + '/api/product/get-all', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
			} catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getProduct(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getProduct(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getProduct(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getProduct(this.page, this.limit);
            } else {
                this.search = value;
                this.getProduct(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

		/*
		 * Update Selected Items
		 */
		async updateSelectedItems(selected) {
			this.selectedItems = selected;
		},

        /*
		 * Delete Product
		 */
        /* Handle Delete Product */
        async handleDelete(id = null) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const product_id = id ? id : this.dataSelected;
                await axios.delete(API_URL + '/api/product/delete/' + product_id, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Refresh Page */
                window.location.reload();
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dihapus!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
            }
        },
        /* Handle Mutiple Delete */
        handleMutipleDelete() {
            // Looping dan Eksekusi penghapusan data
            for(let i = 0; i < this.selectedItems.length; i++){
                this.handleDelete(this.selectedItems[i]._id);
            }
        },

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getProduct(this.page, this.limit);
        },

		/*
		 * Approval Product
		 */
        async approveProduct(id) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                await axios.put(`${API_URL}/api/product/approve/${id}`, {}, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Refresh Page */
                window.location.reload();
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal disetujui!",
                    position: "bottom",
                    type: "error"
                });

                alert(error)
				console.error(error);
            }
        },

        /*
         * Get Category
         */
        async getCategory() {
            try {
                const response = await axios.get(`${API_URL}/api/category/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.categoryData = response.data;

                for(let i=0; i < this.categoryData.length; i++){
                    this.categoryOptions.push({
                        label: this.categoryData[i].name,
                        value: this.categoryData[i]._id
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },

        /*
         * Get Total Cart Items
         * Desc: Ambil Data Item Keranjang Belanja
         */
        async getTotalCartItems() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });
            
            try {
                const response = await axios.get(`${API_URL}/api/cart/get-cart`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                this.total_item = response.data.data.cart_items.length > 0 ? response.data.data.cart_items.reduce((total, item) => { return Number(total) + Number(item.quantity) }, 0) : 0;
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                console.log(error)
            }
        },

        /*
		 * Add Product To Listing Product
		 */
        async addProductToListingProduct(product) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let data = {
                    product: product,
                    user: this.profileData.id
                };

                await axios.post(`${API_URL}/api/my-product/add`, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Refresh Page */
                this.$.success('Produk Telah Ditambahkan Ke Listing Produk Anda', {duration: 3000});
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal disetujui!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
            }
        },

        /*
         * Download Stock by Product
         */
        async downloadStockByProduct(type) {
            try {
                let products = [];
                
                if(type === 'single') {
                    products.push(this.dataSelected)
                }else if(type === 'mutiple') {
                    for(let i = 0; i < this.selectedItems.length; i++){
                        products.push(this.selectedItems[i]._id);
                    }
                }
                
                const data = {
                    products: products
                };

                const response = await axios.post(`${API_URL}/api/stock/download-stock-by-product`, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Download Data */
                const fileDownload = require('js-file-download');
                return fileDownload(response.data.data, 'export-stock.csv');
            } catch (error) {
                console.error(error);
            }
        },
        /*
		 * Import Update Stock
		 */
        async importUpdateStock(event) {
            var data = new FormData();
            var csv = event.target.files[0];
            console.log(event.target.files[0])
            data.append('uploadfile', csv)

            try {
                const res = await axios.post(API_URL + '/api/stock/bulk-update-stock', data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                console.log(res);

                /* Set Toggle */
                this.bulkUpdateStockModal = false; // Sembunyikan Modal Ubah Stok

                /* Refresh Data */
                this.getProduct(this.page, this.limit);
            }catch (error) {
                console.log(error);
            }
        },

        /*
         * Other Helpers
         */
        formatPriceRange(variant) {
            const max_price = Math.max.apply(Math, this.convertVariantPriceToArray(variant));
            const min_price = Math.min.apply(Math, this.convertVariantPriceToArray(variant));

            if(max_price === min_price) {
                return 'Rp' + this.formatPrice(max_price);
            }else{
                return `Rp ${this.formatPrice(min_price)} - Rp ${this.formatPrice(max_price)}`;
            }
        },
		convertVariantPriceToArray(variant) {
            var array = [];

            for(let i=0; i < variant.length; i++){
                array.push(variant[i].price);
            }

            return array;
		},
        formatPrice
	}
}
</script>

<style scoped>
</style>