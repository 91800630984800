<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="flex justify-between items-center mb-8">
                <!-- Left: Title -->
                <div class="mb-4 sm:mb-0">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold">Dashboard</h1>
                </div>

                <!-- Right: Actions  -->
                <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    <!-- Filter Date -->
                    <litepie-datepicker
                        :formatter="{ date: 'DD MMM YYYY', month: 'MMM' }"
                        v-model="filterDate"
                        :options="dateOptions"
                    ></litepie-datepicker>
                </div>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->
            
            <div v-if="profileData.role === 'RESELLER'" class="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                <span class="font-medium">Pengumuman</span> Untuk yang ingin ikut Training cara pakai SatuStock silahkan mendaftar disini => <a href="https://bit.ly/satustock" target="_blank" class="text-blue-600 hover:text-blue-700 font-medium">Daftar Training</a>.
            </div>
            <div v-if="profileData.role === 'VENDOR' && profileData.vendor_verified === undefined || profileData.role === 'VENDOR' && profileData.vendor_verified === false" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 border border-red-300" role="alert">
                <span class="font-medium">Perhatian!</span> Akun anda belum disetujui oleh admin, silahkan menunggu hingga akun anda aktif agar bisa mengakses fitur di SatuStock.
            </div>

            <div class="bg-white rounded border border-gray-300 p-6 mb-5" v-if="profileData.role === 'RESELLER'">
                <h4 class="font-proxima text-gray-500 font-semibold text-xl text-center mb-1">Mau Jualan Dimana?</h4>
                <h4 class="font-proxima text-gray-500 text-base text-center mb-4">Tinggal pilih channel penjualan yang kamu kuasai, untuk dapatkan profit dari setiap penjualanmu.</h4>
                <div class="md:grid md:grid-cols-3 gap-4 space-y-3 md:space-y-0">
                    <div class="bg-gray-50 rounded border border-gray-200 p-6">
                        <img src="@/assets/images/ads.png" width="75" class="mx-auto" />
                        <h4 class="font-proxima text-gray-500 font-medium text-base text-center mb-4 max-w-xs">Facebook/IG Ads, Google Ads, dan Tiktok Ads</h4>
                        <center>
                            <z-button @click="$router.push('/selling/form-order')" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2">Mulai Jualan</z-button>
                        </center>
                    </div>
                    <div class="bg-gray-50 rounded border border-gray-200 p-6">
                        <img src="@/assets/images/seo.png" width="75" class="mx-auto" />
                        <h4 class="font-proxima text-gray-500 font-medium text-base text-center mb-4 max-w-xs">Organik FB Marketplace, Whatsapp, SEO, Jualan Langsung</h4>
                        <center>
                            <z-button @click="$router.push('/selling/manual-order')" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2">Mulai Jualan</z-button>
                        </center>
                    </div>
                    <div class="bg-gray-50 rounded border border-gray-200 p-6">
                        <img src="@/assets/images/add-to-cart.png" width="75" class="mx-auto" />
                        <h4 class="font-proxima text-gray-500 font-medium text-base text-center mb-4 max-w-xs">Marketplace Shopee, Tiktok Shop, Lazada, Tokopedia</h4>
                        <center>
                            <z-button @click="$router.push('/selling/marketplace')" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2">Mulai Jualan</z-button>
                        </center>
                    </div>
                </div>
            </div>

            <!-- Content Area -->
            <div class="md:grid md:grid-cols-4 gap-4 mb-5">
                <div class="bg-white rounded border border-gray-300 p-6 md:mb-0 mb-2">
                    <h4 class="font-statistics text-gray-500 font-medium text-sm text-center mb-2">Semua Pesanan</h4>
                    <h1 class="font-statistics text-4xl font-bold text-gray-600 text-center">{{ totalOrder }}</h1>
                </div>
                <div class="bg-white rounded border border-gray-300 p-6 md:mb-0 mb-2">
                    <h4 class="font-statistics text-gray-500 font-medium text-sm text-center mb-2">Pesanan Dikirim</h4>
                    <h1 class="font-statistics text-4xl font-bold text-gray-600 text-center">{{ totalDelivered }}</h1>
                </div>
                <div class="bg-white rounded border border-gray-300 p-6 md:mb-0 mb-2">
                    <h4 class="font-statistics text-gray-500 font-medium text-sm text-center mb-2">Pengiriman Gagal</h4>
                    <h1 class="font-statistics text-4xl font-bold text-gray-600 text-center">{{ totalFailed }}</h1>
                </div>
                <div class="bg-white rounded border border-gray-300 p-6 md:mb-0 mb-2">
                    <h4 class="font-statistics text-gray-500 font-medium text-sm text-center mb-2">Total Omzet</h4>
                    <h1 class="font-statistics text-4xl font-bold text-gray-600 text-center">Rp{{ formatPrice(totalOmzet) }}</h1>
                </div>
            </div>
            <div class="bg-white rounded border border-gray-300 p-6">
                <DashboardChart :dateStatistics="dateStatistics" :allOrder="dataAllOrder" :deliveredOrder="dataDeliveredOrder" />
                <!-- <LineChart v-bind="lineChartProps" /> -->
            </div>
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../config.js' // Import API URL
import formatPrice from '../utils/formatPrice.js'
import formatDate from '../utils/dateFormat.js'
import dayjs from 'dayjs';

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import DashboardChart from '@/components/special/DashboardChart.vue';
import ZButton from '@/components/common/ZButton.vue';

export default {
    name: 'Dashboard',
    components: {
        NavigationMenu,
        DashboardChart,
        ZButton
    },
    data() {
        return {
            /* Core Data */
			totalOrder: 0,
            totalDelivered: 0,
            totalFailed: 0,
            totalOmzet: 0,
            dateStatistics: [],
            dateAllOrder: [],
            dataAllOrder: [],
            dataDeliveredOrder: [],

            /* Filter */
            filterDate: {
                startDate: '',
                endDate: ''
            },
            /* Extra */
            dateOptions: {
                shortcuts: {
                    today: 'Hari ini',
                    yesterday: 'Kemarin',
                    past: period => period + ' hari terakhir',
                    currentMonth: 'Bulan ini',
                    pastMonth: 'Bulan lalu'
                },
                footer: {
                    apply: 'Terapkan',
                    cancel: 'Batal'
                }
            },
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    watch: {
        filterDate() {
            this.getTotalOrder('total-order');
            this.getTotalOrder('total-delivered');
            this.getTotalOrder('total-failed');
            this.getTotalOrder('total-omzet');
            this.getOrderStatistics('order');
            this.getOrderStatistics('delivered');
        }
    },
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        let today = new Date();
        let start_date = dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('DD MMM YYYY');
        let end_date = dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('DD MMM YYYY');
        this.filterDate.startDate = start_date;
        this.filterDate.endDate = end_date;
        this.getTotalOrder('total-order');
        this.getTotalOrder('total-delivered');
        this.getTotalOrder('total-failed');
        this.getTotalOrder('total-omzet');
        this.getOrderStatistics('order');
        this.getOrderStatistics('delivered');
	},
	methods: {
		/*
		 * Get Total Order
		 */
		async getTotalOrder(type) {
            try {
                let params = {};
                if(this.filterDate.startDate !== '') {
                    params.start_date = this.convertDate(this.filterDate.startDate);
                }
                if(this.filterDate.endDate !== '') {
                    params.end_date = this.convertDate(this.filterDate.endDate);
                }
                if(this.profileData.role === 'RESELLER') {
                    params.user = this.profileData.id;
                }
                if(this.profileData.role === 'VENDOR') {
                    params.vendor = this.profileData.vendor;
                }

                let endpoint_url;
                if(type === 'total-order'){
                    endpoint_url = '/api/report-order/get-total-order';
                }else if(type === 'total-delivered'){
                    endpoint_url = '/api/report-order/get-total-delivered';
                }else if(type === 'total-failed'){
                    endpoint_url = '/api/report-order/get-total-failed';
                }else if(type === 'total-omzet'){
                    endpoint_url = '/api/report-order/get-total-omzet';
                }

                const response = await axios.get(`${API_URL + endpoint_url}`, {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                if(type === 'total-order'){
                    this.totalOrder = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(type === 'total-delivered'){
                    this.totalDelivered = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(type === 'total-failed'){
                    this.totalFailed = response.data.data.length > 0 ? response.data.data[0].count : 0;
                }else if(type === 'total-omzet'){
                    this.totalOmzet = response.data.data.length > 0 ? response.data.data[0].total_payment : 0;
                }
            } catch (error) {
                console.log(error)
            }
        },

        /*
		 * Get Statistics Order
		 */
        async getOrderStatistics(type) {
            try {
                let params = {};
                if(this.filterDate.startDate !== '') {
                    params.start_date = this.convertDate(this.filterDate.startDate);
                }
                if(this.filterDate.endDate !== '') {
                    params.end_date = this.convertDate(this.filterDate.endDate);
                }
                if(this.profileData.role === 'RESELLER') {
                    params.user = this.profileData.id;
                }
                if(this.profileData.role === 'VENDOR') {
                    params.vendor = this.profileData.vendor;
                }

                let endpoint_url;
                if(type === 'order'){
                    endpoint_url = '/api/report-order/get-total-order-statistics';
                }else if(type === 'delivered'){
                    endpoint_url = '/api/report-order/get-total-delivered-statistics';
                }

                const response = await axios.get(`${API_URL + endpoint_url}`, {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                if(type === 'order'){
                    response.data.data.forEach((item) => {
                        this.dateStatistics.push(dayjs(new Date(item.date)).format('DD MMM YYYY'));
                    });
                    response.data.data.forEach((item) => {
                        this.dateAllOrder.push(item.date);
                    });
                    response.data.data.forEach((item) => {
                        this.dataAllOrder.push(item.order_count);
                    });
                }else if(type === 'delivered'){
                    this.dateAllOrder.forEach((item) => {
                        let getOrder = response.data.data.find(val => val.date === item);
                        if(getOrder) {
                            this.dataDeliveredOrder.push(getOrder.order_count);
                        }else{
                            this.dataDeliveredOrder.push(0);
                        }
                    });
                }
            } catch (error) {
                console.log(error)
            }
        },

        /* Helpers */
        convertDate(date) {
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        formatDate,
        formatPrice
    }
}
</script>

<style scoped>
</style>