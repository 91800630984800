<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="sm:flex sm:justify-between sm:items-center mb-8">
                <!-- Left: Title -->
                <div class="mb-4 sm:mb-0">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold">Pendapatan dari Cashback Ongkir</h1>
                </div>

                <!-- Right: Actions  -->
                <div class="md:grid md:grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center md:space-y-0 space-y-1.5">
                    <!-- Search form -->
                    <!-- <z-input placeholder="Cari merk..." v-model="search" @input="getBrand(page, limit)"></z-input> -->

                    <!-- Tarik Data Button -->
                    <z-button @click="exportIncome" class="bg-blue-600 hover:bg-blue-700 text-white flex items-center text-sm mr-2 md:w-auto w-full justify-center">
                        <svg class="w-5 h-5 fill-current opacity-50 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M18.948 11.112C18.511 7.67 15.563 5 12.004 5c-2.756 0-5.15 1.611-6.243 4.15-2.148.642-3.757 2.67-3.757 4.85 0 2.757 2.243 5 5 5h1v-2h-1c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.757 2.673-3.016l.581-.102.192-.558C8.153 8.273 9.898 7 12.004 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-2v2h2c2.206 0 4-1.794 4-4a4.008 4.008 0 0 0-3.056-3.888z"></path><path d="M13.004 14v-4h-2v4h-3l4 5 4-5z"></path>
                        </svg>
                        <span class="ml-2">Tarik Data Pendapatan</span>
                    </z-button>

                    <!-- Filter Status -->
                    <z-select v-model="statusSelected" label="Status" :options="statusOptions" full @changeData="getResellerCashback(this.page, this.limit)" class="mr-2 md:w-52 w-full"></z-select>

                    <litepie-datepicker
                        :formatter="{ date: 'DD MMM YYYY', month: 'MMM' }"
                        v-model="filterDate"
                        :options="dateOptions"
                    ></litepie-datepicker>
                    
                    <!-- Add Button -->
                    <!-- <z-button @click="$router.push('/settlement/add')" class="bg-green-600 hover:bg-green-700 text-white flex items-center text-sm">
                        <svg class="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span class="ml-2">Tarik Dana</span>
                    </z-button> -->
                </div>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!---------------->
            <!-- Statistics -->
            <!---------------->
            <div class="md:grid md:grid-cols-4 md:gap-4 mb-5">
                <div class="bg-white rounded border border-gray-300 p-6 flex items-center justify-center">
                    <div>
                        <h4 class="font-statistics text-gray-400 font-medium text-sm text-center mb-2">Total Pendapatan</h4>
                        <h1 class="font-statistics text-4xl font-bold text-center text-gray-800 mb-2">Rp{{ formatPrice(total_income) }}</h1>
                    </div>
                </div>
            </div>
            <!-------------------->
            <!-- End Statistics -->
            <!-------------------->

            <!----------->
            <!-- Table -->
            <!----------->
            <div class="w-full">
                <z-table
                    title="Cashback Ongkir"
                    :data="data.data"
                    :meta="data.meta"
                    :columns="columns"
                    :mutipleCheck="true"
                    @updateSelectedItems="updateSelectedItems"
                    :sorting="true"
                    @sortingHandler="sortingHandler"
                >
                    <template v-slot:total_income="{item}">
                        <h4 class="font-proxima">Rp{{ formatPrice(item.total_income) }}</h4>
                    </template>
                    <template v-slot:order="{item}">
                        <h4 class="font-proxima">{{ item.order.invoice }}</h4>
                    </template>
                    <template v-slot:isPaid="{item}">
                        {{ item.isPaid ? 'Sudah Dicairkan' : 'Belum Dicairkan' }}
                    </template>
                    <template v-slot:action="{item}">
                        <span class="hidden">{{item}}</span>
                        <!-- Action Button -->
                        <ZDropdown class="relative inline-flex">
                            <li>
                                <a class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer" @click="$router.push(`/brand/edit/${item._id}`)">Cairkan</a>
                            </li>
                        </ZDropdown>
                    </template>
                </z-table>
            </div>
            <!--------------->
            <!-- End Table -->
            <!--------------->

            <!---------------->
            <!-- Pagination -->
            <!---------------->
            <div class="mt-8">
                <z-pagination 
                    :meta="data.meta"
                    :PageNavHandler="pageNavHandler"
                    :pageNavNumberHandler="pageNavNumberHandler"
                />
            </div>
            <!-------------------->
            <!-- End Pagination -->
            <!-------------------->
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatPrice from '../../utils/formatPrice.js'
import dayjs from 'dayjs';

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
// import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZDropdown from '@/components/common/ZDropdown.vue';
import ZSelect from '@/components/common/ZSelect.vue';

export default {
    components: {
        NavigationMenu,
        ZTable,
        ZPagination,
        // ZInput,
        ZButton,
        ZDropdown,
        ZSelect
    },
    data() {
        return {
            /* Core Data */
			data: [],
            selectedItems: [],
            dataSelected: null,
            deleteMutiple: false,
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'total_income',
                    label: 'Total Pendapatan',
                    visibility: true
                },
                {
                    id: 2,
                    field: 'order',
                    label: 'No Pesanan',
                    visibility: true
                },
                {
                    id: 3,
                    field: 'order_status',
                    label: 'Status Pesanan',
                    visibility: true
                },
                // {
                //     id: 4,
                //     field: 'isPaid',
                //     label: 'Status Pencairan',
                //     visibility: true
                // },
                {
                    id: 4,
                    field: 'action',
                    label: 'Action',
                    visibility: true,
                },
			],
			/* Toggle */
			sidebarOpen: false,
            loading: false,
            confirmDeleteModal: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: '',
            sort_order: -1,
            filter_date: '',
            filterDate: {
                startDate: '',
                endDate: ''
            },
            statusSelected: '',
            statusOptions: [{
                label: 'PENDING', 
                value: 'PENDING'
            },{
                label: 'PAID',
                value: 'PAID'
            },{
                label: 'ON PROCESS',
                value: 'ON PROCESS'
            },{
                label: 'SHIPPED',
                value: 'SHIPPED'
            },{
                label: 'DELIVERED',
                value: 'DELIVERED'
            },{
                label: 'UNDELIVERY',
                value: 'UNDELIVERY'
            },{
                label: 'RE-DELIVERY',
                value: 'RE-DELIVERY'
            },{
                label: 'RETURN',
                value: 'RETURN'
            },{
                label: 'REFUND',
                value: 'REFUND'
            },{
                label: 'CANCELLED',
                value: 'CANCELLED'
            }],
            /* Extra */
            total_income: 0,
            dateOptions: {
                shortcuts: {
                    today: 'Hari ini',
                    yesterday: 'Kemarin',
                    past: period => period + ' hari terakhir',
                    currentMonth: 'Bulan ini',
                    pastMonth: 'Bulan lalu'
                },
                footer: {
                    apply: 'Terapkan',
                    cancel: 'Batal'
                }
            },
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    watch: {
        filterDate() {
            this.getResellerCashback(this.page, this.limit);
            this.getTotalResellerCashback();
        }
    },
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        let today = new Date();
        let start_date = dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('DD MMM YYYY');
        let end_date = dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('DD MMM YYYY');
        this.filterDate.startDate = start_date;
        this.filterDate.endDate = end_date;
        this.getResellerCashback(this.page, this.limit);
        this.getTotalResellerCashback();
	},
	methods: {
		/*
		* Get List Reseller Cashback
		*/
		async getResellerCashback(page, limit) {
            /* Run Loading */
            this.loadingToggle = true;

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                sort: JSON.stringify(this.sort_by),
                user: this.profileData.id,
                type_income: 'shipping_cashback',
                start_date: this.filterDate.startDate,
                end_date: this.filterDate.endDate,
            };

            if(this.statusSelected !== '') {
                params.status = this.statusSelected.value;
            }

			try {
                const response = await axios.get(API_URL + '/api/income/reseller/get-all', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dimuat!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getResellerCashback(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getResellerCashback(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getResellerCashback(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getResellerCashback(this.page, this.limit);
            } else {
                this.search = value;
                this.getResellerCashback(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getResellerCashback(this.page, this.limit);
        },

        /*
		* Get Total Reseller Cashback
		*/
		async getTotalResellerCashback() {
            /* Run Loading */
            this.loadingToggle = true;

            let params = {
                user: this.profileData.id,
                type_income: 'shipping_cashback'
            };
            if(this.filterDate.startDate !== '') {
                params.start_date = this.convertDate(this.filterDate.startDate);
            }
            if(this.filterDate.endDate !== '') {
                params.end_date = this.convertDate(this.filterDate.endDate);
            }

			try {
                const response = await axios.get(API_URL + '/api/income/get-total-reseller-income', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.total_income = response.data.data.length > 0 ? response.data.data[0].total_income : 0;

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

				console.error(error);
			}
		},

        /*
         * Export Income
         */
        async exportIncome() {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let filter = {
                    start_date: this.convertDate(this.filterDate.startDate),
                    end_date: this.convertDate(this.filterDate.endDate),
                    status: this.statusSelected,
                    user: this.profileData.id
                }

                const response = await axios.get(API_URL + '/api/income/export-reseller-income', {
                    params: filter,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;
                const fileDownload = require('js-file-download');
                return fileDownload(response.data, 'export-reseller-income.csv');
            } catch (error) {
                console.log(error)
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

		/*
        * Other Helpers
        */
        convertDate(date) {
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        formatPrice
	}
}
</script>

<style scoped>
</style>