<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Edit Produk</h2>

                <div class="w-full md:flex md:items-center mb-3" v-if="product_data !== null">
                    <img :src="product_data.images[0]" class="border border-gray-200 rounded mr-4 md:mb-0 mb-4 md:block hidden" width="120" />
                    <img :src="product_data.images[0]" class="border border-gray-200 rounded mr-4 md:mb-0 mb-4 w-full md:hidden" />
                    <div>
                        <h4 class="font-semibold font-proxima">{{ product_data.name }}</h4>
                        <h4 class="text-gray-700 font-medium font-proxima text-xs mt-2">Pengiriman dari {{ product_data.vendor.city }}</h4>
                        <!-- Mobile -->
                        <div>
                            <h3 class="text-gray-700 font-proxima font-medium text-sm">Rp{{ formatPrice(product_data.variants[0].price) }}</h3>
                        </div>
                    </div>
                </div>

                <div class="mb-3" v-if="tiktok_auth_token.length > 0">
                    <label class="font-proxima font-semibold block">Pilih Toko Tiktokshop</label>
                    <ZAutoCompleteInputNew v-model="tiktok_token_selected" label="Toko Tiktokshop" :options="tiktokAuthOptions" full @changeData="getTiktokCategory"></ZAutoCompleteInputNew>
                </div>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Produk</label>
                    <z-input v-model="product_name" full></z-input>
                </div>

                <div class="border border-gray-300 rounded p-6 mb-3">
                    <h4 class="font-proxima font-semibold block text-lg mb-5 pb-2 border-b border-gray-300">Kategori Tiktok Shop</h4>

                    <div class="mb-3">
                        <label class="font-proxima font-semibold block">Kategori 1</label>
                        <z-auto-complete-input-new v-model="category_1" label="Kategori Tiktok Shop" :options="categoryOptions1" full @changeData="getCategory2(); getTiktokAttribute()"></z-auto-complete-input-new>
                    </div>

                    <div class="mb-3" v-if="categoryOptions2.length > 0">
                        <label class="font-proxima font-semibold block">Kategori 2</label>
                        <z-auto-complete-input-new v-model="category_2" label="Kategori Tiktok Shop" :options="categoryOptions2" full @changeData="getCategory3(); getTiktokAttribute()"></z-auto-complete-input-new>
                    </div>

                    <div class="mb-3" v-if="categoryOptions3.length > 0">
                        <label class="font-proxima font-semibold block">Kategori 3</label>
                        <z-auto-complete-input-new v-model="category_3" label="Kategori Tiktok Shop" :options="categoryOptions3" full @changeData="getCategory4(); getTiktokAttribute()"></z-auto-complete-input-new>
                    </div>

                    <div class="mb-3" v-if="categoryOptions4.length > 0">
                        <label class="font-proxima font-semibold block">Kategori 4</label>
                        <z-auto-complete-input-new v-model="category_4" label="Kategori Tiktok Shop" :options="categoryOptions4" full @changeData="getCategory5(); getTiktokAttribute()"></z-auto-complete-input-new>
                    </div>

                    <div class="mb-3" v-if="categoryOptions5.length > 0">
                        <label class="font-proxima font-semibold block">Kategori 5</label>
                        <z-auto-complete-input-new v-model="category_5" label="Kategori Tiktok Shop" :options="categoryOptions5" full @changeData="getTiktokAttribute()"></z-auto-complete-input-new>
                    </div>
                </div>

                <div class="border border-gray-300 rounded p-6 mb-3" v-if="attributeData.length > 0">
                    <h4 class="font-proxima font-semibold block text-lg mb-5 pb-2 border-b border-gray-300">Varian Tiktok Shop</h4>

                    <div v-for="(item, index) in attributeData" :key="index">
                        <div class="mb-3" v-if="item.attribute_name == 'Spesifikasi' || item.attribute_name == 'Warna' || item.attribute_name == 'Ukuran'">
                            <label class="font-proxima font-semibold block">{{ item.attribute_name }} <span v-if="item.input_type.is_mandatory" class="text-red-600 text-sm">(Wajib Diisi!)</span></label>
                            <z-select v-if="item.values && item.values.length > 0" v-model="item.value_selected" label="Pilih Nilai Atribut" :options="item.values" full></z-select>
                            <z-auto-complete-mutiple-input v-else-if="item.values && item.values.length === 0 && item.input_type.is_customized" v-model="item.value_selected" label="Pilih Nilai Atribut" :options="variantOptions" full></z-auto-complete-mutiple-input>
                            <z-input v-else v-model="item.value_selected" placeholder="Masukkan Nilai Atribut" full></z-input>
                        </div>
                    </div>
                </div>

                <!-- <div>
                    <label class="font-proxima font-semibold block">Form Slug/URL</label>
                    <z-input v-model="slug" full @input="slugValidationAndFormatter"></z-input>
                    <h4 v-if="slugValidation" class="font-medium text-red-600 text-xs mt-1">Alamat form/slug sudah pernah terpakai!</h4>
                </div> -->

                <div class="mb-3">
                    <label class="block font-semibold font-proxima" for="receiver_address">Deskripsi</label>
                    <!-- <textarea v-model="description" id="custom_script" class="w-full bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima" row="3">
                    </textarea> -->
                    <editor
                        api-key="7tm56s1v0nzouz97uxh9gf2jfq5z4zp6bn11v25x94hbz163"
                        :init="{
                            height: 300,
                            plugins: [
                                'advlist autolink lists link image charmap',
                                'searchreplace visualblocks code fullscreen',
                                'print preview anchor insertdatetime media',
                                'paste code help wordcount table'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright | \
                                bullist numlist outdent indent | help'
                        }"
                        v-model="description"
                    />
                </div>

                <!-- <div>
                    <label class="font-proxima font-semibold block">Kategori</label>
                    <z-select v-model="csSelected" label="Pilih CS" :options="filteredTeamCSOptions" full @changeData="addTeamCS"></z-select>
                </div> -->

            </div>

            <!-- Custom Price -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Setting Harga Jual</h2>
                
                <!-- Input Margin Masal -->
                <div class="mb-3 md:flex items-end justify-start md:space-x-2">
                    <div class="md:w-1/3 w-full md:mb-0 mb-1">
                        <h4 class="text-sm font-proxima">Margin Produk</h4>
                        <z-input v-model="marginMassPrice" placeholder="Margin" type="number" full></z-input>
                    </div>
                    <z-button @click="generateMarginProduct" class="text-white button-diginesia md:w-1/3 w-full">
                        Terapkan Masal
                    </z-button>
                </div>

                <!-- Tabel Product Variant yang sudah digenerated + Input Stok dan Harga Variant -->
                <table class="md:block hidden table-fixed w-full border-collapse text-gray-600 text-md text-center">
                    <!-- Judul Data Produk Varian -->
                    <thead class="bg-white border border-gray-200 text-gray-700 font-medium">
                        <tr>
                            <td class="w-2/6 py-2">Info SKU</td>
                            <td class="w-2/6 py-2">Harga Barang</td>
                            <td class="w-2/6 py-2">Margin</td>
                            <td class="w-2/6 py-2">Harga Jual</td>
                        </tr>
                    </thead>
                    <!-- Input Isian Data Produk Varian : Harga, Stok, dan Kode SKU -->
                    <tbody class="bg-white border border-gray-200">
                        <tr v-for="(item, i) in custom_price" :key="i">
                            <td class="border-gray-200 border-t py-2">
                                <h4 class="text-sm font-proxima font-semibold">{{ item.sku }}</h4>
                                <h4 class="text-xs font-proxima">{{ item.options.toString().replace(',', ' ') }}</h4>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.price) }}</h4>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <div class="relative">
                                    <input v-model="item.margin" class="bg-gray-50 pr-3 pl-8 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima w-full" type="number" @input="item.sell_price = item.price + item.margin">
                                    <div class="absolute inset-0 right-auto flex items-center pointer-events-none">
                                        <span class="text-sm text-gray-400 font-medium px-3 font-proxima">Rp</span>
                                    </div>
                                </div>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.sell_price) }}</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- [Mobile] Tabel Product Variant yang sudah digenerated + Input Stok dan Harga Variant -->
                <table class="md:hidden table-fixed w-full border-collapse text-gray-600 text-md text-center">
                    <!-- Judul Data Produk Varian -->
                    <thead class="bg-white border border-gray-200 text-gray-700 font-medium">
                        <tr>
                            <td class="w-1/2 py-2">Info SKU & Harga Barang</td>
                            <td class="w-1/2 py-2">Margin & Harga Jual</td>
                        </tr>
                    </thead>
                    <!-- Input Isian Data Produk Varian : Harga, Stok, dan Kode SKU -->
                    <tbody class="bg-white border border-gray-200">
                        <tr v-for="(item, i) in custom_price" :key="i">
                            <!-- Mobile -->
                            <td class="border-gray-200 border-t py-2">
                                <h4 class="text-sm font-proxima font-semibold">{{ item.sku }}</h4>
                                <h4 class="text-xs font-proxima">{{ item.options.toString().replace(',', ' ') }}</h4>
                                <br/>
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.price) }}</h4>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <div class="relative">
                                    <input v-model="item.margin" class="bg-gray-50 pr-3 pl-8 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima w-full" type="number" @input="item.sell_price = item.price + item.margin">
                                    <div class="absolute inset-0 right-auto flex items-center pointer-events-none">
                                        <span class="text-sm text-gray-400 font-medium px-3 font-proxima">Rp</span>
                                    </div>
                                </div>
                                <br/>
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.sell_price) }}</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Save Button -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <template v-if="error_report">
                    <div class="p-4 my-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                        {{ error_report }}
                    </div>
                </template>
                <div>
                    <z-button @click="exportProductToTiktokShop" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Mulai Ekspor</z-button>
                </div>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL
import formatPrice from '../../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZSelect from '@/components/common/ZSelect.vue';
import ZAutoCompleteInputNew from '@/components/common/ZAutoCompleteInputNew.vue';
import ZAutoCompleteMutipleInput from '@/components/common/ZAutoCompleteMutipleInput.vue';

/* External Component/Library */
import Editor from '@tinymce/tinymce-vue'
import fastCartesian from 'fast-cartesian'; // Panggil Library Fast Cartesian

export default {
    components: {
        NavigationMenu,
        ZInput,
        ZButton,
        ZSelect,
        ZAutoCompleteInputNew,
        ZAutoCompleteMutipleInput,
        'editor': Editor,
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Data */
            product_data: null,
            tiktok_auth_token: [],
            tiktok_token_selected: null,
            product_attribute: [],
            /* Form Settings */
            product_name: '',
            description: '',
            category: '',
            brand: '',
            category_1: '',
            category_2: '',
            category_3: '',
            category_4: '',
            category_5: '',
            /* Options */
            categoryData: [],
            categoryOptions1: [],
            categoryOptions2: [],
            categoryOptions3: [],
            categoryOptions4: [],
            categoryOptions5: [],
            attributeData: [],
            tiktokAuthOptions: [],
            /* Pricing */
            custom_price: [],
            marginMassPrice: 0,
            variantOptions: [],
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false,
            slugValidation: false,
            /* Form Validation */
            error_report: null
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getAllAuthorization();
        this.getProductBySlug();
        //this.getAuthTokenTiktokShop();
	},
    methods: {
        /*
         * Get All Auhorization
         */
        async getAllAuthorization() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let params = {
                    pagination: false
                };

                const response = await axios.get(`${API_URL}/api/integration/tiktokshop/get-all-authorization/${this.profileData.id}`, {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.tiktok_auth_token = response.data;
                this.tiktokAuthOptions = this.tiktok_auth_token.map(item => {
                    return {
                        label: item.seller_name,
                        value: item
                    }
                });

                if(this.tiktok_auth_token.length === 0) {
                    this.$router.push('/settings/marketplace-integration/tiktokshop');
                }

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Get Product By Slug
         */
        async getProductBySlug () {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/product/get/slug/${this.$route.params.slug}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                this.product_data = response.data.data;
                this.description = this.product_data.product_detail;
                this.custom_price = response.data.data.variants.map(item => {
                    return {
                        sku: item.sku,
                        price: item.price,
                        margin: 0,
                        sell_price: item.price,
                        options: item.options,
                        stock: item.stock.stock,
                        product: item.product
                    }
                });
                response.data.data.options.forEach(item => {
                    item.values.forEach(val => {
                        this.variantOptions.push({
                            label: val.option_name,
                            value: val.option_name
                        })
                    })
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                console.log(error)
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Get Auth Token Tiktok Shop
         */
        async getAuthTokenTiktokShop() {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/integration/tiktokshop/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.tiktok_token = response.data.data;
                this.getTiktokCategory();

                this.loadingToggle = false;
            } catch (error) {
                this.loadingToggle = false;
                this.$router.push('/settings/marketplace-integration');
            }
        },

        /*
         * Export Product to Tiktok Shop
         */
        async exportProductToTiktokShop () {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                /* Olah Attribute jadi Sales Attribute/Variant Produk Tiktok */
                var product = []; // Container untuk tampung Semua Array Option
                const attributeData = this.attributeData.filter(item => item.value_selected !== '')

                /* Extract option/pilihan dari Object menjadi Array untuk di proses di Cartesian */
                for(let i=0; i < attributeData.length; i++){
                    var box = []; // Container untuk tampung Array Option Satuan
                    for(let x=0; x < attributeData[i].value_selected.length; x++){
                        box.push(attributeData[i].value_selected[x].value); // Proses Extract Option lalu tampung di variable array box
                    }
                    product.push(box); // Masukkan Semua Array ke variable product
                }

                /* Proses Cartesian (menggabungkan 2 array atau lebih) */
                var productCartesian = fastCartesian(product);
                
                /* Generate Final Product Variant */
                const product_attribute = productCartesian.map(cartesian => {
                    console.log(cartesian.toString())
                    console.log(this.custom_price[0].options.toString())
                    const variant = this.custom_price.find(item => item.options.toString() == cartesian.toString() || item.options.reverse().toString() == cartesian.toString());
                    console.log(variant)

                    if(variant) {
                        let sales_attributes = [];
                        for(let i=0; i < attributeData.length; i++) {
                            let value;
                            cartesian.forEach(val => {
                                const checkVal = attributeData[i].value_selected.find(attribute_val => attribute_val.value == val);
                                if(checkVal) {
                                    value = checkVal.value;
                                }
                            });

                            sales_attributes.push({
                                attribute_id: attributeData[i].attribute_id,
                                attribute_name: attributeData[i].attribute_name,
                                custom_value: value
                            })
                        }

                        return {
                            sales_attributes: sales_attributes,
                            seller_sku: variant.sku,
                            stock: variant.stock,
                            original_price: variant.sell_price
                        };
                    }
                })

                Promise.all(product_attribute)
                    .then(async (attribute) => {
                        try {
                            let category;
                            if(this.category_1 != '') {
                                category = this.category_1.value;
                            }
                            if(this.category_2 != '') {
                                category = this.category_2.value;
                            }
                            if(this.category_3 != '') {
                                category = this.category_3.value;
                            }
                            if(this.category_4 != '') {
                                category = this.category_4.value;
                            }
                            if(this.category_5 != '') {
                                category = this.category_5.value;
                            }

                            /* Validasi Attribute */
                            const checkNullAttribute = attribute.filter(item => item == null);
                            if(checkNullAttribute && checkNullAttribute.length > 0) {
                                this.error_report = 'Pengisian atribut masih salah';
                                /* Stop Loading */
                                loading.hide();
                                this.loading = false;
                                return;
                            }

                            const data = {
                                shop_id: this.tiktok_token_selected.value.shop_id,
                                access_token: this.tiktok_token_selected.value.access_token,
                                product_id: this.product_data._id,
                                product_sku: this.product_data.sku,
                                product_name: this.product_name,
                                product_description: this.description,
                                product_images: this.product_data.images,
                                product_weight: this.product_data.package_weight,
                                product_options: this.product_data.options,
                                product_variant: this.custom_price,
                                product_category: category,
                                product_attribute: attribute,
                                user: this.profileData.id
                            };

                            const response = await axios.post(`${API_URL}/api/integration/tiktokshop/product/add`, data, {
                                headers: {
                                    'Authorization': this.$store.state.auth.token
                                }
                            });
                            console.log(response)
                            this.$router.push('/tiktokshop/list-product');

                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        } catch (error) {
                            this.error_report = error.response.data.message;
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                this.error_report = error.response.data.message;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Get Tiktok Category
         */
        async getTiktokCategory () {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/integration/tiktokshop/category?shop_id=${this.tiktok_token_selected.value.shop_id}&access_token=${this.tiktok_token_selected.value.access_token}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                console.log(response.data)
                this.categoryData = response.data.message.data.category_list;

                const categoryOptions1 = this.categoryData.filter(item => item.is_leaf == false && item.parent_id == 0);
                this.categoryOptions1 = categoryOptions1.map(item => {
                    return {
                        value: item.id,
                        label: item.local_display_name
                    }
                });

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },
        /*
         * Get Tiktok Category 2
         */
        async getCategory2 () {
            const categoryOptions2 = this.categoryData.filter(item => item.parent_id == this.category_1.value);
            this.categoryOptions2 = categoryOptions2.map(item => {
                return {
                    value: item.id,
                    label: item.local_display_name
                }
            });
        },
        /*
         * Get Tiktok Category 3
         */
        async getCategory3 () {
            const categoryOptions3 = this.categoryData.filter(item => item.parent_id == this.category_2.value);
            this.categoryOptions3 = categoryOptions3.map(item => {
                return {
                    value: item.id,
                    label: item.local_display_name
                }
            });
        },
        /*
         * Get Tiktok Category 4
         */
        async getCategory4 () {
            const categoryOptions4 = this.categoryData.filter(item => item.parent_id == this.category_3.value);
            this.categoryOptions4 = categoryOptions4.map(item => {
                return {
                    value: item.id,
                    label: item.local_display_name
                }
            });
        },
        /*
         * Get Tiktok Category 5
         */
        async getCategory5 () {
            const categoryOptions5 = this.categoryData.filter(item => item.parent_id == this.category_4.value);
            this.categoryOptions5 = categoryOptions5.map(item => {
                return {
                    value: item.id,
                    label: item.local_display_name
                }
            });
        },

        /*
         * Get Tiktok Attribute
         */
        async getTiktokAttribute () {
            this.loadingToggle = true;

            let category;
            if(this.category_1 != '') {
                category = this.category_1.value;
            }
            if(this.category_2 != '') {
                category = this.category_2.value;
            }
            if(this.category_3 != '') {
                category = this.category_3.value;
            }
            if(this.category_4 != '') {
                category = this.category_4.value;
            }
            if(this.category_5 != '') {
                category = this.category_5.value;
            }

            try {
                const response = await axios.get(`${API_URL}/api/integration/tiktokshop/attributes?shop_id=${this.tiktok_token_selected.value.shop_id}&access_token=${this.tiktok_token_selected.value.access_token}&category_id=${category}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                console.log(response.data)
                this.attributeData = response.data.message.data.attributes.map(item => {
                    return {
                        attribute_id: item.id,
                        attribute_name: item.name,
                        attribute_type: item.attribute_type,
                        input_type: item.input_type,
                        values: item.values && item.values.length > 0 ? item.values.map(val => {
                            return {
                                value: val.id,
                                label: val.name
                            }
                        }) : [],
                        value_selected: ''
                    }
                });
                //this.categoryData = response.data.message.response.category_list;

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },

        /* Helpers */
        generateMarginProduct() {
            this.custom_price.map((item) => {
                item.margin = this.marginMassPrice;
                item.sell_price = Number(item.price) + Number(this.marginMassPrice);
            });
        },
        async slugValidationAndFormatter(event) {
            this.slug = event.target.value.replace(/\s+/g, '-').toLowerCase();

            try {
                const checkSlug = await axios.get(API_URL + '/api/my-form/slug-validation/' + this.slug + '/user/' + this.profileData.id, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loadingToggle = false;
                this.slugValidation = checkSlug.data.data;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },
        formatPrice
    }
}
</script>

<style scoped>
</style>