<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="md:flex md:justify-between sm:items-center mb-8">
                <!-- Left: Title -->
                <div class="mb-4 md:mb-0">
                    <h1 class="text-xl font-proxima text-gray-800 font-bold">Listing Produk</h1>
                </div>

                <!-- Right: Actions  -->
                <div class="md:flex items-center">
                    <!-- Search form -->
                    <z-input placeholder="Cari produk..." v-model="search" @input="tableInputSearchHandler"></z-input>
                    <!-- Delete button -->
                    <div class="flex items-center" v-if="selectedItems && selectedItems.length > 0">
                        <div class="hidden xl:block text-sm italic mr-2 whitespace-nowrap"><span>{{selectedItems.length}}</span> item yang dipilih</div>
                        <z-button class="button-diginesia text-white text-sm" @handleDelete="deleteMutiple = true; confirmDeleteModal = true">Hapus Masal</z-button>
                    </div>
                </div>
            </div>
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!----------->
            <!-- Table -->
            <!----------->
            <div class="w-full">
                <z-table
                    title="Produk"
                    :data="data.data"
                    :meta="data.meta"
                    :columns="columns"
                    :mutipleCheck="true"
                    @updateSelectedItems="updateSelectedItems"
                    :sorting="true"
                    @sortingHandler="sortingHandler"
                >
                    <template v-slot:images="{item}">
                        <div class="px-3">
                            <img :src="item.product.images[0].replace('upload/', 'upload/c_fill,h_300,w_300/')" width="100" />
                        </div>
                    </template>
                    <template v-slot:name="{item}">
                        {{ item.product.name.slice(0, 40) + (item.product.name.length > 40 ? "..." : "") }}
                    </template>
                    <template v-slot:sku="{item}">
                        {{ item.product.sku }}
                    </template>
                    <template v-slot:price="{item}">
                        {{ formatPriceRange(item.product.variants) }}
                    </template>
                    <template v-slot:stock="{item}">
                        {{ item.product.variants.reduce((total, value) => { return Number(total) + Number(value.stock.stock) }, 0) }}
                    </template>
                    <template v-slot:vendor="{item}">
                        {{ item.product.vendor.city }}
                        <!-- <a class="text-sm text-blue-400 hover:text-blue-500" :href="`http://localhost:4000/order/${item.slug}`" target="_blank">Kunjungi Form</a> -->
                    </template>
                    <template v-slot:action="{item}">
                        <span class="hidden">{{item}}</span>
                        <!-- Action Button -->
                        <ZDropdown class="inline-flex">
                            <li>
                                <a @click.stop="detailProductModal = true; selectedProduct = item.product" class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer">Detail Produk</a>
                            </li>
                            <li>
                                <a @click="$router.push('/my-form/add/' + item.product.slug)" class="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 cursor-pointer">Buat Form Order</a>
                            </li>
                            <li>
                                <a class="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3 cursor-pointer" @click.stop="dataSelected = item._id; confirmDeleteModal = true">Hapus</a>
                            </li>
                        </ZDropdown>
                    </template>
                </z-table>
            </div>
            <!--------------->
            <!-- End Table -->
            <!--------------->

            <!---------------->
            <!-- Pagination -->
            <!---------------->
            <div class="mt-8">
                <z-pagination 
                    :meta="data.meta"
                    :PageNavHandler="pageNavHandler"
                    :pageNavNumberHandler="pageNavNumberHandler"
                />
            </div>
            <!-------------------->
            <!-- End Pagination -->
            <!-------------------->
            
            <!-- Confirm Delete Modal -->
            <ZModalAction id="danger-modal" :modalOpen="confirmDeleteModal" @close-modal="confirmDeleteModal = false">
                <div class="flex space-x-4">
                    <!-- Icon -->
                    <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-red-100">
                        <svg class="w-4 h-4 shrink-0 fill-current text-red-500" viewBox="0 0 16 16">
                            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                        </svg>
                    </div>
                    <!-- Content -->
                    <div>
                        <!-- Modal header -->
                        <div class="mb-2">
                            <div class="text-lg font-semibold text-gray-800 font-proxima">Hapus Data Produk</div>
                        </div>
                        <!-- Modal content -->
                        <div class="text-sm mb-10 font-proxima">
                            <div class="space-y-2">
                                <p>Anda yakin ingin menghapus data produk ini?<br/> Data akan dihapus secara permanen. Tindakan ini tidak bisa dibatalkan.</p>
                            </div>
                        </div>
                        <!-- Modal footer -->
                        <div class="flex flex-wrap justify-end space-x-2">
                            <button class="px-3 py-1.5 text-sm rounded border-gray-200 hover:border-gray-300 text-gray-600 font-proxima" @click.stop="confirmDeleteModal = false">Batal</button>
                            <button @click="deleteMutiple ? handleMutipleDelete() :  handleDelete()" class="px-3 py-1.5 text-sm rounded bg-red-500 hover:bg-red-600 text-white font-proxima">Ya, Hapus Sekarang</button>
                        </div>
                    </div>
                </div>
            </ZModalAction>
            <ZDetailProduct v-if="detailProductModal === true" :product="selectedProduct" :modalOpen="detailProductModal" @closeModal="detailProductModal = false"></ZDetailProduct>
            <!-- End Content Area -->
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL
import formatPrice from '../../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZDropdown from '@/components/common/ZDropdown.vue';
import ZModalAction from '@/components/common/ZModalAction.vue';
import ZDetailProduct from '@/components/special/ZDetailProduct.vue';

export default {
    components: {
        NavigationMenu,
        ZTable,
        ZPagination,
        ZInput,
        ZButton,
        ZDropdown,
        ZModalAction,
        ZDetailProduct,
    },
    data() {
        return {
            /* Core Data */
			data: [],
            selectedItems: [],
            dataSelected: null,
            deleteMutiple: false,
            selectedProduct: '',
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'images',
                    label: 'Gambar',
                    visibility: true,
                },
                {
                    id: 2,
                    field: 'name',
                    label: 'Nama Produk',
                    visibility: true,
                    sort: true
                },
                {
                    id: 3,
                    field: 'sku',
                    label: 'SKU',
                    visibility: true,
                    sort: true
                },
                {
                    id: 4,
                    field: 'price',
                    label: 'Harga',
                    visibility: true,
                },
                {
                    id: 5,
                    field: 'stock',
                    label: 'Stok',
                    visibility: true,
                },
                {
                    id: 6,
                    field: 'vendor',
                    label: 'Asal Pengiriman/Gudang',
                    visibility: true,
                },
                {
                    id: 8,
                    field: 'action',
                    label: 'Action',
                    visibility: true,
                }
			],
			/* Toggle */
			sidebarOpen: false,
            loadingToggle: false,
            confirmDeleteModal: false,
            detailProductModal: false,
			/* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: '',
            sort_order: -1,
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getProduct(this.page, this.limit);
	},
	methods: {
		/*
		* Get List Product
		*/
		async getProduct(page, limit) {
            /* Run Loading */
            this.loadingToggle = true;

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                sort: JSON.stringify(this.sort_by)
            };

			try {
                const response = await axios.get(API_URL + '/api/my-product/get-all/' + this.profileData.id, {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dimuat!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getProduct(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getProduct(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getProduct(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getProduct(this.page, this.limit);
            } else {
                this.search = value;
                this.getProduct(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

		/*
		 * Update Selected Items
		 */
		async updateSelectedItems(selected) {
			this.selectedItems = selected;
		},

        /*
		 * Delete Product
		 */
        /* Handle Delete Product */
        async handleDelete(id = null) {
            /* Run Loading */
            this.loadingToggle = true;

            try {
                const product_id = id ? id : this.dataSelected;
                await axios.delete(API_URL + '/api/my-product/delete/' + product_id, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                this.loadingToggle = false;

                /* Refresh Page */
                window.location.reload();
            } catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dihapus!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
            }
        },
        /* Handle Mutiple Delete */
        handleMutipleDelete() {
            // Looping dan Eksekusi penghapusan data
            for(let i = 0; i < this.selectedItems.length; i++){
                this.handleDelete(this.selectedItems[i]._id);
            }
        },

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getProduct(this.page, this.limit);
        },

       /*
        * Other Helpers
        */
        formatPriceRange(variant) {
            const max_price = Math.max.apply(Math, this.convertVariantPriceToArray(variant));
            const min_price = Math.min.apply(Math, this.convertVariantPriceToArray(variant));

            if(max_price === min_price) {
                return 'Rp' + this.formatPrice(max_price);
            }else{
                return `Rp ${this.formatPrice(min_price)} - Rp ${this.formatPrice(max_price)}`;
            }
        },
		convertVariantPriceToArray(variant) {
            var array = [];

            for(let i=0; i < variant.length; i++){
                array.push(variant[i].price);
            }

            return array;
		},
        formatPrice
	}
}
</script>

<style scoped>
</style>