<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <section v-if="loading" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 w-2/3 mx-auto">
                <!-- Error Message -->
                <div v-if="error_message" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                    <span class="font-medium">Peringatan!</span><br/>{{ error_message }}.
                </div>
                <div v-if="success_message" class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                    <span class="font-medium">Sukses!</span><br/>{{ success_message }}.
                </div>

                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Import Produk</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Mulai Dari Produk No (Dimulai dari Angka 0)</label>
                    <input type="number" v-model="page" class="border border-gray-300 rounded px-3 py-2 w-full" />
                </div>

                <z-button @click="scanProductFromShopee(this.page, this.limit)" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Ambil Product Dari Toko Shopee</z-button>

                <!-- Set Category dan Brand -->
                <div class="my-4">
                    <!-- <div class="mb-3">
                        <label class="font-proxima font-semibold block mb-1">Kategori</label>
                        <z-select v-model="category" label="Kategori" :options="categoryOptions" full></z-select>
                    </div> -->

                    <!-- <div class="mb-3">
                        <label class="font-proxima font-semibold block mb-1">Merk</label>
                        <z-select v-model="brand" label="Merk" :options="brandOptions" full></z-select>
                    </div> -->
                </div>
                <!-- End Set Category dan Brand -->

                <div class="mt-8 mb-4 sm:mb-0" v-if="data_product && data_product.items.length > 0">
                    <h1 class="text-lg font-proxima text-gray-800 font-bold">Daftar Produk Shopee</h1>
                </div>

                <!-- Table -->
                <div v-if="data_product && data_product.items.length > 0" class="mt-4">
                    <table class="table-auto w-full">
                        <!------------------->
                        <!-- Header Column -->
                        <!------------------->
                        <thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
                            <tr>
                                <!-- <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                                    <div class="flex items-center">
                                        <label class="inline-flex">
                                            <span class="sr-only">Select all</span>
                                            <input class="form-checkbox" type="checkbox" v-model="selectAllItem" @click="selectItem" />
                                        </label>
                                    </div>
                                </th> -->
                                <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div class="font-semibold text-left flex items-center">
                                        Foto Produk
                                        <!-- <button class="focus:outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-current ml-2" viewBox="0 0 24 24" style="transform:;-ms-filter:"><path d="M6.227 11h11.547c.862 0 1.32-1.02.747-1.665l-5.773-6.495c-.397-.448-1.097-.448-1.494 0L5.479 9.335C4.906 9.98 5.364 11 6.227 11zM11.253 21.159c.397.448 1.097.448 1.494 0l5.773-6.495C19.094 14.02 18.636 13 17.773 13H6.227c-.862 0-1.32 1.02-.747 1.665L11.253 21.159z"></path></svg>
                                        </button> -->
                                    </div>
                                </th>
                                <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div class="font-semibold text-left flex items-center">
                                        Info Produk
                                        <!-- <button class="focus:outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-current ml-2" viewBox="0 0 24 24" style="transform:;-ms-filter:"><path d="M6.227 11h11.547c.862 0 1.32-1.02.747-1.665l-5.773-6.495c-.397-.448-1.097-.448-1.494 0L5.479 9.335C4.906 9.98 5.364 11 6.227 11zM11.253 21.159c.397.448 1.097.448 1.494 0l5.773-6.495C19.094 14.02 18.636 13 17.773 13H6.227c-.862 0-1.32 1.02-.747 1.665L11.253 21.159z"></path></svg>
                                        </button> -->
                                    </div>
                                </th>
                                <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div class="font-semibold text-left flex items-center">
                                        Aksi
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <!------------------------->
                        <!-- / End Header Column -->
                        <!------------------------->

                        <!--------------->
                        <!-- List Item -->
                        <!--------------->
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(item, index) in data_product.items" :key="index" class="text-sm font-medium">
                                <!-- <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                                    <div class="flex items-center">
                                        <label class="inline-flex">
                                            <span class="sr-only">Select</span>
                                            <input class="form-checkbox" type="checkbox" :value="item" v-model="selectedItem" />
                                        </label>
                                    </div>
                                </td> -->
                                <td class="px-2 py-3 whitespace-nowrap">
                                    <img :src="item.image.image_url_list[0]" width="100" />
                                </td>
                                <td class="px-2 py-3 whitespace-nowrap">
                                    {{ item.item_name.slice(0, 40) + (item.item_name.length > 40 ? "..." : "") }}
                                    <br/>
                                    <span class="text-gray-500" v-if="item.price_info && item.price_info.length > 0">Rp{{ formatPrice(item.price_info[0].current_price) }}</span>
                                    <span class="text-gray-500" v-else>Rp{{ formatPrice(item.variants.model[0].price_info[0].current_price) }}</span>
                                    <br/>
                                    <span class="text-blue-500">Tipe Produk: {{ item.variants.model.length > 0 ? 'Variant' : 'Single Product' }}</span>
                                    <br/>
                                    <span class="text-gray-500">SKU: {{ item.item_sku }}</span>
                                    <!-- <br/>
                                    <span class="text-gray-500">Kategori Produk: {{ item.category_id }}</span> -->
                                </td>
                                <td class="px-2 py-3 whitespace-nowrap">
                                    <button @click="uploadMediaImages(item)" class="bg-blue-600 hover:bg-blue-700 px-3 py-1 text-sm font-semibold text-white rounded">Import Produk</button>
                                </td>
                            </tr>
                        </tbody>
                        <!--------------------->
                        <!-- / End List Item -->
                        <!--------------------->
                    </table>
                </div>

                <div class="flex justify-center" v-if="data_product && data_product.meta">
                    <nav class="flex" role="navigation" aria-label="Navigation">
                        <div class="mr-2">
                            <button
                                @click="scanProductFromShopee(Number(data_product.meta.next_offset - (this.limit * 2)), this.limit)"
                                :disabled="data_product.meta.next_offset && data_product.meta.next_offset == this.limit || data_product.meta.total_count < this.limit"
                                :class="data_product.meta.next_offset && data_product.meta.next_offset !== this.limit ? 'hover:bg-yellow-500 border-gray-200 text-gray-600 hover:text-gray-800 shadow-sm' : 'border-gray-200 text-gray-300'"
                                class="inline-flex items-center justify-center border border-transparent rounded leading-5 px-2.5 py-2 bg-white"
                            >
                                <span class="sr-only">Previous</span><wbr />
                                <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
                                    <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
                                </svg>
                            </button>
                        </div>
                        <div class="ml-2">
                            <button
                                @click="scanProductFromShopee(data_product.meta.next_offset, this.limit)"
                                :disabled="data_product.meta.has_next_page == false"
                                :class="data_product.meta.has_next_page ? 'hover:bg-yellow-500 border-gray-200 text-gray-600 hover:text-gray-800 shadow-sm' : 'border-gray-200 text-gray-300'"
                                class="inline-flex items-center justify-center border border-transparent rounded leading-5 px-2.5 py-2 bg-white"
                            >
                                <span class="sr-only">Next</span><wbr />
                                <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
                                    <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                                </svg>
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatPrice from '../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZButton from '@/components/common/ZButton.vue';
//import ZSelect from '@/components/common/ZSelect.vue';

export default {
    components: {
        NavigationMenu,
        ZButton,
        //ZSelect
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Product Information */
            data_product: '',
            page: 0,
            limit: 10,
            category: '',
            brand: '',
            /*
             * Relation Options
             */
            /* Category Options */
            categoryData: '',
            categoryOptions: [],
            /* Brand Options */
            brandData: '',
            brandOptions: [],
            /* Extra */
            selectAllItem: false,
            selectedItem: [],
            errors: [],
            error_message: null,
            success_message: null,
            loading: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/login');
            });
        }
        
        //this.getCategory();
        this.getBrand();
        this.getAuthTokenShopee();
    },
    methods: {
        /*
         * Get Category
         */
        async getCategory() {
            try {
                const response = await axios.get(`${API_URL}/api/category/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.categoryData = response.data;

                for(let i=0; i < this.categoryData.length; i++){
                    this.categoryOptions.push({
                        label: this.categoryData[i].name,
                        value: this.categoryData[i]._id
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        /*
         * Get Brand
         */
        async getBrand() {
            /* Reset Options */
            this.brandOptions = []; // Reset
            
            try {
                const response = await axios.get(`${API_URL}/api/brand/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.brandData = response.data;

                for(let i=0; i < this.brandData.length; i++){
                    this.brandOptions.push({
                        label: this.brandData[i].brand_name,
                        value: this.brandData[i]._id
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },

        /*
         * Get Auth Token Shopee
         */
        async getAuthTokenShopee() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.shopee_token = response.data.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                this.$router.push('/settings/marketplace-integration');
            }
        },

        /*
         * Scan Product From Shopee
         */
        async scanProductFromShopee(page, limit) {
            this.error_message = null;

            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/scan-product-from-shopee?access_token=${this.shopee_token.access_token}&shop_id=${this.shopee_token.shop_id}&page=${page}&limit=${limit}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.data_product = response.data.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                window.scrollTo(0,0);
                this.error_message = error.response.data.message;
            }
        },

        /*
         * Upload Media Images
         */
        async uploadMediaImages(product) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Mapping Images - Upload ke Cloudinary*/
            const images = product.image.image_url_list.map(async item => {
                try {
                    if(item) {
                        let data = {
                            file: item,
                            platform: 'shopee'
                        }

                        return await axios.post(API_URL + '/api/media/upload-media-from-url', data, {
                            headers: {
                                'Authorization': this.$store.state.auth.token
                            }
                        })
                            .then(image => {
                                console.log(image.data.data.file_url)
                                return image.data.data.file_url;
                            })
                            .catch(() => {
                                return null;
                            });
                    }
                } catch (error) {
                    return null;
                }
            })

            await Promise.all(images)
                .then(async result => {

                    // const checkError = result.some(item => item === null);
                    // if(checkError) {
                    //     this.loadingToggle = false;
                        
                    //     // this.$notify({
                    //     //     title: "Gagal",
                    //     //     text: "Gambar sudah dipakai!",
                    //     //     position: "bottom",
                    //     //     type: "error"
                    //     // });
                        
                    //     return
                    // }

                    console.log(result)
                    /* Stop Loading */
                    loading.hide();
                    this.loading = false;

                    this.importProduct(product, result.filter(item => item !== null));
                })
                .catch(error => {
                    console.log(error)
                })
        },
        /*
         * Import Product
         */
        async importProduct(product, media_images) {
            this.error_message = null;

            if (media_images.length === 0) {
                this.$toast.success('Gambar berhasil diimport, Silahkan Klik Import lagi!', {duration: 3000});
                return;
            }

            /* Validasi Form - Check Category & Brand */
            // if (this.category === '') {
            //     this.error_message = 'Kategori Produk Harus Diisi!';
            //     window.scrollTo(0,0);
            //     return;
            // }
            // let category_id;
            // const getCategory = await this.getCategoryByShopeeCategoryID(product.category_id);
            // if(getCategory.status && getCategory.status === 1) {
            //     category_id = getCategory.data._id;
            //     console.log(category_id)
            // }else{
            //     //window.scrollTo(0,0);
            //     //this.error_message = getCategory;
            //     this.$toast.error(getCategory, {duration: 3000});
            //     return;
            // }
            // if (this.brand === '') {
            //     //this.error_message = 'Merk Produk Harus Diisi!';
            //     this.$toast.error('Merk Produk Harus Diisi!', {duration: 3000});
            //     //window.scrollTo(0,0);
            //     return;
            // }

            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Check Variasi Product */
            let options = []; // Variable untuk Informasi Nama Variasi dan Pilihan Variasi
            let product_variant = [];
            if(product.variants.model && product.variants.model.length > 0 && product.variants.tier_variation && product.variants.tier_variation.length > 0) {
                /* Isi Options/Informasi Nama Variasi dan Pilihan Variasinya pada Product */
                for(let i=0; i < product.variants.tier_variation.length; i++){
                    options.push({
                        name: product.variants.tier_variation[i].name, // Nama Variasi String
                        values: product.variants.tier_variation[i].option_list.map(item => {
                            return {
                                option_name: item.option,
                                imageURL: ''
                            }
                        }) // Pilihan Variasi Object
                    });
                }
                /* Setup Variants */
                product_variant = product.variants.model.map(item => {
                    let optionsList = item.tier_index.map((tier, index) => {
                        return product.variants.tier_variation[index].option_list[tier].option
                    })

                    return {
                        sku: item.model_sku,
                        price: item.price_info[0].current_price,
                        options: optionsList,
                        stock: item.stock_info_v2.summary_info.total_available_stock
                    }
                })
            }else{
                /* Setup Single Options */
                options.push({
                    name: "Type",
                    values: [
                        {
                            option_name: "Single",
                            imageURL: ""
                        }
                    ]
                });
                /* Setup Single Variant */
                product_variant = [{
                    sku: product.item_sku,
                    price: product.price_info[0].current_price,
                    options: [
                        'Single'
                    ],
                    stock: product.stock_info_v2.summary_info.total_available_stock
                }];
            }

            try {
                let data = {
                    product_name: product.item_name,
                    sku: product.item_sku ? product.item_sku : product_variant[0].sku,
                    category: "6390590c0a21c432809ad053",
                    brand: "6390591d0a21c432809ad06e",
                    images: media_images,
                    video: product.video_info ? product.video_info.video_url : null,
                    benefit: [], // SKIP
                    product_detail: product.description.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                    testimonial: [], // SKIP
                    variants: product_variant,
                    options: options,
                    package_weight: product.weight * 1000,
                    user: this.profileData.id,
                    vendor: this.profileData.vendor
                };

                await axios.post(`${API_URL}/api/integration/shopee/import-product-from-shopee`, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                //window.scrollTo(0,0);
                //this.success_message = `${product.item_name} Berhasil Diimport!`
                this.$toast.success(`${product.item_name} Berhasil Diimport!`, {duration: 3000});

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                //window.scrollTo(0,0);
                //this.error_message = error.response.data.message;
                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },
        
        /*
         * Get Category By Shopee Category ID
         */
        async getCategoryByShopeeCategoryID(id) {
            try {
                const response = await axios.get(`${API_URL}/api/category/get-by-shopee-category/${id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                return response.data;
            } catch (error) {
                console.error(error);
                return error.response.data.message;
            }
        },
        formatPrice
    }
}
</script>

<style scoped>
</style>