<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Main Category -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mb-3">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Kategori Induk</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">ID Kategori</label>
                    <z-input v-model="category_id" full></z-input>
                </div>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Kategori</label>
                    <z-input v-model="name" full></z-input>
                </div>

                <!-- <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Kategori Induk</label>
                    <z-select v-model="parent_category_id" label="Kategori" :options="categoryOptions" full></z-select>
                </div> -->
            </div>

            <!-- Shopee -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mb-3">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Shopee</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">ID Kategori Shopee</label>
                    <z-input v-model="shopee_category_id" full></z-input>
                </div>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Kategori Shopee</label>
                    <z-input v-model="shopee_category_name" full></z-input>
                </div>
            </div>

            <!-- Tiktok Shop -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mb-3">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Tiktok Shop</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">ID Kategori Tiktok Shop</label>
                    <z-input v-model="tiktok_category_id" full></z-input>
                </div>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Kategori Tiktok Shop</label>
                    <z-input v-model="tiktok_category_name" full></z-input>
                </div>
            </div>

            <!-- Button Action -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2">
                <div>
                    <z-button @click="updateCategory" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Simpan</z-button>
                </div>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZInput from '@/components/common/ZInput.vue';
// import ZSelect from '@/components/common/ZSelect.vue';
import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        NavigationMenu,
        ZInput,
        // ZSelect,
        ZButton
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            name: '',
            image: '',
            /* Relation */
            parent: null,
            /* Extra */
            shopee_category_id: '',
            shopee_category_name: '',
            tiktok_category_id: '',
            tiktok_category_name: '',

            /*
             * Relation Options
             */
            /* Category Options */
            categoryData: '',
            categoryOptions: [],

            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/signin');
            });
        }

        this.getCategory();
    },
    methods: {
        /*
         * Get Category
         */
        async getCategory() {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/category/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.categoryData = response.data;

                for(let i=0; i < this.categoryData.length; i++){
                    this.categoryOptions.push({
                        label: this.categoryData[i].name,
                        value: this.categoryData[i]._id
                    })
                }

                this.getCategoryById();
                this.loadingToggle = false;
            } catch (error) {
                this.loadingToggle = false;
                console.error(error);
            }
        },
        /*
         * Get Category By Id
         */
        async getCategoryById () {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/category/get/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                this.category_id = response.data.data.category_id;
                this.name = response.data.data.name;
                this.image = response.data.data.image;
                this.parent_category_id = response.data.data.parent_category_id ? this.categoryOptions.find(item => item.value == response.data.data.parent_category_id) : null;
                this.shopee_category_id = response.data.data.shopee_category_id ? response.data.data.shopee_category_id : '';
                this.shopee_category_name = response.data.data.shopee_category_name ? response.data.data.shopee_category_name : '';
                this.tiktok_category_id = response.data.data.tiktok_category_id ? response.data.data.tiktok_category_id : '';
                this.tiktok_category_name = response.data.data.tiktok_category_name ? response.data.data.tiktok_category_name : '';

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;

                this.$notify({
                    title: "Gagal",
                    text: "Kategori gagal diambil!",
                    position: "bottom",
                    type: "error"
                });
            }
        },

        /*
         * Update Category
         */
        async updateCategory () {
            this.loadingToggle = true;

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Basic Information */
                category_id: this.category_id,
                name: this.name,
                image: this.image,
                /* Relational */
                parent_category_id: this.parent_category_id && this.parent_category_id.value ? this.parent_category_id.value : undefined,
                /* Extra */
                shopee_category_id: this.shopee_category_id,
                shopee_category_name: this.shopee_category_name,
                tiktok_category_id: this.tiktok_category_id,
                tiktok_category_name: this.tiktok_category_name
            }

            try {
                await axios.put(`${API_URL}/api/category/update/${this.$route.params.id}`, {category: form}, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loadingToggle = false;
                this.$router.push('/category/list');
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;

                this.$notify({
                    title: "Gagal",
                    text: "Kategori gagal disimpan!",
                    position: "bottom",
                    type: "error"
                });
            }
        },
    }
}
</script>

<style scoped>
</style>