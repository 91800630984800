<template>
    <div class="min-h-screen bg-gray-100 text-gray-800 antialiased flex items-center justify-center sm:py-12">
        <section v-if="loading !== null" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="relative py-3 mx-auto text-center w-full max-w-sm">
            <span class="text-2xl font-semibold font-proxima">Login Admin SatuStock</span>
            <div class="relative mt-4 bg-white shadow-md sm:rounded-lg text-left">
                <div class="h-2 bg-yellow-400 rounded-t-md"></div>
                <div class="py-6 px-8">
                    <!-- Error Message -->
                    <div v-if="error_message" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                        <span class="font-medium">Peringatan!</span><br/>{{ error_message }}.
                    </div>
                    <!-- Email -->
                    <div class="mb-3">
                        <label class="font-proxima font-semibold block mb-1">No Handphone</label>

                        <div class="relative bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima flex items-center">
                            <!-- <h4 class="font-proxima text-gray-900 text-base mr-1">+62</h4> -->
                            <input
                                class="bg-gray-50 focus:outline-none w-2/3"
                                type="text"
                                placeholder="No Handphone"
                                required
                                v-model="phoneNumber"
                            />
                            <button :disabled="verifyLoading ? true : false" @click="sendVerifyCode" :class="verifyLoading ? 'bg-yellow-300' : 'bg-yellow-200'" class="text-xs rounded hover:bg-yellow-300 focus:outline-none px-2 py-1 w-1/3"><span v-if="verifyLoading == true">({{verifyCountDown}}) Ulangi</span><span v-else>Kirim Kode</span></button>
                        </div>
                        <span class="text-xs text-gray-500 font-normal block">*silahkan masukkan kode verifikasi yang dikirim via whatsapp</span>
                    </div>
                    <!-- Password -->
                    <div class="mb-3">
                        <label class="font-proxima font-semibold block mb-1">Kode Verifikasi</label>
                        <z-input v-model="verify_code" type="text" full></z-input>
                    </div>
                    <!-- Button Action -->
                    <div class="flex justify-between items-baseline mt-8">
                        <z-button @click="handleLogin" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2">Masuk</z-button>
                        <!-- <a href="#" class="text-sm font-proxima hover:underline">Lupa Password?</a> -->
                    </div>
                </div>
            </div>
            <!-- Footer -->
            <!-- <h4 class="text-gray-500 text-sm font-proxima mt-6">Belum punya akun? <a @click="$router.push('/auth/register')" class="text-yellow-500 hover:text-yellow-600 font-semibold cursor-pointer">Daftar Sekarang!</a></h4> -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        ZInput,
        ZButton
    },
    data() {
        return {
            phoneNumber: '',
            verify_code: '',
            /* Toggle */
            verifyLoading: false,
            verifyCountDown: 120,
            verifiedStatus: false,
            alertToggle: false,
            loadingButton: false,
            /* Extra */
            loading: null,
            error_message: null
        }
    },
    computed: {
        loggedIn() {
            return this.$store.getters['auth/isLoggedIn']; // <== ini kalau pakai namespace, kalau engga tinggal this.$store.getters.isLoggedIn
        }
    },
    created() {
        if (this.loggedIn) {
            this.$router.push('/');
        }
    },
    methods: {
        async sendVerifyCode() {
            try {
                let data = {
                    phone_number: this.phoneNumber,
                    type: 'whatsapp'
                }

                const response = await axios.post(API_URL + '/api/auth/send-verify-code', data);
                console.log(response);

                this.verifyCountDown = 120;
                this.verifyCountDownTimer(); // Start Countdown
                this.verifyLoading = true;
            } catch (error) {
                console.error(error);
            }
        },
        verifyCountDownTimer() {
            if(this.verifyCountDown > 0) {
                setTimeout(() => {
                    this.verifyCountDown -= 1
                    this.verifyCountDownTimer()
                }, 1000)
            }else{
                this.verifyLoading = false;
            }
        },
        async handleLogin(){
            /* Set Form Data */
            const phone_number = this.phoneNumber;
            const verify_code = this.verify_code;
            this.loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Commit ke Vuex */
            this.$store.dispatch('auth/login_admin', { phone_number, verify_code }).then(() => {
                
                this.$router.push('/');
                this.loading.hide();
                this.loading = null;

            }).catch(err => {
                
                /* Gagal munculkan alert */
                if (err) {
                    /* Show Error Message */
                    this.error_message = err.response.data.message;
                    //alert(err);
                    this.alertToggle = true;
                    //this.loadingButton = false;
                    this.loading.hide();
                    this.loading = null;
                }

            });
        },
    }
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}
</style>