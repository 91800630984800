<template>
    <div class="w-full bg-white drop-shadow-md rounded-lg mb-3">
        <div class="bg-gray-400 md:h-52 h-40 rounded-t-lg p-4 bg-no-repeat bg-center bg-cover" :style="`background-image: url(${product_item.images[0].replace('upload/', 'upload/c_fill,h_300,w_300/')})`">
        </div>
        <!-- <img class="object-cover rounded-tl-lg rounded-tr-lg" :src="item.images[0].replace('upload/', 'upload/c_fill,h_300,w_300/')" /> -->
        <div class="md:px-5 px-4 md:py-3 py-2">
            <h3 class="md:text-md text-sm font-proxima">{{ product_item.name.slice(0, 18) + (product_item.name.length > 18 ? "..." : "") }}</h3>
            <p class="space-x-2 flex justify-between">
                <span class="md:text-md text-sm font-proxima font-semibold text-yellow-500">{{ formatPriceRange(product_item.variants) }}</span>
                <span v-if="product_item.variants.reduce((total, value) => { return Number(total) + Number(value.stock.stock) }, 0) === 0" class="px-3 py-0.5 border border-red-500 text-[11px] text-red-500">Stok Habis</span>
            </p>
            <div class="flex justify-between items-center pt-4">
                <div class="flex items-center md:space-x-1 space-x-0.5">
                    <a :href="`/shopee/edit-product/${product_item.slug}${$route.query.redirect_from && $route.query.redirect_from == 'selling_marketplace' ? '?redirect_from=selling_marketplace' : ''}`" target="_blank">
                        <img src="@/assets/images/logo_mp/icon/shopee.png" width="27" />
                    </a>
                    <a :href="`/tiktokshop/edit-product/${product_item.slug}${$route.query.redirect_from && $route.query.redirect_from == 'selling_marketplace' ? '?redirect_from=selling_marketplace' : ''}`" target="_blank">
                        <img src="@/assets/images/logo_mp/icon/tiktok.png" width="27" />
                    </a>
                    <a :href="`/lazada/edit-product/${product_item.slug}${$route.query.redirect_from && $route.query.redirect_from == 'selling_marketplace' ? '?redirect_from=selling_marketplace' : ''}`" target="_blank">
                        <img src="@/assets/images/logo_mp/icon/lazada.png" width="27" />
                    </a>
                </div>
                <div class="flex items-center md:space-x-1 space-x-0.5">
                    <button @click.stop="addToCart(product_item)" class="bg-yellow-500 text-white rounded-full p-1.5 md:block hidden">
                        <svg class="md:w-4 md:h-4 w-3.5 h-3.5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M21.822 7.431A1 1 0 0 0 21 7H7.333L6.179 4.23A1.994 1.994 0 0 0 4.333 3H2v2h2.333l4.744 11.385A1 1 0 0 0 10 17h8c.417 0 .79-.259.937-.648l3-8a1 1 0 0 0-.115-.921z"></path><circle cx="10.5" cy="19.5" r="1.5"></circle><circle cx="17.5" cy="19.5" r="1.5"></circle>
                        </svg>
                    </button>
                    <button v-if="profileData.role === 'RESELLER'" @click="addProductToListingProduct(product_item._id)" class="bg-yellow-500 text-white rounded-full p-1.5">
                        <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M20.205 4.791a5.938 5.938 0 0 0-4.209-1.754A5.906 5.906 0 0 0 12 4.595a5.904 5.904 0 0 0-3.996-1.558 5.942 5.942 0 0 0-4.213 1.758c-2.353 2.363-2.352 6.059.002 8.412L12 21.414l8.207-8.207c2.354-2.353 2.355-6.049-.002-8.416z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="pt-3 pb-2">
                <button @click.stop="detailProduct(product_item)" class="mb-1 px-4 py-1.5 bg-gray-700 hover:bg-gray-800 text-center text-xs text-white font-medium rounded duration-300 w-full">
                    Detail Produk
                </button>

                <button v-if="profileData.role === 'RESELLER'" @click="$router.push('/my-form/add/' + product_item.slug)" class="px-4 py-1.5 bg-yellow-500 hover:bg-yellow-600 text-center text-xs text-white font-medium rounded duration-300 w-full">
                    Buat Form
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import formatPrice from '../../utils/formatPrice.js'

export default {
    props: {
        product_item: { 
            type: Object 
        },
        shopee_token: {
            type: Object
        },
        tiktok_token: {
            type: Object
        },
        lazada_token: {
            type: Object
        },
        profileData: {
            type: Object
        },
        vendorID: {
            type: String
        }
    },
    methods: {
        addProductToListingProduct(item_id) {
            this.$emit('addProductToListingProduct', item_id);
        },
        addToCart(item) {
            const data = {
                addToCartModal: true, 
                selectedProduct: item
            };

            this.$emit('addToCart', data);
        },
        detailProduct(item) {
            const data = {
                detailProductModal: true, 
                selectedProduct: item 
            }

            this.$emit('detailProduct', data);
        },
        /*
        * Other Helpers
        */
        formatPriceRange(variant) {
            const max_price = Math.max.apply(Math, this.convertVariantPriceToArray(variant));
            const min_price = Math.min.apply(Math, this.convertVariantPriceToArray(variant));

            if(max_price === min_price) {
                return 'Rp' + this.formatPrice(max_price);
            }else{
                return `Rp ${this.formatPrice(min_price)} - Rp ${this.formatPrice(max_price)}`;
            }
        },
		convertVariantPriceToArray(variant) {
            var array = [];

            for(let i=0; i < variant.length; i++){
                array.push(variant[i].price);
            }

            return array;
		},
        formatPrice
    }
}
</script>