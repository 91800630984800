import axios from 'axios'
import { API_URL } from '@/config.js' // Import API URL

export default {
    namespaced: true,
    state: {
        token: null,
        user: {}
    },
    mutations: {
        auth_success (state, data) {
            state.token = data.token
            state.user = data.user
        },
        
        logout (state) {
            state.token = ''
            state.user = {}
        }
    },
    actions: {
        /**
         * Login Method.
         * 
         * @param {string}      email
         * @param {string}      password
         * 
         * @returns {Object}
         */
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                /* Konversi Inputan Objek ke Form Data */
                let user_data = {
                    email: user.email,
                    password: user.password
                }

                /* Check Login */
                axios.post(API_URL + '/api/auth/login', user_data)
                    .then(async res => {
                        /* Defini Data User dari Response Login */
                        const responseData = res.data;
                        let data = {
                            token: responseData.data.token,
                            user: responseData.data.user
                        };

                        /* Commit Data ke auth_success method di mutation Vuex */
                        commit('auth_success', data);

                        /* Beri Feedback Respon Data ke Client */
                        resolve(res);
                    })
                    .catch(err => {
                        /* Beri Feedback Respon Error ke Client */
                        reject(err);
                    })
            })
        },

        /**
         * Login Admin Method.
         * 
         * @param {string}      email
         * @param {string}      password
         * 
         * @returns {Object}
         */
         login_admin({commit}, user) {
            return new Promise((resolve, reject) => {
                /* Konversi Inputan Objek ke Form Data */
                console.log(user)
                let user_data = {
                    phone_number: user.phone_number,
                    verify_code: user.verify_code
                }

                /* Check Login */
                axios.post(API_URL + '/api/auth/login-admin', user_data)
                    .then(async res => {
                        /* Defini Data User dari Response Login */
                        const responseData = res.data;
                        let data = {
                            token: responseData.data.token,
                            user: responseData.data.user
                        };

                        /* Commit Data ke auth_success method di mutation Vuex */
                        commit('auth_success', data);

                        /* Beri Feedback Respon Data ke Client */
                        resolve(res);
                    })
                    .catch(err => {
                        /* Beri Feedback Respon Error ke Client */
                        reject(err);
                    })
            })
        },

        /**
         * Signup Method.
         * 
         * @param {string}      fullname
         * @param {string}      email
         * @param {string}      password
         * @param {string}      phone
         * @param {string}      role
         * 
         * @returns {Object}
         */
        register({commit}, user) {
            return new Promise((resolve, reject) => {
                axios.post(API_URL + '/api/auth/register', user)
                    .then(async res => {
                        /* Defini Data User dari Response Signup */
                        const responseData = res.data;
                        let data = {
                            token: responseData.data.token,
                            user: responseData.data.user
                        };

                        /* Commit Data ke auth_success method di mutation Vuex */
                        commit('auth_success', data);

                        /* Beri Feedback Respon Data ke Client */
                        resolve(res);
                    })
                    .catch(err => {
                        /* Beri Feedback Respon Error ke Client */
                        reject(err)
                    })
            })
        },

        /**
         * Login Method.
         * 
         * @param {string}      email
         * @param {string}      password
         * 
         * @returns {Object}
         */
        loginAsUser({commit}, data) {
            return new Promise((resolve, reject) => {
                /* Check Login */
                axios.post(API_URL + '/api/auth/login-as-user/', { token: data.token })
                    .then(async res => {
                        /* Defini Data User dari Response Login */
                        const responseData = res.data;
                        let data = {
                            token: responseData.data.token,
                            user: responseData.data.user
                        };

                        /* Commit Data ke auth_success method di mutation Vuex */
                        commit('auth_success', data);

                        /* Beri Feedback Respon Data ke Client */
                        resolve(res);
                    })
                    .catch(err => {
                        /* Beri Feedback Respon Error ke Client */
                        reject(err);
                    })
            })
        },

        /**
         * Get Profile Method.
         * 
         * @param {string}      fullname
         * @param {string}      email
         * @param {string}      password
         * @param {string}      phone
         * @param {string}      role
         * 
         * @returns {Object}
         */
        getProfile({commit}, data) {
            return new Promise((resolve, reject) => {
                axios.get(API_URL + '/api/auth/get-profile', {
                    headers: {
                        'Authorization': data.auth_token
                    }
                })
                    .then(async res => {
                        /* Defini Data User dari Response Signup */
                        const responseData = res.data;
                        let data = {
                            token: responseData.data.token,
                            user: responseData.data.user
                        };

                        /* Commit Data ke auth_success method di mutation Vuex */
                        commit('auth_success', data);

                        /* Beri Feedback Respon Data ke Client */
                        resolve(res);
                    })
                    .catch(err => {
                        console.log(err)
                        /* Beri Feedback Respon Error ke Client */
                        reject(err)
                    })
            })
        },

        /**
         * Logout Method
         */
        logout({commit}) {
            return new Promise((resolve) => {
                /* Commit ke mutation untuk perintah logout/kosongkan data Vuex */
                commit('logout')

                // Feedback ke Client
                resolve()
            })
        }
    },
    getters: {
        isLoggedIn (state) {
            return !!state.token
        },
        
        userProfile (state) {
            return state.user
        }
    }
}