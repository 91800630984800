<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu :total_item="total_item" />
        <section v-if="loading" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!----------------->
            <!-- Page header -->
            <!----------------->
            <div class="bg-white w-full px-5 py-4 rounded flex items-center space-x-3">
                <!-- Search form -->
                <z-input placeholder="Cari produk..." v-model="search" full class="w-4/5"></z-input>
                <!-- Button -->
                <button @click="tableInputSearchHandler" class="bg-yellow-500 hover:bg-yellow-600 px-3 py-2 text-white font-proxima rounded border border-yellow-600 w-1/5">Cari</button>
            </div>

            <!-- <div class="w-full mt-6 rounded grid grid-cols-4 gap-3">
                <button @click="categorySelected = '62ac989536e76a24f4245ae1'; $router.push('/product/list'); getProduct(1, limit)" class="bg-white hover:bg-gray-50 flex items-center px-6 py-4 space-x-3 rounded">
                    <img src="@/assets/images/category/fashion.png" width="42" />
                    <h4 class="font-proxima font-semibold text-left text-sm">Fashion<br/>Fullfilment</h4>
                </button>
                <button class="bg-white flex items-center px-6 py-4 space-x-3 rounded">
                    <img src="@/assets/images/category/homeliving.png" width="42" class="grayscale" />
                    <h4 class="font-proxima font-semibold text-left text-sm">Home Living<br/>Fullfilment</h4>
                </button>
                <button class="bg-white flex items-center px-6 py-4 space-x-3 rounded">
                    <img src="@/assets/images/category/toys.png" width="42" class="grayscale" />
                    <h4 class="font-proxima font-semibold text-left text-sm">Toys & Hobbies<br/>Fullfilment</h4>
                </button>
                <button @click="categorySelected = '62dfa38f455ffa001b55889f'; $router.push('/product/list'); getProduct(1, limit)" class="bg-white hover:bg-gray-50 flex items-center px-6 py-4 space-x-3 rounded">
                    <img src="@/assets/images/category/beauty.png" width="42" />
                    <h4 class="font-proxima font-semibold text-left text-sm">Beauty & Health<br/>Fullfilment</h4>
                </button>
            </div> -->
            <!--------------------->
            <!-- End Page header -->
            <!--------------------->

            <!------------------>
            <!-- Product Card -->
            <!------------------>
            <div class="grid md:grid-cols-5 grid-cols-2 gap-2 mt-8">
                <div class="w-full bg-white drop-shadow-md rounded-lg mb-3" v-for="(item, index) in data.data" :key="index">
                    <div class="bg-gray-400 md:h-52 h-40 rounded-t-lg p-4 bg-no-repeat bg-center bg-cover" :style="`background-image: url(${item.images[0].replace('upload/', 'upload/c_fill,h_300,w_300/')})`">
                    </div>
                    <!-- <img class="object-cover rounded-tl-lg rounded-tr-lg" :src="item.images[0].replace('upload/', 'upload/c_fill,h_300,w_300/')" /> -->
                    <div class="md:px-5 px-4 md:py-3 py-2">
                        <h3 class="md:text-md text-sm font-proxima">{{ item.name.slice(0, 18) + (item.name.length > 18 ? "..." : "") }}</h3>
                        <p class="space-x-2 flex justify-between">
                            <span class="md:text-md text-sm font-proxima font-semibold text-yellow-500">{{ formatPriceRange(item.variants) }}</span>
                            <span v-if="item.variants.reduce((total, value) => { return Number(total) + Number(value.stock.stock) }, 0) === 0" class="px-3 py-0.5 border border-red-500 text-[11px] text-red-500">Stok Habis</span>
                        </p>
                        <div class="flex justify-between items-center pt-4">
                            <div class="flex items-center md:space-x-1 space-x-0.5">
                                <a :href="`/shopee/edit-product/${item.slug}${$route.query.redirect_from && $route.query.redirect_from == 'selling_marketplace' ? '?redirect_from=selling_marketplace' : ''}`" target="_blank">
                                    <img src="@/assets/images/logo_mp/icon/shopee.png" width="27" />
                                </a>
                                <a :href="`/tiktokshop/edit-product/${item.slug}${$route.query.redirect_from && $route.query.redirect_from == 'selling_marketplace' ? '?redirect_from=selling_marketplace' : ''}`" target="_blank">
                                    <img src="@/assets/images/logo_mp/icon/tiktok.png" width="27" />
                                </a>
                                <a :href="`/lazada/edit-product/${item.slug}${$route.query.redirect_from && $route.query.redirect_from == 'selling_marketplace' ? '?redirect_from=selling_marketplace' : ''}`" target="_blank">
                                    <img src="@/assets/images/logo_mp/icon/lazada.png" width="27" />
                                </a>
                            </div>
                            <div class="flex items-center md:space-x-1 space-x-0.5">
                                <button @click.stop="addToCartModal = true; selectedProduct = item" class="bg-yellow-500 text-white rounded-full p-1.5 md:block hidden">
                                    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                                        <path d="M21.822 7.431A1 1 0 0 0 21 7H7.333L6.179 4.23A1.994 1.994 0 0 0 4.333 3H2v2h2.333l4.744 11.385A1 1 0 0 0 10 17h8c.417 0 .79-.259.937-.648l3-8a1 1 0 0 0-.115-.921z"></path><circle cx="10.5" cy="19.5" r="1.5"></circle><circle cx="17.5" cy="19.5" r="1.5"></circle>
                                    </svg>
                                </button>
                                <button v-if="profileData.role === 'RESELLER'" @click="addProductToListingProduct(item._id)" class="bg-yellow-500 text-white rounded-full p-1.5">
                                    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                                        <path d="M20.205 4.791a5.938 5.938 0 0 0-4.209-1.754A5.906 5.906 0 0 0 12 4.595a5.904 5.904 0 0 0-3.996-1.558 5.942 5.942 0 0 0-4.213 1.758c-2.353 2.363-2.352 6.059.002 8.412L12 21.414l8.207-8.207c2.354-2.353 2.355-6.049-.002-8.416z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="pt-3 pb-2">
                            <button @click.stop="detailProductModal = true; selectedProduct = item" class="mb-1 px-4 py-1.5 bg-gray-700 hover:bg-gray-800 text-center text-xs text-white font-medium rounded duration-300 w-full">
                                Detail Produk
                            </button>

                            <button v-if="profileData.role === 'RESELLER'" @click="$router.push('/my-form/add/' + item.slug)" class="px-4 py-1.5 bg-yellow-500 hover:bg-yellow-600 text-center text-xs text-white font-medium rounded duration-300 w-full">
                                Buat Form
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!---------------------->
            <!-- End Product Card -->
            <!---------------------->

            <!---------------->
            <!-- Pagination -->
            <!---------------->
            <div class="mt-8">
                <z-pagination 
                    :meta="data.meta"
                    :PageNavHandler="pageNavHandler"
                    :pageNavNumberHandler="pageNavNumberHandler"
                />
            </div>
            <!-------------------->
            <!-- End Pagination -->
            <!-------------------->
            
            <!-- Confirm Delete Modal -->
            <ZModalAction id="danger-modal" :modalOpen="confirmDeleteModal" @close-modal="confirmDeleteModal = false">
                <div class="flex space-x-4">
                    <!-- Icon -->
                    <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-red-100">
                        <svg class="w-4 h-4 shrink-0 fill-current text-red-500" viewBox="0 0 16 16">
                            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                        </svg>
                    </div>
                    <!-- Content -->
                    <div>
                        <!-- Modal header -->
                        <div class="mb-2">
                            <div class="text-lg font-semibold text-gray-800 font-proxima">Hapus Data Produk</div>
                        </div>
                        <!-- Modal content -->
                        <div class="text-sm mb-10 font-proxima">
                            <div class="space-y-2">
                                <p>Anda yakin ingin menghapus data produk ini?<br/> Data akan dihapus secara permanen. Tindakan ini tidak bisa dibatalkan.</p>
                            </div>
                        </div>
                        <!-- Modal footer -->
                        <div class="flex flex-wrap justify-end space-x-2">
                            <button class="px-3 py-1.5 text-sm rounded border-gray-200 hover:border-gray-300 text-gray-600 font-proxima" @click.stop="confirmDeleteModal = false">Batal</button>
                            <button @click="deleteMutiple ? handleMutipleDelete() :  handleDelete()" class="px-3 py-1.5 text-sm rounded bg-red-500 hover:bg-red-600 text-white font-proxima">Ya, Hapus Sekarang</button>
                        </div>
                    </div>
                </div>
            </ZModalAction>
            <ZDetailProduct v-if="detailProductModal === true" :product="selectedProduct" :modalOpen="detailProductModal" @closeModal="detailProductModal = false"></ZDetailProduct>
            <ZAddToCart v-if="addToCartModal === true" :product="selectedProduct" :modalOpen="addToCartModal" @closeModal="addToCartModal = false" @updateCart="getTotalCartItems"></ZAddToCart>
            <!-- End Content Area -->
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatPrice from '../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
//import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
import ZInput from '@/components/common/ZInput.vue';
//import ZButton from '@/components/common/ZButton.vue';
//import ZDropdown from '@/components/common/ZDropdown.vue';
import ZModalAction from '@/components/common/ZModalAction.vue';
import ZDetailProduct from '@/components/special/ZDetailProduct.vue';
import ZAddToCart from '@/components/special/ZAddToCart.vue';
//import ZSelect from '@/components/common/ZSelect.vue';

export default {
    components: {
        NavigationMenu,
        //ZTable,
        ZPagination,
        ZInput,
        //ZButton,
        //ZDropdown,
        ZModalAction,
        ZDetailProduct,
        ZAddToCart,
        //ZSelect
    },
    data() {
        return {
            /* Core Data */
			data: [],
            selectedItems: [],
            dataSelected: null,
            deleteMutiple: false,
            selectedProduct: '',
			/* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'images',
                    label: 'Gambar',
                    visibility: true,
                },
                {
                    id: 2,
                    field: 'name',
                    label: 'Nama Produk',
                    visibility: true,
                    sort: true
                },
                {
                    id: 3,
                    field: 'sku',
                    label: 'SKU',
                    visibility: true,
                    sort: true
                },
                {
                    id: 4,
                    field: 'price',
                    label: 'Harga',
                    visibility: true,
                },
                {
                    id: 5,
                    field: 'stock',
                    label: 'Stok',
                    visibility: true,
                },
                {
                    id: 6,
                    field: 'vendor',
                    label: 'Asal Pengiriman/Gudang',
                    visibility: true,
                },
                // {
                //     id: 7,
                //     field: 'isActive',
                //     label: 'Status',
                //     visibility: true,
                // },
                {
                    id: 8,
                    field: 'action',
                    label: 'Action',
                    visibility: true,
                }
			],
			/* Toggle */
			sidebarOpen: false,
            loading: false,
            confirmDeleteModal: false,
            detailProductModal: false,
            addToCartModal: false,
			/* Pagination */
			page: 1,
			limit: 15,
			/* Filter */
			search: '',
            sort_by: {'createdAt': -1},
            sort_order: -1,
            /* Filter Category */
            categorySelected: null,
            categoryOptions: [],
            /* Extra */
            total_item: 0,
            shopee_token: null,
            tiktok_token: null,
            lazada_token: null,
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        if(this.$route.query.search) {
            this.search = this.$route.query.search;
            this.getProduct(1, this.limit);
        }else{
            /* Pagination via URL */
            if(this.$route.query.page) {
                this.page = this.$route.query.page;
            }
            this.getProduct(this.page, this.limit);
        }
        this.getCategory();
        /* Get Auth Token Platform */
        // this.checkShopeeTokenLogin();
        // this.checkTiktokShopTokenLogin();
        // this.checkLazadaTokenLogin();
	},
	methods: {
		/*
		* Get List Product
		*/
		async getProduct(page, limit) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                sort: JSON.stringify(this.sort_by),
                vendor: this.$route.params.group
            };

            // if(this.profileData.role === 'VENDOR') {
            //     params.user = this.profileData.id;
            // }

            // if(this.categorySelected !== null) {
            //     params.category = this.categorySelected
            // }

			try {
                const response = await axios.get(API_URL + '/api/product/get-all', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
			} catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.$router.push(`/product/list-group/${this.$route.params.group}?page=${Number(this.page) - 1}`);
                    this.page -= 1;
                    this.getProduct(this.page, this.limit);
                break;
                case 'next':
                    this.$router.push(`/product/list-group/${this.$route.params.group}?page=${Number(this.page) + 1}`);
                    this.page += 1;
                    this.getProduct(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.$router.push(`/product/list-group/${this.$route.params.group}?page=${pageNumber}`);
            this.page = pageNumber;
            this.getProduct(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler() {
            // const value = event.target.value; // ambil value dari form input search

            // if (value === '') {
            //     this.search = ''; // Mengkosongkan kembali state querynya
            //     this.getProduct(this.page, this.limit);
            // } else {
            //     this.search = value;
            //     this.getProduct(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            // }
            if (this.search === '') {
                this.$router.push(`/product/list-group/${this.$route.params.group}`)
                //this.search = ''; // Mengkosongkan kembali state querynya
                this.getProduct(this.page, this.limit);
            } else {
                this.$router.push(`/product/list-group/${this.$route.params.group}?search=${this.search}`)
                //this.search = value;
                this.getProduct(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

		/*
		 * Update Selected Items
		 */
		async updateSelectedItems(selected) {
			this.selectedItems = selected;
		},

        /*
		 * Delete Product
		 */
        /* Handle Delete Product */
        async handleDelete(id = null) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const product_id = id ? id : this.dataSelected;
                await axios.delete(API_URL + '/api/product/delete/' + product_id, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Refresh Page */
                window.location.reload();
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal dihapus!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
            }
        },
        /* Handle Mutiple Delete */
        handleMutipleDelete() {
            // Looping dan Eksekusi penghapusan data
            for(let i = 0; i < this.selectedItems.length; i++){
                this.handleDelete(this.selectedItems[i]._id);
            }
        },

        /*
		 * Sorting Handler
		 */
        sortingHandler(value) {
            if(this.sort_order === -1){
                this.sort_order = 1;
            }else{
                this.sort_order = -1;
            }

            this.sort_by = { [value]: this.sort_order };
            this.getProduct(this.page, this.limit);
        },

		/*
		 * Approval Product
		 */
        async approveProduct(id) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                await axios.put(`${API_URL}/api/product/approve/${id}`, {}, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Refresh Page */
                window.location.reload();
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal disetujui!",
                    position: "bottom",
                    type: "error"
                });

                alert(error)
				console.error(error);
            }
        },

        /*
         * Get Category
         */
        async getCategory() {
            try {
                const response = await axios.get(`${API_URL}/api/category/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.categoryData = response.data;

                for(let i=0; i < this.categoryData.length; i++){
                    this.categoryOptions.push({
                        label: this.categoryData[i].name,
                        value: this.categoryData[i]._id
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },

        /*
         * Get Total Cart Items
         * Desc: Ambil Data Item Keranjang Belanja
         */
        async getTotalCartItems() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });
            
            try {
                const response = await axios.get(`${API_URL}/api/cart/get-cart`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                this.total_item = response.data.data.cart_items.length > 0 ? response.data.data.cart_items.reduce((total, item) => { return Number(total) + Number(item.quantity) }, 0) : 0;
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                console.log(error)
            }
        },

        /*
		 * Add Product To Listing Product
		 */
        async addProductToListingProduct(product) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let data = {
                    product: product,
                    user: this.profileData.id
                };

                await axios.post(`${API_URL}/api/my-product/add`, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Refresh Page */
                this.$toast.success('Produk Telah Ditambahkan Ke Listing Produk Anda', {duration: 3000});
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                /* Show Danger Alert */
                this.$notify({
                    title: "Gagal",
                    text: "Data gagal disetujui!",
                    position: "bottom",
                    type: "error"
                });

				console.error(error);
            }
        },

        /*
         * Check Shopee Token Login
         */
        async checkShopeeTokenLogin() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.shopee_token = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /*
         * Check Tiktok Shop Token Login
         */
        async checkTiktokShopTokenLogin() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/tiktokshop/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.tiktok_token = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /*
         * Check Lazada Token Login
         */
        async checkLazadaTokenLogin() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/lazada/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.lazada_token = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

       /*
        * Other Helpers
        */
        formatPriceRange(variant) {
            const max_price = Math.max.apply(Math, this.convertVariantPriceToArray(variant));
            const min_price = Math.min.apply(Math, this.convertVariantPriceToArray(variant));

            if(max_price === min_price) {
                return 'Rp' + this.formatPrice(max_price);
            }else{
                return `Rp ${this.formatPrice(min_price)} - Rp ${this.formatPrice(max_price)}`;
            }
        },
		convertVariantPriceToArray(variant) {
            var array = [];

            for(let i=0; i < variant.length; i++){
                array.push(variant[i].price);
            }

            return array;
		},
        formatPrice
	}
}
</script>

<style scoped>
</style>