<template>
    <SettingPanel>
        <div class="bg-white rounded border border-gray-300 p-6 md:w-2/4 w-full">
            <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Pengaturan Akun Dropship</h2>

            <!-- Info Affiliasi -->
            <div class="bg-white rounded border border-gray-300 p-6 w-full mt-6" v-if="profileData.role === 'RESELLER'">
                <h2 class="text-lg text-gray-700 font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Toko Dropship</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">URL Toko <span class="text-xs text-red-600">(Wajib Diisi!)</span> <span class="text-xs text-gray-600">contoh: https://form.satustock.com/<span class="font-bold text-yellow-600">{{ reseller_url !== '' ? reseller_url : 'url-anda' }}</span></span></label>
                    <z-input v-model="reseller_url" full @input="resellerURLFormatter"></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Toko <span class="text-xs text-red-600">(Wajib Diisi!)</span></label>
                    <z-input v-model="store_name" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">URL Logo Toko <span class="text-xs text-gray-400">(Opsional)</span></label>
                    <z-input v-model="store_logo" full></z-input>
                </div>
                <div class="mb-0">
                    <label class="font-proxima font-semibold block">No Handphone Toko <span class="text-xs text-gray-400">(Opsional)</span></label>
                    <z-input v-model="store_phone" full></z-input>
                </div>
            </div>

            <!-- Info Bank -->
            <div class="bg-white rounded border border-gray-300 p-6 w-full mt-6">
                <h2 class="text-lg text-gray-700 font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Bank untuk Pencairan Dana <span class="text-xs text-gray-600">*Bisa diisi nanti</span></h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Bank</label>
                    <z-input v-model="bank_name" full></z-input>
                </div>
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Pemilik Rekening</label>
                    <z-input v-model="bank_account_name" full></z-input>
                </div>
                <div class="mb-0">
                    <label class="font-proxima font-semibold block">Nomor Rekening</label>
                    <z-input v-model="bank_account_number" full></z-input>
                </div>
            </div>

            <z-button @click="updateUser" class="bg-yellow-600 hover:bg-yellow-700 text-white px-8 py-2 w-full mt-8">Simpan</z-button>
        </div>
    </SettingPanel>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL

import SettingPanel from '@/components/special/SettingPanel.vue';

import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        ZInput,
        ZButton,
        SettingPanel
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Reseller */
            reseller_url: '',
            store_name: '',
            store_logo: '',
            store_phone: '',
            /* Bank Information */
            bank_name: '',
            bank_account_name: '',
            bank_account_number: '',
            /* Basic Information */
            /* Toggle */
            loading: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getUserById();
	},
    methods: {
        /*
         * Get User By Id
         */
        async getUserById () {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/user/get/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                this.reseller_url = response.data.data.reseller_url ? response.data.data.reseller_url : '';
                this.store_name = response.data.data.store_name ? response.data.data.store_name : '';
                this.store_logo = response.data.data.store_logo ? response.data.data.store_logo : '';
                this.store_phone = response.data.data.store_phone ? response.data.data.store_phone : '';

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

        /*
         * Update Pengguna
         */
        async updateUser () {
            /* Validasi Form Update */
            if(this.reseller_url === '') {
                this.$toast.error('Url toko wajib diisi!', {duration: 3000});
                return;
            }
            if(this.store_name === '') {
                this.$toast.error('Nama toko wajib diisi!', {duration: 3000});
                return;
            }

            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Reseller */
                reseller_url: this.reseller_url,
                store_name: this.store_name,
                store_logo: this.store_logo,
                store_phone: this.store_phone,
                /* Bank Information */
                bank_name: this.bank_name,
                bank_account_name: this.bank_account_name,
                bank_account_number: this.bank_account_number,
                /* Update Type */
                update_type: 'account-dropship'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                if(this.profileData.guide_form_order.setup_dropshipper_account === false && this.profileData.guide_manual_order.setup_dropshipper_account === false) {
                    if(this.$route.query.redirect_from == 'selling_form_order') {
                        this.$router.push('/selling/form-order');
                    }else if(this.$route.query.redirect_from == 'selling_manual_order') {
                        this.$router.push('/selling/manual-order');
                    }
                }

                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        this.$toast.success('Perubahan Berhasil Disimpan!', {duration: 3000});
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },
        /* Helpers */
        resellerURLFormatter(event) {
            this.reseller_url = event.target.value.replace(/[^a-zA-Z_-]/g, '').toLowerCase();
        }
    }
}
</script>

<style scoped>
</style>