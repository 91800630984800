<template>
    <div>
        <div class="bg-white border border-gray-200" v-for="(item, index) in variantInput" :key="index" :class="variantInput && variantInput.length > 1 ? 'mb-1.5' : ''">
            <!-- Header -->
            <div class="flex items-center py-2 px-3 border-b border-gray-200">
                <h4 class="font-semibold font-proxima">{{ item.header_title }}</h4>
                <div class="ml-auto">
                    <!-- Button Hapus Varian + Sinkron dengan Data Produk Varian -->
                    <z-button @click="removeRowVariantInput" class="hover:text-red-500 flex items-center">
                        <svg class="ionicon w-5 h-5" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Close</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M368 368L144 144M368 144L144 368'/></svg>
                    </z-button>
                </div>
            </div>
            <div class="px-3 py-3">
                <!-- Variasi -->
                <div class="mb-3">
                    <label class="font-proxima">Nama Variasi</label>
                    <z-input v-model="item.variant_name" placeholder="Contoh: Warna/Ukuran" full @input="generateProductVariant"></z-input>
                </div>
                <!-- Pilihan -->
                <label class="font-proxima">Pilihan</label>
                <div class="flex items-center mb-3" v-for="(option, i) in item.options" :key="i">
                    <z-input v-model="option.option_name" placeholder="Contoh: Merah/43" class="w-full" full @input="generateProductVariant"></z-input>
                    <button class="text-red-600 hover:text-red-700 ml-3" @click="removeRowVariantOptionInput(index, i)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current" style="transform: ;msFilter:;">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path><path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                        </svg>
                    </button>
                </div>
                <!-- Tombol Tambah Pilihan -->
                <z-button @click="addRowVariantOptionInput(index)" class="flex items-center text-white button-diginesia w-full justify-center">
                    <svg class="ionicon w-5 H-5" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Add Circle</title><path d='M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z' fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='32'/><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M256 176v160M336 256H176'/></svg>
                    <span class="ml-2">Tambah Pilihan</span>
                </z-button>
            </div>
        </div>
        <!-- Tombol Tambah Variasi -->
        <z-button @click="addRowVariantInput" class="flex items-center text-white button-diginesia w-full justify-center mt-5">
            <svg class="ionicon w-5 H-5" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Add Circle</title><path d='M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z' fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='32'/><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M256 176v160M336 256H176'/></svg>
            <span class="ml-2">Tambah Variasi</span>
        </z-button>
    </div>
</template>

<script>
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';

/* External Library */
import fastCartesian from 'fast-cartesian'; // Panggil Library Fast Cartesian

export default {
    props: {
        variant_input: {
            type: Array
        },
    },
    components: {
        ZInput,
        ZButton
    },
    data() {
        return {
            product_variant: [],
            variantInput: this.variant_input,
        }
    },
    methods: {
        /*
         * Dynamic Form
         */
        /* Tambah Variasi */
        addRowVariantInput () {
            this.variantInput.push({
                header_title: 'Variasi 2', // Hanya bisa input 2 Variasi Produk
                variant_name: '',
                options: [{
                    option_name: ''
                }]
            });
        },
        /* Hapus Variant kedua */
        removeRowVariantInput () {
            this.variantInput.splice(1, 1);
            this.generateProductVariant(); // Lalu Generate Product Variant untuk setting harga dan stok produk variant
        },
        /* Tambah Pilihan Variasi */
        addRowVariantOptionInput (index) {
            this.variantInput[index]['options'].push({
                option_name: ''
            });
        },
        /* Hapus Pilihan Variasi */
        removeRowVariantOptionInput (variant_index, option_index) {
            this.variantInput[variant_index]['options'].splice(option_index, 1);
            this.generateProductVariant(); // Lalu Generate Product Variant untuk setting harga dan stok produk variant
        },
        /*
         * Generate Product Variant
         */
        generateProductVariant () {
            var product = []; // Container untuk tampung Semua Array Option

            /* Extract option/pilihan dari Object menjadi Array untuk di proses di Cartesian */
            for(let i=0; i < this.variantInput.length; i++){
                var box = []; // Container untuk tampung Array Option Satuan
                for(let x=0; x < this.variantInput[i].options.length; x++){
                    box.push(this.variantInput[i].options[x].option_name); // Proses Extract Option lalu tampung di variable array box
                }
                product.push(box); // Masukkan Semua Array ke variable product
            }

            /* Proses Cartesian (menggabungkan 2 array atau lebih) */
            var productCartesian = fastCartesian(product);
            
            /* Generate Final Product Variant */
            this.product_variant = []; // Kosongkan dulu product variant
            for(let x=0; x < productCartesian.length; x++){
                this.product_variant.push({
                    sku: '',
                    price: '',
                    stock: 0,
                    options: productCartesian[x]
                });
            }

            /* Push Product Variant ke V-Model */
            this.$emit('update:modelValue', this.product_variant);
            this.$emit('getVariantInput', this.variantInput);
        },
    }
}
</script>