<template>
    <ZModal id="detail-product-modal" title="Edit Pesanan" :modalOpen="modalOpen" size="max-w-2xl" @close-modal="this.$emit('closeModal')">
        <div class="p-6" v-if="order">

            <!-- Detail Pesanan -->
            <h4 class="font-proxima font-bold mb-2">Detail Pesanan</h4>
            <div class="mb-8 space-y-1">
                <div class="flex items-center justify-between">
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">No Invoice</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ order.invoice }}</h1>
                </div>
                <div class="flex items-center justify-between">
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Tanggal Pembelian</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ formatDate(order.createdAt) }} - {{ formatTime(order.createdAt) }} WIB</h1>
                </div>
                <div class="flex items-center justify-between">
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Tipe Pesanan</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ order.order_type === 'direct' ? 'Belanja Langsung/Resi Otomatis' : 'Form Order' }}</h1>
                </div>
            </div>

            <!-- Info Produk -->
            <h4 class="font-proxima font-bold mb-2">Info Produk</h4>
            <div class="w-full flex items-center mb-8">
                <img :src="order.items[0].product.images[0]" class="border border-gray-200 rounded mr-4" width="120" />
                <div>
                    <h4 class="font-semibold font-proxima">{{ order.items[0].product.name }}</h4>
                    <h4 class="text-gray-700 font-medium font-proxima text-xs mt-2">Pengiriman dari {{ order.items[0].product.vendor.city }}</h4>
                    <!-- Mobile -->
                    <div>
                        <h3 class="text-gray-700 font-proxima font-medium text-sm">Rp{{ formatPrice(order.items[0].product.variants[0].price) }}</h3>
                    </div>
                </div>
            </div>

            <!-- Product Model -->
            <div class="mb-8" v-if="data_model && data_model.length > 0">
                <h4 class="font-proxima font-bold mb-2">Item Pesanan</h4>
                <div class="relative overflow-x-auto border border-gray-300">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    SKU & Variasi
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Harga
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Stok
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Qty
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in data_model" :key="index" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 dark:text-white whitespace-nowrap">
                                    <h3 class="font-semibold text-gray-900">{{ item.sku }}</h3>
                                    <h4 class="font-medium text-gray-600 text-sm">{{ item.variant }}</h4>
                                </th>
                                <td class="px-6 py-4">
                                    Rp{{ formatPrice(item.sell_price) }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ item.stock }}
                                </td>
                                <td class="px-6 py-4">
                                    <div class="custom-number-input h-8 w-24">
                                        <div class="flex flex-row h-8 w-full rounded-lg relative bg-transparent mt-1">
                                            <button @click="item.qty--" :disabled="item.qty < 1" data-action="decrement" class="border border-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-16 cursor-pointer outline-none rounded-l">
                                                <span class="m-auto text-xl" style="line-height: 15px">−</span>
                                            </button>
                                            <input v-model="item.qty" type="number" class="border-t border-b border-gray-200 outline-none focus:outline-none text-center w-full font-semibold text-sm hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700" name="custom-input-number">
                                            <button @click="item.qty++" data-action="increment" class="border border-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-16 cursor-pointer rounded-r">
                                                <span class="m-auto text-xl" style="line-height: 15px">+</span>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Metode Pembayaran -->
            <div class="mb-8 w-1/2">
                <label class="font-proxima font-semibold block mb-1">Metode Pembayaran</label>
                <z-select v-model="payment_method" label="Metode Pembayaran" :options="payment_method_options" full @changeData="selectPaymentMethod"></z-select>
            </div>

            <!-- Rincian Tagihan -->
            <h2 class="font-proxima font-semibold block mb-1 mt-8">Ringkasan Tagihan</h2>
            <div class="flex items-center justify-between">
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Total Harga ({{ total_quantity }} Barang)</h4>
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Rp{{ formatPrice( total_price ) }}</h4>
            </div>
            <div class="flex items-center justify-between">
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Total Ongkos Kirim</h4>
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Rp{{formatPrice( shipping_fee )}}</h4>
            </div>
            <div class="flex items-center justify-between">
                <h4 class="text-green-500 font-semibold font-proxima text-sm">Diskon Ongkos Kirim</h4>
                <h4 class="text-green-500 font-semibold font-proxima text-sm">Rp{{formatPrice(Number(order.shipping_fee_discount !== null ? order.shipping_fee_discount : 0))}}</h4>
            </div>
            <div class="flex items-center justify-between">
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Biaya Penanganan</h4>
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Rp{{formatPrice( admin_fee )}}</h4>
            </div>
            
            <hr class="w-full height-0.25 bg-gray-200 my-5" />
            <div class="flex items-center justify-between">
                <h4 class="text-gray-600 font-semibold font-proxima text-sm">Total Tagihan</h4>
                <h4 class="font-semibold font-proxima text-sm">Rp{{ formatPrice( grand_total ) }}</h4>
            </div>

            <!-- Modal footer -->
            <div class="mt-8">
                <button @click="updateOrder" class="bg-yellow-500 hover:bg-yellow-600 px-4 py-2 text-white text-center font-semibold font-proxima rounded w-full">Simpan Pesanan</button>
            </div>

        </div>
    </ZModal>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatPrice from '../../utils/formatPrice.js'
import formatDate from '../../utils/dateFormat.js'
import ZModal from '@/components/common/ZModal.vue';
import ZSelect from '@/components/common/ZSelect.vue';

export default {
    props: ['modalOpen', 'order'],
    components: {
        ZModal,
        ZSelect
    },
    data() {
        return {
            /* Shipping */
            data_model: [],
            /* Payment */
            admin_fee: 0,
            unique_code: null,
            payment_method: '',
            payment_method_options: [{
                label: 'Bank Transfer',
                value: 'Bank Transfer'
            },{
                label: 'COD',
                value: 'COD'
            }]
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        },
        total_price() {
            return this.data_model.reduce((total, item) => { return Number(total) + Number(item.sell_price * item.qty) }, 0);
        },
        total_quantity() {
            return this.data_model.reduce((total, item) => { return Number(total) + Number(item.qty) }, 0);
        },
        grand_total() {
            return this.total_price + this.shipping_fee + this.admin_fee;
        }
	},
    created() {
        this.getMyFormById();
    },
    methods: {
        /*
         * Get My Form By Id
         */
        async getMyFormById () {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/my-form/get/${this.order.form._id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                this.payment_method = this.payment_method_options.find(item => item.value === this.order.payment_method);
                this.admin_fee = this.order.admin_fee;
                this.shipping_fee = this.order.shipping_fee;
                this.data_model = response.data.data.custom_price.map(item => {
                    return {
                        sku: item.sku,
                        variant: item.options.toString().replace(',', ' '),
                        sell_price: item.sell_price,
                        price: item.price,
                        margin: item.margin,
                        qty: 0,
                        stock: item.stock
                    }
                });

                this.order.items.forEach(async (item) => {
                    const findVariantIndex = await this.data_model.findIndex(val => val.sku === item.variant.sku);
                    if(findVariantIndex !== -1) {
                        this.data_model[findVariantIndex].qty = item.quantity;
                    }
                })

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },

        /* Update Order */
        async updateOrder() {
            try {
                let filter_items = this.data_model.filter(item => {
                    return item.qty !== 0;
                });

                let items = filter_items.map(item => {
                    return {
                        vendor: this.order.items[0].vendor,
                        product: this.order.items[0].product,
                        variant: item,
                        quantity: item.qty,
                    }
                });

                const data = {
                    items: items,
                    payment_method: this.payment_method.value,
                    admin_fee: this.admin_fee,
                    total_payment: this.grand_total
                };

                await axios.put(`${API_URL}/api/order/update/${this.order._id}`, {order: data}, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                this.$emit('closeModal');
                window.location.reload();
            } catch (error) {
                console.log(error)
                this.$router.push('/auth/login')
            }
        },

        /*
         * Select Payment Method
         */
        selectPaymentMethod() {
            if(this.payment_method.value == 'Bank Transfer') {
                let total = 0;
                if((this.total_price + this.shipping_fee) < 75000) {
                    total = this.generateKodeUnik(100, 323);
                }else if((this.total_price + this.shipping_fee) >= 75000){
                    total = this.generateKodeUnik(324, 521)
                }
                this.unique_code = this.unique_code === null ? total : this.unique_code;
                this.admin_fee = this.unique_code !== null ? this.unique_code : total;
            }else if(this.payment_method.value == 'COD'){
                this.admin_fee = Math.round( (Number(this.total_price) + Number(this.shipping_fee)) * ( 3 / 100 ) );
            }else{
                this.admin_fee = 0;
            }
        },

        /* Helpers */
        formatPrice,
        formatDate,
        formatTime(date) {
            var format = date ? new Date(date).toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' }) : ''
            return format;
        },
        generateKodeUnik(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    }
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
-webkit-appearance: none;
    margin: 0;
}
</style>