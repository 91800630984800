<template>
    <SettingPanel>
        <div class="bg-white rounded border border-gray-300 p-6 md:w-2/4 w-full">
            <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Pilih Gudang Pengiriman</h2>

            <!-- Select Warehouse -->
            <div class="w-full space-y-2">
                <div v-for="(item, index) in vendorData" :key="index">
                    <div @click="selectWarehouse(item._id)" class="px-6 py-5 border border-gray-200 rounded flex items-center justify-between hover:bg-gray-50 cursor-pointer" :class="profileData.selected_warehouse && profileData.selected_warehouse === item._id ? 'bg-gray-50' : 'bg-white'">
                        <div>
                            <h2 class="text-base text-gray-800 font-bold font-proxima mb-1">{{ item.fulfillment_name }}</h2>
                            <h4 class="text-sm text-gray-600 font-medium font-proxima">Alamat: {{ item.address }}</h4>
                            <h4 class="text-sm text-gray-600 font-medium font-proxima">Kota: {{ item.city }}</h4>
                            <h4 class="text-sm text-gray-600 font-medium font-proxima">Provinsi: {{ item.province }}</h4>
                        </div>
                        <div class="text-yellow-500">
                            <svg v-if="profileData.selected_warehouse && profileData.selected_warehouse === item._id" class="fill-current w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </SettingPanel>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL

import SettingPanel from '@/components/special/SettingPanel.vue';

// import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        //ZButton,
        SettingPanel
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            vendorData: [],
            /* Toggle */
            loading: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getVendor();
	},
    methods: {
        /*
         * Get Vendor
         */
        async getVendor() {
            try {
                const response = await axios.get(`${API_URL}/api/vendor/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.vendorData = response.data;
            } catch (error) {
                console.error(error);
            }
        },

        async selectWarehouse (item) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Basic Information */
                selected_warehouse: item,
                /* Update Type */
                update_type: 'select-warehouse'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        if(this.$route.query.redirect_from == 'selling_marketplace') {
                            this.$router.push('/selling/marketplace')
                        }

                        this.$toast.success('Perubahan Berhasil Disimpan!', {duration: 3000});
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            console.log(err)
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
                
                // //this.$router.push('/');
                // this.$store.dispatch('auth/logout').then(() => {
                //     this.$router.push('/auth/login');
                // });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },
    }
}
</script>

<style scoped>
</style>