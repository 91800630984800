<template>
    <div class="p-8 bg-gray-50">
        <!----------------->
        <!-- Page header -->
        <!----------------->
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0">
                <h1 class="text-xl font-proxima text-gray-800 font-bold">Master Produk</h1>
            </div>

            <!-- Right: Actions  -->
            <div class="flex items-center">
                <!-- Search form -->
                <z-input placeholder="Cari produk..." v-model="search" @input="tableInputSearchHandler"></z-input>
            </div>
        </div>
        <!--------------------->
        <!-- End Page header -->
        <!--------------------->

        <!----------->
        <!-- Table -->
        <!----------->
        <div class="w-full">
            <z-table
                title="Produk"
                :data="data.data"
                :meta="data.meta"
                :columns="columns"
                :mutipleCheck="false"
                @updateSelectedItems="updateSelectedItems"
                :sorting="true"
                @sortingHandler="sortingHandler"
            >
                <template v-slot:name="{item}">
                    {{ item.name.slice(0, 40) + (item.name.length > 40 ? "..." : "") }}
                </template>
                <template v-slot:images="{item}">
                    <div :class="profileData.role !== 'ADMIN' && profileData.role !== 'VENDOR' ? 'px-3' : ''">
                        <img :src="item.images[0].replace('upload/', 'upload/c_fill,h_300,w_300/')" width="100" />
                    </div>
                </template>
                <template v-slot:price="{item}">
                    {{ formatPriceRange(item.variants) }}
                </template>
                <template v-slot:stock="{item}">
                    {{ item.variants.reduce((total, value) => { return Number(total) + Number(value.stock.stock) }, 0) }}
                </template>
                <template v-slot:vendor="{item}">
                    {{ item.vendor.city }}
                    <!-- <a class="text-sm text-blue-400 hover:text-blue-500" :href="`http://localhost:4000/order/${item.slug}`" target="_blank">Kunjungi Form</a> -->
                </template>
                <template v-slot:isActive="{item}">
                    {{ item.isActive ? 'Aktif' : 'Nonaktif' }}
                </template>
                <template v-slot:action="{item}">
                    <span class="hidden">{{item}}</span>
                    <!-- Action Button -->
                    <button @click="selectProduct(item.slug)" class="mt-4 text-sm bg-blue-500 hover:bg-blue-600 px-4 py-2 text-white text-center font-semibold font-dosis rounded w-full">Pilih Produk</button>
                </template>
            </z-table>
        </div>
        <!--------------->
        <!-- End Table -->
        <!--------------->

        <!---------------->
        <!-- Pagination -->
        <!---------------->
        <div class="mt-8">
            <z-pagination 
                :meta="data.meta"
                :PageNavHandler="pageNavHandler"
                :pageNavNumberHandler="pageNavNumberHandler"
            />
        </div>
        <!-------------------->
        <!-- End Pagination -->
        <!-------------------->
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatPrice from '../../utils/formatPrice.js'
// import formatDate from '../../utils/dateFormat.js'
// import ZModal from '@/components/common/ZModal.vue';
import ZTable from '@/components/common/ZTable.vue';
import ZPagination from '@/components/common/ZPagination.vue';
import ZInput from '@/components/common/ZInput.vue';

export default {
    //props: ['modalOpen', 'order'],
    components: {
        ZTable,
        ZPagination,
        ZInput
    },
    data() {
        return {
            /* Core Data */
			data: [],
            /* Column Options */
			columns: [
                {
                    id: 1,
                    field: 'images',
                    label: 'Gambar',
                    visibility: true,
                },
                {
                    id: 2,
                    field: 'name',
                    label: 'Nama Produk',
                    visibility: true,
                    sort: true
                },
                {
                    id: 3,
                    field: 'sku',
                    label: 'SKU',
                    visibility: true,
                    sort: true
                },
                {
                    id: 4,
                    field: 'price',
                    label: 'Harga',
                    visibility: true,
                },
                {
                    id: 5,
                    field: 'stock',
                    label: 'Stok',
                    visibility: true,
                },
                {
                    id: 6,
                    field: 'vendor',
                    label: 'Asal Pengiriman/Gudang',
                    visibility: true,
                },
                // {
                //     id: 7,
                //     field: 'isActive',
                //     label: 'Status',
                //     visibility: true,
                // },
                {
                    id: 8,
                    field: 'action',
                    label: 'Action',
                    visibility: true,
                }
			],
            /* Toggle */
            loading: false,
            /* Pagination */
			page: 1,
			limit: 10,
			/* Filter */
			search: '',
            sort_by: {'createdAt': -1},
            sort_order: -1,
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    created() {
        this.getProduct(this.page, this.limit);
    },
    methods: {
        /*
		* Get List Product
		*/
		async getProduct(page, limit) {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            let params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search,
                sort: JSON.stringify(this.sort_by)
            };

            if(this.profileData.role === 'VENDOR') {
                params.user = this.profileData.id;
            }

			try {
                const response = await axios.get(API_URL + '/api/product/get-all', {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                this.data = response.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
			} catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
				console.error(error);
			}
		},

        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getProduct(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getProduct(this.page, this.limit);
                break;
            }
        },
        /*
         * Pagination Number Handler Function
         */
        pageNavNumberHandler (pageNumber) {
            this.page = pageNumber;
            this.getProduct(this.page, this.limit);
        },

        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getProduct(this.page, this.limit);
            } else {
                this.search = value;
                this.getProduct(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },

        selectProduct(value) {
            this.$emit("getProduct", value);
        },
        
        /* Helpers */
        formatPriceRange(variant) {
            const max_price = Math.max.apply(Math, this.convertVariantPriceToArray(variant));
            const min_price = Math.min.apply(Math, this.convertVariantPriceToArray(variant));

            if(max_price === min_price) {
                return 'Rp' + this.formatPrice(max_price);
            }else{
                return `Rp ${this.formatPrice(min_price)} - Rp ${this.formatPrice(max_price)}`;
            }
        },
		convertVariantPriceToArray(variant) {
            var array = [];

            for(let i=0; i < variant.length; i++){
                array.push(variant[i].price);
            }

            return array;
		},
        formatPrice
    }
}
</script>

<style scoped>

</style>