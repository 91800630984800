<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Bank</h2>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Bank</label>
                    <z-input v-model="bank_name" full></z-input>
                </div>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Pemilik Rekening</label>
                    <z-input v-model="account_name" full></z-input>
                </div>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nomor Rekening</label>
                    <z-input v-model="account_number" full></z-input>
                </div>

                <div class="mt-8">
                    <z-button @click="createBank" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Simpan</z-button>
                </div>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        NavigationMenu,
        ZInput,
        ZButton
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            bank_name: '',
            account_name: '',
            account_number: '',
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }
	},
    methods: {
        /*
         * Create Bank
         */
        async createBank () {
            this.loadingToggle = true;

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Basic Information */
                bank_name: this.bank_name,
                account_name: this.account_name,
                account_number: this.account_number
            }

            try {
                await axios.post(API_URL + '/api/bank/save', form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loadingToggle = false;
                this.$router.push('/bank/list');
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;

                this.$notify({
                    title: "Gagal",
                    text: "Bank gagal disimpan!",
                    position: "bottom",
                    type: "error"
                });
            }
        },
    }
}
</script>

<style scoped>
</style>