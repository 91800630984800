<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Edit Produk</h2>

                <div class="w-full md:flex md:items-center mb-3" v-if="product_data !== null">
                    <img :src="product_data.images[0]" class="border border-gray-200 rounded mr-4 md:mb-0 mb-4 md:block hidden" width="120" />
                    <img :src="product_data.images[0]" class="border border-gray-200 rounded mr-4 md:mb-0 mb-4 w-full md:hidden" />
                    <div>
                        <h4 class="font-semibold font-proxima">{{ product_data.name }}</h4>
                        <h4 class="text-gray-700 font-medium font-proxima text-xs mt-2">Pengiriman dari {{ product_data.vendor.city }}</h4>
                        <!-- Mobile -->
                        <div>
                            <h3 class="text-gray-700 font-proxima font-medium text-sm">Rp{{ formatPrice(product_data.variants[0].price) }}</h3>
                        </div>
                    </div>
                </div>

                <div class="mb-3" v-if="shopee_auth_token.length > 0">
                    <label class="font-proxima font-semibold block">Pilih Toko Shopee</label>
                    <z-auto-complete-input-new v-model="shopee_token_selected" label="Toko Shopee" :options="shopeeAuthOptions" full @changeData="getShopeeCategory(); getShopeeLogistics()"></z-auto-complete-input-new>
                </div>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Produk</label>
                    <z-input v-model="product_name" full></z-input>
                </div>

                <div class="border border-gray-300 rounded p-6 mb-3">
                    <h4 class="font-proxima font-semibold block text-lg mb-5 pb-2 border-b border-gray-300">Kategori Shopee</h4>

                    <div class="mb-3">
                        <label class="font-proxima font-semibold block">Kategori 1</label>
                        <z-auto-complete-input-new v-model="category_1" label="Kategori Shopee" :options="categoryOptions1" full @changeData="getCategory2(); getShopeeAttribute()"></z-auto-complete-input-new>
                    </div>

                    <div class="mb-3" v-if="categoryOptions2.length > 0">
                        <label class="font-proxima font-semibold block">Kategori 2</label>
                        <z-auto-complete-input-new v-model="category_2" label="Kategori Shopee" :options="categoryOptions2" full @changeData="getCategory3(); getShopeeAttribute()"></z-auto-complete-input-new>
                    </div>

                    <div class="mb-3" v-if="categoryOptions3.length > 0">
                        <label class="font-proxima font-semibold block">Kategori 3</label>
                        <z-auto-complete-input-new v-model="category_3" label="Kategori Shopee" :options="categoryOptions3" full @changeData="getShopeeAttribute"></z-auto-complete-input-new>
                    </div>
                </div>

                <div class="border border-gray-300 rounded p-6 mb-3" v-if="attributeData.length > 0">
                    <h4 class="font-proxima font-semibold block text-lg mb-5 pb-2 border-b border-gray-300">Atribut Shopee</h4>

                    <div v-for="(item, index) in attributeData" :key="index">
                        <div class="mb-3">
                            <label class="font-proxima font-semibold block">{{ item.display_attribute_name }} <span v-if="item.is_mandatory" class="text-red-600 text-sm">(Wajib Diisi!)</span></label>
                            <z-select v-model="item.value_selected" label="Pilih Nilai Atribut" :options="item.attribute_value_list" full></z-select>
                        </div>
                    </div>
                </div>

                <!-- {{ attributeData }} -->

                <!-- <div>
                    <label class="font-proxima font-semibold block">Form Slug/URL</label>
                    <z-input v-model="slug" full @input="slugValidationAndFormatter"></z-input>
                    <h4 v-if="slugValidation" class="font-medium text-red-600 text-xs mt-1">Alamat form/slug sudah pernah terpakai!</h4>
                </div> -->

                <div class="mb-3">
                    <label class="block font-semibold font-proxima" for="receiver_address">Deskripsi</label>
                    <!-- <textarea v-model="description" id="custom_script" class="w-full bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima" row="3">
                    </textarea> -->
                    <editor
                        api-key="7tm56s1v0nzouz97uxh9gf2jfq5z4zp6bn11v25x94hbz163"
                        :init="{
                            height: 300,
                            plugins: [
                                'advlist autolink lists link image charmap',
                                'searchreplace visualblocks code fullscreen',
                                'print preview anchor insertdatetime media',
                                'paste code help wordcount table'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright | \
                                bullist numlist outdent indent | help'
                        }"
                        v-model="description"
                    />
                </div>

                <!-- <div>
                    <label class="font-proxima font-semibold block">Kategori</label>
                    <z-select v-model="csSelected" label="Pilih CS" :options="filteredTeamCSOptions" full @changeData="addTeamCS"></z-select>
                </div> -->

            </div>

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Pilih Watermark</h2>

                <p>Info: Fitur watermark masih tahap beta, beberapa produk mungkin belum bisa memakai watermark</p>

                <h3 v-if="selectedWatermark" class="text-sm font-medium font-proxima text-gray-700 mb-2">Watermark yang dipilih: <span class="font-bold text-yellow-500">{{ selectedWatermark.file_name.replace('clickcuan/media/', '') }}</span></h3>
                
                <WatermarkSelect @selectedWatermark="selectWatermark" />
            </div>

            <!-- Custom Price -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Setting Harga Jual</h2>
                
                <!-- Input Margin Masal -->
                <div class="mb-3 md:flex items-end justify-start md:space-x-2">
                    <div class="md:w-1/3 w-full md:mb-0 mb-1">
                        <h4 class="text-sm font-proxima">Margin Produk</h4>
                        <z-input v-model="marginMassPrice" placeholder="Margin" type="number" full></z-input>
                    </div>
                    <z-button @click="generateMarginProduct" class="text-white button-diginesia md:w-1/3 w-full">
                        Terapkan Masal
                    </z-button>
                </div>

                <!-- Tabel Product Variant yang sudah digenerated + Input Stok dan Harga Variant -->
                <table class="md:block hidden table-fixed w-full border-collapse text-gray-600 text-md text-center">
                    <!-- Judul Data Produk Varian -->
                    <thead class="bg-white border border-gray-200 text-gray-700 font-medium">
                        <tr>
                            <td class="w-2/6 py-2">Info SKU</td>
                            <td class="w-2/6 py-2">Harga Barang</td>
                            <td class="w-2/6 py-2">Margin</td>
                            <td class="w-2/6 py-2">Harga Jual</td>
                        </tr>
                    </thead>
                    <!-- Input Isian Data Produk Varian : Harga, Stok, dan Kode SKU -->
                    <tbody class="bg-white border border-gray-200">
                        <tr v-for="(item, i) in custom_price" :key="i">
                            <td class="border-gray-200 border-t py-2">
                                <h4 class="text-sm font-proxima font-semibold">{{ item.sku }}</h4>
                                <h4 class="text-xs font-proxima">{{ item.options.toString().replace(',', ' ') }}</h4>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.price) }}</h4>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <div class="relative">
                                    <input v-model="item.margin" class="bg-gray-50 pr-3 pl-8 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima w-full" type="number" @input="item.sell_price = item.price + item.margin">
                                    <div class="absolute inset-0 right-auto flex items-center pointer-events-none">
                                        <span class="text-sm text-gray-400 font-medium px-3 font-proxima">Rp</span>
                                    </div>
                                </div>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.sell_price) }}</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- [Mobile] Tabel Product Variant yang sudah digenerated + Input Stok dan Harga Variant -->
                <table class="md:hidden table-fixed w-full border-collapse text-gray-600 text-md text-center">
                    <!-- Judul Data Produk Varian -->
                    <thead class="bg-white border border-gray-200 text-gray-700 font-medium">
                        <tr>
                            <td class="w-1/2 py-2">Info SKU & Harga Barang</td>
                            <td class="w-1/2 py-2">Margin & Harga Jual</td>
                        </tr>
                    </thead>
                    <!-- Input Isian Data Produk Varian : Harga, Stok, dan Kode SKU -->
                    <tbody class="bg-white border border-gray-200">
                        <tr v-for="(item, i) in custom_price" :key="i">
                            <!-- Mobile -->
                            <td class="border-gray-200 border-t py-2">
                                <h4 class="text-sm font-proxima font-semibold">{{ item.sku }}</h4>
                                <h4 class="text-xs font-proxima">{{ item.options.toString().replace(',', ' ') }}</h4>
                                <br/>
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.price) }}</h4>
                            </td>
                            <td class="border-gray-200 border-t py-2 px-2">
                                <div class="relative">
                                    <input v-model="item.margin" class="bg-gray-50 pr-3 pl-8 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima w-full" type="number" @input="item.sell_price = item.price + item.margin">
                                    <div class="absolute inset-0 right-auto flex items-center pointer-events-none">
                                        <span class="text-sm text-gray-400 font-medium px-3 font-proxima">Rp</span>
                                    </div>
                                </div>
                                <br/>
                                <h4 class="text-sm font-proxima">Rp{{ formatPrice(item.sell_price) }}</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Save Button -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/2 w-full mt-5">
                <template v-if="error_report">
                    <div class="p-4 my-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                        {{ error_report }}
                    </div>
                </template>
                <div>
                    <z-button @click="exportProductToShopee" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Mulai Ekspor</z-button>
                </div>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL
import formatPrice from '../../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZSelect from '@/components/common/ZSelect.vue';
import ZAutoCompleteInputNew from '@/components/common/ZAutoCompleteInputNew.vue';
import WatermarkSelect from '@/components/special/WatermarkSelect.vue';

/* External Component/Library */
import Editor from '@tinymce/tinymce-vue'

export default {
    components: {
        NavigationMenu,
        ZInput,
        ZButton,
        ZSelect,
        ZAutoCompleteInputNew,
        'editor': Editor,
        WatermarkSelect
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Data */
            product_data: null,
            shopee_auth_token: [],
            shopee_token_selected: null,
            category_1: '',
            category_2: '',
            category_3: '',
            selectedWatermark: null,
            /* Options */
            categoryData: [],
            categoryOptions1: [],
            categoryOptions2: [],
            categoryOptions3: [],
            attributeData: [],
            logisticData: [],
            shopeeAuthOptions: [],
            /* Form Settings */
            product_name: '',
            description: '',
            category: '',
            brand: '',
            /* Pricing */
            custom_price: [],
            marginMassPrice: 0,
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false,
            slugValidation: false,
            /* Form Validation */
            error_report: null
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getAllAuthorization();
        this.getProductBySlug();
        //this.getAuthTokenShopee();
	},
    methods: {
        /*
         * Get All Auhorization
         */
        async getAllAuthorization() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let params = {
                    pagination: false
                };

                const response = await axios.get(`${API_URL}/api/integration/shopee/get-all-authorization/${this.profileData.id}`, {
                    params: params,
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.shopee_auth_token = response.data;
                this.shopeeAuthOptions = this.shopee_auth_token.map(item => {
                    return {
                        label: item.shop_name,
                        value: item
                    }
                });

                if(this.shopee_auth_token.length === 0) {
                    this.$router.push('/settings/marketplace-integration/shopee');
                }

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Get Product By Slug
         */
        async getProductBySlug () {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/product/get/slug/${this.$route.params.slug}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                this.product_data = response.data.data;
                this.description = this.product_data.product_detail;
                this.custom_price = response.data.data.variants.map(item => {
                    return {
                        sku: item.sku,
                        price: item.price,
                        margin: 0,
                        sell_price: item.price,
                        options: item.options,
                        stock: item.stock.stock,
                        product: item.product
                    }
                });

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },

        /*
         * Get Auth Token Shopee
         */
        async getAuthTokenShopee() {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.shopee_token = response.data.data;
                this.getShopeeCategory();
                this.getShopeeLogistics();

                this.loadingToggle = false;
            } catch (error) {
                this.loadingToggle = false;
                this.$router.push('/settings/marketplace-integration');
            }
        },

        /*
         * Export Product to Shopee
         */
        async exportProductToShopee () {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let category;
                if(this.category_1 != '') {
                    category = this.category_1.value;
                }
                if(this.category_2 != '') {
                    category = this.category_2.value;
                }
                if(this.category_3 != '') {
                    category = this.category_3.value;
                }

                const data = {
                    shop_id: this.shopee_token_selected.value.shop_id,
                    access_token: this.shopee_token_selected.value.access_token,
                    token_id: this.shopee_token_selected.value._id,
                    product_id: this.product_data._id,
                    product_sku: this.product_data.sku,
                    product_name: this.product_name,
                    product_description: this.description,
                    product_images: this.product_data.images,
                    product_weight: this.product_data.package_weight,
                    product_options: this.product_data.options,
                    product_variant: this.custom_price,
                    product_category: category,
                    product_attribute: this.attributeData.filter(item => item.value_selected !== ''),
                    logistic_info: this.logisticData,
                    user: this.profileData.id
                };

                const response = await axios.post(`${API_URL}/api/integration/shopee/product/add`, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                if(this.$route.query.redirect_from == 'selling_marketplace') {
                    this.updateGuide();
                }else{
                    console.log(response)
                    this.$router.push('/shopee/list-product');
                }

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.error_report = error.response.data.message;
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Get Shopee Category
         */
        async getShopeeCategory () {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/category?shop_id=${this.shopee_token_selected.value.shop_id}&access_token=${this.shopee_token_selected.value.access_token}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                console.log(response.data.message.response.category_list)
                this.categoryData = response.data.message.response.category_list;

                const categoryOptions1 = this.categoryData.filter(item => item.has_children == true && item.parent_category_id == 0);
                this.categoryOptions1 = categoryOptions1.map(item => {
                    return {
                        value: item.category_id,
                        label: item.display_category_name
                    }
                });

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },
        /*
         * Get Shopee Category 2
         */
        async getCategory2 () {
            const categoryOptions2 = this.categoryData.filter(item => item.parent_category_id == this.category_1.value);
            this.categoryOptions2 = categoryOptions2.map(item => {
                return {
                    value: item.category_id,
                    label: item.display_category_name
                }
            });
        },
        /*
         * Get Shopee Category 3
         */
        async getCategory3 () {
            const categoryOptions3 = this.categoryData.filter(item => item.parent_category_id == this.category_2.value);
            this.categoryOptions3 = categoryOptions3.map(item => {
                return {
                    value: item.category_id,
                    label: item.display_category_name
                }
            });
        },

        /*
         * Get Shopee Attribute
         */
        async getShopeeAttribute () {
            this.loadingToggle = true;

            let category;
            if(this.category_1 != '') {
                category = this.category_1.value;
            }
            if(this.category_2 != '') {
                category = this.category_2.value;
            }
            if(this.category_3 != '') {
                category = this.category_3.value;
            }

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/attribute/${category}?shop_id=${this.shopee_token_selected.value.shop_id}&access_token=${this.shopee_token_selected.value.access_token}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                console.log(response.data.message.response)
                this.attributeData = response.data.message.response.attribute_list.map(item => {
                    return {
                        attribute_id: item.attribute_id,
                        display_attribute_name: item.display_attribute_name,
                        is_mandatory: item.is_mandatory,
                        attribute_value_list: item.attribute_value_list.map(val => {
                            return {
                                value: val.value_id,
                                label: val.display_value_name
                            }
                        }),
                        value_selected: ''
                    }
                });
                //this.categoryData = response.data.message.response.category_list;

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },

        /*
         * Update Guide
         */
        async updateGuide() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Update Type */
                update_type: 'export-product'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        this.$router.push('/shopee/list-product?redirect_from=selling_marketplace');
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

        /*
         * Get Shopee Logistics
         */
        async getShopeeLogistics () {
            this.loadingToggle = true;

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/logistics?shop_id=${this.shopee_token_selected.value.shop_id}&access_token=${this.shopee_token_selected.value.access_token}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Mapping Form Data */
                console.log(response.data.data.response)
                const logisticData = response.data.data.response.logistics_channel_list.filter(item => item.enabled == true);
                this.logisticData = logisticData.map(item => {
                    return {
                        enabled: item.enabled,
                        logistic_id: item.logistics_channel_id
                    }
                })

                this.loadingToggle = false;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },
        
        /* Select Watermark */
        selectWatermark(value) {
            this.selectedWatermark = value;

            this.product_data.images = this.product_data.images.map(item => {
                return item.replace('seebuh/image/upload/', `seebuh/image/upload/c_scale,w_500/c_scale,l_${this.selectedWatermark.file_name.replaceAll('/', ':')},w_500/`)
            })
        },

        /* Helpers */
        generateMarginProduct() {
            this.custom_price.map((item) => {
                item.margin = this.marginMassPrice;
                item.sell_price = Number(item.price) + Number(this.marginMassPrice);
            });
        },
        async slugValidationAndFormatter(event) {
            this.slug = event.target.value.replace(/\s+/g, '-').toLowerCase();

            try {
                const checkSlug = await axios.get(API_URL + '/api/my-form/slug-validation/' + this.slug + '/user/' + this.profileData.id, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loadingToggle = false;
                this.slugValidation = checkSlug.data.data;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },
        formatPrice
    }
}
</script>

<style scoped>
</style>