<template>
    <div class="relative">
        <input
            type="text"
            id="search"
            :value="modelValue.label ? modelValue.label : modelValue"
            @input="($event) => { searchTerm = $event.target.value; $emit('update:modelValue', $event.target.value); isOpen = true }"
            :placeholder="placeholder"
            class="w-full bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima"
        />

        <ul
            v-if="searchValues.length && isOpen"
            class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10"
        >
            <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
                Showing {{ searchValues.length }} of {{ options.length }} results
            </li>
            <li
                v-for="(item, index) in searchValues"
                :key="index"
                @click.stop="selectValue(item)"
                class="cursor-pointer hover:bg-gray-100 p-1"
            >
                <span v-if="item.label">{{ item.label }}</span>
                <span v-else>{{ item }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import {ref, computed} from 'vue'
export default {
    props: {
        modelValue: { type: Object }, // untuk get value dari model jangan lupa pakai modelValue
        options: { type: Array },
        placeholder: { type: String },
    },
    emits: ['update:modelValue', 'changeData'],
    setup(props, context) {
        let isOpen = ref(false)
        let searchTerm = ref('')
        const searchValues = computed(() => {
            if (searchTerm.value === '') {
                return []
            }
            let matches = 0
            return props.options.filter(item => {
                if(item.label) {
                    if (item.label.toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 10) {
                        matches++
                        return item
                    }
                }else{
                    if (item.toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 10) {
                        matches++
                        return item
                    }
                }
            })
        });
        const selectValue = (item) => {
            isOpen.value = false;
            context.emit('update:modelValue', item); 
            context.emit('changeData');
            //searchTerm.value = item
        }
        return {
            isOpen,
            searchTerm,
            searchValues,
            selectValue
        }
    }
}
</script>