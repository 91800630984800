<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <section v-if="loading" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-2/3 w-full mx-auto">
                <!-- Error Message -->
                <div v-if="error_message" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                    <span class="font-medium">Peringatan!</span><br/>{{ error_message }}.
                </div>
                <div v-if="success_message" class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                    <span class="font-medium">Sukses!</span><br/>{{ success_message }}.
                </div>

                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Daftar Produk Shopee</h2>

                <!-- Table -->
                <div v-if="data_product && data_product.items.length > 0" class="mt-4">
                    <table class="table-auto w-full">
                        <!------------------->
                        <!-- Header Column -->
                        <!------------------->
                        <thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
                            <tr>
                                <!-- <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                                    <div class="flex items-center">
                                        <label class="inline-flex">
                                            <span class="sr-only">Select all</span>
                                            <input class="form-checkbox" type="checkbox" v-model="selectAllItem" @click="selectItem" />
                                        </label>
                                    </div>
                                </th> -->
                                <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div class="font-semibold text-left flex items-center">
                                        Foto Produk
                                        <!-- <button class="focus:outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-current ml-2" viewBox="0 0 24 24" style="transform:;-ms-filter:"><path d="M6.227 11h11.547c.862 0 1.32-1.02.747-1.665l-5.773-6.495c-.397-.448-1.097-.448-1.494 0L5.479 9.335C4.906 9.98 5.364 11 6.227 11zM11.253 21.159c.397.448 1.097.448 1.494 0l5.773-6.495C19.094 14.02 18.636 13 17.773 13H6.227c-.862 0-1.32 1.02-.747 1.665L11.253 21.159z"></path></svg>
                                        </button> -->
                                    </div>
                                </th>
                                <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div class="font-semibold text-left flex items-center">
                                        Info Produk
                                        <!-- <button class="focus:outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-current ml-2" viewBox="0 0 24 24" style="transform:;-ms-filter:"><path d="M6.227 11h11.547c.862 0 1.32-1.02.747-1.665l-5.773-6.495c-.397-.448-1.097-.448-1.494 0L5.479 9.335C4.906 9.98 5.364 11 6.227 11zM11.253 21.159c.397.448 1.097.448 1.494 0l5.773-6.495C19.094 14.02 18.636 13 17.773 13H6.227c-.862 0-1.32 1.02-.747 1.665L11.253 21.159z"></path></svg>
                                        </button> -->
                                    </div>
                                </th>
                                <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div class="font-semibold text-left flex items-center">
                                        Aksi
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <!------------------------->
                        <!-- / End Header Column -->
                        <!------------------------->

                        <!--------------->
                        <!-- List Item -->
                        <!--------------->
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(item, index) in data_product.items" :key="index" class="text-sm font-medium">
                                <!-- <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                                    <div class="flex items-center">
                                        <label class="inline-flex">
                                            <span class="sr-only">Select</span>
                                            <input class="form-checkbox" type="checkbox" :value="item" v-model="selectedItem" />
                                        </label>
                                    </div>
                                </td> -->
                                <td class="px-2 py-3 whitespace-nowrap">
                                    <img :src="item.image.image_url_list[0]" width="100" />
                                </td>
                                <td class="px-2 py-3 whitespace-nowrap">
                                    {{ item.item_name.slice(0, 40) + (item.item_name.length > 40 ? "..." : "") }}
                                    <br/>
                                    <span class="text-gray-500" v-if="item.price_info && item.price_info.length > 0">Rp{{ formatPrice(item.price_info[0].current_price) }}</span>
                                    <span class="text-gray-500" v-else>Rp{{ formatPrice(item.variants.model[0].price_info[0].current_price) }}</span>
                                    <br/>
                                    <span class="text-blue-500">Tipe Produk: {{ item.variants.model.length > 0 ? 'Variant' : 'Single Product' }}</span>
                                    <br/>
                                    <span class="text-gray-500">SKU: {{ item.item_sku }}</span>
                                    <br/>
                                    <span class="text-gray-500">Kategori Produk: {{ item.category_id }}</span>
                                </td>
                                <td class="px-2 py-3 whitespace-nowrap">
                                    <button @click.stop="selectedItem = item; selectProductToggle = true" class="bg-blue-600 hover:bg-blue-700 px-3 py-1 text-sm font-semibold text-white rounded">Sync Produk</button>
                                </td>
                            </tr>
                        </tbody>
                        <!--------------------->
                        <!-- / End List Item -->
                        <!--------------------->
                    </table>
                </div>

                <div class="flex justify-center" v-if="data_product && data_product.meta">
                    <nav class="flex" role="navigation" aria-label="Navigation">
                        <div class="mr-2">
                            <button
                                @click="scanProductFromShopee(Number(data_product.meta.next_offset) - 2, this.limit)"
                                :disabled="data_product.meta.next_offset && data_product.meta.next_offset == 1 || data_product.meta.total_count < this.limit"
                                :class="data_product.meta.next_offset && data_product.meta.next_offset !== 1 ? 'hover:bg-yellow-500 border-gray-200 text-gray-600 hover:text-gray-800 shadow-sm' : 'border-gray-200 text-gray-300'"
                                class="inline-flex items-center justify-center border border-transparent rounded leading-5 px-2.5 py-2 bg-white"
                            >
                                <span class="sr-only">Previous</span><wbr />
                                <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
                                    <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
                                </svg>
                            </button>
                        </div>
                        <div class="ml-2">
                            <button
                                @click="scanProductFromShopee(data_product.meta.next_offset, this.limit)"
                                :disabled="data_product.meta.has_next_page == false"
                                :class="data_product.meta.has_next_page ? 'hover:bg-yellow-500 border-gray-200 text-gray-600 hover:text-gray-800 shadow-sm' : 'border-gray-200 text-gray-300'"
                                class="inline-flex items-center justify-center border border-transparent rounded leading-5 px-2.5 py-2 bg-white"
                            >
                                <span class="sr-only">Next</span><wbr />
                                <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
                                    <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                                </svg>
                            </button>
                        </div>
                    </nav>
                </div>
            </div>

            <!-- Select Product -->
            <ZModal id="select-product-modal" :modalOpen="selectProductToggle" @close-modal="selectProductToggle = false" title="Pilih Produk" size="w-full">
                <ZSelectProduct v-if="selectProductToggle" @getProduct="getProductBySlug" />
            </ZModal>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL
import formatPrice from '../../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue'
import ZModal from '@/components/common/ZModal.vue';
import ZSelectProduct from '@/components/special/ZSelectProduct.vue';

export default {
    components: {
        NavigationMenu,
        ZModal,
        ZSelectProduct
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Product Information */
            data_product: '',
            page: 0,
            limit: 10,
            category: '',
            brand: '',
            /*
             * Relation Options
             */
            /* Category Options */
            categoryData: '',
            categoryOptions: [],
            /* Brand Options */
            brandData: '',
            brandOptions: [],
            /* Toggle */
            selectProductToggle: false,
            /* Extra */
            //selectAllItem: false,
            selectedItem: null,
            errors: [],
            error_message: null,
            success_message: null,
            loading: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/login');
            });
        }
        
        this.getAuthTokenShopee();
    },
    methods: {
        /*
         * Get Auth Token Shopee
         */
        async getAuthTokenShopee() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/get-auth-token/${this.profileData.id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.shopee_token = response.data.data;

                /* Scan Product From Shopee */
                this.scanProductFromShopee(this.page, this.limit)

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                this.$router.push('/settings/marketplace-integration');
            }
        },

        /*
         * Scan Product From Shopee
         */
        async scanProductFromShopee(page, limit) {
            this.error_message = null;

            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/integration/shopee/scan-product-from-shopee?access_token=${this.shopee_token.access_token}&shop_id=${this.shopee_token.shop_id}&page=${page}&limit=${limit}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Set Integration Data */
                this.data_product = response.data.data;

                /* Stop Loading */
                loading.hide();
                this.loading = false;
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                window.scrollTo(0,0);
                this.error_message = error.response.data.message;
            }
        },

        /*
         * Get Product By Slug
         */
        async getProductBySlug(value) {
            this.error_message = null;

            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const response = await axios.get(`${API_URL}/api/product/get/slug/${value}`);
                console.log(response.data)

                /* Validasi Cocok Produk */
                if(this.selectedItem.item_sku !== response.data.data.sku) {
                    this.selectProductToggle = false;

                    /* Stop Loading */
                    loading.hide();
                    this.loading = false;

                    window.scrollTo(0,0);
                    this.error_message = 'Produk Tidak Cocok!';
                    return;
                }
                
                /* Proses */
                const data = {
                    product_id: response.data.data._id,
                    product_variant: response.data.data.variants,
                    item_id: this.selectedItem.item_id,
                    item_tier_variation: this.selectedItem.variants.tier_variation,
                    item_model: this.selectedItem.variants.model,
                    user: this.profileData.id,
                    shop_id: this.shopee_token.shop_id,
                    token_id: this.shopee_token._id,
                    item_data: this.selectedItem // Opsional
                }

                await axios.post(`${API_URL}/api/integration/shopee/sync-product`, data)
                    .then(() => {
                        this.selectProductToggle = false;

                        /* Set Integration Data */
                        window.scrollTo(0,0);
                        this.success_message = `${this.selectedItem.item_name} Berhasil Disinkronkan!`

                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    })
                    .catch(error => {
                        this.selectProductToggle = false;

                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        window.scrollTo(0,0);
                        this.error_message = error.response.data.message;
                    });
            } catch (error) {
                this.selectProductToggle = false;

                /* Stop Loading */
                loading.hide();
                this.loading = false;

                window.scrollTo(0,0);
                this.error_message = error.response.data.message;
            }
        },

        /* Helpers */
        formatPrice
    }
}
</script>

<style scoped>
</style>