<template>
    <ZModal id="detail-product-modal" title="Detail Produk" :modalOpen="modalOpen" @close-modal="this.$emit('closeModal')">
        <div class="p-6" v-if="product">

            <!-- Informasi Produk -->
            <h4 class="font-proxima font-bold mb-2">Informasi Produk</h4>
            <div class="flex items-center">
                <div class="mr-3">
                    <img v-if="product.images[0].includes('upload/')" :src="product.images[0].replace('upload/', 'upload/c_fill,h_300,w_300/')" width="100" class="border border-gray-300 rounded" />
                    <img v-else :src="product.images[0]" width="100" class="border border-gray-300 rounded" />
                </div>
                <div>
                    <h3 class="font-proxima text-sm font-semibold text-gray-700">{{ product.name }}</h3>
                    <h4 class="font-proxima text-sm text-gray-500">{{ product.sku }}</h4>
                    <h4 class="font-proxima text-sm text-gray-500">Kategori: {{ product.category.name }}</h4>
                    <h4 class="font-proxima text-sm text-gray-500">Merk: {{ product.brand.brand_name }}</h4>
                </div>
            </div>
            
            <!-- Media -->
            <h4 class="font-proxima font-bold mt-5 mb-2">Media</h4>
            <div class="grid grid-cols-4 gap-3">
                <div v-for="(item, index) in product.images" :key="index">
                    <img v-if="item.includes('upload/')" :src="item.replace('upload/', 'upload/c_fill,h_300,w_300/')" width="100" class="border border-gray-300 rounded" />
                    <img v-else :src="item" width="100" class="border border-gray-300 rounded" />
                </div>
            </div>

            <!-- Benefit -->
            <h4 class="font-proxima font-bold mt-5 mb-2">Benefit/Keunggulan Produk</h4>
            <div class="space-y-1">
                <h4 class="text-sm font-proxima flex items-center" v-for="(item, index) in product.benefit" :key="index">
                    <svg class="w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="fill: rgba(87, 180, 75, 1);transform: ;msFilter:;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path></svg>
                    {{ item.benefit_text }}
                </h4>
            </div>

            <!-- Product Spesification -->
            <h4 class="font-proxima font-bold mt-5 mb-2">Rincian Produk</h4>
            <div class="px-4 py-2 bg-gray-50 border border-gray-100 rounded">
                <p v-html="product.product_detail"></p>
            </div>

            <!-- Testimonial -->
            <h4 class="font-proxima font-bold mt-5 mb-2">Testimonial</h4>
            <div class="grid grid-cols-2 gap-3" v-if="product.testimonial.length > 0">
                <div v-for="(item, index) in product.testimonial" :key="index">
                    <img :src="item.replace('upload/', 'upload/c_fill,h_300,w_300/')" width="100" class="border border-gray-300 rounded" />
                </div>
            </div>
            <div v-else>
                <h4 class="font-proxima text-sm">Testimoni belum tersedia</h4>
            </div>

            <!-- Product Model -->
            <h4 class="font-proxima font-bold mt-5 mb-2">Produk Model</h4>
            <div class="relative overflow-x-auto border border-gray-300">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                SKU
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Variasi
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Harga
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Stok
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in product.variants" :key="index" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                {{ item.sku }}
                            </th>
                            <td class="px-6 py-4">
                                {{ item.options.toString().replace(',', ' ') }}
                            </td>
                            <td class="px-6 py-4">
                                Rp{{ formatPrice(item.price) }}
                            </td>
                            <td class="px-6 py-4">
                                {{ item.stock.stock }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Informasi Pengiriman -->
            <h4 class="font-proxima font-bold mt-5 mb-2">Informasi Pengiriman</h4>
            <div>
                <h4 class="font-proxima text-sm">Berat: {{ product.package_weight }}gr</h4>
                <h4 class="font-proxima text-sm">Tipe Pengiriman: COD dan Non COD</h4>
                <h4 class="font-proxima text-sm flex items-center">Ekspedisi Pengiriman: <img src="https://upload.wikimedia.org/wikipedia/commons/9/92/New_Logo_JNE.png" width="45" class="ml-2" /></h4>
                <h4 class="font-proxima text-sm">Alamat Gudang/Pengiriman: {{ product.vendor.address }}</h4>
            </div>

        </div>
    </ZModal>
</template>

<script>
import formatPrice from '../../utils/formatPrice.js'
import ZModal from '@/components/common/ZModal.vue';

export default {
    props: ['modalOpen', 'product'],
    components: {
        ZModal
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        formatPrice
    }
}
</script>

<style scoped>

</style>