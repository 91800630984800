<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="md:flex md:items-start md:space-x-3 max-w-7xl mx-auto md:px-2 px-4 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 md:w-1/4 w-full md:mb-0 mb-2">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Menu Affiliasi</h2>
                
                <div class="space-y-2">
                    <button @click="$router.push('/affiliate/dashboard'); menu_selected = 'dashboard'" class="w-full px-2 py-1.5 border border-gray-200 rounded font-proxima font-semibold flex items-center text-gray-600" :class="menu_selected === 'dashboard' ? 'bg-gray-200' : ''">
                        <svg class="fill-current h-5 w-5 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M3 5v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2zm16.001 14H5V5h14l.001 14z"></path><path d="M11 7h2v10h-2zm4 3h2v7h-2zm-8 2h2v5H7z"></path>
                        </svg>
                        Dashboard Affiliasi
                    </button>
                    <button @click="$router.push('/affiliate/link'); menu_selected = 'link'" class="w-full px-2 py-1.5 border border-gray-200 rounded font-proxima font-semibold flex items-center text-gray-600" :class="menu_selected === 'link' ? 'bg-gray-200' : ''">
                        <svg class="fill-current h-5 w-5 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"></path><path d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"></path>
                        </svg>
                        Link Affiliasi
                    </button>
                    <button @click="$router.push('/affiliate/payout'); menu_selected = 'payout'" class="w-full px-2 py-1.5 border border-gray-200 rounded font-proxima font-semibold flex items-center text-gray-600" :class="menu_selected === 'payout' ? 'bg-gray-200' : ''">
                        <svg class="fill-current h-5 w-5 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;">
                            <path d="M12 15c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92 0-1.12-.52-3-4-3-2 0-2-.63-2-1s.7-1 2-1 1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3 2 0 2 .68 2 1s-.62 1-2 1z"></path><path d="M5 2H2v2h2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4h2V2H5zm13 18H6V4h12z"></path>
                        </svg>
                        Pembayaran
                    </button>
                </div>
            </div>

            <!-- Product Information -->
            <AffiliateDashboard v-if="menu_selected === 'dashboard'" />
            <AffiliateLink v-if="menu_selected === 'link'" />
            <AffiliatePayout v-if="menu_selected === 'payout'" />
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import AffiliateDashboard from '@/pages/Affiliate/AffiliateDashboard.vue';
import AffiliateLink from '@/pages/Affiliate/AffiliateLink.vue';
import AffiliatePayout from '@/pages/Affiliate/AffiliatePayout.vue';
// import ZInput from '@/components/common/ZInput.vue';
// import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        NavigationMenu,
        AffiliateDashboard,
        AffiliateLink,
        AffiliatePayout,
        // ZInput,
        // ZButton
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            menu_selected: '',
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.menu_selected = this.$route.path.split('/')[2];
	},
    methods: {
    }
}
</script>

<style scoped>
</style>