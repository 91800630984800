<template>
    <div class="min-h-screen bg-gray-800 text-gray-800 antialiased flex items-center justify-center sm:py-12">
        <section v-if="loading !== null" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="relative py-3 px-4 md:px-0 mx-auto text-center w-full max-w-sm">

            <div class="flex-shrink-0 flex items-center justify-center">
                <img class="mr-1" width="40" src="@/assets/images/logo/logo-gold.png" alt="SatuStock" />
                <h1 class="text-white text-2xl font-bold font-diginesia">SatuStock</h1>
            </div>

            <div class="relative mt-4 bg-white shadow-md rounded-lg text-left">
                <div class="h-2 bg-yellow-400 rounded-t-md"></div>
                <div class="py-6 px-8">
                    <h4 class="text-xl font-semibold font-proxima mb-4 text-center">Masuk ke Dashboard</h4>

                    <!-- Error Message -->
                    <div v-if="error_message" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                        <span class="font-medium">Peringatan!</span><br/>{{ error_message }}.
                    </div>

                    <!-- Email -->
                    <div class="mb-3">
                        <label class="font-proxima font-semibold block mb-1">Email</label>
                        <z-input v-model="email" full @input="emailFormatter"></z-input>
                    </div>
                    <!-- Password -->
                    <div class="mb-3">
                        <label class="font-proxima font-semibold block mb-1">Password</label>
                        <z-input v-model="password" type="password" full></z-input>
                    </div>
                    <!-- Button Action -->
                    <div class="flex justify-between items-baseline mt-8">
                        <z-button @click="handleLogin" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2">Masuk</z-button>
                        <a @click="$router.push('/auth/forgot-password')" class="text-sm font-proxima hover:underline cursor-pointer">Lupa Password?</a>
                    </div>
                </div>
            </div>
            <!-- Footer -->
            <h4 class="text-gray-300 text-sm font-proxima mt-6">Belum punya akun? <a @click="$router.push('/auth/register')" class="text-yellow-500 hover:text-yellow-600 font-semibold cursor-pointer">Daftar Sekarang!</a></h4>
        </div>
    </div>
</template>

<script>
import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        ZInput,
        ZButton
    },
    data() {
        return {
            email: '',
            password: '',
            /* Extra */
            loading: null,
            error_message: null
        }
    },
    computed: {
        loggedIn() {
            return this.$store.getters['auth/isLoggedIn']; // <== ini kalau pakai namespace, kalau engga tinggal this.$store.getters.isLoggedIn
        }
    },
    created() {
        if (this.loggedIn) {
            this.$router.push('/');
        }
    },
    methods: {
        async handleLogin(){
            /* Set Form Data */
            const { email, password } = this
            this.loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Commit ke Vuex */
            this.$store.dispatch('auth/login', { email, password })
                .then(() => {
                    this.$router.push('/');
                    this.loading.hide();
                    this.loading = null;
                }).catch(err => {
                    /* Gagal munculkan alert */
                    if (err) {
                        /* Show Error Message */
                        this.error_message = err.response.data.message;
                        this.alertToggle = true;
                        //this.loadingButton = false;
                        this.loading.hide();
                        this.loading = null;
                    }
                });
        },
        /* Email Formatter */
        emailFormatter(event) {
            this.email = event.target.value.toLowerCase();
        }
    }
}
</script>

<style scoped>

</style>