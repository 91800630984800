<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu :total_item="total_item" />
        <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Guide Dropshipper -->
            <div v-if="$route.query.redirect_from && $route.query.redirect_from == 'selling_manual_order'" class="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                <h2><b>Langkah Terakhir!</b><br/>Jika ada pesanan dari pesanan manual ini, anda bisa cek pesanannya disini => <a @click="$router.push('/order/list?redirect_form=selling_form_order')" class="text-blue-600 hover:text-blue-500 cursor-pointer font-semibold">Lihat Pesanan</a></h2>
            </div>

            <!-- Payment Method -->
            <div class="bg-white rounded-md mb-5 p-8">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima">Pilihan Layanan</h2>

                <div class="mt-4 md:grid md:grid-cols-2 gap-3">
                    <div @click="shipping_courier = 'JNE'" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="shipping_courier" value="JNE" class="block">
                        <h3 class="font-semibold">Ekonomis</h3>
                    </div>
                    <!-- <div @click="shipping_courier = 'Ninja Xpress'" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="shipping_courier" value="Ninja Xpress" class="block">
                        <h3 class="font-semibold">Standard</h3>
                    </div> -->
                </div>
            </div>

            <!-- Pickup Address -->
            <!-- <div class="bg-white rounded-md mb-5">
                <Address :address="address" @openListAddress="listAddressToggle = true" @openAddAddress="formAddressToggle = true" />
            </div> -->

            <!-- Goods Info -->
            <div class="bg-white rounded-md mb-5 p-8">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima">Info Barang/Produk</h2>

                <button @click.stop="selectProductToggle = true" class="mt-4 text-sm bg-yellow-500 hover:bg-yellow-600 px-4 py-2 text-white text-center font-semibold font-dosis rounded w-full">Pilih Produk</button>
            
                <div class="w-full flex items-center mb-3 mt-4 bg-gray-50 rounded p-3" v-if="product_data !== null">
                    <img :src="product_data.images[0]" class="border border-gray-200 rounded mr-4" width="120" />
                    <div>
                        <h4 class="font-semibold font-proxima">{{ product_data.name }}</h4>
                        <h4 class="text-gray-700 font-medium font-proxima text-xs mt-2">Pengiriman dari {{ product_data.vendor.city }}</h4>
                        <!-- Mobile -->
                        <div>
                            <h3 class="text-gray-700 font-proxima font-medium text-sm">Rp{{ formatPrice(product_data.variants[0].price) }}</h3>
                        </div>
                    </div>
                </div>

                <!-- Product Model -->
                <div v-if="data_model && data_model.length > 0">
                    <h4 class="font-proxima font-bold mb-2">Produk Model</h4>
                    <div class="relative overflow-x-auto border border-gray-300">
                        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                        SKU & Variasi
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Harga Barang
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Margin
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Harga Jual
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Qty
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in data_model" :key="index" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" class="px-6 py-4 dark:text-white whitespace-nowrap">
                                        <h3 class="font-semibold text-gray-900">{{ item.sku }}</h3>
                                        <h4 class="font-medium text-gray-600 text-sm">{{ item.variant }}</h4>
                                    </th>
                                    <td class="px-6 py-4">
                                        Rp{{ formatPrice(item.price) }}
                                    </td>
                                    <td class="px-6 py-4">
                                        <div class="relative">
                                            <input v-model="item.margin" class="bg-gray-50 pr-3 pl-8 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima w-full" type="number" @input="item.sell_price = item.price + item.margin">
                                            <div class="absolute inset-0 right-auto flex items-center pointer-events-none">
                                                <span class="text-sm text-gray-400 font-medium px-3 font-proxima">Rp</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4">
                                        Rp{{ formatPrice(item.sell_price) }}
                                    </td>
                                    <td class="px-6 py-4">
                                        <div class="custom-number-input h-8 w-24">
                                            <div class="flex flex-row h-8 w-full rounded-lg relative bg-transparent mt-1">
                                                <button @click="item.qty--" :disabled="item.qty < 1" data-action="decrement" class="border border-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-16 cursor-pointer outline-none rounded-l">
                                                    <span class="m-auto text-xl" style="line-height: 15px">−</span>
                                                </button>
                                                <input v-model="item.qty" type="number" class="border-t border-b border-gray-200 outline-none focus:outline-none text-center w-full font-semibold text-sm hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700" name="custom-input-number">
                                                <button @click="item.qty++" data-action="increment" class="border border-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-16 cursor-pointer rounded-r">
                                                    <span class="m-auto text-xl" style="line-height: 15px">+</span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <!-- Customer -->
            <div class="bg-white rounded-md mb-5 p-8" v-if="data_model && data_model.length > 0">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima">Detail Penerima</h2>

                <div class="space-y-2">
                    <div class="mt-4 grid grid-cols-1 gap-3">
                        <div>
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="customer_name">Nama Lengkap</label>
                            <z-input v-model="customer_name" full></z-input>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-3">
                        <div>
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="customer_phone">No Telepon</label>
                            <div class="relative flex items-center bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima">
                                <h4 class="font-proxima text-gray-900 text-base mr-2">+62</h4>
                                <input v-model="customer_phone" id="customer_phone" class="w-full bg-gray-50 focus:outline-none font-proxima" type="number" />
                            </div>
                        </div>
                        <div v-if="shipping_courier === 'JNE'">
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="customer_province">Provinsi</label>
                            <ZAutoCompleteInput v-model="customer_province" :options="provinceOptions" label="Provinsi" @changeData="getCity">
                            </ZAutoCompleteInput>
                        </div>
                        <div v-else-if="shipping_courier === 'Ninja Xpress'">
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="customer_district">Kecamatan</label>
                            <ZAutoCompleteInput v-model="customer_district" :options="districtOptions" placeholder="Pilih Kecamatan" @input="getDistrictNinja" @changeData="getShippingFeeNinja">
                            </ZAutoCompleteInput>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-3" v-if="shipping_courier === 'JNE'">
                        <div>
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="customer_city">Kota/Kabupaten</label>
                            <ZAutoCompleteInput v-model="customer_city" :options="cityOptions" label="Kota/Kabupaten" @changeData="getDistrict">
                            </ZAutoCompleteInput>
                        </div>
                        <div>
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="customer_district">Kecamatan</label>
                            <ZAutoCompleteInput v-model="customer_district" :options="districtOptions" label="Kecamatan" @changeData="getShippingFeeJNE">
                            </ZAutoCompleteInput>
                        </div>
                    </div>
                    <!-- <div class="grid grid-cols-2 gap-3">
                        <div>
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="customer_subdistrict">Kelurahan</label>
                            <ZAutoCompleteInput v-model="customer_subdistrict" :options="subdistrictOptions" placeholder="Pilih Kelurahan" @changeData="customer_postal_code = customer_subdistrict && customer_subdistrict.value ? customer_subdistrict.value.postal_code : ''">
                            </ZAutoCompleteInput>
                        </div>
                        <div>
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="customer_postal_code">Kode POS</label>
                            <z-input v-model="customer_postal_code" full></z-input>
                        </div>
                    </div> -->
                    <div>
                        <label class="block text-sm font-semibold mb-1 font-proxima" for="customer_address">Alamat</label>
                        <textarea v-model="customer_address" id="customer_address" class="w-full bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima" row="3">
                        </textarea>
                    </div>
                </div>
            </div>

            <!-- Goods Info -->
            <!-- <div class="bg-white rounded-md mb-5 p-8">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima">Info Barang</h2>

                <div class="space-y-2">
                    <div class="mt-4 grid grid-cols-1 gap-3">
                        <div>
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="goods_desc">Nama Produk/Barang</label>
                            <z-input v-model="goods_desc" full></z-input>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-3">
                        <div>
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="goods_value">Nilai Barang</label>
                            <z-input v-model="goods_value" full></z-input>
                        </div>
                        <div>
                            <label class="block text-sm font-semibold mb-1 font-proxima" for="goods_weight">Berat</label>
                            <z-input v-model="goods_weight" full></z-input>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- Payment Method -->
            <div class="bg-white rounded-md mb-5 p-8" v-if="data_model && data_model.length > 0">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima">Metode Pembayaran</h2>

                <div class="mt-4 md:grid md:grid-cols-2 md:gap-3 md:space-y-0 space-y-1.5">
                    <div @click="selectPaymentMethod('COD')" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="payment_method" value="COD" class="block">
                        <h3 class="font-semibold">COD</h3>
                    </div>
                    <div @click="selectPaymentMethod('Bank Transfer')" class="flex items-center space-x-6 border border-gray-300 px-6 py-4 w-full hover:bg-gray-50 cursor-pointer">
                        <input type="radio" v-model="payment_method" value="Bank Transfer" class="block">
                        <h3 class="font-semibold">Non COD</h3>
                    </div>
                </div>
            </div>

            <!-- Checkout Button -->
            <div class="bg-white rounded-md px-8 py-5">
                <div class="py-4 mb-1">
                    <div class="flex items-center justify-between mb-2">
                        <h5 class="font-proxima text-gray-700 text-sm">Total Harga</h5>
                        <h5 class="font-proxima text-gray-700 text-sm">Rp{{ formatPrice(totalPrice ? totalPrice : 0) }}</h5>
                    </div>
                    <div class="flex items-center justify-between mb-2">
                        <h5 class="font-proxima text-gray-700 text-sm">Total Ongkos Kirim</h5>
                        <h5 class="font-proxima text-gray-700 text-sm">Rp{{ formatPrice(shipping_fee ? shipping_fee : 0) }}</h5>
                    </div>
                    <div class="flex items-center justify-between mb-2">
                        <h5 class="font-proxima text-gray-700 text-sm">Biaya Penanganan</h5>
                        <h5 class="font-proxima text-gray-700 text-sm">Rp{{ formatPrice(admin_fee ? admin_fee : 0) }}</h5>
                    </div>
                    <div class="border-t-2 border-dashed border-gray-400 my-5"></div>
                    <div class="flex items-center justify-between">
                        <h5 class="font-proxima font-bold text-gray-700 text-sm">Total Yang Harus Dibayar</h5>
                        <h5 class="font-proxima font-bold text-gray-700 text-sm">Rp{{ formatPrice( Number(totalPrice) + Number(shipping_fee) + Number(admin_fee) ) }}</h5>
                    </div>
                </div>
                <template v-if="errors.length > 0">
                    <div v-for="(item, index) in errors" :key="index" class="p-4 my-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                        {{ item }}
                    </div>
                </template>
                <button @click="createOrder" class="bg-green-600 hover:bg-green-500 px-4 py-2.5 text-white text-center font-semibold font-dosis rounded w-full">Pesan Sekarang</button>
            </div>
            
            <!-- Modal -->
            <!-- List Address -->
            <!-- <ZModal id="address-modal" :modalOpen="listAddressToggle" @close-modal="listAddressToggle = false" title="Daftar Alamat">
                <ListAddress v-if="listAddressToggle" @addAddress="listAddressToggle = false; formAddressToggle = true" @editAddress="editAddress" />
            </ZModal> -->
            <!-- Form Address -->
            <!-- <ZModal id="address-modal" :modalOpen="formAddressToggle" @close-modal="formAddressToggle = false" title="Form Alamat">
                <FormAddress v-if="formAddressToggle" :address="addressData" />
            </ZModal> -->

            <!-- Select Product -->
            <ZModal id="select-product-modal" :modalOpen="selectProductToggle" @close-modal="selectProductToggle = false" title="Pilih Produk" size="w-full">
                <ZSelectProduct v-if="selectProductToggle" @getProduct="getProductBySlug" />
            </ZModal>


            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
// import formatDate from '../../utils/dateFormat.js'
import formatPrice from '../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
//import Address from '@/components/order/PickupAddress.vue';
//import ListAddress from '@/components/order/ListPickupAddress.vue';
//import FormAddress from '@/components/order/FormPickupAddress.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZAutoCompleteInput from '@/components/common/ZAutoCompleteInputNew.vue';
//import ProductItems from '@/components/cart/ProductItems.vue';
import ZModal from '@/components/common/ZModal.vue';
import ZSelectProduct from '@/components/special/ZSelectProduct.vue';

export default {
    components: {
        NavigationMenu,
        //Address,
        //ListAddress,
        //FormAddress,
        ZInput,
        ZAutoCompleteInput,
        //ProductItems,
        ZModal,
        ZSelectProduct,
    },
    data() {
        return {
            /* Address */
            address: null,
            addressData: null, // Untuk Edit Data
            /* Customer Detail */
            customer_name: '',
            customer_phone: '',
            customer_address: '',
            customer_province: '',
            customer_city: '',
            customer_district: '',
            customer_subdistrict: '',
            customer_postal_code: '',
            /* Options */
            provinceOptions: [],
            cityOptions: [],
            districtOptions: [],
            subdistrictOptions: [],
            /* Product */
            product_data: null,
            data_model: [],
            /* Goods Info */
            // goods_desc: '',
            // goods_value: '',
            // goods_weight: '',
            /* Shipping Method */
            shipping_fee: 0,
            shipping_tariff_code: '',
            shipping_courier: 'JNE',
            /* Payment Method */
            payment_method: '',
            admin_fee: 0,
            unique_code: null,
            /* Toggle */
            formAddressToggle: false,
            listAddressToggle: false,
            selectProductToggle: false,
            loading: null,
            /* Extra */
            errors: [],
            total_item: 0,
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        },
        totalPrice() {
            return this.data_model.reduce((total, item) => { return Number(total) + Number(item.sell_price * item.qty) }, 0);
        },
        totalQty() {
            return this.data_model.reduce((total, item) => { return Number(total) + Number(item.qty) }, 0);
        },
        totalPayment() {
            return Number(this.totalPrice) + Number(this.shipping_fee) + Number(this.admin_fee);
        }
	},
	async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/signin');
            });
        }

        /* Validasi Vendor Terverifikasi */
        if(this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === undefined || this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === false) {
            this.$router.push('/');
        }

        //this.getPrimaryAddress();
        this.getProvince();

        if(this.profileData.guide_manual_order.create_manual_order === false) {
            this.updateGuide();
        }
	},
	methods: {
		// /*
        //  * Get Primary Address
        //  */
        // async getPrimaryAddress() {
        //     try {
        //         const response = await axios.get(`${API_URL}/api/pickup-address/get-primary/${this.profileData.id}`, {
        //             headers: {
        //                 'Authorization': this.$store.state.auth.token
        //             }
        //         });
                
        //         /* Set Address */
        //         this.address = response.data.data;
        //         //this.getTariff();
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
        // /*
        //  * Edit Address
        //  */
        // editAddress(value) {
        //     this.addressData = value;
        //     this.listAddressToggle = false;
        //     this.formAddressToggle = true;
        // },

        /*
         * Get Province
         */
        async getProvince() {
            try {
                const response = await axios.get(`${API_URL}/api/province/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Reset Data */
                this.provinceOptions = [];
                /* Set Province Options */
                response.data.map(item => {
                    this.provinceOptions.push({
                        label: item.province,
                        value: item.province
                    });
                })
            } catch (error) {
                console.log(error)
            }
        },
        /*
         * Get City
         */
        async getCity() {
            try {
                const response = await axios.get(`${API_URL}/api/city/get-all/${this.customer_province.value}?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Reset Data */
                this.cityOptions = [];
                /* Set City Options */
                response.data.map(item => {
                    this.cityOptions.push({
                        label: item.city,
                        value: item.city
                    });
                })
            } catch (error) {
                console.log(error)
            }
        },
        /*
         * Get District
         */
        async getDistrict() {
            try {
                const response = await axios.get(`${API_URL}/api/district/get-all/${this.customer_city.value}?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Reset Data */
                this.districtOptions = [];
                /* Set District Options */
                response.data.map(item => {
                    this.districtOptions.push({
                        label: item.district,
                        value: item.district
                    });
                })
            } catch (error) {
                console.log(error)
            }
        },
        /*
         * Get Subdistrict
         */
        // async getSubdistrict() {
        //     try {
        //         const response = await axios.get(`${API_URL}/api/subdistrict/get-all/${this.customer_district}?pagination=false`, {
        //             headers: {
        //                 'Authorization': this.$store.state.auth.token
        //             }
        //         });
                
        //         /* Reset Data */
        //         this.subdistrictOptions = [];
        //         /* Set Subdistrict Options */
        //         await response.data.map(item => {
        //             this.subdistrictOptions.push({
        //                 label: item.subdistrict,
        //                 value: item
        //             });
        //         });
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },

        /*
         * Get District Ninja
         */
        async getDistrictNinja() {
            try {
                const response = await axios.get(`${API_URL}/api/integration/ninja/check-zone-code/${this.customer_district}?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Reset Data */
                this.districtOptions = [];
                /* Set District Options */
                response.data.map(item => {
                    this.districtOptions.push({
                        label: item.district + ', ' + item.city + ', ' + item.province,
                        value: item
                    });
                })
            } catch (error) {
                console.log(error)
            }
        },

        /*
         * Get Product By Slug
         */
        async getProductBySlug(value) {
            try {
                const response = await axios.get(`${API_URL}/api/product/get/slug/${value}`);
                
                /* Set Province Options */
                this.product_data = response.data.data;
                this.data_model = response.data.data.variants.map(item => {
                    return {
                        sku: item.sku,
                        variant: item.options.toString().replace(',', ' '),
                        price: item.price,
                        sell_price: item.price,
                        margin: 0,
                        qty: 0,
                        stock: item.stock
                    }

                    // return {
                    //     sku: item.sku,
                    //     variant: item.options.toString().replace(',', ' '),
                    //     price: item.price,
                    //     qty: 0,
                    //     stock: item.stock.stock
                    // }
                });
                this.selectProductToggle = false;
            } catch (error) {
                console.log(error)
            }
        },

        /*
        * Get Tarif Ongkir JNE Express
        */
        async getShippingFeeJNE() {
            /* Cek Apakah Sudah Pilih Produk Atau Belum */
            if(this.product_data === null) {
                this.$toast.error('Silahkan pilih produk terlebih dahulu', {duration: 3000});
            }

            try {
                const data = {
                    customer_province: this.customer_province.value,
                    customer_district: this.customer_district.value,
                    origin: this.product_data.vendor.origin,
                    // destination: destination.data.data.tariff_code,
                    weight: this.product_data.package_weight * this.totalQty,  
                }

                const response = await axios.post(`${API_URL}/api/integration/jne/tarif`, data);
                
                /* Set Province Options */
                this.shipping_fee = response.data.data.price;
                this.shipping_tariff_code = response.data.data.tariff_code;
            } catch (error) {
                console.log(error)
            }
        },

        /*
        * Get Tarif Ongkir Ninja Xpress
        */
        async getShippingFeeNinja() {
            try {
                const data = {
                    service_level: 'Standard',
                    from: this.product_data.vendor.district,
                    to: {
                        l1_tier_code: this.customer_district.value.l1_tier_code,
                        l2_tier_code: this.customer_district.value.l2_tier_code,
                    },
                    weight: this.product_data.package_weight * this.totalQty,  
                };

                const response = await axios.post(`${API_URL}/api/integration/ninja/tarif`, data);
                
                /* Set Province Options */
                this.shipping_fee = response.data.data.price;
                this.shipping_tariff_code = response.data.data.tariff_code;
            } catch (error) {
                console.log(error)
            }
        },

        /*
         * Select Payment Method
         */
        selectPaymentMethod(value) {
            this.payment_method = value;

            if(this.payment_method == 'Bank Transfer') {
                let total = 0;
                if((this.totalPrice + this.shipping_fee) < 75000) {
                    total = this.generateKodeUnik(100, 323);
                }else if((this.totalPrice + this.shipping_fee) >= 75000){
                    total = this.generateKodeUnik(324, 521)
                }
                this.unique_code = this.unique_code === null ? total : this.unique_code;
                this.admin_fee = this.unique_code !== null ? this.unique_code : total;
            }else if(this.payment_method == 'COD'){
                this.admin_fee = Math.round( (Number(this.totalPrice) + Number(this.shipping_fee)) * ( 3 / 100 ) );
            }else{
                this.admin_fee = 0;
            }
        },
        
        /*
         * Form Validation
         */
        checkForm() {
            this.errors = [];

            if (this.payment_method === '') {
                this.errors.push('Metode pembayaran belum dipilih!');
            }
        },
        /*
         * Create Order
         */
        async createOrder() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            this.checkForm();
            if(this.errors.length > 0) {
                return;
            }

            const items = this.data_model.map(item => {
                if(item.qty > 0) {
                    return {
                        vendor: this.product_data.vendor,
                        product: this.product_data,
                        variant: item,
                        quantity: item.qty
                    }
                }
            });

            const filtered_items = items.filter(item => {
                return item != null;
            });

            if(filtered_items.length === 0) {
                this.$toast.error('Silahkan masukkan jumlah produk yang ingin dipesan', {duration: 3000});
                
                /* Stop Loading */
                loading.hide();
                this.loading = false;
                
                return;
            }

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Order Information */
                order_type: 'create-order',
                /* Item Information */
                items: filtered_items,
                /* Pickup Information */
                // pickup_name: this.address.pickup_name,
                // pickup_phone: this.address.pickup_phone,
                // pickup_address: this.address.pickup_address,
                // pickup_district: this.address.pickup_district,
                // pickup_city: this.address.pickup_city,
                // pickup_province: this.address.pickup_province,
                // pickup_postal_code: this.address.pickup_postal_code,
                /* Customer Information */
                customer_name: this.customer_name,
                customer_phone: this.customer_phone,
                customer_address: this.customer_address,
                customer_district: this.shipping_courier === 'Ninja Xpress' ? this.customer_district.value.district : this.customer_district.value,
                customer_city: this.shipping_courier === 'Ninja Xpress' ? this.customer_district.value.city : this.customer_city.value,
                customer_province: this.shipping_courier === 'Ninja Xpress' ? this.customer_district.value.province : this.customer_province.value,
                customer_postal_code: this.customer_postal_code,
                /* Shipping Information */
                shipping_fee: this.shipping_fee,
                shipping_tariff_code: this.shipping_tariff_code,
                shipping_courier: this.shipping_courier,
                /* Payment Information */
                payment_method: this.payment_method,
                admin_fee: this.admin_fee,
                total_payment: Number(this.totalPrice) + Number(this.shipping_fee) + Number(this.admin_fee),
                /* Informasi Relasi/Integrasi dengan Entitas Lain */
                user: this.profileData.id ? this.profileData.id : null,
                user_type: this.profileData.role,
                vendor: this.product_data.vendor
            }

            try {
                await axios.post(API_URL + '/api/order/create-order', form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;

                if(this.payment_method === 'COD') {
                    this.$router.push('/order/list');
                }else if(this.payment_method === 'Bank Transfer') {
                    this.$router.push('/payment?total_payment=' + this.totalPayment);
                }
            } catch (error) {
                console.log(error)
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /*
         * Update Guide
         */
        async updateGuide() {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Update Type */
                update_type: 'create-manual-order'
            }

            try {
                await axios.put(`${API_URL}/api/user/update/${this.profileData.id}`, form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Commit ke Vuex */
                this.$store.dispatch('auth/getProfile', { auth_token: this.$store.state.auth.token })
                    .then(() => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    }).catch(err => {
                        /* Gagal munculkan alert */
                        if (err) {
                            /* Show Error Message */
                            this.$toast.error(err.response.data.message, {duration: 3000});
                            /* Stop Loading */
                            loading.hide();
                            this.loading = false;
                        }
                    });
            } catch (error) {
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error(error.response.data.message, {duration: 3000});
            }
        },

        /* Helpers */
        generateKodeUnik(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        formatPrice
    }
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
-webkit-appearance: none;
    margin: 0;
}
</style>