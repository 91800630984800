<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <section v-if="loading !== null" ref="loadingContainer" class="fixed h-full w-full"></section><!-- Loading -->
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Produk</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Nama Produk <span class="text-gray-400 font-proxima text-xs mb-1">*Panjang maksimal karakter 70</span></label>
                    <z-input v-model="product_name" full></z-input>
                </div>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">SKU Produk</label>
                    <z-input v-model="sku" @input="skuValidationAndFormatter"></z-input>
                    <h4 v-if="skuValidation" class="font-medium text-red-600 text-xs mt-1">Sku sudah pernah terpakai!</h4>
                </div>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Kategori</label>
                    <z-select v-model="category" label="Kategori" :options="categoryOptions" full></z-select>
                </div>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Merk</label>
                    <z-select v-model="brand" label="Merk" :options="brandOptions" full></z-select>
                </div>
            </div>

            <!-- Media -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Media</h2>
                
                <z-upload v-model="images"></z-upload>

                <div class="mt-3">
                    <label class="font-proxima font-semibold block mb-1">Video</label>
                    <z-input v-model="video" placeholder="Silahkan Taruh Link Video Youtube Disini.." full></z-input>
                </div>
            </div>

            <!-- Benefit -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Manfaat/Benefit Produk</h2>

                <label class="font-proxima">Benefit</label>
                <div class="flex items-center mb-3" v-for="(item, index) in benefit" :key="index">
                    <z-input v-model="item.benefit_text" placeholder="Contoh: Menjaga daya tahan tubuh" class="w-full" full></z-input>
                    <button class="text-red-600 hover:text-red-700 ml-3" @click="benefit.splice(index, 1)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current" style="transform: ;msFilter:;">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path><path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                        </svg>
                    </button>
                </div>
                <!-- Tombol Tambah Pilihan -->
                <z-button @click="benefit.push({ benefit_text: '' })" class="flex items-center text-white button-diginesia w-full justify-center">
                    <svg class="ionicon w-5 H-5" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Add Circle</title><path d='M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z' fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='32'/><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M256 176v160M336 256H176'/></svg>
                    <span class="ml-2">Tambah Benefit</span>
                </z-button>
            </div>

            <!-- Product Spesification -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Rincian/Spesifikasi Produk</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Deskripsi Produk</label>
                    <editor
                        api-key="7tm56s1v0nzouz97uxh9gf2jfq5z4zp6bn11v25x94hbz163"
                        :init="{
                            height: 300,
                            plugins: [
                                'advlist autolink lists link image charmap',
                                'searchreplace visualblocks code fullscreen',
                                'print preview anchor insertdatetime media',
                                'paste code help wordcount table'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright | \
                                bullist numlist outdent indent | help'
                        }"
                        v-model="product_detail"
                    />
                </div>
            </div>

            <!-- Testimonial -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Testimonial</h2>
                <z-upload v-model="testimonial"></z-upload>
            </div>

            <!-- Price & Stock Information -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Harga & Stok</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block mb-1">Tipe Produk</label>
                    <z-select v-model="type_product" label="Tipe Produk" :options="typeProductOptions" full></z-select>
                </div>

                <template v-if="type_product.value === 'single'">
                    <div class="mb-3">
                        <label class="font-proxima font-semibold block mb-1">Harga Jual</label>
                        <z-input v-model="price" full></z-input>
                    </div>

                    <div class="mb-0">
                        <label class="font-proxima font-semibold block mb-1">Stok</label>
                        <z-input v-model="stock" full></z-input>
                    </div>
                </template>
                <template v-else-if="type_product.value === 'variant'">
                    <z-variant v-model="product_variant" :variant_input="variantInput" @getVariantInput="(value) => { variantInput = value }"></z-variant>
                    <z-variant-price v-model="product_variant" v-if="product_variant && product_variant.length > 0" :sku="sku" @generateValueProductVariant="generateValueProductVariant"></z-variant-price>
                </template>
            </div>

            <!-- Shipping Information -->
            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-5">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Informasi Pengiriman</h2>

                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Berat (gram)</label>
                    <z-input v-model="package_weight" full></z-input>
                </div>
            </div>

            <div class="bg-white rounded border border-gray-300 p-6 w-1/2 mt-8">
                <template v-if="errors.length > 0">
                    <div v-for="(item, index) in errors" :key="index" class="p-4 my-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                        {{ item }}
                    </div>
                </template>
                <z-button @click="uploadMediaImages" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Simpan</z-button>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL

import NavigationMenu from '@/components/header/NavigationMenu.vue';
import ZInput from '@/components/common/ZInput.vue';
import ZSelect from '@/components/common/ZSelect.vue';
import ZUpload from '@/components/common/ZUpload.vue';
import ZButton from '@/components/common/ZButton.vue';
import ZVariant from '@/components/special/ZVariant.vue';
import ZVariantPrice from '@/components/special/ZVariantPrice.vue';

/* External Component/Library */
import Editor from '@tinymce/tinymce-vue'

export default {
    components: {
        NavigationMenu,
        ZInput,
        ZSelect,
        ZUpload,
        ZButton,
        ZVariant,
        ZVariantPrice,
        'editor': Editor,
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Product Information */
            product_name: '',
            sku: '',
            category: '',
            brand: '',
            /* Media */
            images: [],
            video: '',
            /* Benefit */
            benefit: [{
                benefit_text: ''
            }],
            /* Product Spesification */
            product_detail: '',
            /* Testimonial */
            testimonial: [],
            /* Type Product */
            type_product: {
                label: 'Single',
                value: 'single'
            },
            typeProductOptions: [{
                label: 'Single',
                value: 'single'
            },{
                label: 'Variasi',
                value: 'variant'
            }],
            /* Single Product */
            price: '',
            stock: '',
            /* Variant Product */
            product_variant: [],
            variantInput: [{
                header_title: 'Variasi 1',
                variant_name: '',
                options: [{
                    option_name: ''
                }]
            }],
            /* Shipping Information */
            package_weight: 0,
            /*
             * Relation Options
             */
            /* Category Options */
            categoryData: '',
            categoryOptions: [],
            /* Brand Options */
            brandData: '',
            brandOptions: [],
            /* Extra */
            errors: [],
            skuValidation: false,
            loading: null
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/login');
            });
        }

        /* Validasi Vendor Terverifikasi */
        if(this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === undefined || this.profileData.role === 'VENDOR' && this.profileData.vendor_verified === false) {
            this.$router.push('/');
        }

        this.isLoading = true;

        this.getCategory();
        this.getBrand();
    },
    methods: {
        /*
         * Get Category
         */
        async getCategory() {
            try {
                const response = await axios.get(`${API_URL}/api/category/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.categoryData = response.data;

                for(let i=0; i < this.categoryData.length; i++){
                    this.categoryOptions.push({
                        label: this.categoryData[i].name,
                        value: this.categoryData[i]._id
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        /*
         * Get Brand
         */
        async getBrand() {
            /* Reset Options */
            this.brandOptions = []; // Reset
            
            try {
                const response = await axios.get(`${API_URL}/api/brand/get-all?pagination=false`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                
                /* Store Data */
                this.brandData = response.data;

                for(let i=0; i < this.brandData.length; i++){
                    this.brandOptions.push({
                        label: this.brandData[i].brand_name,
                        value: this.brandData[i]._id
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },

        /*
         * Upload Media Images
         */
        async uploadMediaImages() {
            this.loading = this.$loading.show({ container: this.$refs.loadingContainer });

            this.checkProductInput();
            if(this.errors.length > 0) {
                return;
            }

            /* Mapping Images - Upload ke Cloudinary*/
            const images = this.images.map(async item => {
                try {
                    if(item.file) {
                        let formData = new FormData();
                        formData.append("file", item.file);

                        const image = await axios.post(API_URL + '/api/media/upload-media', formData, {
                            headers: {
                                'Authorization': this.$store.state.auth.token
                            }
                        });

                        return image.data.file_url;
                    }
                } catch (error) {
                    return null;
                }
            })

            Promise.all(images)
                .then(async result => {

                    const checkError = result.some(item => item === null);
                    if(checkError) {
                        this.loadingToggle = false;
                        
                        this.$notify({
                            title: "Gagal",
                            text: "Gambar sudah dipakai!",
                            position: "bottom",
                            type: "error"
                        });
                        
                        return
                    }

                    this.uploadTestimonialImages(result);
                })
                .catch(error => {
                    console.log(error)
                })
        },
        /*
         * Upload Testimonial Images
         */
        async uploadTestimonialImages(media_images) {
            /* Mapping Testimonial - Upload ke Cloudinary*/
            const images = this.testimonial.map(async item => {
                try {
                    if(item.file) {
                        let formData = new FormData();
                        formData.append("file", item.file);

                        const image = await axios.post(API_URL + '/api/media/upload-media', formData, {
                            headers: {
                                'Authorization': this.$store.state.auth.token
                            }
                        });

                        return image.data.file_url;
                    }
                } catch (error) {
                    return null;
                }
            })

            Promise.all(images)
                .then(async result => {

                    const checkError = result.some(item => item === null);
                    if(checkError) {
                        this.loadingToggle = false;
                        
                        this.$notify({
                            title: "Gagal",
                            text: "Gambar sudah dipakai!",
                            position: "bottom",
                            type: "error"
                        });
                        
                        return
                    }

                    this.createProduct(media_images, result);
                })
                .catch(error => {
                    console.log(error)
                })
        },
        /*
         * Create Product Validation
         */
        checkProductInput() {
            this.errors = [];

            if (this.product_name === '') {
                this.errors.push('Nama product harus diisi!');
            }
            if (this.sku === '') {
                this.errors.push('Sku produk belum diisi!');
            }
            if (this.category === '') {
                this.errors.push('Kategori produk belum dipilih!');
            }
            if (this.brand === '') {
                this.errors.push('Merk produk belum dipilih!');
            }
            if (this.images.length === 0) {
                this.errors.push('Gambar produk belum diupload!');
            }
            // if (this.product_variant.length === 0) {
            //     this.errors.push('Stok & harga belum diisi!');
            // }
            if (this.package_weight === 0) {
                this.errors.push('Berat belum diisi!');
            }
            if (this.skuValidation === true) {
                this.errors.push('Sku sudah pernah dipakai!');
            }
        },
        /*
         * Create Product
         */
        async createProduct (media_images, testimonial_images) {
            /* Check Variasi Product */
            let options = []; // Variable untuk Informasi Nama Variasi dan Pilihan Variasi
            if(this.type_product.value === 'variant') {
                /* Isi Options/Informasi Nama Variasi dan Pilihan Variasinya pada Product */
                for(let i=0; i < this.variantInput.length; i++){
                    options.push({
                        name: this.variantInput[i].variant_name, // Nama Variasi String
                        values: this.variantInput[i].options // Pilihan Variasi Object
                    });
                }
            }else{
                /* Setup Single Options */
                options.push({
                    name: "Type",
                    values: [
                        {
                            option_name: "Single",
                            imageURL: ""
                        }
                    ]
                });
                /* Setup Single Variant */
                this.product_variant = [{
                    sku: this.sku + '-Single',
                    price: this.price,
                    options: [
                        'Single'
                    ],
                    stock: this.stock
                }];
            }

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Product Information */
                name: this.product_name,
                sku: this.sku,
                brand: this.brand.value,
                category: this.category.value,
                /* Media */
                images: media_images,
                video: this.video,
                /* Benefit */
                benefit: this.benefit,
                /* Product Spesification */
                product_detail: this.product_detail,
                /* Testimonial */
                testimonial: testimonial_images,
                /* Variant Product */
                options: options,
                variants: this.product_variant,
                /* Shipping Information */
                package_weight: this.package_weight,
                /* Informasi Relasi/Integrasi dengan Entitas Lain */
                user: this.profileData.id ? this.profileData.id : null,
                vendor: this.profileData.vendor ? this.profileData.vendor : null,
                /* Status */
                isActive: false
            }

            try {
                await axios.post(API_URL + '/api/product/create', form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loading.hide();
                this.loading = null;
                this.$router.push('/product/list');
            } catch (error) {
                console.log(error)
                this.loading.hide();
                this.loading = null;

                this.$notify({
                    title: "Gagal",
                    text: "Produk gagal disimpan!",
                    position: "bottom",
                    type: "error"
                });
            }
        },

        /* Helpers */
        /*
         * Generate Value Product Variant
         */
        generateValueProductVariant (value) {
            if(this.sku !== '') {
                this.product_variant.map((item) => {
                    item.sku = this.sku + '-' + item.options.toString().replace(',', '-');
                    item.price = value.variantMassPrice;
                    item.stock = value.variantMassQty;
                });
            }
        },
        async skuValidationAndFormatter(event) {
            this.sku = event.target.value.replace(/\s+/g, '-').toUpperCase();

            try {
                const checkSku = await axios.get(API_URL + '/api/product/sku-validation/' + this.sku, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                this.loadingToggle = false;
                this.skuValidation = checkSku.data.data;
            } catch (error) {
                console.log(error)
                this.loadingToggle = false;
            }
        },
    }
}
</script>

<style scoped>
</style>