<template>
    <ZModal id="detail-order-modal" title="Rincian Pesanan" :modalOpen="modalOpen" @close-modal="this.$emit('closeModal')">
        <div class="p-6" v-if="order">

            <div class="mb-8 space-y-1">
                <div class="flex items-center justify-between">
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">No Invoice</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ order.invoice }}</h1>
                </div>
                <div class="flex items-center justify-between">
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Tanggal Pembelian</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ formatDate(order.createdAt) }} - {{ formatTime(order.createdAt) }} WIB</h1>
                </div>
                <div class="flex items-center justify-between" v-if="order.status === 'CANCELLED'">
                    <h1 class="font-proxima text-red-500 text-sm font-semibold w-1/2">Alasan Pembatalan</h1>
                    <h1 class="font-proxima text-red-500 text-sm font-semibold w-1/2">{{ order.cancel_reason }}</h1>
                </div>
                <div class="flex items-center justify-between">
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Tipe Pesanan</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2" v-if="order.order_type === 'direct'">Belanja Langsung/Resi Otomatis</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2" v-if="order.order_type === 'form'">Form Order</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2" v-if="order.order_type === 'create-order'">Order Manual</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2" v-if="order.order_type === 'marketplace-order'">Marketplace Order</h1>
                </div>
                <template v-if="order.order_type === 'direct' || order.order_type === 'marketplace-order'">
                    <div class="flex items-center justify-between">
                        <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Platform Resi Otomatis</h1>
                        <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ order.cashless_platform }}</h1>
                    </div>
                    <div class="flex items-center justify-between" v-if="profileData.role === 'ADMIN' || profileData.role === 'VENDOR'">
                        <template v-if="order.order_type === 'marketplace-order'">
                            <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Bukti Resi</h1>
                            <div class="font-proxima w-1/2" v-if="order.cashless_platform == 'Shopee'">
                                <button @click="downloadResiMarketplaceShopee(order)" class="bg-blue-600 hover:bg-blue-700 px-2 py-1 text-xs text-white font-semibold rounded">Download Resi</button>
                            </div>
                            <div class="font-proxima w-1/2" v-if="order.cashless_platform == 'Tiktok Shop'">
                                <button @click="downloadResiMarketplaceTiktokshop(order)" class="bg-blue-600 hover:bg-blue-700 px-2 py-1 text-xs text-white font-semibold rounded">Download Resi</button>
                            </div>
                            <div class="font-proxima w-1/2" v-if="order.cashless_platform == 'Lazada'">
                                <button @click="downloadResiMarketplaceLazada(order)" class="bg-blue-600 hover:bg-blue-700 px-2 py-1 text-xs text-white font-semibold rounded">Download Resi</button>
                            </div>
                        </template>
                        <template v-else>
                            <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Bukti Resi</h1>
                            <div class="font-proxima w-1/2">
                                <button @click="downloadResi(order.cashless_proof)" class="bg-blue-600 hover:bg-blue-700 px-2 py-1 text-xs text-white font-semibold rounded">Download Resi</button>
                            </div>
                        </template>
                    </div>
                    <template v-if="order.awb_no == undefined || order.awb_no == null || order.awb_no == ''">
                        <div class="flex items-center justify-between" v-if="order.order_type === 'marketplace-order' && profileData.role === 'ADMIN' || order.order_type === 'marketplace-order' && profileData.role === 'VENDOR'">
                            <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Konfirmasi Pesanan Marketplace</h1>
                            <div class="font-proxima w-1/2" v-if="order.cashless_platform == 'Shopee'">
                                <button @click="confirmShipOrderShopee(order)" class="bg-blue-600 hover:bg-blue-700 px-2 py-1 text-xs text-white font-semibold rounded">Konfirmasi Pesanan</button>
                            </div>
                        </div>
                        <div class="flex items-center justify-between" v-if="order.order_type === 'marketplace-order' && profileData.role === 'ADMIN' || order.order_type === 'marketplace-order' && profileData.role === 'VENDOR'">
                            <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Buat Resi Marketplace</h1>
                            <div class="font-proxima w-1/2" v-if="order.cashless_platform == 'Shopee'">
                                <button @click="createShippingDocumentShopee(order)" class="bg-blue-600 hover:bg-blue-700 px-2 py-1 text-xs text-white font-semibold rounded">Generate Resi MP</button>
                            </div>
                            <div class="font-proxima w-1/2" v-if="order.cashless_platform == 'Lazada'">
                                <button @click="generateAWBLazada(order)" class="bg-blue-600 hover:bg-blue-700 px-2 py-1 text-xs text-white font-semibold rounded">Generate Resi MP</button>
                            </div>
                        </div>
                    </template>
                    <div class="flex items-center justify-between" v-if="order.awb_no && order.order_type === 'marketplace-order'">
                        <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Resi</h1>
                        <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ order.awb_no ? order.awb_no : 'Belum Ada Resi' }}</h1>
                    </div>
                </template>
                <template v-else>
                    <div class="flex items-center justify-between">
                        <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Resi</h1>
                        <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ order.awb_no ? order.awb_no : 'Belum Ada Resi' }}</h1>
                    </div>
                    <div class="flex items-center justify-between" v-if="profileData.role === 'ADMIN'">
                        <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Buat Resi Otomatis</h1>
                        <div class="font-proxima w-1/2">
                            <button @click="generateAWB(order)" class="bg-blue-600 hover:bg-blue-700 px-2 py-1 text-xs text-white font-semibold rounded">Generate Resi</button>
                        </div>
                    </div>
                    <div class="flex items-center justify-between" v-if="order.order_type === 'form'">
                        <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Form Order</h1>
                        <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ order.form.form_title }}</h1>
                    </div>
                </template>
                <div class="flex items-center justify-between" v-if="profileData.role === 'ADMIN'">
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">Dropshipper</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ order.user.fullName }} ({{order.user.email}})</h1>
                </div>
                <div class="flex items-center justify-between" v-if="profileData.role === 'ADMIN' || profileData.role === 'RESELLER'">
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">CS</h1>
                    <h1 class="font-proxima text-gray-500 text-sm font-semibold w-1/2">{{ order.cs ? order.cs.fullName : '-' }}</h1>
                </div>
            </div>

            <template v-if="trackingData">
                <template v-if="order.shipping_courier === 'Ninja Xpress'">
                    <h2 class="text-gray-700 text-lg font-semibold font-proxima mb-2">Tracking Pesanan</h2>
                    <div class="mb-6">
                        <div class="mb-2 font-proxima text-sm font-bold">No Resi: {{ trackingData.tracking_number ? trackingData.tracking_number : '-' }}</div>
                        <table class="table w-full rounded">
                            <thead>
                                <tr>
                                    <td class="border border-gray-200 px-3 py-1">Tanggal</td>
                                    <td class="border border-gray-200 px-3 py-1">Keterangan</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(track, i) in trackingData.events" :key="i">
                                    <td class="border border-gray-200 px-3 py-0.5">{{ formatDate(track.timestamp) }} {{ formatTime(track.timestamp) }} WIB</td>
                                    <td class="border border-gray-200 px-3 py-0.5">{{ track.status }} {{ track.comments ? track.comments : '' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <h2 class="text-gray-700 text-lg font-semibold font-proxima mb-2">Tracking Pesanan</h2>
                    <div class="mb-6">
                        <div class="mb-2 font-proxima text-sm font-bold">No Resi: {{ trackingData.cnote ? trackingData.cnote.cnote_no : '-' }}</div>
                        <table class="table w-full rounded">
                            <thead>
                                <tr>
                                    <td class="border border-gray-200 px-3 py-1">Tanggal</td>
                                    <td class="border border-gray-200 px-3 py-1">Keterangan</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(track, i) in trackingData.history" :key="i">
                                    <td class="border border-gray-200 px-3 py-0.5">{{ track.date }}</td>
                                    <td class="border border-gray-200 px-3 py-0.5">{{ track.desc }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </template>

            <template v-if="order.order_note">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima mb-2">Catatan Pesanan</h2>
                <p class="font-proxima font-medium text-sm p-4 border border-gray-200 rounded mb-8">{{ order.order_note }}</p>
            </template>

            <h2 class="text-gray-700 text-lg font-semibold font-proxima mb-2">Pesanan Anda</h2>
            <template v-if="order && order.items && order.items.length > 0">
                <div class="rounded overflow-hidden border border-gray-300 mb-1" v-for="(item, index) in order.items" :key="index">
                    <div class="bg-white px-3 py-2">
                        <div class="md:flex items-center justify-between md:space-x-2">
                            <div class="flex items-center">
                                <img :src="item.product.images[0]" class="border border-gray-200 rounded mr-4" width="100" />
                                <div>
                                    <h4 class="font-semibold font-proxima">{{ item.product.name }}</h4>
                                    <h4 class="text-gray-400 font-semibold font-proxima text-sm">{{ item.variant.variant }}</h4>
                                    <h3 class="text-gray-600 font-proxima font-bold text-sm hidden md:block" v-if="order.order_type !== 'form' && order.order_type !== 'create-order'">Rp{{ formatPrice( item.variant.price ) }} x {{ item.quantity }}</h3>
                                    <h3 class="text-gray-600 font-proxima font-bold text-sm hidden md:block" v-else>Rp{{ formatPrice( item.variant.sell_price ) }} x {{ item.quantity }}</h3>
                                </div>
                            </div>
                            <div class="md:block hidden">
                                <h3 class="text-gray-600 font-proxima font-bold text-sm" v-if="order.order_type !== 'form' && order.order_type !== 'create-order'">Rp{{ formatPrice( item.variant.price * item.quantity ) }}</h3>
                                <h3 class="text-gray-600 font-proxima font-bold text-sm" v-else>Rp{{ formatPrice( item.variant.sell_price * item.quantity ) }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="order.order_type === 'form' || order.order_type === 'create-order'">
                <h2 class="text-gray-700 text-lg font-semibold font-proxima mt-8">Info Alamat</h2>
                <div class="flex items-center justify-between">
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">Nama Penerima</h4>
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">{{ order.customer_name }}</h4>
                </div>
                <div class="flex items-center justify-between">
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">No HP Penerima</h4>
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">{{ order.customer_phone }}</h4>
                </div>
                <div class="flex items-center justify-between">
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">Alamat</h4>
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">{{ order.customer_address }}</h4>
                </div>
                <div class="flex items-center justify-between">
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">Kecamatan</h4>
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">{{ order.customer_district }}</h4>
                </div>
                <div class="flex items-center justify-between">
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">Kota</h4>
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">{{ order.customer_city }}</h4>
                </div>
                <div class="flex items-center justify-between">
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">Provinsi</h4>
                    <h4 class="text-gray-500 font-medium font-proxima text-sm">{{ order.customer_province }}</h4>
                </div>
            </template>

            <h2 class="text-gray-700 text-lg font-semibold font-proxima mt-8">Ringkasan Belanja</h2>
            <div class="flex items-center justify-between">
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Metode Pembayaran</h4>
                <h4 class="text-gray-500 font-medium font-proxima text-sm">{{ order.payment_method }}</h4>
            </div>

            <hr class="w-full height-0.25 bg-gray-200 my-5" />
            
            <div class="flex items-center justify-between">
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Total Harga ({{ order.items.reduce((total, item) => { return Number(total) + Number(item.quantity) },0) }} Barang)</h4>
                <h4 class="text-gray-500 font-medium font-proxima text-sm" v-if="order.order_type !== 'form' && order.order_type !== 'create-order'">Rp{{ formatPrice( order.items.reduce((total, item) => { return Number(total) + Number(item.variant.price * item.quantity) },0) ) }}</h4>
                <h4 class="text-gray-500 font-medium font-proxima text-sm" v-else>Rp{{ formatPrice( order.items.reduce((total, item) => { return Number(total) + Number(item.variant.sell_price * item.quantity) },0) ) }}</h4>
            </div>
            <div class="flex items-center justify-between">
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Total Ongkos Kirim</h4>
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Rp{{formatPrice( Number(order.shipping_fee !== null ? order.shipping_fee : 0) )}}</h4>
            </div>
            <div class="flex items-center justify-between">
                <h4 class="text-green-500 font-semibold font-proxima text-sm">Diskon Ongkos Kirim</h4>
                <h4 class="text-green-500 font-semibold font-proxima text-sm">Rp{{formatPrice(Number(order.shipping_fee_discount !== null ? order.shipping_fee_discount : 0))}}</h4>
            </div>
            <div class="flex items-center justify-between">
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Biaya Penanganan</h4>
                <h4 class="text-gray-500 font-medium font-proxima text-sm">Rp{{formatPrice( Number(order.admin_fee !== null ? order.admin_fee : 0) )}}</h4>
            </div>
            
            <hr class="w-full height-0.25 bg-gray-200 my-5" />
            <div class="flex items-center justify-between">
                <h4 class="text-gray-600 font-semibold font-proxima text-sm">Total Tagihan</h4>
                <h4 class="font-semibold font-proxima text-sm" v-if="order.order_type !== 'form' && order.order_type !== 'create-order'">Rp{{ formatPrice( order.items.reduce((total, item) => { return Number(total) + Number(item.variant.price * item.quantity)},0) + Number((order.shipping_fee !== null ? order.shipping_fee : 0) - (order.shipping_fee_discount !== null ? order.shipping_fee_discount : 0)) + Number(order.admin_fee !== null ? order.admin_fee : 0) ) }}</h4>
                <h4 class="font-semibold font-proxima text-sm" v-else>Rp{{ formatPrice( order.items.reduce((total, item) => { return Number(total) + Number(item.variant.sell_price * item.quantity)},0) + Number((order.shipping_fee !== null ? order.shipping_fee : 0) - (order.shipping_fee_discount !== null ? order.shipping_fee_discount : 0)) + Number(order.admin_fee !== null ? order.admin_fee : 0) ) }}</h4>
            </div>

            {{ shipmentProviderLazada }}

        </div>
    </ZModal>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../config.js' // Import API URL
import formatPrice from '../../utils/formatPrice.js'
import formatDate from '../../utils/dateFormat.js'
import ZModal from '@/components/common/ZModal.vue';

export default {
    props: ['modalOpen', 'order'],
    components: {
        ZModal
    },
    data() {
        return {
            trackingData: null,
            shipmentProviderLazada: []
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    created() {
        if(this.order.awb_no) {
            this.getTracking(this.order.awb_no);
        }
        //this.getShipmentProviderLazada();
    },
    methods: {
        /* Download Resi */
        downloadResi(link) {
            window.open(link, '_blank');
        },
        /*
         * Create Resi Otomatis
         */
        async generateAWB(item) {
            /* Ambil Data Vendor */
            const getVendorData = await axios.get(API_URL + '/api/vendor/get-by-id/' + item.items[0].vendor._id, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });

            /* Hitung Berat */
            let weight;
            if(item.items.reduce((total, item) => { return Number(total) + Number(item.product.package_weight) }, 0) / 1000 < 1) {
                weight = 1;
            }else{
                weight = item.items.reduce((total, item) => { return Number(total) + Number(item.product.package_weight) }, 0) / 1000;
            }

            let data = {
                /* Seller Data */
                merchant_id: getVendorData.data.vendor.vendor_name,
                pic_name: getVendorData.data.vendor.vendor_name,
                pic_phone: getVendorData.data.vendor.vendor_phone,
                pic_city: getVendorData.data.vendor.city,
                pic_district: getVendorData.data.vendor.district,
                pic_address: getVendorData.data.vendor.address,
                pic_province: getVendorData.data.vendor.province,
                pic_postal_code: getVendorData.data.vendor.postal_code,
                customer_id: item.user,
                invoice: item._id.slice(16, 24),
                customer_name: item.customer_name,
                customer_address1: item.customer_address,
                customer_address2: item.customer_district ? item.customer_district : '',
                customer_city: item.customer_city,
                customer_province: item.customer_province,
                customer_postal_code: item.customer_postal_code,
                customer_phone: item.customer_phone,
                qty: item.items.reduce((total, item) => { return Number(total) + Number(item.quantity) }, 0),
                weight: weight,
                product: item.items[0].product.name,
                total_price: item.items.reduce((total, item) => { return Number(total) + Number(item.variant.sell_price) }, 0),
                grand_total: item.total_payment,
                note: '',
                branch_code: getVendorData.data.vendor.branch,
                origin_code: getVendorData.data.vendor.origin,
                destination_code: item.shipping_tariff_code,
                service: item.shipping_method,
                cod: item.payment_method == 'COD' ? 'YES' : 'NO'
            };

            let url_endpoint = '/api/integration/jne/generate-awb/';
            if(item.shipping_courier === 'Ninja Xpress') {
                url_endpoint = '/api/integration/ninja/generate-awb/';
                data.items = item.items.map(item => {
                    return {
                        item_description: item.product.name,
                        quantity: item.quantity,
                        is_dangerous_good: false
                    };
                });
                data.invoice = item.invoice;
                data.pickup_id = getVendorData.data.vendor._id;
            }

            try {
                await axios.post(API_URL + url_endpoint + item._id, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                //this.getOrder(this.page, this.limit);
                window.location.reload();
            } catch (error) {
                console.log(error)
            }
        },

        /*
         * Get Tracking Data
         */
        async getTracking(awb_no) {
            try {
                let url_endpoint = '/api/integration/jne/tracking/';
                if(this.order.shipping_courier === 'Ninja Xpress') {
                    url_endpoint = '/api/integration/ninja/tracking/'
                }

                return await axios.get(`${API_URL}${url_endpoint}${awb_no}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                }).then(response => {
                    this.trackingData = response.data.data
                })
            } catch (error) {
                console.log(error)                
            }
        },

        /* ConfirmShipOrder Shopee */
        async confirmShipOrderShopee(item) {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                let data = {
                    order_number: item.marketplace_invoice,
                    user: item.user._id
                }

                await axios.post(`${API_URL}/api/integration/shopee/confirm-ship-order`, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                })
                    .then(async () => {
                        window.location.reload();
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    })
                    .catch(error => {
                        /* Show Error Message */
                        this.$toast.error(error.response.data.message, {duration: 3000});
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    });
            } catch (error) {
                /* Show Error Message */
                this.$toast.error(error.response.data.message, {duration: 3000});
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /* Create Shipping Document Shopee */
        async createShippingDocumentShopee(item) {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                await axios.get(`${API_URL}/api/integration/shopee/get-tracking-number?order_number=${item.marketplace_invoice}&user=${item.user._id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                })
                    .then(async (result) => {
                        const data = {
                            user: item.user._id,
                            order_number: item.marketplace_invoice,
                            tracking_number: result.data.data.response.tracking_number,
                            package_number: item.shopee_package_number
                        };

                        await axios.post(`${API_URL}/api/integration/shopee/create-shipping-document`, data, {
                            headers: {
                                'Authorization': this.$store.state.auth.token
                            }
                        })
                            .then(async () => {
                                window.location.reload();
                                /* Stop Loading */
                                loading.hide();
                                this.loading = false;
                            })
                            .catch(error => {
                                /* Show Error Message */
                                this.$toast.error(error.response.data.message, {duration: 3000});
                                /* Stop Loading */
                                loading.hide();
                                this.loading = false;
                            });
                    })
                    .catch(error => {
                        /* Show Error Message */
                        this.$toast.error(error.response.data.message, {duration: 3000});
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    });
            } catch (error) {
                /* Show Error Message */
                this.$toast.error(error.response.data.message, {duration: 3000});
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /* Download Resi Marketplace Shopee */
        async downloadResiMarketplaceShopee(item) {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                await axios.get(`${API_URL}/api/integration/shopee/download-shipping-document?order_number=${item.marketplace_invoice}&user=${item.user._id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                })
                    .then(result => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                        
                        window.open(result.data.data.url);
                    })
                    .catch(error => {
                        console.log(error)
                        // /* Show Error Message */
                        // this.$toast.error(error.response.data.message, {duration: 3000});
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    })
            } catch (error) {
                /* Show Error Message */
                this.$toast.error(error.response.data.message, {duration: 3000});
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /* Download Resi Marketplace Tiktok Shop */
        async downloadResiMarketplaceTiktokshop(item) {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                await axios.get(`${API_URL}/api/integration/tiktokshop/get-shipping-document?order_number=${item.marketplace_invoice}&user=${item.user._id}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    },
                })
                    .then(result => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;

                        window.open(result.data.data.data.doc_url)
                    })
                    .catch(error => {
                        console.log(error)
                        /* Show Error Message */
                        this.$toast.error(error.response.data.message, {duration: 3000});
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    })
            } catch (error) {
                /* Show Error Message */
                this.$toast.error(error.response.data.message, {duration: 3000});
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        /* Generate AWB Lazada */
        async generateAWBLazada(item) {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const data = {
                    user: item.user._id,
                    order_number: item.marketplace_invoice,
                    order_item_ids: item.items.map(val => {
                        return val.item_id
                    })
                };

                await axios.post(`${API_URL}/api/integration/lazada/generate-awb`, data, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                })
                    .then(async () => {
                        window.location.reload();
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    })
                    .catch(error => {
                        /* Show Error Message */
                        this.$toast.error(error.response.data.message, {duration: 3000});
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    });
            } catch (error) {
                /* Show Error Message */
                this.$toast.error(error.response.data.message, {duration: 3000});
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },
        /* Download Resi Marketplace Lazada */
        async downloadResiMarketplaceLazada(item) {
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            try {
                const order_item_ids = item.items.map(val => {
                    return val.item_id
                });
                
                await axios.get(`${API_URL}/api/integration/lazada/download-shipping-label?order_number=${item.marketplace_invoice}&user=${item.user._id}&order_item_ids=${order_item_ids.toString()}`, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                })
                    .then(result => {
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                        
                        window.open(result.data.data.url);
                    })
                    .catch(error => {
                        console.log(error)
                        // /* Show Error Message */
                        this.$toast.error(error.response.data.message, {duration: 3000});
                        /* Stop Loading */
                        loading.hide();
                        this.loading = false;
                    })
            } catch (error) {
                /* Show Error Message */
                this.$toast.error(error.response.data.message, {duration: 3000});
                /* Stop Loading */
                loading.hide();
                this.loading = false;
            }
        },

        // /* Ambil Shipment Provider Lzada */
        // async getShipmentProviderLazada() {
        //     this.loading = true;
        //     const loading = this.$loading.show({ container: this.$refs.loadingContainer });

        //     try {
        //         await axios.get(`${API_URL}/api/integration/lazada/get-shipment-provider?user=${this.order.user._id}`, {
        //             headers: {
        //                 'Authorization': this.$store.state.auth.token
        //             },
        //         })
        //             .then(result => {
        //                 /* Stop Loading */
        //                 loading.hide();
        //                 this.loading = false;

        //                 console.log(result.data)
        //                 this.shipmentProviderLazada = result.data.data.shipment_providers;
        //             })
        //             .catch(error => {
        //                 console.log(error)
        //                 /* Show Error Message */
        //                 this.$toast.error(error.response.data.message, {duration: 3000});
        //                 /* Stop Loading */
        //                 loading.hide();
        //                 this.loading = false;
        //             })
        //     } catch (error) {
        //         /* Show Error Message */
        //         this.$toast.error(error.response.data.message, {duration: 3000});
        //         /* Stop Loading */
        //         loading.hide();
        //         this.loading = false;
        //     }
        // },

        formatPrice,
        formatDate,
        formatTime(date) {
            var format = date ? new Date(date).toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' }) : ''
            return format;
        }
    }
}
</script>

<style scoped>

</style>