<template>
    <div class="bg-gray-200 min-h-screen h-auto">
        <NavigationMenu />
        <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-8">
            <!-- Content Area -->

            <!-- Product Information -->
            <div class="bg-white rounded border border-gray-300 p-6 w-full">
                <h2 class="text-xl font-bold font-proxima pb-2 mb-5 border-b border-gray-200">Penarikan Dana</h2>
                
                <div class="mb-3">
                    <label class="font-proxima font-semibold block">Jumlah Penarikan Dana</label>
                    <input v-model="total_settlement" type="number" :max="total_balance" class="w-full bg-gray-50 px-3 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:outline-yellow-500 font-proxima" @input="($event) => $event.target.value > total_balance ? total_settlement = total_balance : total_settlement = $event.target.value" />
                </div>

                <div class="bg-gray-50 p-5 rounded">
                    <div class="w-full flex items-center justify-between mb-3">
                        <h4 class="font-proxima text-sm">Saldo Anda</h4>
                        <h4 class="font-proxima text-sm">Rp{{ formatPrice(total_balance) }}</h4>
                    </div>
                    <div class="w-full flex items-center justify-between">
                        <h4 class="font-proxima text-sm">Minimum Pencairan Dana</h4>
                        <h4 class="font-proxima text-sm">Rp{{ formatPrice(100000) }}</h4>
                    </div>
                </div>

                <div class="mt-8" v-if="total_settlement >= 100000">
                    <z-button @click="createSettlement" class="bg-yellow-500 hover:bg-yellow-600 text-white px-8 py-2 w-full">Request Tarik Dana</z-button>
                </div>
            </div>
            
            <!-- End Content Area -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../../config.js' // Import API URL
import formatPrice from '../../../utils/formatPrice.js'

import NavigationMenu from '@/components/header/NavigationMenu.vue';
// import ZInput from '@/components/common/ZInput.vue';
import ZButton from '@/components/common/ZButton.vue';

export default {
    components: {
        NavigationMenu,
        // ZInput,
        ZButton
    },
    data() {
        return {
            /* 
             * Field Data Product 
             */
            /* Basic Information */
            total_balance: 0,
            total_settlement: 0,
            /* Toggle */
            sidebarOpen: false,
            alertToggle: false,
            loadingToggle: false
        }
    },
    computed: {
        profileData() {
            let profile = this.$store.getters['auth/userProfile'];
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
	},
    async created () {
        /* AUTH - Jika Belum Login arahkan ke Halaman Login */
        try {
            await axios.get(`${API_URL}/api/auth/check-auth?email=${this.$store.state.auth.user.email}&role=${this.$store.state.auth.user.role}`, {
                headers: {
                    'Authorization': this.$store.state.auth.token
                }
            });
        } catch (error) {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/auth/login');
            });
        }

        this.getTotalBalance();
	},
    methods: {
        /*
         * Create Settlement
         */
        async createSettlement () {
            /* Run Loading */
            this.loading = true;
            const loading = this.$loading.show({ container: this.$refs.loadingContainer });

            /* Menyiapkan Data Inputan di Variable form */
            let form = {
                /* Basic Information */
                total_settlement: this.total_settlement
            }

            try {
                await axios.post(API_URL + '/api/settlement/save', form, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });

                /* Stop Loading */
                loading.hide();
                this.loading = false;
                this.$router.push('/finance/settlement/list');
            } catch (error) {
                console.log(error)
                /* Stop Loading */
                loading.hide();
                this.loading = false;

                this.$toast.error("Settlement gagal disimpan!", {duration: 3000});
            }
        },

        /*
		* Get Total Balanace
		*/
		async getTotalBalance() {
            /* Run Loading */
            this.loadingToggle = true;

			let endpoint_url = '';
            if(this.profileData.role === 'RESELLER') {
                endpoint_url = '/api/user/get/' + this.profileData.id;
            }else if(this.profileData.role === 'ADMIN') {
                endpoint_url = '/api/income/get-total-balance-platform';
            }else if(this.profileData.role === 'VENDOR') {
                endpoint_url = '/api/income/get-total-balance-vendor/' + this.profileData.vendor;
            }

			try {
                const response = await axios.get(API_URL + endpoint_url, {
                    headers: {
                        'Authorization': this.$store.state.auth.token
                    }
                });
                if(this.profileData.role === 'RESELLER') {
                    this.total_balance = this.total_balance = response.data.data.balance ? response.data.data.balance : 0;
                }else if(this.profileData.role === 'ADMIN') {
                    this.total_balance = this.total_balance = response.data.data.total_balance ? response.data.data.total_balance : 0;
                }else if(this.profileData.role === 'VENDOR') {
                    this.total_balance = this.total_balance = response.data.data.balance ? response.data.data.balance : 0;
                }

                /* Stop Loading */
                this.loadingToggle = false;
			} catch (error) {
                /* Stop Loading */
                this.loadingToggle = false;

				console.error(error);
			}
		},

        formatPrice
    }
}
</script>

<style scoped>
</style>